// eoc-fetch is already being used in eoc resources, so there is no reason not
// to override the whitelist here.

// eslint-disable-next-line jira/import-whitelist
import { fetch } from '@atlassian/eoc-fetch';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { createResource } from '@atlassian/react-resource-router';
import {
	type TenantInfo,
	type TenantInfoClientResponse,
	type TenantInfoServerResponse,
	ConsolidationState,
} from '../../types';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from './constants';

/**
 * Does the request to the internal OpsGenie gateway if run on the server.
 * On the client, will do a regular request with the cloudid prefix.
 */
export const fetchConsolidationState = (
	cloudId: string,
	appPermissions?: {
		hasServiceDeskAccess: boolean;
	},
) => {
	let url = '/internal/v1/customers/tenant-info';
	if (cloudId) {
		url = `${url}/${cloudId}`;
	} else {
		throw new Error('No tenant info exists.');
	}

	let promise: Promise<TenantInfo>;

	if (appPermissions && appPermissions.hasServiceDeskAccess === false) {
		promise = new Promise((resolve) => {
			resolve({
				consolidationState: ConsolidationState.NOT_CONSOLIDATED,
			});
		});
		return promise;
	}

	if (__SERVER__) {
		promise = fetch<TenantInfoServerResponse>(url, {
			exactUrl: true,
			headers: {
				'x-ssr-service-name': fg('tenant-info-issuer-change')
					? 'og-customer-int-api'
					: 'og-apigw-atlassian-gw',
			},
		});
	} else {
		promise = fetch<TenantInfoClientResponse>('/v1/customers/tenant-info').then(({ data }) => data);
	}

	if (ff('eoc-tenant-info-hook_dq6ke')) {
		return promise;
	}
	return promise.then((response) => response.consolidationState);
};

/**
 * This is SSR-enabled and will do the request on SSR whenever possible.
 * To disable this behaviour, provide the optional parameter as true.
 */
export const getConsolidationStateResource = (forceBrowser = false) =>
	createResource<TenantInfo | ConsolidationState>({
		type: CONSOLIDATION_STATE_RESOURCE_TYPE,
		getKey: () => 'tenantInformation',
		getData: (_, { tenantContext: { cloudId, appPermissions } }) =>
			fetchConsolidationState(cloudId, appPermissions),
		maxAge: Infinity,
		isBrowserOnly: forceBrowser,
	});
