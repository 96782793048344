import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';

const LazyLicenseViolationErrorPage = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-arj-license-violation-page", jiraPriority: 'ondemand' */ './index'
		),
);

export const AsyncLicenseViolationErrorPage = (props: Props) => (
	<Placeholder name="arj-license-violation-page" fallback={<Spinner />}>
		<LazyLicenseViolationErrorPage {...props} />
	</Placeholder>
);
