import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_DEVOPS_REPORT_PRECONDITION } from '../constants';
import type { PreconditionResult } from '../types';

export const devOpsReportPreconditionResource = createResource<PreconditionResult>({
	type: RESOURCE_TYPE_DEVOPS_REPORT_PRECONDITION,
	getKey: ({ query }) => query.projectKey,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-devops-report-precondition", jiraSpaEntry: "async-resource-devops-report-precondition" */ './get-data'
		),
	maxAge: 60 * 1000,
});

export const useDevOpsReportPreconditionResource = () =>
	useResource<PreconditionResult>(devOpsReportPreconditionResource);
