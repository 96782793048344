import { useMemo } from 'react';
import { useAnnouncementBannerSettingsV4Resource } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources/src/controllers/settings/resource/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { NAME, HEIGHT } from '../../common/constants';

export const useBannerAnnouncement = () => {
	const { data } = useAnnouncementBannerSettingsV4Resource();

	const isAnonymous = useIsAnonymous();

	return useMemo(
		() => ({
			isEnabled:
				Boolean(data?.isEnabled) &&
				!data?.isDismissed &&
				((data?.visibility === 'private' && !isAnonymous) || data?.visibility === 'public'),
			name: NAME,
			height: HEIGHT,
		}),
		[data?.isDismissed, data?.isEnabled, data?.visibility, isAnonymous],
	);
};
