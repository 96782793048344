import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "escalations/priority-filter-resource", jiraSpaEntry: "escalations/priority-filter-resource" */ './get-data'
	);

export type PriorityFilterData = {
	maxResults: number;
	startAt: number;
	total: number;
	isLast: boolean;
	values: {
		self: string;
		statusColor: string;
		description: string;
		iconUrl: string;
		name: string;
		id: string;
		isDefault: boolean;
	}[];
};

export const priorityFilterResource = createResource<PriorityFilterData>({
	type: 'ESCALATIONS_PRIORITY_FILTER_RESOURCE',
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
});

export const usePriorityFilterResource = () =>
	useResource<PriorityFilterData>(
		createResource<PriorityFilterData>({
			type: 'ESCALATIONS_PRIORITY_FILTER_RESOURCE',
			getKey: ({ match }) => getProjectKeyOrId(match),
			getDataLoader,
		}),
	);
