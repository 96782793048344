import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { getTaskIndex } from '../common/utils';

export const getNextNonCompleteTask = (
	tasks: string[],
	completed: string[],
	openTask: string | null,
): string | null => {
	const taskPosition = getTaskIndex(tasks, openTask);
	// if the task does not exist(-1), we want to start at 0
	// if it exists we want to start at array index + 1 (taskPosition is already index+1)
	const start = taskPosition === -1 ? 0 : taskPosition;
	const len = tasks.length;

	// This loop:
	// 1) searches for non completed items from the start index to last index of the array
	// 2) searches from 0 until start if nothing was found during 1)
	for (let i = start; i < start + len; i += 1) {
		const ind = i % len;
		if (!completed.includes(tasks[ind])) {
			return tasks[ind];
		}
	}

	// nothing was found there is no task to open
	return null;
};

export const getNextTask = (tasks: string[], openTask: string | null): string | null => {
	const taskPosition = getTaskIndex(tasks, openTask);
	// if the task does not exist(-1), we want to start at 0
	// if it exists we want to start at array index + 1 (taskPosition is already index+1)
	const ind = taskPosition === -1 ? 0 : taskPosition;
	return tasks[ind] ? tasks[ind] : null;
};

const store = createLocalStorageProvider('quickstart');

export const setLocalStorageItem = (key: string, value: string) => {
	store.set(key, value);
};

export const getLocalStorageItem = (key: string) => store.get(key);
