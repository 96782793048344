import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { customFieldsDirectoryResource } from '@atlassian/jira-router-resources-admin-pages-custom-fields-directory/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsCustomFieldsRoute } from '@atlassian/jira-router-routes-global-settings-custom-fields-routes';
import CustomFields, {
	LazyCustomFieldsDirectoryAdmin,
} from './ui/admin-pages/custom-fields-directory';

export const globalSettingsCustomFieldsRouteEntry = createEntry(globalSettingsCustomFieldsRoute, () => ({
    ufoName: 'admin.custom-fields-directory',
    component: CustomFields,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},

    resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		customFieldsDirectoryResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyCustomFieldsDirectoryAdmin]
}));
