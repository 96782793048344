import {
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	ISSUE_ACTION_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_PANEL_MODULE,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
	SOURCE_ISSUE_VIEW,
	UI_MODIFICATIONS_MODULE,
} from '@atlassian/jira-forge-ui-constants';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import fetchForgeModules from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules';
import type { ActivationId, CloudId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ForgeResponse } from '../../types';
import { dedupeContextAndGlance } from './utils';

/**
 * Takes an array of arrays and uses the type to find the appropriate array to return. This is so we can ensure
 * that when constructing the forge response only the correct type is put into the forge response.
 *
 * It is not expected that Extension[] will contain mixed types, however Extension[][] will.
 *
 * @param result The array of arrays that contain extensions returned from the fetch forge API
 * @param type The type of the ExtensionPointModule
 */
export const getExtensions = <ExtensionModuleType extends ExtensionPointModule>(
	result: Extension[][],
	type: ExtensionModuleType,
) =>
	result.find((extensions): extensions is Extract<Extension, { type: ExtensionModuleType }>[] =>
		extensions.some((extension) => extension.type === type),
	) || [];

export const fetchIssueForgeData = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	activationId: ActivationId,
	locale?: string,
): Promise<ForgeResponse> => {
	const modulesToFetch: ExtensionPointModule[] = [
		ISSUE_ACTION_MODULE,
		ISSUE_GLANCE_MODULE,
		ISSUE_CONTEXT_MODULE,
		ISSUE_PANEL_MODULE,
		ISSUE_ACTIVITY_MODULE,
		CUSTOM_FIELD_MODULE,
		CUSTOM_FIELD_TYPE_MODULE,
		ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
		UI_MODIFICATIONS_MODULE,
	];

	const contextIds = [`ari:cloud:jira:${cloudId}:workspace/${activationId}`];

	const result = await fetchForgeModules(
		cloudId,
		modulesToFetch,
		{ issueKey },
		{ include: { scopes: true } },
		SOURCE_ISSUE_VIEW,
		contextIds,
		locale,
	);

	const { extensions, accessNarrowedExtensions } = result;

	const mappedResult = {
		issueAction: extensions.jiraIssueAction || [],
		issueGlance: extensions.jiraIssueGlance || [],
		issueContext: extensions.jiraIssueContext || [],
		issuePanel: extensions.jiraIssuePanel || [],
		issueActivity: extensions.jiraIssueActivity || [],
		customField: extensions.jiraCustomField || [],
		customFieldType: extensions.jiraCustomFieldType || [],
		issueViewBackgroundScript: extensions.jiraIssueViewBackgroundScript || [],
		uiModifications: extensions.jiraUiModifications || [],
		blockedExtensions: {
			issueAction: accessNarrowedExtensions.jiraIssueAction || [],
			issueGlance: accessNarrowedExtensions.jiraIssueGlance || [],
			issueContext: accessNarrowedExtensions.jiraIssueContext || [],
			issuePanel: accessNarrowedExtensions.jiraIssuePanel || [],
			issueActivity: accessNarrowedExtensions.jiraIssueActivity || [],
			customField: accessNarrowedExtensions.jiraCustomField || [],
			customFieldType: accessNarrowedExtensions.jiraCustomFieldType || [],
			issueViewBackgroundScript: accessNarrowedExtensions.jiraIssueViewBackgroundScript || [],
			uiModifications: accessNarrowedExtensions.jiraUiModifications || [],
		},
	};

	return dedupeContextAndGlance(mappedResult);
};
