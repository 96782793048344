import React, { useMemo } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import Lozenge from '@atlaskit/lozenge';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { useFeatures } from '../../../controllers/features';
import messages from './messages';

const BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY = 'blocking-invitations-log-spotlight';
/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const AdminHelperSection = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	return (
		<Section title={formatMessage(messages.adminHelper)}>
			{features.hasPermissionHelper && (
				<MenuItem
					analytics={{ itemId: 'permission-helper' }}
					href={navigationPath.adminHelperPermissionHelper}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.permissionHelper)}
				</MenuItem>
			)}
			{features.hasNotificationHelper && (
				<MenuItem
					analytics={{ itemId: 'notification-helper' }}
					href={navigationPath.adminHelperNotificationHelper}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.notificationHelper)}
				</MenuItem>
			)}
			{features.hasEmailNotificationsLogHelper && (
				<SpotlightTarget name={BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY}>
					<MenuItem
						analytics={{ itemId: 'email-notifications-log' }}
						href={navigationPath.adminHelperEmailNotificationsLogHelper}
						selectedOn={matchUrlPathname()}
					>
						<Box xcss={emailNotificationsLogContainerStyles}>
							{ff('platform.jlove-pulsar.jlovep-1538-notification-email-log-filters_4p0s1')
								? formatMessage(messages.adminEmailAuditNotificationsLog)
								: formatMessage(messages.emailNotificationsLog)}

							{!ff(
								'platform.jlove-pulsar.jlovep-1472-remove-unblock-user-invites-new-lozenge_7ig5k',
							) && <Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>}
						</Box>
					</MenuItem>
					<EngagementSpotlight engagementId={BLOCKING_INVITATIONS_LOG_SPOTLIGHT_KEY} />
				</SpotlightTarget>
			)}
		</Section>
	);
};

const emailNotificationsLogContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
});
