import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_RAPIDBOARDS_WRM_INITIAL_ALL_DATA } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialAllDataResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_WRM_INITIAL_ALL_DATA,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-initial-all-data", jiraSpaEntry: "async-resource-rapidboards-initial-all-data" */ './get-data'
		),
	maxAge: Number.MAX_VALUE,
	isBrowserOnly: true,
});
