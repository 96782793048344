import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import getData from './get-data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueForgeDataResource = createResource<any>({
	type: 'ISSUE_FORGE_DATA',
	getKey: ({ match }) => {
		const issueKey = getIssueKey(match);
		return issueKey || '';
	},
	getData: (...args) => getData(...args),
	isBrowserOnly: true,
});
