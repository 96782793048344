import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareFormSubmitRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_FORM_SUBMIT,
	path: '/jira/software/projects/:projectKey/form/:formId',
	exact: true,
};
