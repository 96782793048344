import React from 'react';
import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { versionDetailsRelayResourceCustom } from '@atlassian/jira-router-resources-software-versions/src/controllers/index.tsx';
import { softwareVersionDetailRoute } from '@atlassian/jira-router-routes-software-versions-routes';
import { VersionDetailSkeleton } from '@atlassian/jira-skeletons/src/ui/versions/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout';
import { Redirect, type RouteContext } from '@atlassian/react-resource-router';
import { VersionDetailPage, LazyVersionDetail } from './ui/version-detail';

const VersionDetailPageLegacyRedirect = ({
	match: {
		params: { projectKey, versionId, tab },
	},
}: RouteContext) => {
	// This should never happen as if it wasn't matched, this won't be rendered unless someone re-used this component in a wrong route.
	if (projectKey == null || versionId == null) {
		throw Error('projectKey or versionId not found in match object of RouteContext');
	}
	return (
		<Redirect
			to={`/projects/${projectKey}/versions/${versionId}/tab/${
				tab != null ? tab : 'release-report-all-issues'
			}`}
		/>
	);
};

export const softwareVersionDetailRouteEntry = createEntry(softwareVersionDetailRoute, () => ({
    component: VersionDetailPage,
    skeleton: VersionDetailSkeleton,
    canTransitionIn: () => true,
    layout: softwareProjectLayoutNoOnboarding,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		versionDetailsRelayResourceCustom,
		projectContextResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyVersionDetail],

    meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_VERSION_DETAIL),
			teamName: 'fusion-solaris',
		},
	}
}));

export const softwareVersionDetailNoTabPathRedirectRouteEntry = createEntry(
	{
		group: ROUTE_GROUPS_SOFTWARE,
		name: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,
		path: '/projects/:projectKey/versions/:versionId',
	},
	() => ({
        component: VersionDetailPageLegacyRedirect,
        canTransitionIn: () => true,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [...getNavigationResources()],
        forPaint: [LazyAtlassianNavigation, LazyVersionDetail]
    }),
);

export const softwareVersionDetailLegacyRedirectRouteEntry = createEntry(
	{
		group: ROUTE_GROUPS_SOFTWARE,
		name: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_LEGACY_REDIRECT,
		path: '/browse/:projectKey/fixforversion/:versionId',
	},
	() => ({
        component: VersionDetailPageLegacyRedirect,
        canTransitionIn: () => true,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [...getNavigationResources()],
        forPaint: [LazyAtlassianNavigation, LazyVersionDetail]
    }),
);
