import { useState, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const fetchReleaseVersion = () => performGetRequest('/rest/migration/c2c_rollout_phase');

export const useReleaseVersion = () => {
	const [loading, setLoading] = useState(false);
	const [release, setRelease] = useState('GA');
	const [error, setError] = useState();

	const fetch = async () => {
		try {
			setLoading(true);
			setError(undefined);
			const data = await fetchReleaseVersion();
			setRelease(data.release);
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (apiError: any) {
			setLoading(false);
			setError(apiError);
			fireErrorAnalytics({
				error: apiError,
				meta: {
					id: 'useReleaseVersion',
					packageName: 'jiraNavigationAppsSidebarGlobalSettingsSystem',
					teamName: 'navigation',
				},
			});
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return { loading, release, error };
};
