import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_PROJECT_PERMISSIONS } from '../../constants';
import type { ProjectPermissions } from './types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-project-permissions", jiraSpaEntry: "async-resource-project-permissions" */ './get-data'
	);

export const projectCreatePermissionsResource = createResource<ProjectPermissions | null>({
	type: RESOURCE_TYPE_PROJECT_PERMISSIONS,
	getKey: () => 'state',
	getDataLoader,
	maxAge: Infinity,
});

export const useProjectCreatePermissionsResource = () =>
	useResource<ProjectPermissions | null>(projectCreatePermissionsResource);
