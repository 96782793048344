import { useEffect, useMemo } from 'react';
import type { DataState } from '@atlassian/jira-common-services/src/types';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_SERVICEDESK_IS_ITSM_PROJECT } from '../constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-is-itsm-project", jiraSpaEntry: "async-resource-servicedesk-is-itsm-project" */ './get-data'
	);

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export const servicedeskIsItsmProjectResource = createResource<boolean>({
	type: RESOURCE_TYPE_SERVICEDESK_IS_ITSM_PROJECT,
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

const useIsItsmTemplateProjectNew = (projectKey?: string): DataState<boolean> => {
	const { data, loading, error } = useResourceWithCustomRouterContext(
		servicedeskIsItsmProjectResource,
		projectKey ? { matchParams: { projectKey } } : undefined,
		{
			shouldPrefetch: true,
		},
	);

	return useMemo(
		() => ({
			data,
			loading,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		}),
		[data, loading, error],
	);
};

const useIsItsmTemplateProjectOld = (_?: string): DataState<boolean> => {
	const { data, loading, error, refresh } = useResource(servicedeskIsItsmProjectResource);
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if ((data === null || data === undefined) && !loading && !(error as Error)) {
			refresh();
		}
	}, [data, loading, error, refresh]);

	return useMemo(
		() => ({
			data,
			loading,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		}),
		[data, loading, error],
	);
};

export const useIsItsmTemplateProject = functionWithCondition(
	() => fg('fix_useinitresource_data_bug'),
	useIsItsmTemplateProjectNew,
	useIsItsmTemplateProjectOld,
);
