import log from '@atlassian/jira-common-util-logging/src/log';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { trackBrowserMetricsForJira } from '@atlassian/jira-insights-experience-tracker/src/common/utils/metrics.tsx';
import { getStatusCodeGroup } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/utils/analytics.tsx';
import { analyticsStatuses } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/utils/constants.tsx';
import type { GenericSettings } from '@atlassian/jira-insights-settings/src/common/types';
import { withDefaults } from '@atlassian/jira-insights-settings/src/common/utils/with-default';
import { fetchInsightsSettings } from '@atlassian/jira-insights-settings/src/services/insights-settings';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
// eslint-disable-next-line jira/restricted/@atlassian/react-resource-router
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE,
	KEY_PREFIX,
	ANALYTICS_SOURCE,
	insightsSettingsLoaderAnalytics,
	FIVE_MINS_IN_MILLS,
} from './constants';

export const insightsBoardSettingsResource = createResource<GenericSettings>({
	type: RESOURCE_TYPE,
	maxAge: FIVE_MINS_IN_MILLS,
	getKey: ({ match }): string => `${KEY_PREFIX}-${String(match.params.boardId)}`,
	getData: async ({ match }) => {
		const boardId = Number(match.params.boardId);
		if (!Number.isInteger(boardId)) {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return new Promise(() => {});
		}

		const webAnalyticsClient = await getAnalyticsWebClientPromise();
		const actionSubject = `${insightsSettingsLoaderAnalytics.ACTION_SUBJECT}.${insightsSettingsLoaderAnalytics.ACTION_SUBJECT_ID}`;
		const analyticsClient = webAnalyticsClient.getInstance();
		if (!analyticsClient) {
			return fetchInsightsSettings({ boardId }).then((data) =>
				withDefaults(data.context.board.settings),
			);
		}

		analyticsClient.sendOperationalEvent({
			action: 'dataFetch',
			actionSubject,
			source: ANALYTICS_SOURCE,
			attributes: {
				status: analyticsStatuses.STARTED,
				boardId,
			},
		});
		const metricsTracker = trackBrowserMetricsForJira({ browserMetricsKey: actionSubject });
		try {
			metricsTracker.start();

			const data = await fetchInsightsSettings({ boardId });
			analyticsClient.sendOperationalEvent({
				action: 'dataFetch',
				actionSubject,
				source: ANALYTICS_SOURCE,
				attributes: {
					statusCodeGroup: '2xx',
					status: analyticsStatuses.SUCCEEDED,
					boardId,
				},
			});
			metricsTracker.end();
			return Promise.resolve(withDefaults(data.context.board.settings));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			metricsTracker.cancel();

			if (!isClientFetchError(error)) {
				analyticsClient.sendOperationalEvent({
					action: 'dataFetch',
					actionSubject,
					source: ANALYTICS_SOURCE,
					attributes: {
						statusCodeGroup: getStatusCodeGroup(
							error instanceof FetchError ? error.statusCode : -1,
						),
						status: analyticsStatuses.FAILED,
						boardId,
					},
				});
				log.safeErrorWithoutCustomerData(
					actionSubject,
					'Error fetching insights settings data',
					error,
				);
				throw error;
			}

			fireErrorAnalytics({
				meta: {
					id: actionSubject,
					packageName: 'jiraBoardSettingsInsightsRoute',
					teamName: 'helios-headcoach',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			throw error;
		}
	},
});
