import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_AUTOMATION_IS_LEGACY_ENABLED } from './constants';

export const navigationSidebarAutomationLegacyEnabledResource = createResource<{
	enabled: boolean;
}>({
	type: RESOURCE_TYPE_AUTOMATION_IS_LEGACY_ENABLED,
	getKey: ({ query }) => `jsm-legacy-automation-enabled-${query.projectKey}`,
	getData: async (_, { tenantContext: { licensedProducts } }) => {
		const defaultValue = { enabled: true };
		if (!licensedProducts || !licensedProducts[LICENSED_PRODUCTS.JIRA_SERVICEDESK]) {
			return defaultValue;
		}
		const { enabled } = await performGetRequest('/rest/servicedesk/automation/1/legacy/state');
		return { enabled };
	},
	maxAge: Infinity,
});
