import isEqual from 'lodash/isEqual';
import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route, Match } from '@atlassian/react-resource-router';
import { calcRapidBoardResetStateV2, getApdexMatchObject, isValidRoute } from './utils';

type CheckResetPageProps = {
	currentMatch: Match;
	currentRoute: Route;
	nextMatch: Match;
	nextRoute: Route;
	action: 'PUSH' | 'REPLACE' | 'POP';
};
const isReplaceActionAndPIN = (action: 'PUSH' | 'REPLACE' | 'POP', route: Route): boolean =>
	action === 'REPLACE' && route.group === ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR;
/**
 * Determines whether a page should reset upon navigation, factoring in specific conditions
 * related to route changes and user actions. This function aims to enhance user experience
 * by intelligently refreshing page content when necessary.
 */
export const createCheckShouldResetPage = () => {
	let rapidBoardPaths: Array<string> = [];
	return ({ currentMatch, currentRoute, nextMatch, nextRoute, action }: CheckResetPageProps) => {
		let shouldReset = true;
		if (isReplaceActionAndPIN(action, nextRoute) || currentRoute.isRedirect === true) {
			shouldReset = false;
		} else {
			const cleanMatch = getApdexMatchObject(currentMatch, currentRoute);
			const cleanNextMatch = getApdexMatchObject(nextMatch, nextRoute);
			shouldReset = !isEqual(cleanMatch, cleanNextMatch) && shouldReset;
			if (shouldReset) {
				// RapidboardPaths is being persisted here.
				// resetDone boolean is calculated in the util function.
				const [paths, resetBoolean] = calcRapidBoardResetStateV2(
					rapidBoardPaths,
					nextRoute.name,
					!!nextMatch.query.useStoredSettings,
				);
				rapidBoardPaths = paths;
				if (isValidRoute(nextRoute) || nextRoute.group === ROUTE_GROUPS_CLASSIC_SOFTWARE) {
					shouldReset = !resetBoolean;
				}
			}
		}
		return shouldReset;
	};
};
