/** @jsx jsx */
import type { ReactNode } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import {
	InnerWrapper,
	InnerWrapperWithBackground,
	OuterWrapper,
	OuterWrapperWithBackground,
} from './styled';

const styles = cssMap({
	// Remove when cleaning up 'jwm_fix_popup_broken_with_custom_background'
	rootOld: {
		position: 'relative',
	},
	root: {
		position: 'relative',
		// Explicitly setting z-index here to ensure elements in the header render over content
		zIndex: 0,
	},
	fullHeight: {
		minHeight: 0,
		height: '100%',
	},
	insetRoot: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: Tokens.WRAPPER_PADDING,
	},
	insetRootVisualRefresh: {
		paddingTop: token('space.250'),
	},
	insetContainer: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: Tokens.WRAPPER_BORDER_RADIUS,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: Tokens.WRAPPER_BACKGROUND,
	},
});

/**
 * This component sets a fluid layout that works with the new page
 * layout created in @atlassian/navigation-system. It does not use
 * explicit sizes set from CSS variables but grows to the space given.
 */
export const ThemedContentLayout = ({
	children,
	appearance,
}: {
	appearance: 'default' | 'inset';
	children: ReactNode;
}) => (
	<div
		css={[
			fg('jwm_fix_popup_broken_with_custom_background') && styles.root,
			!fg('jwm_fix_popup_broken_with_custom_background') && styles.rootOld,
			styles.fullHeight,
			appearance === 'inset' && styles.insetRoot,
			isVisualRefreshEnabled() && appearance === 'inset' && styles.insetRootVisualRefresh,
		]}
	>
		<div css={[styles.fullHeight, appearance === 'inset' && styles.insetContainer]}>{children}</div>
	</div>
);

export const BusinessWrapper = ({
	withWhiteBackground,
	children,
}: {
	withWhiteBackground: boolean;
	children: ReactNode;
}) => {
	if (withWhiteBackground) {
		return (
			<OuterWrapperWithBackground>
				<InnerWrapperWithBackground>{children}</InnerWrapperWithBackground>
			</OuterWrapperWithBackground>
		);
	}

	return (
		<OuterWrapper>
			<InnerWrapper>{children}</InnerWrapper>
		</OuterWrapper>
	);
};
