import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4UserOptOutForDogfooding } from './get-will-show-nav4-user-opt-out-for-dogfooding';
import { getWillShowNav4UserOptOutForVisualRefresh } from './get-will-show-nav4-user-opt-out-for-visual-refresh';

// We don't inline usage of feature flags/gates as our logic to show or no show will become complex at times and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design+draft#Decision-points
// All changes to this function should be replicated in the backend (see readme.md).
export const getWillShowNav4UserOptOut = () =>
	// eslint-disable-next-line jira/ff/no-preconditioning
	(fg('jira_nav4') && (getWillShowNav4UserOptOutForDogfooding() || fg('jira_nav4_eap'))) ||
	// User opt out is being shared with visual refresh. It should show if visual refresh is on regardless of nav4.
	getWillShowNav4UserOptOutForVisualRefresh();
