import React from 'react';
import {
	SpotlightTarget as SpotlightTargetOriginal,
	// @ts-expect-error - TS2305 - Module '"@atlaskit/onboarding"' has no exported member 'SpotlightTargetProps'.
	type SpotlightTargetProps,
} from '@atlaskit/onboarding';
import { withNestingOptimisation } from '../with-nesting-optimisation';

const SpotlightTargetWrapper = ({ children, ...props }: SpotlightTargetProps) => (
	<SpotlightTargetOriginal {...props}>
		{/*
		 * The atlaskit spotlight target component merely registers a ref on the child component,
		 *  so there is no way to give it a test id. We want to keep the test id as closely coupled
		 *  with the target as possible, so we add a span with id here, rather than on the child
		 *  where it could become more easily uncoupled.
		 */}
		<span data-test-id={`ak-spotlight-target-${props.name}`}>{children}</span>
	</SpotlightTargetOriginal>
);

export const SpotlightTarget =
	withNestingOptimisation<SpotlightTargetProps>(SpotlightTargetWrapper);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SpotlightTargetProps };
