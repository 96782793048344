import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_FORGE_FIELD_CONTEXTUAL_CONFIG } from './constants';
import type { ResourceDataType } from './controllers/get-data';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ResourceDataType };

export const fieldContextConfigPageResource = createResource<ResourceDataType>({
	type: RESOURCE_TYPE_FORGE_FIELD_CONTEXTUAL_CONFIG,
	getKey: () => 'data',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-forge-field-context-config-resource", jiraSpaEntry: "async-forge-field-context-config-resource" */ './controllers/get-data'
		),
	maxAge: 5 * 1000,
});
