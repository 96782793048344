import React from 'react';
import { SpotlightManager } from '@atlaskit/onboarding';
import { CrossFlowProvider } from '@atlassian/jira-cross-flow/src/services/index.tsx';
import { FavoriteChangeProvider } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider';
import { PostOfficeProvider } from '@atlassian/jira-post-office-provider';
import { ProjectCreateDrawerProvider } from '@atlassian/jira-project-create-drawer-context';
import type { ClientUiManagersProps } from './types';

// TBD, need to update this component to use the new sidebar state.
export const ClientUiManagers = ({ children }: ClientUiManagersProps) => {
	const { showFlag } = useFlagService();

	return (
		<CrossFlowProvider>
			<PostOfficeProvider>
				<ProjectCreateDrawerProvider>
					<FavoriteChangeProvider onShowFlag={showFlag}>
						<SpotlightManager>
							<ModalContextProvider>{children}</ModalContextProvider>
						</SpotlightManager>
					</FavoriteChangeProvider>
				</ProjectCreateDrawerProvider>
			</PostOfficeProvider>
		</CrossFlowProvider>
	);
};
