import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsIssuesNewIssueSearchRoute } from '@atlassian/jira-router-routes-admin-pages-new-issue-features-routes';
import type { Route } from '@atlassian/react-resource-router';
import NewIssueSearchTransition, {
	LazyNewIssueSearchTransition,
} from './ui/spa/admin-pages/new-issue-features/new-issue-search-transition';

export const newIssueFeaturesRouteEntries: Route[] = [
	createEntry(globalSettingsIssuesNewIssueSearchRoute, () => ({
        ufoName: 'admin.new-issue-search-transition',
        component: NewIssueSearchTransition,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyNewIssueSearchTransition],
        canTransitionIn: () => true
    })),
];
