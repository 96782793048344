import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/sidebar-boundary';
import { SidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/ui/sidebar-content';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/ui/skeleton';
import Placeholder from '@atlassian/jira-placeholder';

const LazySidebarContent = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-global-settings-issues-settings" */ './main'
		),
);

const AsyncSidebar = () => (
	<SidebarBoundary
		id="nav4.sidebar.global-settings.issues-settings"
		packageName="jiraNavigationAppsSidebarNav4GlobalSettingsIssuesSettings"
	>
		<SidebarContent currentPageName="issues">
			<Placeholder name="nav4.sidebar.global-settings.issues-settings" fallback={<Skeleton />}>
				<LazySidebarContent />
			</Placeholder>
		</SidebarContent>
	</SidebarBoundary>
);

export default memo(AsyncSidebar);
