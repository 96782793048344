import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type { TeamDashboardSidebar as TeamDashboardSidebarType } from './ui/index.tsx';

export const LazyTeamDashboardSidebar = lazyForPaint<typeof TeamDashboardSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-team-dashboard" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

const TeamDashboardSidebar = () => (
	<SidebarBoundary id="eocTeamDashboard">
		<LazyTeamDashboardSidebar />
	</SidebarBoundary>
);

TeamDashboardSidebar.displayName = 'TeamDashboardSidebar';

export default TeamDashboardSidebar;
