import React, { useState } from 'react';
import { EntryPointContainer } from 'react-relay';
import { topNavigationEntryPoint } from '@atlassian/jira-atlassian-navigation/entrypoint';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { JiraAtlassianNavigationSkeleton as TopNavigationSkeleton } from '@atlassian/jira-atlassian-navigation/src/ui/skeleton';
import { JS_EVAL_START } from '@atlassian/jira-common-constants/src/analytics-marks';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout';
import { TOP_NAVIGATION } from '@atlassian/jira-entry-point-page-layout-fields';
import { EntryPointPlaceholder } from '@atlassian/jira-entry-point-placeholder';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { stopInitialPageLoadTimingFromPerformanceMarkStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
/**
 * Manages the rendering of the navigation component by conditionally displaying either a placeholder
 * or the actual navigation content. This decision is based on the current routing context and
 * the availability of navigation entry points, aiming to enhance the user experience through
 * efficient loading strategies.
 */
export const AsyncAtlassianNavigation = () => {
	const isUsingEntryPoint = Boolean(useCurrentRoute().entryPoint?.());
	const topNavEntryPointReference =
		usePageLayoutEntryPoint<typeof topNavigationEntryPoint>(TOP_NAVIGATION);
	useState(() => {
		if (!__SERVER__ && isUsingEntryPoint) {
			/* @ts-expect-error - TS2339 - We're using JSResourceReference from @atlassian/react-async. */
			topNavigationEntryPoint.root.onComplete(() => {
				stopInitialPageLoadTimingFromPerformanceMarkStart(
					'navigation-primary.bundle-ready',
					'jira-spa.js:eval-stop',
				);
				const resources = performance.getEntriesByType('mark');
				for (let i = resources.length - 1; i >= 0; i -= 1) {
					if (
						(resources[i].name.startsWith('async-navigation') ||
							resources[i].name.startsWith('async-atlassian-navigation')) &&
						resources[i].name.endsWith(JS_EVAL_START)
					) {
						stopInitialPageLoadTimingFromPerformanceMarkStart(
							'navigation-primary.eval',
							resources[i].name,
						);
						break;
					}
				}
			});
		}
		return undefined;
	});
	return isUsingEntryPoint ? (
		// There is already a Placeholder in parent
		// remove this one when EntryPointPlaceholder merges with Placeholder
		<EntryPointPlaceholder
			name="top_navigation_skeleton"
			fallback={<TopNavigationSkeleton />}
			/* @ts-expect-error - TS2322 - We're using JSResourceReference from @atlassian/react-async. */
			entryPoint={topNavigationEntryPoint}
		>
			{topNavEntryPointReference ? (
				<EntryPointContainer
					entryPointReference={topNavEntryPointReference}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					props={{} as never}
				/>
			) : null}
		</EntryPointPlaceholder>
	) : (
		<LazyAtlassianNavigation />
	);
};
