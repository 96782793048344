import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_ROADMAP } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nextGenRoadmapResource = createResource<any>({
	type: RESOURCE_TYPE_ROADMAP,
	getKey: ({ match }) => match.params.boardId || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-roadmap", jiraSpaEntry: "async-resource-roadmap" */ './get-data'
		),
	// SSR doesn't care about this and we can't check FFs here because its not in the render path
	maxAge: 1000 * 60 * 60, // 1 hour max age
	isBrowserOnly: true,
});
