import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { notificationLogsRoute } from '@atlassian/jira-router-routes-servicedesk-notification-logs-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import { NotificationLogs, LazyNotificationLogs } from './ui';

export const notificationLogsRouteEntry: Route = createEntry(notificationLogsRoute, () => ({
    layout: serviceProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},

    resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],

    component: NotificationLogs,
    forPaint: [LazyAtlassianNavigation, LazyNotificationLogs],
    perfMetricKey: 'jsm-notification-logs-component-load'
}));
