import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ISSUE_VIEW } from '@atlassian/jira-capabilities/src/constants.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { fullPageIssuePreloadLayout } from '@atlassian/jira-issue-preload-layout';
import { smartRepliesOptInStatusResources } from '@atlassian/jira-issue-smart-replies-preferences/src/index.tsx';
import { EmbedModeProvider } from '@atlassian/jira-issue-view-embed-mode/src/services/provider/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import {
	useProjectKey,
	useProjectType,
	useProjectId,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { issueViewRelayResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-agg-data/index.tsx';
import { issueForgeDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-forge-data/index.tsx';
import { issueGiraDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-data/index.tsx';
import { issueGiraNonCriticalDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-non-critical-data/index.tsx';
import { issueMediaReadPermissionResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-read-permissions/index.tsx';
import { issueMediaUploadPermissionResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-upload-permissions/index.tsx';
import { issueMediaUserAuthResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-user-auth/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
	getModernIssueTransitionResources,
} from '@atlassian/jira-router-resources-navigation';
import { getOfferingCatalogueFeaturesResource } from '@atlassian/jira-router-resources-offering-catalogue-features';
import { queuesNavigationItems as servicedeskNavigationItemsResource } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	browseIssueRoute,
	browseIssueEmbedRoute,
} from '@atlassian/jira-router-routes-issue-routes';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/index.tsx';
import type IssuePageType from '@atlassian/jira-spa-apps-issue';
import { markAsBentoBundleReady } from '@atlassian/jira-spa/src/services/mark-bento-bundle-as-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { Redirect, usePathParam } from '@atlassian/react-resource-router';

const pageId = 'spa-issue';

const conditionalIssueGiraNonCriticalDataResource = resourceWithCondition2(
	() => ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w'),
	issueGiraNonCriticalDataResource,
);

const conditionalSmartRepliesOptInStatusResources = resourceWithCondition2(
	() => fg('smart_replies_m1_jira_labs'),
	smartRepliesOptInStatusResources,
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyIssuePage = lazyForPaint<typeof IssuePageType>(
	() =>
		markAsBentoBundleReady(
			import(
				/* webpackChunkName: "async-issue-app--full-page", jiraSpaEntry: "spa-issue" */ '@atlassian/jira-spa-apps-issue'
			),
		),
	{ ssr: false },
);

export const IssueComponent = () => {
	const { data } = useProjectContext();
	const [issueKey] = usePathParam('issueKey');

	const [projectKey, issueSuffix] = issueKey !== undefined ? issueKey.split('-') : [];
	let actualIssueKey = issueKey;
	if (data?.projectKey && data.projectKey !== projectKey) {
		actualIssueKey = `${data.projectKey}-${issueSuffix}`;
	}

	// special handling for product discovery (=== Polaris) project issues. During the experimental phase of
	// the polaris idea view (product-specific issue view), we will redirect to a polaris-specific issue view
	// page here instead of bento.
	// this will only happen for issues of this specific project type, and only if the switchover FF is enabled
	if (
		!__SERVER__ &&
		data?.projectType === PRODUCT_DISCOVERY_PROJECT &&
		data?.projectKey !== undefined &&
		actualIssueKey !== undefined
	) {
		return (
			<Redirect
				to={`/jira/polaris/projects/${data.projectKey}/ideas?selectedIssue=${actualIssueKey}`}
			/>
		);
	}

	return <LazyPage Page={LazyIssuePage} pageId={pageId} shouldShowSpinner={false} />;
};

export const IssueEmbedComponent = () => {
	const [issueKey] = usePathParam('issueKey');
	const projectKey = useProjectKey(issueKey || '');
	const projectType = useProjectType(projectKey);
	const projectId = useProjectId(projectKey);

	// Special handling for product discovery (=== Polaris) project issues in embed mode.
	// We have to redirect to Polaris issue embed view instead of loading Jira issue view.
	if (
		projectType === PRODUCT_DISCOVERY_PROJECT &&
		projectKey !== undefined &&
		projectId !== undefined &&
		issueKey !== undefined
	) {
		return (
			<Redirect
				to={`/secure/JiraProductDiscoveryEmbedded.jspa?projectId=${projectId}&projectKey=${projectKey}&issueKey=${issueKey}`}
			/>
		);
	}

	return (
		<EmbedModeProvider>
			<IssueComponent />
		</EmbedModeProvider>
	);
};

export const browseIssueRouteEntry = createEntry(browseIssueRoute, () => ({
    component: IssueComponent,
    skeleton: IssueViewSkeleton,
    layout: composeLayouts(genericProjectLayout, fullPageIssuePreloadLayout),

    navigation: {
		sidebar: genericProjectSidebars,
		menuId: MENU_ID.PROJECTS,
		horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	},

    resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getOfferingCatalogueFeaturesResource(),
		issueMediaReadPermissionResource,
		issueMediaUserAuthResource,
		issueGiraDataResource,
		conditionalIssueGiraNonCriticalDataResource,
		issueMediaUploadPermissionResource,
		issueForgeDataResource,
		issueViewRelayResource,
		servicedeskNavigationItemsResource,
		workspaceResource,
		getConsolidationStateResource(),
		conditionalSmartRepliesOptInStatusResources,
	],

    forPaint: [LazyAtlassianNavigation, LazyIssuePage],
    ufoName: 'issue-view',

    meta: {
		capability: ISSUE_VIEW,
	},

    bifrost: {
		bundling: {
			packageName: '@atlassian/jira-router-routes-issue-entries',
			whichExportToUse: 'browseIssueRouteEntry',
		},
		enabled: true,
	}
}));

export const browseIssueEmbedRouteEntry = createEntry(browseIssueEmbedRoute, () => ({
    component: IssueEmbedComponent,
    skeleton: IssueViewSkeleton,
    layout: chromelessLayout,

    resources: [
		...getBusinessProjectResources(),
		...getModernIssueTransitionResources(),
		issueMediaReadPermissionResource,
		issueMediaUserAuthResource,
		issueGiraDataResource,
		conditionalIssueGiraNonCriticalDataResource,
		issueMediaUploadPermissionResource,
		issueForgeDataResource,
		issueViewRelayResource,
		servicedeskNavigationItemsResource,
		workspaceResource,
		themePreferenceResource,
		conditionalSmartRepliesOptInStatusResources,
	],

    forPaint: [LazyIssuePage],
    ufoName: 'issue-view-embed',

    meta: {
		capability: ISSUE_VIEW,
	},

    bifrost: {
		bundling: {
			packageName: '@atlassian/jira-router-routes-issue-entries',
			whichExportToUse: 'browseIssueEmbedRouteEntry',
		},
		enabled: true,
	}
}));
