import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';
import pageIdGenerator from './page-id-generator';

export const LazyRequests = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-requests-page", jiraSpaEntry: "requests" */ '@atlassian/jira-software-requests-page'
		),
	),
);

export const LazyNextGenRequestsPage = ({ match, query }: RouteContext) => (
	<LazyPage
		Page={LazyRequests}
		pageId={pageIdGenerator('next-gen-requests', match, query)}
		shouldShowSpinner={false}
	/>
);

export const LazyClassicRequestsPage = ({ match, query }: RouteContext) => (
	<LazyPage
		Page={LazyRequests}
		pageId={pageIdGenerator('classic-requests', match, query)}
		shouldShowSpinner={false}
	/>
);
