import { useMemo } from 'react';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import {
	isExtensionHiddenByAppAccessRules,
	isExtensionVisible,
} from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import {
	forgeProjectModuleV1Resource,
	forgeProjectModuleV2Resource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { useForgeItemStack } from '../../common/utils/find-item-stack/index.tsx';
import type { UseNavigationResourceReturnWithStackAndAN } from '../types';

const useProjectForgeAppsV1 = (
	routeName?: string,
	projectKey?: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> => {
	const { data: projectContext } = useProjectContext(
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);
	const { data, loading, error } = useResourceWithCustomRouterContext(
		forgeProjectModuleV1Resource,
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);
	const projectData = useMemo(() => data?.extensions || null, [data]);
	const accessNarrowedData = useMemo(() => data?.accessNarrowedExtensions || null, [data]);
	const stack = useForgeItemStack(projectData, routeName);

	// Instead of dumping projectContext as is we'd like to hardcode the context
	// to be sure that correct prop are exposed externally
	const context = useMemo(
		() =>
			projectContext
				? {
						projectKey: projectContext.projectKey,
						projectId: projectContext.projectId,
						projectName: projectContext.projectName,
						projectType: projectContext.projectType,
						isProjectAdmin: projectContext.isProjectAdmin,
						isProjectArchived: projectContext.isProjectArchived,
					}
				: {},
		[projectContext],
	);

	return useMemo<UseNavigationResourceReturnWithStackAndAN<ProjectPage[]>>(
		() => ({
			data: projectData
				? projectData.filter((extension) => doesExtensionMeetConditions(extension, context))
				: null,
			accessNarrowedData,
			loading,
			error,
			stack,
		}),
		[projectData, accessNarrowedData, loading, error, stack, context],
	);
};

const useProjectForgeAppsV2 = (
	routeName?: string,
	projectKey?: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> => {
	const { data, loading, error } = useResourceWithCustomRouterContext(
		forgeProjectModuleV2Resource,
		projectKey
			? {
					matchParams: { projectKey },
				}
			: undefined,
	);
	const projectData = useMemo(() => data?.filter(isExtensionVisible) || null, [data]);
	const accessNarrowedData = useMemo(
		() => data?.filter(isExtensionHiddenByAppAccessRules) || null,
		[data],
	);
	const stack = useForgeItemStack(projectData, routeName);

	return useMemo<UseNavigationResourceReturnWithStackAndAN<ProjectPage[]>>(
		() => ({
			data: projectData,
			accessNarrowedData,
			loading,
			error,
			stack,
		}),
		[projectData, accessNarrowedData, loading, error, stack],
	);
};

export const useProjectForgeApps = functionWithCondition(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	useProjectForgeAppsV2,
	useProjectForgeAppsV1,
);

export const useProjectForgeAppsNav4 = (
	projectKey: string,
): UseNavigationResourceReturnWithStackAndAN<ProjectPage[]> =>
	useProjectForgeApps(undefined, projectKey);
