import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { metricsOverviewPageResource } from '@atlassian/jira-router-resources-performance-metric-overview/src/services/index.tsx';
import {
	globalSettingsPerformanceCustomFieldRoute,
	globalSettingsPerformanceIssueArchivalRoute,
	globalSettingsPerformanceProjectRoleActorRoute,
	globalSettingsPerformanceOverviewRoute,
	globalSettingsPerformanceMetricDetailRoute,
	globalSettingsPerformanceProjectCleanupRoute,
} from '@atlassian/jira-router-routes-admin-pages-performance-routes';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	CustomFieldMetricDetail,
	LazyCustomFieldDetail,
} from './ui/spa/admin-pages/performance/metric-detail/custom-field';
import {
	IssueMetricDetail,
	LazyIssueDetail,
} from './ui/spa/admin-pages/performance/metric-detail/issue';
import {
	ProjectCleanupMetricDetail,
	LazyProjectCleanupDetail,
} from './ui/spa/admin-pages/performance/metric-detail/project-cleanup';
import {
	ProjectRoleActorMetricDetail,
	LazyProjectRoleActorDetail,
} from './ui/spa/admin-pages/performance/metric-detail/project-role-actor';
import { RedirectToExactMetricRoutes } from './ui/spa/admin-pages/performance/metric-detail/redirect-to-new';
import Overview, { LazyOverview } from './ui/spa/admin-pages/performance/overview';

export const performanceAndScaleRouteEntries: Route[] = [
	createEntry(globalSettingsPerformanceOverviewRoute, () => ({
        component: Overview,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			metricsOverviewPageResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyOverview]
    })),
	// This is an old route that is not used anymore. It is kept here for backward compatibility.
	createEntry(globalSettingsPerformanceMetricDetailRoute, () => ({
        component: RedirectToExactMetricRoutes,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(globalSettingsPerformanceProjectCleanupRoute, () => ({
        // On FF cleanup of site-optimizer-project-cleanup replace with ProjectCleanupMetricDetail
        component: componentWithFG(
			'site-optimizer-project-cleanup',
			ProjectCleanupMetricDetail,
			ErrorPagesNotFound,
		),

        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyProjectCleanupDetail]
    })),
	createEntry(globalSettingsPerformanceCustomFieldRoute, () => ({
        component: CustomFieldMetricDetail,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyCustomFieldDetail]
    })),
	createEntry(globalSettingsPerformanceIssueArchivalRoute, () => ({
        component: IssueMetricDetail,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyIssueDetail]
    })),
	createEntry(globalSettingsPerformanceProjectRoleActorRoute, () => ({
        component: ProjectRoleActorMetricDetail,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyProjectRoleActorDetail]
    })),
];
