import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_RAPIDBOARDS_BOARD_CONFIG } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardConfigResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_BOARD_CONFIG,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-board-config-data", jiraSpaEntry: "async-resource-rapidboards-board-config-data" */ './get-data'
		),
	maxAge: Number.MAX_VALUE,
	isBrowserOnly: true,
});
