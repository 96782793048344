/**
 * @generated SignedSource<<5fdff864cbe60400d500ee0124810a87>>
 * @relayHash a01b68213bbbc7b0c8ddb1a4b41de84a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 21bde05c27ed23a329bc5f548e7408778099eac9b94f2c84f10f83d977b86add

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainIssueAggQuery } from './mainIssueAggQuery.graphql';

import issueViewRelayApproversFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-approvers.relayprovider';
import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayCheckboxSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-checkbox-select.relayprovider';
import issueViewRelayComponentsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-components.relayprovider';
import issueViewRelayGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-group-picker.relayprovider';
import issueViewRelayHeaderActionsFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-header-actions.relayprovider';
import issueViewRelayIssueTypeSwitcherFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-type-switcher.relayprovider';
import issueViewRelayMultiUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user.relayprovider';
import issueViewRelayOriginalEstimateFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-original-estimate.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayPeopleFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-people.relayprovider';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayResolutionFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider';
import issueViewRelaySecurityLevelFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-security-level.relayprovider';
import issueViewRelaySingleLineTextMessageFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider';
import issueViewRelayTimeTrackingFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';
import issueViewRelayWatchersFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-watchers.relayprovider';

const node: PreloadableConcreteRequest<mainIssueAggQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "21bde05c27ed23a329bc5f548e7408778099eac9b94f2c84f10f83d977b86add",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider": issueViewRelayMultiUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider
    }
  }
};

export default node;
