import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS,
	ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const roleBasedNotificationsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS,
	path: '/jira/settings/products/ops/role-based-notifications',
	exact: true,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const roleBasedNotificationsDetailRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_ROLE_BASED_NOTIFICATIONS_DETAIL,
	path: '/jira/settings/products/ops/role-based-notifications/:id',
	exact: true,
} as const as Route;
