import React, { memo } from 'react';
import merge from 'lodash/merge';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useThemeHook } from '@atlassian/jira-business-theme-providers/src/controllers/theme/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	BASE_THEME,
	BUSINESS_WRAPPER_HEIGHT,
	BUSINESS_WRAPPER_PADDING,
	BUSINESS_WRAPPER_WIDTH,
	BUSINESS_WRAPPER_WITH_BACKGROUND_HEIGHT,
	BUSINESS_WRAPPER_WITH_BACKGROUND_WIDTH,
	DEFAULT_BACKGROUND_CONFIG,
	HIGH_BRIGHTNESS_THEME,
	LOW_BRIGHTNESS_THEME,
	NO_BACKGROUND_CONFIG,
	Variables,
	GRADIENT_THEME,
	NAV4_BUSINESS_WRAPPER_WITH_BACKGROUND_HEIGHT,
	NAV4_BUSINESS_WRAPPER_HEIGHT,
} from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import type {
	ColorBackgroundConfig,
	GradientBackgroundConfig,
	ImageBackgroundConfig,
	BusinessTheme,
	PartialBusinessTheme,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { themeManager } from '@atlassian/jira-custom-theme-constants/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BACKGROUND_NEUTRAL_HOVERED_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_PRESSED_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_SUBTLE_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_ELEVATION_SURFACE_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_SELECTED_VAR,
	UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_VAR,
} from '@atlassian/navigation-kit-common';
import { useBackgroundImageUrl } from './utils';
/**
 * Will merge multiple themes together, with the last theme taking precedence.
 * The first argument is the base theme, and the rest are partial themes that
 * will be merged into the base theme.
 */
function mergeThemes(
	theme: BusinessTheme,
	...partialThemes: PartialBusinessTheme[]
): BusinessTheme {
	return merge({}, theme, ...partialThemes);
}

const buildHorizontalNavVariables = (theme: BusinessTheme) => {
	// can be moved inline once custom_project_navigation_m2 is cleaned up, this is just to stop the test from failing due to unused gate mocks
	const shouldShowNav4 = getWillShowNav4();
	if (fg('custom_project_navigation_m2') || shouldShowNav4) {
		return `${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_VAR}: ${theme['color.link']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BACKGROUND_NEUTRAL_HOVERED_VAR}: ${theme['color.background.neutral.hovered']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_LINK_PRESSED_VAR}: ${theme['color.link.pressed']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_SUBTLE_VAR}: ${theme['color.text.subtle']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_TEXT_VAR}: ${theme['color.text']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_ELEVATION_SURFACE_VAR}: ${theme['color.elevation.surface']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_SELECTED_VAR}: ${theme['color.border.selected']};
${UNSAFE_HORIZONTAL_NAVIGATION_COLOR_BORDER_VAR}: ${theme['color.border']};`;
	}
	return '';
};

const buildThemeVariables = (
	config: ColorBackgroundConfig | GradientBackgroundConfig | ImageBackgroundConfig,
	backgroundImageUrl: string | null,
	isDarkMode: boolean,
) => {
	const applicationColorMode = isDarkMode ? 'dark' : 'light';
	const brightnessOverrides =
		config.brightness === 'high' && !isDarkMode ? HIGH_BRIGHTNESS_THEME : LOW_BRIGHTNESS_THEME;
	const isImageConfig = config.name === 'image';
	const isGradientConfig = themeManager.isGradientTheme(config.name);

	/**
	 * The base theme and the custom theme get merged here, with the custom theme taking precedence.
	 * If you want to override configurations globally, then you should make modifications to the
	 * BASE_THEME, while if you want to customize a specific theme, then you should make modifications
	 * in the theme's themselves.
	 */
	const theme = mergeThemes(
		// the base theme for the application
		BASE_THEME,
		// the brightness overrides
		isImageConfig ? {} : brightnessOverrides,
		// gradient specific overrides
		isGradientConfig ? GRADIENT_THEME : {},
		// The custom background configuration
		config.theme[applicationColorMode],
	);

	let elevationSurface = theme['elevation.surface'] ?? theme['color.elevation.surface'];

	if (backgroundImageUrl) {
		// in dark mode, the image will be darkened by blending with a black transparent background
		elevationSurface = `url(${backgroundImageUrl}) center center / cover no-repeat${isDarkMode ? ' #0000004d' : ''}`;
	} else if (applicationColorMode === 'dark') {
		if (NO_BACKGROUND_CONFIG.name !== config.name && !isGradientConfig) {
			/**
			 * Add a gradient to the elevation surface for any themes in dark mode. This could be done in the
			 * meta configuration but it's easier to do bulk edits here.
			 */
			elevationSurface = `linear-gradient(180deg, ${theme['color.elevation.surface']} 0%, ${token(
				'elevation.surface',
				'#1D2125',
			)} 400px)`;
		}
	}

	// Wrapper configuration
	const withWrapper = config.name !== DEFAULT_BACKGROUND_CONFIG.name;
	const wrapperBackground = withWrapper ? token('elevation.surface', N0) : 'unset';
	const wrapperBorderRadius = withWrapper ? `${gridSize}px` : '0';
	const isNav4 = getWillShowNav4();
	const businessWrapperWithBackgroundHeight = isNav4
		? NAV4_BUSINESS_WRAPPER_WITH_BACKGROUND_HEIGHT
		: BUSINESS_WRAPPER_WITH_BACKGROUND_HEIGHT;
	const businessWrapperHeight = isNav4 ? NAV4_BUSINESS_WRAPPER_HEIGHT : BUSINESS_WRAPPER_HEIGHT;
	const wrapperHeight = withWrapper ? businessWrapperWithBackgroundHeight : businessWrapperHeight;
	const wrapperPadding = withWrapper ? `${BUSINESS_WRAPPER_PADDING}px` : '0';
	const wrapperWidth = withWrapper
		? BUSINESS_WRAPPER_WITH_BACKGROUND_WIDTH
		: BUSINESS_WRAPPER_WIDTH;

	return `
${Variables.BACKDROP_FILTER}: ${isImageConfig ? 'blur(8px)' : 'none'};
${Variables.COLOR_BACKGROUND_BRAND_BOLD}: ${theme['color.background.brand.bold']};
${Variables.COLOR_BACKGROUND_BRAND_BOLD_HOVERED}: ${theme['color.background.brand.bold.hovered']};
${Variables.COLOR_BACKGROUND_BRAND_BOLD_PRESSED}: ${theme['color.background.brand.bold.pressed']};
${Variables.COLOR_BACKGROUND_DISABLED}: ${theme['color.background.disabled']};
${Variables.COLOR_BACKGROUND_INPUT}: ${theme['color.background.input']};
${Variables.COLOR_BACKGROUND_NEUTRAL}: ${theme['color.background.neutral']};
${Variables.COLOR_BACKGROUND_NEUTRAL_HOVERED}: ${theme['color.background.neutral.hovered']};
${Variables.COLOR_BACKGROUND_NEUTRAL_PRESSED}: ${theme['color.background.neutral.pressed']};
${Variables.COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED}: ${theme['color.background.neutral.subtle.hovered']};
${Variables.COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED}: ${theme['color.background.neutral.subtle.pressed']};
${Variables.COLOR_BACKGROUND_SELECTED}: ${theme['color.background.selected']};
${Variables.COLOR_BORDER}: ${theme['color.border']};
${Variables.COLOR_BORDER_INPUT}: ${theme['color.border.input']};
${Variables.COLOR_BORDER_INVERSE}: ${theme['color.border.inverse']};
${Variables.COLOR_BORDER_SELECTED}: ${theme['color.border.selected']};
${Variables.COLOR_ELEVATION_SURFACE}: ${theme['color.elevation.surface']};
${Variables.COLOR_ICON_DANGER}: ${theme['color.icon.danger']};
${Variables.COLOR_ICON_INFORMATION}: ${theme['color.icon.information']};
${Variables.COLOR_ICON_SUCCESS}: ${theme['color.icon.success']};
${Variables.COLOR_ICON_WARNING}: ${theme['color.icon.warning']};
${Variables.COLOR_LINK}: ${theme['color.link']};
${Variables.COLOR_LINK_PRESSED}: ${theme['color.link.pressed']};
${Variables.COLOR_SKELETON}: ${theme['color.skeleton']};
${Variables.COLOR_TEXT}: ${theme['color.text']};
${Variables.COLOR_TEXT_DISABLED}: ${theme['color.text.disabled']};
${Variables.COLOR_TEXT_INVERSE}: ${theme['color.text.inverse']};
${Variables.COLOR_TEXT_SELECTED}: ${theme['color.text.selected']};
${Variables.COLOR_TEXT_SUBTLE}: ${theme['color.text.subtle']};
${Variables.COLOR_TEXT_SUBTLEST}: ${theme['color.text.subtlest']};
${Variables.CONTENT_HEIGHT}: ${businessWrapperHeight};
${Variables.CONTENT_WIDTH}: ${BUSINESS_WRAPPER_WIDTH};
${Variables.ELEVATION_SURFACE}: ${elevationSurface};
${Variables.ELEVATION_SURFACE_CONTRAST}: ${theme['elevation.surface.contrast']};
${Variables.ELEVATION_SURFACE_CONTRAST_BOLDER}: ${theme['elevation.surface.contrast.bolder']};
${Variables.WRAPPER_BACKGROUND}: ${wrapperBackground};
${Variables.WRAPPER_BORDER_RADIUS}: ${wrapperBorderRadius};
${Variables.WRAPPER_HEIGHT}: ${wrapperHeight};
${Variables.WRAPPER_PADDING}: ${wrapperPadding};
${Variables.WRAPPER_WIDTH}: ${wrapperWidth};
${buildHorizontalNavVariables(theme)}`;
};

export const ThemeTokensSetter = memo(() => {
	const [{ themeSetting }] = useThemeHook();

	const backgroundConfig = themeManager.get({
		themeSetting,
		enableImages: true,
	});

	const backgroundImageUrl = useBackgroundImageUrl(themeSetting);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
		<style
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: `:root{ ${buildThemeVariables(backgroundConfig, backgroundImageUrl, false)} }
        [data-color-mode=dark]{ ${buildThemeVariables(backgroundConfig, backgroundImageUrl, true)} }`,
			}}
		/>
	);
});
