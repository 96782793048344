import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type NextGenReportsOverviewType from '@atlassian/jira-spa-apps-next-gen-reports-overview';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';
import { getBoardId } from '../../../../common';

export const LazyNextGenReportsOverview = lazyForPaint<typeof NextGenReportsOverviewType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-reports-overview", jiraSpaEntry: "next-gen-reports-overview" */ '@atlassian/jira-spa-apps-next-gen-reports-overview'
		),
	),
);

type Props = RouteContext;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ location, match }: Props) => (
	<LazyPage
		Page={LazyNextGenReportsOverview}
		pageId={`next-gen-reports-overview-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);
