import { createPollingCollection } from '../polling-collection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let pollingSingleton: ((arg1: string) => Promise<any>) | null = null;

export type LoadBridgeWeakOptions = {
	/** AMD name of the resource being loaded */
	name: string;
};

/**
 * Load an AMD module only once WRM has loaded it separately.
 * This is a weak load with infinite retries. If you want to limit retries you need to
 * Promise.race() at your point of use.
 * It is only safe to use where the WRM page content will load the same AMD module, and then wait
 * for jira-frontend to call the same AMD module and implement the API.
 */
export const loadBridgeWeak = <T,>({ name }: LoadBridgeWeakOptions): Promise<T> => {
	const getter =
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		pollingSingleton ?? createPollingCollection<string, T>((v): T => window.require(v), 100);
	pollingSingleton = getter;
	return getter(name);
};
