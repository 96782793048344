import type { MenuId, SelectedPath } from '../../types';
import { isMenuIdList, toMenuIdList } from '../menu-id';

// The context version of `isSelectedPath` takes a `menuId` and returns a boolean.
// We support a curried version of the underlying function here so we can provide the context version without wrapping.
export function isSelectedPath(path: SelectedPath): (menuId: MenuId) => boolean;
// An uncurried version of the underlying function is still used internally, for example, the sidebar resource.
export function isSelectedPath(path: SelectedPath, menuId: MenuId): boolean;
export function isSelectedPath(path: SelectedPath, ...args: [] | [string]) {
	const next = (menuId: MenuId) => {
		return isMenuIdList(menuId)
			? toMenuIdList(menuId).some((id) => path.includes(id))
			: path.includes(menuId);
	};
	if (args.length === 0) return next;
	return next(args[0]);
}
