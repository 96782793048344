import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { createProjectRoute } from '@atlassian/jira-router-routes-create-project-routes';
import type CreateProjectType from '@atlassian/jira-spa-apps-create-project';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCreateProject = lazyForPaint<typeof CreateProjectType>(
	() =>
		import(
			/* webpackChunkName: "async-create-project", jiraSpaEntry: "async-create-project" */ '@atlassian/jira-spa-apps-create-project'
		),
);

export const CreateProject = () => (
	<LazyPage Page={LazyCreateProject} pageId="spa-apps/create-project" shouldShowSpinner />
);

export const createProjectRouteEntry = createEntry(createProjectRoute, () => ({
    component: CreateProject,
    layout: genericProjectLayout,
    resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
    forPaint: [LazyAtlassianNavigation, LazyCreateProject]
}));
