import get from 'lodash/get';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import type { Properties as RequiredFrontendProperties } from './get-required-frontendconfig-properties';
import type { Properties as RequiredProjectProperties } from './get-required-project-properties';
import {
	ONE_DAY_IN_MILLIS,
	RESOURCE_TYPE_SERVICEDESK_REQUIRED_PROJECT_PROPERTIES,
	RESOURCE_TYPE_SERVICEDESK_REQUIRED_FRONTEND_PROPERTIES,
} from './types';

const getRequiredProjectPropertiesDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-required-project-properties-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-required-project-properties'
	);

const getKey = ({ match, query }: RouterContext) =>
	// projectKey from url path/query or browse/{issueKey}
	getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0];

export const requiredProjectProperties = createResource<RequiredProjectProperties>({
	type: RESOURCE_TYPE_SERVICEDESK_REQUIRED_PROJECT_PROPERTIES,
	getKey,
	getDataLoader: getRequiredProjectPropertiesDataLoader,
});

const getRequiredFrontendPropertiesDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-properties-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-required-frontendconfig-properties'
	);

export const requiredFrontendProperties = createResource<RequiredFrontendProperties>({
	type: RESOURCE_TYPE_SERVICEDESK_REQUIRED_FRONTEND_PROPERTIES,
	getKey,
	getDataLoader: getRequiredFrontendPropertiesDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});
