import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJsmEmailRequestsRoute } from '@atlassian/jira-router-routes-products-jsm-email-requests-routes';
import RedirectToLegacyRouting from './common/ui/redirect/legacy-routing';
import ProductsJsmEmailRequests, { LazyProductsJsmEmailRequests } from './ui';

export const productsJsmEmailRequestsRouteEntry = createEntry(productsJsmEmailRequestsRoute, () => ({
    ufoName: 'admin.jsm-email-requests',

    component: componentWithFF(
		'migrate-jsm-email-requests-settings-page_xbqbk',
		ProductsJsmEmailRequests,
		RedirectToLegacyRouting,
	),

    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
    forPaint: [LazyAtlassianNavigation, LazyProductsJsmEmailRequests]
}));
