import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	routeGroupsProjectSettingsServicedeskCustomerNotificationsRoute,
	routeGroupsProjectSettingsServicedeskNotificationsCustomerNotificationsRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import { getSettingsNavigationResources, SERVICE_DESK_PRODUCT } from './ui/product-routes/common';
import ServiceDeskCustomerNotifications, {
	LazyProjectSettingsServiceDeskCustomerNotifications,
} from './ui/service-desk-customer-notifications';
import { ServiceDeskCustomerNotificationsNextGenRedirect } from './ui/service-desk-customer-notifications-next-gen-redirect';

export const customerNotificationsSettingsRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskCustomerNotificationsRoute, () => ({
        component: ServiceDeskCustomerNotifications,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerNotifications]
    })),
	createEntry(routeGroupsProjectSettingsServicedeskNotificationsCustomerNotificationsRoute, () => ({
        component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			ServiceDeskCustomerNotificationsNextGenRedirect,
			ServiceDeskCustomerNotifications,
		),

        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerNotifications]
    })),
];
