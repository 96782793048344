import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { CONNECT_GENERAL_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-general-query-params';
import { ROUTE_GROUPS_CONNECT } from '@atlassian/jira-common-constants/src/spa-routes';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import {
	genericConnectSidebars,
	genericProjectSidebars,
} from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { connectGeneralResources } from '@atlassian/jira-router-resources-connect-general';
import { connectProjectResources } from '@atlassian/jira-router-resources-connect-project';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	getServiceDeskNavigationResources,
	getServiceDeskNavigationSingleResources,
	getServicedeskPageContextResources,
	getServiceDeskSharedNavigationSingleResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import {
	connectGeneralRoute,
	connectGeneralServiceDeskProjectRoute,
	connectGeneralInvalidRoute,
	connectProjectRoute,
	servicedeskConnectProjectRoute,
} from '@atlassian/jira-router-routes-connect-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { RouteContext, Route } from '@atlassian/react-resource-router';
import ConnectGeneralPage, { LazyGeneralConnect } from './ui/spa/connect/general';
import ConnectProjectPage, { LazyProjectsConnect } from './ui/spa/connect/projects';

export const getProjectConnectRouteDefaultResources = () => [
	...getBusinessProjectResources(),
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	...getNavigationSidebarProjectSettingsResource(),
	connectProjectResources,
];

export const projectConnectRouteEntry = {
	component: ConnectProjectPage,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},
	forPaint: [LazyAtlassianNavigation, LazyProjectsConnect],
	layout: genericProjectLayout,
	ufoName: 'jira-connect',
};

const getGeneralConnectRouteResources = () => [
	...getBusinessProjectResources(),
	...getNavigationResources(),
	navigationSidebarGlobalResource,
	...getNavigationSidebarGlobalSettingsResources(),
	...getNavigationSidebarProjectResource(),
	...getNavigationSidebarProjectSettingsResource(),
	connectGeneralResources,
	getConsolidationStateResource(),
];

const generalConnectRouteEntry = {
	layout: createLayout(),
	navigation: {
		menuId: MENU_ID.ADDONS,
		sidebar: genericConnectSidebars,
	},
	component: ConnectGeneralPage,
	resources: getGeneralConnectRouteResources(),
	canTransitionIn: (current: RouteContext, next: RouteContext) => {
		const { query } = next.match;

		// must be in sync with com.atlassian.jira.web.filters.frontend.ConnectGeneralPagesFrontendRoute::matchesRequest
		// block transitions to mobile version
		if (
			query.mobile === 'true' ||
			query.includeWebResources === 'true' ||
			query.classifier === 'json'
		) {
			return false;
		}

		// block transitions to marketplace app as it requires specialised Connect API -
		const emceeKeyRegex =
			/com\.atlassian\.(jira|confluence)\.emcee($|\.local|\.staging|\.development\..*)/g;
		const addonKey = next.match.params.addonKey || '';
		if (emceeKeyRegex.test(addonKey)) {
			return false;
		}
		return true;
	},
	forPaint: [LazyAtlassianNavigation, LazyGeneralConnect],
	ufoName: 'jira-connect',
};

const connectGeneralServiceDeskProjectRouteQuery: string[] = [
	...CONNECT_GENERAL_QUERY_PARAMS,
	...connectGeneralServiceDeskProjectRoute.query,
];

export const connectRouteEntries: Route[] = [
	createEntry(
		{
			...connectGeneralServiceDeskProjectRoute,
			group: ROUTE_GROUPS_CONNECT,
			query: connectGeneralServiceDeskProjectRouteQuery,
		},
		() => ({
            ...generalConnectRouteEntry,

            resources: [
				...getGeneralConnectRouteResources(),
				...getServiceDeskNavigationSingleResources(),
			]
        }),
	),
	createEntry(connectGeneralRoute, () => ({
        ...generalConnectRouteEntry
    })),
	createEntry(connectGeneralInvalidRoute, () => ({
        ...generalConnectRouteEntry
    })),
	createEntry(connectProjectRoute, () => ({
        ...projectConnectRouteEntry,

        // Match resources that are fetched for legacyConnectProjectRouteEntry
        // See src/packages/router-routes/legacy/connect-project/entries/src/index.tsx
        resources: [
			...getProjectConnectRouteDefaultResources(),
			...getServiceDeskSharedNavigationSingleResources(),
			getConsolidationStateResource(),
		]
    })),
];

export const servicedeskConnectProjectRouteEntry = createEntry(servicedeskConnectProjectRoute, () => ({
    ...projectConnectRouteEntry,
    layout: serviceProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
	},

    resources: [
		...getProjectConnectRouteDefaultResources(),
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	]
}));
