import type {
	RouteResource,
	RouterDataContext,
	ResourceStoreContext,
} from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_NOOP } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyResourceData = any;

/**
 * @deprecated resourceWithCondition2 is preferred and will eventually replace resourceWithCondition
 */
export const resourceWithCondition = (
	conditionGetter: () => boolean,
	resourceTrue: RouteResource<AnyResourceData>,
): RouteResource<AnyResourceData> =>
	resourceTrue && {
		get type() {
			return conditionGetter() ? resourceTrue.type : RESOURCE_TYPE_NOOP;
		},
		getKey: resourceTrue.getKey,
		get maxAge() {
			return conditionGetter() ? resourceTrue.maxAge : Number.MAX_SAFE_INTEGER;
		},
		getData: (routerContext: RouterDataContext, customContext: ResourceStoreContext) =>
			conditionGetter()
				? resourceTrue.getData(routerContext, customContext)
				: Promise.resolve(null),
		maxCache: resourceTrue.maxCache,
		get isBrowserOnly() {
			return resourceTrue.isBrowserOnly;
		},
		get depends() {
			return resourceTrue.depends;
		},
		get skipBrowser() {
			return resourceTrue.skipBrowser;
		},
	};

// IMPORTANT - spreading resources can invoke getters, cloning will safely duplicate/edit resource
export const safeCloneResource = (
	resource: RouteResource<AnyResourceData>,
	amendments: Partial<RouteResource<AnyResourceData>> = {},
): RouteResource<AnyResourceData> =>
	// @ts-expect-error - TS2322 - Type 'Partial<RouteResource<any>>' is not assignable to type 'RouteResource<any>'.
	resource && // IMPORTANT - resources can be falsy in SSR "routes" build
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	Object.defineProperties({} as Partial<RouteResource<AnyResourceData>>, {
		...Object.getOwnPropertyDescriptors(resource),
		...Object.getOwnPropertyDescriptors(amendments),
	});

// IMPORTANT - conditional resource is not type-safe, use only in non-specific cases such as routes
export const resourceWithCondition2 = (
	conditionGetter: () => boolean,
	itemTrue: RouteResource<AnyResourceData>,
	itemFalse: RouteResource<AnyResourceData> = safeCloneResource(itemTrue, {
		getKey: () => 'DEGENERATE_KEY',
		getData: () => null,
		maxAge: Number.MAX_SAFE_INTEGER,
	}),
): RouteResource<AnyResourceData> =>
	// @ts-expect-error - TS2740 - Type '{}' is missing the following properties from type 'RouteResource<any>': type, getKey, maxAge, getData, and 3 more.
	itemTrue && // IMPORTANT - resources can be falsy in SSR "routes" build
	Object.defineProperties(
		{},
		Object.fromEntries(
			[...Object.keys(itemTrue), ...Object.keys(itemFalse)]
				.filter((v, i, a) => a.indexOf(v) === i)
				.map((key) => [
					key,
					{
						configurable: false,
						enumerable: true,
						// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RouteResource<any>'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RouteResource<any>'.
						get: () => (conditionGetter() ? itemTrue[key] : itemFalse[key]),
					},
				]),
		),
	);

export const browserOnlyResourceWithCondition = (
	conditionGetter: () => boolean,
	resource: RouteResource<AnyResourceData>,
): RouteResource<AnyResourceData> =>
	resource && {
		...resource,
		get isBrowserOnly() {
			return !!conditionGetter();
		},
	};
