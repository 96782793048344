import React, { memo, useEffect, useState } from 'react';
import { NestableNavigationContent, Section } from '@atlaskit/side-navigation';
import { CONNECT_ROUTES_SET } from '@atlassian/jira-common-constants/src/spa-routes-sets';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { Route } from '@atlassian/react-resource-router';
import { SECTION_ID } from '../../../../../common/constants';
import { useProjectReportsConnectItems } from '../../../../../controllers';
import useAutoExpandSidebar from '../../../../../controllers/auto-expand-sidebar/index.tsx';
import { Reports } from './reports/main.tsx';

const calculateStack = (route: Route, connectStack: null | Array<string>) => {
	if (CONNECT_ROUTES_SET.has(route.name) && connectStack) {
		const [first, ...other] = connectStack;

		// omit the reports nesting level as we render the reports menu
		// as a top level menu in business projects
		if (SECTION_ID.REPORTS === first) {
			return other;
		}

		return connectStack;
	}

	return [];
};

const ReportsMenu = () => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: connectItems, stack: connectStack } = useProjectReportsConnectItems();

	const [stack, setStack] = useState<string[]>(() => calculateStack(route, connectStack));

	useEffect(() => {
		setStack(calculateStack(route, connectStack));
	}, [route, connectStack]);

	return (
		<NestableNavigationContent stack={stack} onChange={setStack}>
			<Section>
				<Reports connectItems={connectItems} />
			</Section>
		</NestableNavigationContent>
	);
};

export default memo(ReportsMenu);
