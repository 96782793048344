import {
	ROUTE_NAMES_SOFTWARE_CODE,
	ROUTE_NAMES_SOFTWARE_CODE_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import MAIN_CODE_PAGE_QUERY, {
	type mainCodeInJiraUpdatedQuery as MainCodeInJiraUpdatedQuery,
} from '@atlassian/jira-relay/src/__generated__/mainCodeInJiraUpdatedQuery.graphql';
// eslint-disable-next-line jira/import-whitelist
import {
	getPullRequestsUpdatedFromDate,
	getNumPullRequestsToFetch,
} from '@atlassian/jira-software-code-page/src/common/utils.tsx';
import type {
	RouterDataContext,
	ResourceStoreContext,
	RouterContext,
} from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_CODE } from '../constants';

let numPullRequestsToFetch = 0;

export const codePageResource = createRelayResource<MainCodeInJiraUpdatedQuery>({
	type: RESOURCE_TYPE_CODE,
	getQuery(
		{ match: { params }, query, route: { name } }: RouterDataContext | RouterContext,
		{ tenantContext: { cloudId } }: ResourceStoreContext,
	) {
		const isCodeRoute =
			name === ROUTE_NAMES_SOFTWARE_CODE || name === ROUTE_NAMES_SOFTWARE_CODE_CLASSIC;
		// Set numPullRequestsToFetch on initial call only and not on every query param change
		if (!numPullRequestsToFetch && isCodeRoute) {
			numPullRequestsToFetch = getNumPullRequestsToFetch(query);
		}
		// Reset numPullRequestsToFetch when navigating away from the code page
		if (!isCodeRoute) {
			numPullRequestsToFetch = 0;
		}

		return {
			parameters: MAIN_CODE_PAGE_QUERY,
			options: {
				fetchPolicy: 'store-and-network',
			},
			variables: {
				cloudId,
				projectKey: params.projectKey ?? '',
				pullRequestsUpdatedFrom: getPullRequestsUpdatedFromDate(),
				numPullRequestsToFetch,
				siteAri: `ari:cloud:jira::site/${cloudId}`,
			},
		};
	},
});
