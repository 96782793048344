import { useMemo, useEffect } from 'react';
import { generateTheme, type NavigationTheme } from '@atlaskit/atlassian-navigation';
import { useThemeObserver, getTokenValue } from '@atlaskit/tokens';
import { useTheme as useGlobalThemeData } from '@atlassian/jira-controllers-global-theme/src/controllers/index.tsx';
import { useThemePreference } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { addThemeColor } from '../../services/theme-color-meta-tag';
import { capLightness } from './colors';
import type { UseThemeType } from './types';

const caseInsensitiveEquals = (one: string, two: string) => one.toLowerCase() === two.toLowerCase();

const calculateBackground = (backgroundColor: string, isDarkMode: boolean) => {
	// White is a proxy for 'default' when setting accent colours, we really want to default to 'elevation.surface'
	if (caseInsensitiveEquals(backgroundColor, '#ffffff')) {
		// getTokenValue doesn't work in SSR thus hardcode, fix in: https://product-fabric.atlassian.net/browse/DSP-9781
		const fallback = isDarkMode ? '#1d2125' : '#ffffff';

		// Can't be getToken() as AK Nav needs a hex string. It parses it to determine the correct text color
		return getTokenValue('elevation.surface', fallback);
	}
	return isDarkMode ? capLightness(backgroundColor, 35) : backgroundColor;
};

const useIsDarkThemeResolver = () => {
	// The best source of theme info. But doesn't work in SSR, fix in: https://product-fabric.atlassian.net/browse/DSP-9781
	const { colorMode } = useThemeObserver();

	// This works in SSR, but we need the browser to resolve 'auto', so we are wrong in SSR if 'auto' resolves to 'dark'
	const { theme: preference } = useThemePreference();

	return __SERVER__ ? preference === 'dark' : colorMode === 'dark';
};

export const useTheme: UseThemeType = (): NavigationTheme | undefined => {
	const [
		{
			navigationTheme: { usesCustomColours, backgroundColor, highlightColor },
		},
	] = useGlobalThemeData();

	const isDarkMode = useIsDarkThemeResolver();

	const bg = useMemo(
		() => calculateBackground(backgroundColor, isDarkMode),
		[backgroundColor, isDarkMode],
	);

	useEffect(() => {
		addThemeColor(bg);
	}, [bg]);

	if (!usesCustomColours) {
		return undefined;
	}

	return generateTheme({
		name: 'jira',
		backgroundColor: bg,
		highlightColor,
	});
};
