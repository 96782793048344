import type { AGGError } from '@atlassian/jira-relay-errors';

export class ForgeFetchError extends Error {
	public readonly traceId?: string;

	public readonly statusCodes?: Array<number>;

	constructor(message: string, traceId?: string, errors?: ReadonlyArray<AGGError>) {
		super(message);
		this.name = ForgeFetchError.name;
		this.traceId = traceId;
		this.statusCodes = errors
			?.map((graphQLError) => graphQLError?.extensions?.statusCode)
			.filter((x): x is number => x != null);
		if ('captureStackTrace' in Error) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
			(Error as any).captureStackTrace(this, ForgeFetchError); // :tableflip:
		}
	}
}
