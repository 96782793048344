import { useCallback, useEffect, useState } from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_ISSUE_TRANSITION_OPT_IN,
	ISSUE_TRANSITION_LABS_VISIBILITY_API,
	ISSUE_TRANSITION_OPT_IN_STATUS_RESOURCE_KEY,
} from '../common/constants';
import type {
	IssueTransitionOptInStatusResourceData,
	IssueTransitionOptInStatusGetResponse,
	IssueTransitionOptInStatusUpdateRequest,
	ResponseStatus,
	UseIssueTransitionLabsOptIn,
} from '../types';
import getDefaultIssueTransitionLabsOptInState from '../utils/get-default-issue-transition-labs-opt-in-state/index.tsx';

/*
 * API Contract: https://hello.atlassian.net/wiki/spaces/JIE/pages/2983777277/Issue+Transition+Jira+Labs+Toggle+for+Legacy+Experience+Backend+API#API-Contract
 */
const fetchGetIssueTransitionOptInState = async () =>
	performGetRequest<IssueTransitionOptInStatusGetResponse>(ISSUE_TRANSITION_LABS_VISIBILITY_API);

export const issueTransitionOptInStatusResource =
	createResource<IssueTransitionOptInStatusResourceData>({
		type: RESOURCE_TYPE_ISSUE_TRANSITION_OPT_IN,
		getKey: () => ISSUE_TRANSITION_OPT_IN_STATUS_RESOURCE_KEY,
		getData: async (): Promise<IssueTransitionOptInStatusResourceData> => {
			let issueTransitionOptInStatus = null;

			const isModernIssueTransitionSRBCohort =
				fg('issue-transition-customisation-for-srb') || isFedRamp();
			const isModernITSRenderEnabled = ff('render-modern-issue-transition_cxt0z') || isFedRamp();
			if (!isModernITSRenderEnabled) {
				return {
					issueTransitionOptInStatus: {
						isEnabled: false,
						expiryTime: -1,
					},
				};
			}

			if (isModernIssueTransitionSRBCohort) {
				return {
					issueTransitionOptInStatus: {
						isEnabled: true,
					},
				};
			}

			try {
				issueTransitionOptInStatus = await fetchGetIssueTransitionOptInState();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.statusCode === 404) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'issueTransitionOptInStatusResource',
							packageName: 'jiraRouterResourcesIssueTransitionLabsOptIn',
							teamName: 'gryffindor',
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
			return {
				issueTransitionOptInStatus:
					issueTransitionOptInStatus ?? getDefaultIssueTransitionLabsOptInState(),
			};
		},
		maxAge: Number.MAX_VALUE,
	});

const fetchModifyIssueTransitionOptInState = async (
	payload: IssueTransitionOptInStatusUpdateRequest,
) =>
	performPutRequest<IssueTransitionOptInStatusGetResponse>(ISSUE_TRANSITION_LABS_VISIBILITY_API, {
		body: JSON.stringify(payload),
	});

export const useIssueTransitionLabsOptIn: UseIssueTransitionLabsOptIn = () => {
	const {
		data,
		loading: loadingGetResource,
		error: errorGetResource,
		update,
	} = useResource(issueTransitionOptInStatusResource);

	const [issueTransitionLabsOptInState, setIssueTransitionOptInState] =
		useState<IssueTransitionOptInStatusGetResponse>(() => {
			if (data?.issueTransitionOptInStatus) {
				return data.issueTransitionOptInStatus;
			}
			return getDefaultIssueTransitionLabsOptInState();
		});
	const [responseStatusGet, setResponseStatusGet] = useState<ResponseStatus>('not started');
	const [responseStatusPut, setResponseStatusPut] = useState<ResponseStatus>('not started');

	const updateIssueTransitionOptInStatus = useCallback(
		async (updates: IssueTransitionOptInStatusUpdateRequest): Promise<ResponseStatus> => {
			let response: ResponseStatus = 'loading';
			setResponseStatusPut('loading');
			try {
				const updatedPreferences = await fetchModifyIssueTransitionOptInState(updates);
				setResponseStatusPut('success');
				update(() => ({
					issueTransitionOptInStatus: updatedPreferences,
				}));
				response = 'success';
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireErrorAnalytics({
					error: err,
					meta: {
						id: 'issueTransitionOptInStatusUpdate',
						packageName: 'jiraRouterResourcesIssueTransitionLabsOptIn',
						teamName: 'gryffindor',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
				setResponseStatusPut('error');
				response = 'error';
			}
			return response;
		},
		[update],
	);

	useEffect(() => {
		if (loadingGetResource) {
			setResponseStatusGet('loading');
			return;
		}

		if (errorGetResource) {
			setResponseStatusGet('error');
			return;
		}

		if (data?.issueTransitionOptInStatus) {
			setResponseStatusGet('success');
			setIssueTransitionOptInState(data.issueTransitionOptInStatus);
		}
	}, [loadingGetResource, errorGetResource, data?.issueTransitionOptInStatus]);

	return {
		stateGet: {
			responseStatus: responseStatusGet,
			state: issueTransitionLabsOptInState,
		},
		stateUpdate: {
			responseStatus: responseStatusPut,
			setState: updateIssueTransitionOptInStatus,
		},
	};
};
