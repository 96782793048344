import React, { useMemo } from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER } from '@atlassian/jira-common-constants/src/spa-routes';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useFeatures } from '../../../controllers/features';
import messages from './messages';

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const UserInterfaceSection = () => {
	const isJiraAdmin = useIsAdmin();
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	return (
		<Section title={formatMessage(messages.userInterface)}>
			{features.hasUserDefaults && (
				<MenuItem
					analytics={{ itemId: 'user-defaults' }}
					href={navigationPath.userInterfacesUserDefaults}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.userDefaults)}
				</MenuItem>
			)}
			{features.hasEditDefaultDashboard && (
				<MenuItem
					analytics={{ itemId: 'edit-default-dashboard' }}
					href={navigationPath.userInterfacesEditDefaultDashboard}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.systemDashboard)}
				</MenuItem>
			)}
			{features.hasLookAndFeel && (
				<MenuItem
					analytics={{ itemId: 'look-and-feel' }}
					href={navigationPath.userInterfacesLookAndFeel}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.lookAndFeel)}
				</MenuItem>
			)}
			{features.hasPasAdmin && (
				<MenuItem
					analytics={{ itemId: 'pas-admin-link-jira' }}
					href={navigationPath.userInterfacesPasAdmin}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.pasAdmin)}
				</MenuItem>
			)}
			{isJiraAdmin && (
				<MenuItem
					analytics={{ itemId: 'announcement-banner' }}
					href={navigationPath.userInterfacesSiteAdmin}
					selectedOn={[ROUTE_NAMES_GLOBAL_SETTINGS_SYSTEM_UI_ANNOUNCEMENT_BANNER]}
				>
					{formatMessage(messages.announcementBanner)}
				</MenuItem>
			)}
		</Section>
	);
};
