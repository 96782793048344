import React from 'react';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/metrics/constants.tsx';
import {
	SidebarReady,
	SidebarRenderStart,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/metrics/main.tsx';
import { Menu } from './menu';

export const SidebarGlobalSettingsSystem = () => (
	<>
		<SidebarRenderStart />
		<Menu />
		{!__SERVER__ && <SidebarReady id={SIDEBAR_ID.GLOBAL_SETTINGS_SYSTEM} />}
	</>
);
