import React from 'react';
import { UserPreferencesContainer } from '@atlassian/jira-user-preferences-services/src/components/main.tsx';
import { UserContainer } from '@atlassian/jira-user-services/src/main.tsx';
import type { UserContainersProps } from './types';

export const UserContainers = ({ baseUrl, children }: UserContainersProps) => (
	<UserContainer isGlobal baseUrl={baseUrl}>
		{/* @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. | TS2322 - Type 'boolean' is not assignable to type 'never'. */}
		<UserPreferencesContainer isGlobal>{children}</UserPreferencesContainer>
	</UserContainer>
);
