import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

const JPD_COLLECTIONS_OVERVIEW_REGEX = new RegExp('^/jira/polaris/roadmaps(/)*$');

// This is the only stage and phase that requires route matching.
// We should endeavour to use `window.location` here as it won't require a parameter.
// Any issues with SSR will be gone in the next phase.
export const getIsRouteExcludedForDogfoodingPhase1 = () => {
	if (__SERVER__) {
		return false;
	}

	const pathname = getPathname();

	if (
		!ff('polaris.sidebar-v4') &&
		(pathname.includes('/jira/polaris/') ||
			pathname.includes('/secure/JiraProductDiscoveryEmbedded') ||
			pathname.includes('/jira/discovery/'))
	) {
		return true;
	}

	if (pathname.includes('/jira/polaris/roadmaps')) {
		const isJpdCollectionsOverview = JPD_COLLECTIONS_OVERVIEW_REGEX.test(pathname);

		if (isJpdCollectionsOverview && fg('jpd-sidebar-v4-for-roadmaps-overview')) {
			// do not exclude collection overview route
			return false;
		}

		// if main gate for roadmaps is disabled then exclude route
		if (!fg('jpd-sidebar-v4-for-roadmaps')) {
			return true;
		}
	}
	return false;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getPathname = () => window.location.pathname;
