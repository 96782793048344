import { envType } from '@atlassiansox/analytics-web-client';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { IntegratedChoreographerMessageTypesEnum } from './constants';
import type {
	ChoreographedMessage,
	ChoreographedMessageTypes,
	MaybeChoreographedComponentProps,
} from './types';

export function shouldBeChoreographed(
	props?: MaybeChoreographedComponentProps,
): props is ChoreographedMessage {
	return (
		!!props?.messageType &&
		IntegratedChoreographerMessageTypesEnum.includes(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			props.messageType as ChoreographedMessageTypes,
		)
	);
}

export function environmentToEnvType(environment: Environment): envType {
	switch (environment) {
		case 'dev':
			return envType.DEV;
		case 'staging':
			return envType.STAGING;
		case 'prod':
			return envType.PROD;
		default:
			return envType.LOCAL;
	}
}
