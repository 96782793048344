import React, { useEffect, useRef, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID } from '@atlassian/jira-confluence-integration-controls/src/constants';
import {
	EmbeddedConfluenceSidepanelContainer,
	useEmbeddedConfluenceSidePanel,
} from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { useEmbeddedPanelEntryPoint } from '../../controllers/use-embedded-panel-entry-point';

const DELAY = 310;

export const PanelInLayout = () => {
	const rightSidebarCurrentState = useActiveRightSidebarState();
	const [{ panel: isOpen }] = useEmbeddedConfluenceSidePanel();

	const [localPanelId, setLocalPanelId] = useState<unknown>();
	const prevPanelIdRef = useRef<unknown>();
	const delayRef = useRef<unknown>(null);

	const panelId = rightSidebarCurrentState?.panelId;
	const { entryPointActions, entryPoint } = useEmbeddedPanelEntryPoint();

	useEffect(() => {
		if (isOpen) {
			entryPointActions?.load();
		}
	}, [isOpen, entryPointActions, localPanelId]);
	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		// @ts-expect-error - TS2769 - No overload matches this call.
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID) {
			/**
			 * The right-sidebar is showing the embedded confluence content...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);

					// TODO: trigger close analytics event
				}, DELAY);
			}
		} else {
			/**
			 * If the right sidebar is not showing the embedded confluence content,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		// @ts-expect-error - TS2769 - No overload matches this call.
		return () => clearTimeout(delayRef.current);
	}, [panelId, rightSidebarCurrentState]);

	if (localPanelId !== EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID) {
		return null;
	}

	return <Box xcss={panelStyles}>{entryPoint}</Box>;
};

export const SafeEmbeddedConfluencePanel = () => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-side-embedded-confluence-panel"
		packageName="jiraIssueCreateConfluenceContent"
	>
		<EmbeddedConfluenceSidepanelContainer>
			<PanelInLayout />
		</EmbeddedConfluenceSidepanelContainer>
	</JSErrorBoundary>
);

const panelStyles = xcss({
	padding: 'space.100',
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
});
