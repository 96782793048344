import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { systemConfigurationGeneralResource } from '@atlassian/jira-router-resources-system-configuration-settings';
import { emailNotificationsLogAccessRoute } from '@atlassian/jira-router-routes-admin-pages-email-notifications-log-routes';
import type NotificationLogsType from '@atlassian/jira-spa-apps-notification-logs';
import { notificationLogsEntryPoint } from '@atlassian/jira-spa-apps-notification-logs/entrypoint';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

const emailNotificationsLogEntryPointPage = createPageEntryPoint({
	main: notificationLogsEntryPoint,
	topNavigationMenuId: MENU_ID.SETTINGS,
});

export const LazyNotificationLogsType = lazyForPaint<typeof NotificationLogsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-email-notifications-log", jiraSpaEntry: "async-system-email-notifications-log" */ '@atlassian/jira-spa-apps-notification-logs'
		),
	),
);

const NotificationLogs = () => (
	<LazyPage Page={LazyNotificationLogsType} pageId="Notification Logs" shouldShowSpinner={false} />
);

export const systemConfigurationEmailNotificationsLogRouteEntries: Route[] = [
	createEntry(emailNotificationsLogAccessRoute, () => ({
        component: NotificationLogs,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			systemConfigurationGeneralResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyNotificationLogsType],
        pageId: 'admin-settings-email-notifications-log',

        entryPoint() {
			return ff('platform.jlove-pulsar.jlovep-423-jsw-notification-logs-ui')
				? emailNotificationsLogEntryPointPage
				: undefined;
		}
    })),
];
