import type { MenuId } from '../../types';

const MENU_ID_DELIM = '   '; // Three spaces

export function toMenuIdFromMenuIdList(menuIds: string[]): MenuId {
	return menuIds.join(MENU_ID_DELIM);
}

export function isMenuIdList(maybeMenuIdList: MenuId): boolean {
	return maybeMenuIdList.indexOf(MENU_ID_DELIM) !== -1;
}

// This should generally be called only after calling isMenuIdList()
export function toMenuIdList(menuIdList: MenuId): string[] {
	return menuIdList.split(MENU_ID_DELIM);
}
