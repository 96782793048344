import log from '@atlassian/jira-common-util-logging/src/log';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ErrorId, ErrorMessage, PackageName } from '../types';
import { logError } from './error-logger';

const HTTP_LOCATION = 'project-settings-apps.http.catch-and-log-fetch-error';

const getStatusCodeGroup = (error: Error): string => {
	if (error instanceof FetchError) {
		const { statusCode } = error;
		if (statusCode >= 100 && statusCode <= 199) return '1xx';
		if (statusCode >= 300 && statusCode <= 399) return '3xx';
		if (statusCode >= 400 && statusCode <= 499) return '4xx';
		if (statusCode >= 500 && statusCode <= 599) return '5xx';
	}

	if (error instanceof ValidationError) {
		return '4xx';
	}

	return 'unknown';
};

/**
 * All fetch calls made in project-settings should be wrapped by this method to ensure HTTP related errors can be
 * separated from JS errors in Splunk. The promise provided to this method MUST NOT perform any logic beside making
 * the fetch call (i.e. transforming responses). The caught error will be rethrown so any downstream methods can perform
 * their own error handling.
 *
 * @param promise Promise to wrap so any errors are caught and logged.
 * @param message Message to include in the log. This should provide context as to what action the user was taking.
 */
const catchAndLogFetchError = <T,>(promise: Promise<T>, message: string): Promise<T> =>
	promise.catch((error) => {
		log.safeErrorWithoutCustomerData(HTTP_LOCATION, message, error);
		throw error;
	});

/**
 * FAMA version of catchAndLogFetchError, which uses the GASv3 analytics pipeline to also allow consumption from
 * SignalFx and Sentry. See logError documentation for examples on how to consume data generated by this pipeline
 * from the different analytics and monitoring platforms.
 *
 * ! IMPORTANT !
 * Just using this method will not do anything. There are followup manual configuration steps
 * that are necessary before the events appear anywhere. Make sure to read the linked pages twice.
 * ! IMPORTANT !
 *
 * All fetch calls made in project-settings should be wrapped by this method to ensure HTTP related errors can be
 * separated from JS errors in Splunk. The promise provided to this method MUST NOT perform any logic beside making
 * the fetch call (i.e. transforming responses). The caught error will be rethrown so any downstream methods can perform
 * their own error handling.
 *
 * @see https://hello.atlassian.net/wiki/spaces/MEASURE/blog/2019/11/19/590258982/FAMA+for+all
 * @see https://hello.atlassian.net/wiki/spaces/JPCE/pages/988564274/Monitoring+FE+experiences
 *
 * @param promise Promise to wrap so all errors are caught and logged as fetch errors. Make sure the promise only does network call and nothing else
 * @param packageName Camel case package name (e.g. 'jiraProjectSettingsAppsIssueTypePage'). Can be arbitrary but must be defined in dataportal + terraform
 * @param errorId Camel case functionality identifier (e.g. 'addIssueType'). Can be arbitrary but must be defined in dataportal + terraform
 * @param message Message to include in the log. This should provide context as to what action the user was taking.
 */
export const famaCatchAndLogFetchError = <T,>(
	promise: Promise<T>,
	packageName: PackageName,
	errorId: ErrorId,
	message: ErrorMessage,
): Promise<T> =>
	promise.catch((error) => {
		const statusCodeGroup = getStatusCodeGroup(error);
		logError(packageName, errorId, message, error, {
			http: true,
			statusCodeGroup,
		});
		throw error;
	});

export default catchAndLogFetchError;
