import React from 'react';
import querystring from 'query-string';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount';
import { FlagServiceSubscriber } from '@atlassian/jira-flags';
import {
	ContextualAnalyticsData,
	MODAL,
	FireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { RouterActions, RouterSubscriber } from '@atlassian/react-resource-router';
import notificationMessages from './common/utils';

/**
 * Defines the component for the `querystring-notifier` module.
 */
const QueryStringNotifier = () => (
	<ContextualAnalyticsData sourceType={MODAL} sourceName="querystringNotifications">
		<FlagServiceSubscriber>
			{(flagService) => (
				<RouterSubscriber>
					{({ location: { search } }) => (
						<RouterActions>
							{({ replace }) => {
								const { showFlag, ...query } = querystring.parse(search);

								if (!showFlag) {
									return null;
								}

								return (
									<>
										{notificationMessages[showFlag] ? (
											<FireOperationalAnalytics
												eventName="querystringNotification shown"
												componentName="flag"
												attributes={{ showFlag }}
											/>
										) : (
											<FireOperationalAnalytics
												eventName="querystringNotification notFound"
												componentName="flag"
												attributes={{ showFlag }}
											/>
										)}

										<OpTrigger
											op={() => {
												if (notificationMessages[showFlag]) {
													flagService.showFlag(notificationMessages[showFlag]);
												}

												replace(`?${querystring.stringify(query)}`);
											}}
										/>
									</>
								);
							}}
						</RouterActions>
					)}
				</RouterSubscriber>
			)}
		</FlagServiceSubscriber>
	</ContextualAnalyticsData>
);

export default QueryStringNotifier;
