import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type helpCenterSettingsCustomerNotificationsType from '@atlassian/jira-spa-apps-help-center-settings-customer-notifications';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsCustomerNotifications = lazyForPaint<
	typeof helpCenterSettingsCustomerNotificationsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-customer-notifications", jiraSpaEntry: "async-help-center-settings-customer-notifications" */ '@atlassian/jira-spa-apps-help-center-settings-customer-notifications'
		),
	),
);

export const HelpCenterSettingsCustomerNotifications = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsCustomerNotifications}
		pageId="help-center-settings-customer-notifications"
		shouldShowSpinner
	/>
);
