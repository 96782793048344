import React, { Component, type ReactNode } from 'react';
import sendExperienceAnalytics, {
	type AdditionalEventAttributes,
} from '@atlassian/jira-common-experience-tracking-analytics';
import { TASK_ABORT, type Action } from '@atlassian/jira-experience-tracker/src/common/constants';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import Context, { type ExperienceContext } from '../common/context';
import type { TrackerCallbacks } from '../common/types';

type Props = {
	isExperienceReady?: boolean;
	experience: string;
	analyticsSource: string | null;
	application: Application | null;
	edition: ApplicationEdition | null;
	children: ReactNode;
	experienceId: string | null | undefined; // example: issueId (changes to this prop reset the experience tracking),
	parentTrackerCallbacks: TrackerCallbacks | null;
	additionalAttributes: AdditionalEventAttributes;
};
/**
 * Provides a framework for tracking user experiences across a React component tree.
 * It manages state related to the tracking of start, success, failure, and abort conditions
 * for user experiences, handling timers and lifecycle events to ensure accurate reporting.
 * Captures errors in child components to mark experiences as unsuccessful, facilitating
 * comprehensive tracking without manual error handling in every component.
 */
export default class ExperienceTrackingProvider extends Component<Props> {
	static defaultProps = {
		parentTrackerCallbacks: null,
		additionalAttributes: {},
	};

	constructor(props: Props) {
		super(props);
		this.resetExperience();
	}

	componentDidMount() {
		this.checkAndStartTimer();
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.isExperienceReady && this.props.isExperienceReady) {
			this.checkAndStartTimer();
		}
	}

	componentDidCatch(error: Error) {
		// This catches any errors that bubble up to the Provider that
		// may not be caught by any ExperienceTrackers
		this.trackExperience({
			wasExperienceSuccesful: false,
			location: 'error-reached-provider',
			additionalAttributes: {
				errorMessage: error.message,
				errorName: error.name,
			},
		});

		// We're deliberately rethrowing the error here because we don't want to interfere with
		// any error handling the container app may have in place.
		throw error;
	}

	componentWillUnmount() {
		clearTimeout(this.timerId);
	}

	onPending = () => {
		this.pendingCount += 1;
		this.shouldTrackExperience = true;
		this.checkAndStartTimer();
	};

	onSuccess = () => {
		this.pendingCount -= 1;
		this.checkAndStartTimer();
	};

	onFailure = (location: string, additionalAttributes?: AdditionalEventAttributes) => {
		// We need to track failure *immediately*, otherwise this component may
		// be unmounted and we'd miss our chance.
		this.trackExperience({
			wasExperienceSuccesful: false,
			location,
			additionalAttributes,
		});
	};

	onAbort = (location: string, additionalAttributes?: AdditionalEventAttributes) => {
		this.trackExperience({
			wasExperienceSuccesful: false,
			action: TASK_ABORT,
			location,
			additionalAttributes,
		});
	};

	checkAndStartTimer() {
		if (this.shouldTrackExperience && this.props.isExperienceReady) {
			this.startTimer();
		}
	}

	startTimer() {
		clearTimeout(this.timerId);

		this.timerId = setTimeout(() => {
			if (this.pendingCount === 0) {
				this.trackExperience({
					wasExperienceSuccesful: true,
				});
			}
		}, 1000);
	}

	timerId: NodeJS.Timeout | undefined;

	// @ts-expect-error - TS2564 - Property 'pendingCount' has no initializer and is not definitely assigned in the constructor.
	pendingCount: number;

	// @ts-expect-error - TS2564 - Property 'shouldTrackExperience' has no initializer and is not definitely assigned in the constructor.
	shouldTrackExperience: boolean;

	// @ts-expect-error - TS2564 - Property 'hasTrackedExperience' has no initializer and is not definitely assigned in the constructor.
	hasTrackedExperience: boolean;

	// To avoid triggering updates in consumers on every render, keep a single copy that updates only on experience resets
	// @ts-expect-error - TS2564 - Property 'memoizedContext' has no initializer and is not definitely assigned in the constructor.
	memoizedContext: ExperienceContext;

	// Resetting must not call setState since this can be executed in the render phase
	resetExperience() {
		clearTimeout(this.timerId);
		this.timerId = undefined;
		this.shouldTrackExperience = false;
		this.hasTrackedExperience = false;
		this.pendingCount = 0;
		this.memoizedContext = {
			consumerCallbacks: {
				onPending: this.onPending,
				onSuccess: this.onSuccess,
				onFailure: this.onFailure,
				onAbort: this.onAbort,
			},
			experienceId: this.props.experienceId,
			experience: this.props.experience,
		};
	}

	trackExperience({
		wasExperienceSuccesful,
		action,
		location,
		additionalAttributes,
	}: {
		wasExperienceSuccesful: boolean;
		action?: Action;
		location?: string;
		additionalAttributes?: AdditionalEventAttributes;
	}) {
		if (this.hasTrackedExperience) {
			// We only ever want to send a single success/failure event for an experience
			return;
		}
		this.hasTrackedExperience = true;
		const { parentTrackerCallbacks, additionalAttributes: additionalAttributesFromProps } =
			this.props;
		if (parentTrackerCallbacks) {
			if (wasExperienceSuccesful) {
				parentTrackerCallbacks.onExperienceSuccess();
			} else {
				parentTrackerCallbacks.onExperienceFailure(location, {
					...additionalAttributesFromProps,
					...additionalAttributes,
				});
			}
		} else {
			const { experience } = this.memoizedContext;
			const { analyticsSource, application, edition } = this.props;
			analyticsSource &&
				sendExperienceAnalytics({
					experience,
					action,
					wasExperienceSuccesful,
					location,
					analyticsSource,
					application,
					edition,
					additionalAttributes: {
						...additionalAttributesFromProps,
						...additionalAttributes,
					},
				});
		}
	}

	// Check every render to reset the experience state instance variables
	checkExperienceIdForExperienceReset() {
		if (this.memoizedContext.experienceId !== this.props.experienceId) {
			this.resetExperience();
		}
	}

	render() {
		this.checkExperienceIdForExperienceReset();
		return <Context.Provider value={this.memoizedContext}>{this.props.children}</Context.Provider>;
	}
}
