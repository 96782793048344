import { useEffect } from 'react';
import clearMarksWithPrefix from '@atlassian/jira-common-performance/src/clear-marks-with-prefix.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import {
	isEventDelayActive,
	setEventDelayActive,
} from '@atlassian/jira-is-event-delay-active-utils/src/utils/index.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import usePageLoadedSubscriber from '@atlassian/jira-spa-state-controller/src/components/page-loaded-state/index.tsx';
import { isAutomaticExposureCollectionEnabled } from '@atlassian/jira-track-all-changes-sampling';
import {
	DELAY_PERIOD_MARK_PREFIX,
	DELAY_PERIOD_MARK_START,
	DELAY_PERIOD_MARK_END,
} from './constants';

const STOP_DELAY_TIMEOUT = 10000;

let stopDelayTimeout: ReturnType<typeof setTimeout>;
let lastDelayActionPromise = getAnalyticsWebClientPromise().then((clientWrapper) =>
	clientWrapper.getInstance(),
);

export const stopLowPriorityEventDelay = () => {
	lastDelayActionPromise = lastDelayActionPromise.then((client) => {
		if (!isEventDelayActive()) {
			return client;
		}

		clearTimeout(stopDelayTimeout);

		// stopLowPriorityEventDelay performs a flush of the delayed events,
		// so the delayed period needs to be marked as ended _before_ calling that method.
		setMark(DELAY_PERIOD_MARK_END);
		setEventDelayActive(false);

		client.stopLowPriorityEventDelay();

		return client;
	});
};

export const startLowPriorityEventDelay = () => {
	lastDelayActionPromise = lastDelayActionPromise.then((client) => {
		// If startLowPriorityEventDelay is called when it's already active, then we are just extending
		// the delay period, so we don't want to reset any metadata we have captured about the original start.
		if (!isEventDelayActive()) {
			clearMarksWithPrefix(DELAY_PERIOD_MARK_PREFIX);
			setMark(DELAY_PERIOD_MARK_START);
		}

		client.startLowPriorityEventDelay();
		setEventDelayActive(true);
		clearTimeout(stopDelayTimeout);
		stopDelayTimeout = setTimeout(stopLowPriorityEventDelay, STOP_DELAY_TIMEOUT);
		return client;
	});
};

export const getLastDelayActionPromise = () => lastDelayActionPromise;

const EventDelayController = () => {
	const [loadedState] = usePageLoadedSubscriber();

	useEffect(() => {
		/**
		 * The delay mechanism is currently locked so that only automatic exposure events
		 * can leverage it.
		 * We can remove the performance overhead of starting and stopping critical sections when
		 * these events won't be getting fired.
		 */
		if (!loadedState.hasLoaded && isAutomaticExposureCollectionEnabled()) {
			startLowPriorityEventDelay();
		}
	}, [loadedState.hasLoaded]);

	return null;
};

export default EventDelayController;
