import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_PRODUCT_JSW_CONFIGURATION } from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const productsJswConfigurationResource = createResource<any>({
	type: RESOURCE_TYPE_PRODUCT_JSW_CONFIGURATION,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-products-jsw-configuration", jiraSpaEntry: "async-resource-products-jsw-configuration" */ './controllers/get-data'
		),
});
