import type { ScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier/types';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectSidebarNavInfo } from './types';

export const fetchProjectSidebarNavInfo = (
	scopeIdentifier: ScopeIdentifier,
): Promise<ProjectSidebarNavInfo> => {
	const { identifierType, identifierValue } = scopeIdentifier;
	if (identifierType == null || identifierValue == null) {
		throw new Error('invalid identifier specified');
	}
	let queryParam = '';
	switch (identifierType) {
		case 'boardId':
			queryParam = `boardId=${identifierValue}`;
			break;
		case 'projectKey':
		case 'projectId':
			queryParam = `projectIdOrKey=${identifierValue}`;
			break;
		default:
			break;
	}
	return performGetRequest(`/rest/navigation/4/sidebar?${queryParam}`);
};
