import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common';
import {
	routeGroupsProjectSettingsServicedeskJourneysRoute,
	routeGroupsProjectSettingsServicedeskJourneyConfigurationRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import { journeyConfigPageEntryPoint } from './ui/journey-config';
import { journeysPageEntryPoint } from './ui/journeys';

export const journeysRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskJourneysRoute, () => ({
        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        layout: serviceProjectLayout,
        resources: [...getServiceDeskSettingsResources()],
        entryPoint: () => journeysPageEntryPoint
    })),
	createEntry(routeGroupsProjectSettingsServicedeskJourneyConfigurationRoute, () => ({
        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        layout: serviceProjectLayout,
        resources: [...getServiceDeskSettingsResources()],
        entryPoint: () => journeyConfigPageEntryPoint
    })),
];
