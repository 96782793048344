import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SAVED_VIEWS,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PROGRAM_BOARDS,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PLAN_INFO,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCHEDULING,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCENARIOS,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_AUTO_SCHEDULER,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_CUSTOM_FIELDS,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_EXCLUSION_RULES,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_ISSUE_SOURCES,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_FIND_YOUR_ISSUE,
	ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_REMOVED_ISSUES,
	ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,
	ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	archivedPlansRoute,
	trashedPlansRoute,
	trashedPlanDirectLinkRoute,
	arjSettingsFinancialYearPageRoute,
	planIncrementRoute,
	planIncrementsRoute,
} from './ui/spa';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	softwareArjSettingsDependenciesRoute,
	softwareArjSettingsDependenciesRedirectRoute,
	softwareArjSettingsLoadingLimitsRoute,
	softwareArjSettingsLoadingLimitsRedirectRoute,
	softwareArjSettingsPermissionsRoute,
	softwareArjSettingsPermissionsRedirectRoute,
} from './ui/settings';

export const softwareAdvancedRoadmapsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	path: '/jira/plans/:planId/scenarios/:scenarioId',
};

export const softwarePlanDependenciesRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	path: '/jira/plans/:planId/scenarios/:scenarioId/dependencies',
};

export const softwarePlanSummaryRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
	path: '/jira/plans/:planId/scenarios/:scenarioId/summary',
};

export const softwarePlanCalendarRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	path: '/jira/plans/:planId/scenarios/:scenarioId/calendar',
};

export const softwarePlanReleasesRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_RELEASES,
	path: '/jira/plans/:planId/scenarios/:scenarioId/releases',
};

export const softwarePlanTimelineRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	path: '/jira/plans/:planId/scenarios/:scenarioId/timeline',
};

export const softwarePlanSettingsPermissionsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PERMISSIONS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/permissions',
};

export const softwarePlanSettingsSavedViewsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SAVED_VIEWS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/saved-views',
};

export const softwarePlanSettingsProgramBoardsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PROGRAM_BOARDS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/program-boards',
};

export const softwarePlanSettingsPlanInfoRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_PLAN_INFO,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/plan-info',
};

export const softwarePlanSettingsSchedulingRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCHEDULING,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/scheduling',
};

export const softwarePlanSettingsScenariosRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_SCENARIOS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/scenarios',
};

export const softwarePlanSettingsAutoSchedulerRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_AUTO_SCHEDULER,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/auto-scheduler',
};

export const softwarePlanSettingsCustomFieldsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_CUSTOM_FIELDS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/custom-fields',
};

export const softwarePlanSettingsExclusionRulesRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_EXCLUSION_RULES,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/exclusion-rules',
};

export const softwarePlanSettingsIssueSourcesRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_ISSUE_SOURCES,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/issue-sources',
};

export const softwarePlanSettingsFindYourIssueRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_FIND_YOUR_ISSUE,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/find-your-issue',
};

export const softwarePlanSettingsRemovedIssuesRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SETTINGS_REMOVED_ISSUES,
	path: '/jira/plans/:planId/scenarios/:scenarioId/settings/removed-issues',
};

export const softwarePlanTeamsRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_TEAMS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/teams',
};

export const softwareAdvancedRoadmaps1Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	path: '/jira/plans/:planId(\\d+)',
};

export const softwareAdvancedRoadmapsRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT,
	path: '/secure/PortfolioPlanView.jspa',
};

export const softwareAdvancedRoadmaps3Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	path: '/jira/plans/reports/:reportId',
};

export const softwareAdvancedRoadmapsRedirect1Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_REDIRECT,
	path: '/secure/PortfolioReportView.jspa',
};

export const softwareAdvancedRoadmaps5Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/export',
};

export const softwareAdvancedRoadmapsSetupRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_SETUP,
	path: '/jira/plans/:planId/setup',
};

export const softwareAdvancedRoadmapsGettingStartedRedirectRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ADVANCED_ROADMAPS_GETTING_STARTED_REDIRECT,
	path: '/secure/PortfolioGettingStarted.jspa',
};
