import { createHook, createSubscriber } from '@atlassian/react-sweet-state';
import Store from '../store';
import type { Actions, State } from '../types';

export const SpaStateSubscriber = createSubscriber<State, Actions>(Store);

export const SpaStateActionsSubscriber = createSubscriber<State, Actions, undefined, undefined>(
	Store,
	{
		selector: null,
	},
);

export const useSpaState = createHook(Store);

export const useSpaStateActions = createHook(Store, {
	selector: null,
});
