import React, { Component, createContext, type ReactNode, useContext } from 'react';
import noop from 'lodash/noop';
import { getIsJsmBlankProjectTemplate } from '@atlassian/jira-common-constants/src/project-template-type-keys.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { LazyComponent as AsyncProjectBuilderDrawerComponent } from '@atlassian/jira-servicedesk-project-builder/src/async';
import { useRouterActions, type RouterActionsType } from '@atlassian/react-resource-router';
import type { ConsumerData, OpenDrawerMethod } from './types';
import { clearResourcesAssociatedWithProjectKey } from './utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const Context = createContext<ConsumerData>({
	data: {
		shouldRenderDrawer: false,
		isOpen: false,
		isNextGen: false,
		showExperienceSelection: true,
		recommendedTemplate: undefined,
		defaultSelectedTemplateKey: '',
		source: '',
		isTemplatePickerOpenInitially: false,
		isImportProjectOpenInitially: false,
		selectedProjectType: null,
		recommendationSessionId: undefined,
	},
	methods: {
		open: noop,
		close: noop,
		onSuccess: noop,
		onCreateStarted: noop,
		setOnCloseCallback: noop,
		clearOnCloseCallback: noop,
	},
} as ConsumerData);

export type Props = {
	children: ReactNode;
	setLocation: RouterActionsType['push'];
};
type State = {
	isOpen: boolean;
	shouldRenderDrawer: boolean;
	isNextGen: boolean;
	showExperienceSelection: boolean;
	recommendedTemplate:
		| undefined
		| {
				templateKey: string;
				isSimplified: boolean;
		  };
	defaultSelectedTemplateKey: string;
	source?: string;
	isTemplatePickerOpenInitially: boolean;
	isImportProjectOpenInitially: boolean;
	selectedProjectType: ProjectType | null;
	recommendationSessionId?: string;
	onCloseCallback: () => void;
};
// eslint-disable-next-line jira/react/no-class-components
export const ProviderInternal = class ProjectCreateDrawerProvider extends Component<Props, State> {
	state = {
		isOpen: false,
		shouldRenderDrawer: false,
		isNextGen: false,
		showExperienceSelection: true,
		recommendedTemplate: undefined,
		defaultSelectedTemplateKey: '',
		source: '',
		isTemplatePickerOpenInitially: false,
		isImportProjectOpenInitially: false,
		selectedProjectType: null,
		recommendationSessionId: undefined,
		onCloseCallback: noop,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess = (projectKey: any): void => {
		if (projectKey) {
			this.props.setLocation(projectKey.returnUrl);
			this.close(true);
		} else {
			this.close(false);
		}
	};

	// Callback that is called once when the drawer is closed.
	setOnCloseCallback = (callback: () => void) => {
		this.setState({
			onCloseCallback: callback,
		});
	};

	clearOnCloseCallback = () => {
		this.setState({ onCloseCallback: noop });
	};

	open: OpenDrawerMethod = (attributes = {}) => {
		// @ts-expect-error - TS2345 - Argument of type '{ isNextGen?: boolean | undefined; showExperienceSelection?: boolean | undefined; recommendedTemplate?: { templateKey: string; isSimplified: boolean; } | undefined; defaultSelectedTemplateKey?: string | undefined; ... 4 more ...; shouldRenderDrawer: true; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, "source" | "isOpen" | "isNextGen" | ... 5 more ... | "isTemplatePickerOpenInitially"> | null) | Pick<...> | null'.
		this.setState({
			isOpen: true,
			shouldRenderDrawer: true,
			...attributes,
		});
	};

	close = (projectCreated?: boolean) => {
		const callOnCloseCallBack = this.state.source === 'navigationNext' || !projectCreated;
		this.setState({
			isOpen: false,
			isNextGen: false,
			showExperienceSelection: true,
			recommendedTemplate: undefined,
			defaultSelectedTemplateKey: '',
			source: '',
			isTemplatePickerOpenInitially: false,
			isImportProjectOpenInitially: false,
			selectedProjectType: null,
			recommendationSessionId: undefined,
		});

		if (callOnCloseCallBack) {
			// Call the added callback
			this.state.onCloseCallback();
		}
	};

	closeOld = () => {
		this.setState({
			isOpen: false,
			isNextGen: false,
			showExperienceSelection: true,
			recommendedTemplate: undefined,
			defaultSelectedTemplateKey: '',
			source: '',
			isTemplatePickerOpenInitially: false,
			selectedProjectType: null,
			recommendationSessionId: undefined,
		});
		this.state.onCloseCallback();
	};

	onCreateStarted = (projectKey: string, templateKey: string) => {
		if (getIsJsmBlankProjectTemplate(templateKey)) {
			AsyncProjectBuilderDrawerComponent.preload();
		}
		clearResourcesAssociatedWithProjectKey(projectKey);
	};

	render() {
		const { children } = this.props;
		const {
			isOpen,
			isNextGen,
			source,
			showExperienceSelection,
			shouldRenderDrawer,
			recommendedTemplate,
			defaultSelectedTemplateKey,
			isTemplatePickerOpenInitially,
			isImportProjectOpenInitially,
			selectedProjectType,
			recommendationSessionId,
		} = this.state;
		return (
			<Context.Provider
				value={{
					data: {
						isOpen,
						isNextGen,
						source,
						showExperienceSelection,
						shouldRenderDrawer,
						recommendedTemplate,
						defaultSelectedTemplateKey,
						isTemplatePickerOpenInitially,
						isImportProjectOpenInitially,
						selectedProjectType,
						recommendationSessionId,
					},
					methods: {
						open: this.open,
						close: this.close,
						onSuccess: this.onSuccess,
						onCreateStarted: this.onCreateStarted,
						setOnCloseCallback: this.setOnCloseCallback,
						clearOnCloseCallback: this.clearOnCloseCallback,
					},
				}}
			>
				{children}
			</Context.Provider>
		);
	}
};

export const ProjectCreateDrawerProvider = (props: { children: ReactNode }) => {
	const { push } = useRouterActions();
	return <ProviderInternal {...props} setLocation={push} />;
};

export const { Consumer: ProjectCreateDrawerConsumer } = Context;
Context.displayName = 'ProjectCreateDrawerConsumer';

export const useProjectCreateDrawer = () => {
	const projectCreateDrawer = useContext(Context);
	if (!projectCreateDrawer) {
		throw new Error('useProjectCreateDrawer must be used within a ProjectCreateDrawerProvider');
	}
	return projectCreateDrawer;
};
