import {
	ASSETS_CF_TYPE,
	ASSIGNEE_TYPE,
	ATTACHMENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DESCRIPTION_TYPE,
	DUE_DATE_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	SUMMARY_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	VERSION_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { toPackageName } from '@atlassian/jira-project-settings-apps-common/src/consts.tsx';
import type { PackageName } from '@atlassian/jira-project-settings-apps-common/src/types';

export const PACKAGE_NAME: PackageName = toPackageName(
	'jiraProjectSettingsAppsIssueTypePageProductFeatures',
);

export const INACTIVE_LIST = 'inactive-list' as const;
export const FIELD_TYPE_LIST = 'field-type-list' as const;
export const DELETE_DROPPABLE_ID = 'delete-droppable-id';
export const IGNORE_CLICK_OUTSIDE_CLASS_NAME = '9|.^=*D(riA_=lUv787iQ7hwW5HIz=}t/OdJy%q';

export const DO_NOT_ANIMATE_WIDTH = 'DO_NOT_ANIMATE_WIDTH' as const;
// UI interactions (prefix is needed because all Jira uses the same analytics event for interactions)
export const SEARCH_PREVIOUSLY_CREATED_FIELDS_INTERACTION = 'swift_searchPreviouslyCreatedFields';

export const JSD_FIELD_HELPTEXT = 'jsd.field.helpText';

export const ASSOCIATED_SCREEN_ID_REQUEST_OWNER_PROPERTY_KEY =
	'jsm.issue.type.associated.screen.id';
export const ID_REQUEST_OWNER_PROPERTY_KEY = 'jsm.issue.type.id';
export const NAME_REQUEST_OWNER_PROPERTY_KEY = 'jsm.issue.type.name';
export const ICON_ID_REQUEST_OWNER_PROPERTY_KEY = 'jsm.issue.type.icon.id';
export const ICON_URL_REQUEST_OWNER_PROPERTY_KEY = 'jsm.issue.type.icon.url';

export const REQUEST_OWNER_PROPERTY_KEYS = [
	ASSOCIATED_SCREEN_ID_REQUEST_OWNER_PROPERTY_KEY,
	NAME_REQUEST_OWNER_PROPERTY_KEY,
	ICON_ID_REQUEST_OWNER_PROPERTY_KEY,
	ICON_URL_REQUEST_OWNER_PROPERTY_KEY,
] as const;

export const SUGGESTED_GROUP = 'suggestedGroup' as const;
export const ASSETS_GROUP = 'assetsGroup' as const;
export const DATES_GROUP = 'datesGroup' as const;
export const LABELS_GROUP = 'labelsGroup' as const;
export const NUMBER_GROUP = 'numberGroup' as const;
export const PEOPLE_GROUP = 'peopleGroup' as const;
export const SELECT_GROUP = 'selectGroup' as const;
export const SYSTEM_GROUP = 'systemGroup' as const;
export const TEXT_GROUP = 'textGroup' as const;
export const VERSION_GROUP = 'versionGroup' as const;
export const ADVANCED_GROUP = 'advancedGroup' as const;
export const OTHER_GROUP = 'otherGroup' as const;
export const RESTRICTED_GROUP = 'restrictedGroup' as const;
export const AI_SUGGESTED_GROUP = 'aiSuggestedGroup' as const;

export const INIT = 'INIT' as const;
export const LOADING = 'LOADING' as const;
export const SUCCESS = 'SUCCESS' as const;
export const FAILURE = 'FAILURE' as const;

export type Group =
	| typeof SUGGESTED_GROUP
	| typeof ASSETS_GROUP
	| typeof DATES_GROUP
	| typeof LABELS_GROUP
	| typeof NUMBER_GROUP
	| typeof PEOPLE_GROUP
	| typeof SELECT_GROUP
	| typeof SYSTEM_GROUP
	| typeof TEXT_GROUP
	| typeof VERSION_GROUP
	| typeof ADVANCED_GROUP
	| typeof OTHER_GROUP
	| typeof RESTRICTED_GROUP
	| typeof AI_SUGGESTED_GROUP;

/**
 * classicFieldTypesMap and ngFieldTypesMap are done following
 * https://hello.atlassian.net/wiki/spaces/VPORT/pages/1204567097/Classic+fields+mapping+to+issue-type-page+app.
 * We might need to add more field types into ngFieldTypesMap, otherwise omitted fields will be grouped within "Other" group.
 */
export const classicFieldTypesMap: {
	[key: string]: Group;
} = {
	assetspicker: ADVANCED_GROUP,
	date: DATES_GROUP,
	datetime: DATES_GROUP,
	labelpicker: LABELS_GROUP,
	number: NUMBER_GROUP,
	multiuserpicker: PEOPLE_GROUP,
	userpicker: PEOPLE_GROUP,
	checkbox: SELECT_GROUP,
	radio: SELECT_GROUP,
	cascadingselect: SELECT_GROUP,
	multiselect: SELECT_GROUP,
	select: SELECT_GROUP,
	attachment: SYSTEM_GROUP,
	text: TEXT_GROUP,
	textarea: TEXT_GROUP,
};

export const ngFieldTypesMap: {
	[key: string]: Group;
} = {
	[ASSETS_CF_TYPE]: ADVANCED_GROUP,
	[DATE_CF_TYPE]: DATES_GROUP,
	[DATETIME_CF_TYPE]: DATES_GROUP,
	[LABELS_CF_TYPE]: LABELS_GROUP,
	[NUMBER_CF_TYPE]: NUMBER_GROUP,
	people: PEOPLE_GROUP,
	[MULTI_CHECKBOXES_CF_TYPE]: SELECT_GROUP,
	[CASCADING_SELECT_CF_TYPE]: SELECT_GROUP,
	[SELECT_CF_TYPE]: SELECT_GROUP,
	[ATTACHMENT_TYPE]: SYSTEM_GROUP,
	[TEXT_CF_TYPE]: TEXT_GROUP,
	[TEXT_AREA_CF_TYPE]: TEXT_GROUP,
	text: TEXT_GROUP,
	[MULTI_VERSION_CF_TYPE]: ADVANCED_GROUP,
	[VERSION_CF_TYPE]: ADVANCED_GROUP,
	[SUMMARY_TYPE]: SYSTEM_GROUP,
	[DESCRIPTION_TYPE]: SYSTEM_GROUP,
	[LABELS_TYPE]: SYSTEM_GROUP,
	[PEOPLE_CF_TYPE]: PEOPLE_GROUP,
	[PRIORITY_TYPE]: SYSTEM_GROUP,
	[DUE_DATE_TYPE]: SYSTEM_GROUP,
	[TIME_ESTIMATE_TYPE]: SYSTEM_GROUP,
	[REPORTER_TYPE]: SYSTEM_GROUP,
	[ASSIGNEE_TYPE]: SYSTEM_GROUP,
};

export const SWIFT_VERSION_M1 = 'M1';
export const SWIFT_VERSION_M2 = 'M2';

export type SwiftVersion = typeof SWIFT_VERSION_M1 | typeof SWIFT_VERSION_M2;
