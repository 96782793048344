import merge from 'lodash/merge';
import type { CSSFn, ItemState } from '@atlaskit/menu';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	castRealCSSFnToWronglyTypedCSSFn,
	type LegacyEmotionCssObject,
} from '../../../../../types';

export const cssFn: CSSFn = castRealCSSFnToWronglyTypedCSSFn<ItemState>(
	(currentStyles: LegacyEmotionCssObject): LegacyEmotionCssObject => {
		const newStyle = {
			'[data-item-elem-after] [data-right-arrow]': {
				display: 'none',
			},
			'&:hover [data-item-elem-after]': {
				'& > :not([data-right-arrow])': {
					display: 'none',
				},
				'& [data-right-arrow]': {
					display: 'flex',
				},
			},

			height: `${gridSize * 5.5}px`,
		};
		return merge(currentStyles, newStyle);
	},
);
