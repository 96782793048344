import {
	ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	ROUTE_NAMES_PLAYBOOKS_LIST,
	ROUTE_NAMES_PLAYBOOKS_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes';

const getProjectSettingsUrl = (product: string): string =>
	`/jira/${product}/projects/:projectKey/settings`;

export const SERVICE_DESK_PRODUCT = 'servicedesk';

export const servicedeskPlaybooksListRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PLAYBOOKS_LIST,
	path: `${getProjectSettingsUrl(SERVICE_DESK_PRODUCT)}/playbooks`,
	exact: true,
};

export const servicedeskPlaybooksEditRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PLAYBOOKS_EDIT,
	path: `${getProjectSettingsUrl(SERVICE_DESK_PRODUCT)}/playbooks/:id`,
	exact: true,
};
