import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { coreProjectLayout } from '@atlassian/jira-core-project-layout';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	routeGroupsServicedeskRouteNamesProformaExportRoute,
	routeGroupsServicedeskRouteNamesProformaIssueFormsGeneralRoute,
	routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute,
	routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRoute,
	routeGroupsClassicBusinessRouteNamesProformaExportRoute,
	routeGroupsClassicBusinessRouteNamesProformaIssueFormsGeneralRoute,
	routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRoute,
	routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute,
	routeGroupsSoftwareRouteNamesProformaExportRoute,
	routeGroupsSoftwareRouteNamesProformaIssueFormsGeneralRoute,
	routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRoute,
	routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute,
} from '@atlassian/jira-router-routes-proforma-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import ProformaExport, { LazyProformaExport } from './ui/export';
import ProformaIssueFormsDirect, { LazyProformaIssueFormsDirect } from './ui/issue-forms-direct';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/issue-forms-direct/embed';
import ProformaIssueFormsGeneral, { LazyProformaIssueFormsGeneral } from './ui/issue-forms-general';

export const serviceDeskProformaRouteEntries: Route[] = [
	createEntry(routeGroupsServicedeskRouteNamesProformaExportRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaExport,
        forPaint: [LazyAtlassianNavigation, LazyProformaExport]
    })),
	createEntry(routeGroupsServicedeskRouteNamesProformaIssueFormsGeneralRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaIssueFormsGeneral,
        forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsGeneral],
        perfMetricKey: 'issue-forms-general'
    })),
	createEntry(routeGroupsServicedeskRouteNamesProformaIssueFormsDirectRoute, () => ({
        layout: serviceProjectLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirect,

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        forPaint: [LazyProformaIssueFormsDirect],
        perfMetricKey: 'issue-forms-direct'
    })),
	createEntry(routeGroupsServicedeskRouteNamesProformaIssueFormsDirectEmbedRoute, () => ({
        layout: chromelessLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirectEmbed,
        forPaint: [LazyProformaIssueFormsDirectEmbed],
        perfMetricKey: 'issue-forms-direct-embed'
    })),
];

export const classicBusinessProformaRouteEntries: Route[] = [
	createEntry(routeGroupsClassicBusinessRouteNamesProformaExportRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaExport,
        forPaint: [LazyAtlassianNavigation, LazyProformaExport]
    })),
	createEntry(routeGroupsClassicBusinessRouteNamesProformaIssueFormsGeneralRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaIssueFormsGeneral,
        forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsGeneral],
        perfMetricKey: 'issue-forms-general'
    })),
	createEntry(routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectRoute, () => ({
        layout: coreProjectLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirect,

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        forPaint: [LazyProformaIssueFormsDirect],
        perfMetricKey: 'issue-forms-direct'
    })),
	createEntry(routeGroupsClassicBusinessRouteNamesProformaIssueFormsDirectEmbedRoute, () => ({
        layout: chromelessLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirectEmbed,
        forPaint: [LazyProformaIssueFormsDirectEmbed],
        perfMetricKey: 'issue-forms-direct-embed'
    })),
];

export const softwareProformaRouteEntries: Route[] = [
	createEntry(routeGroupsSoftwareRouteNamesProformaExportRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaExport,
        forPaint: [LazyAtlassianNavigation, LazyProformaExport]
    })),
	createEntry(routeGroupsSoftwareRouteNamesProformaIssueFormsGeneralRoute, () => ({
        layout: globalLayout,
        resources: [...getNavigationResources()],
        component: ProformaIssueFormsGeneral,
        forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsGeneral],
        perfMetricKey: 'issue-forms-general'
    })),
	createEntry(routeGroupsSoftwareRouteNamesProformaIssueFormsDirectRoute, () => ({
        layout: softwareProjectLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirect,

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        forPaint: [LazyProformaIssueFormsDirect],
        perfMetricKey: 'issue-forms-direct'
    })),
	createEntry(routeGroupsSoftwareRouteNamesProformaIssueFormsDirectEmbedRoute, () => ({
        layout: chromelessLayout,
        resources: [projectContextResource],
        component: ProformaIssueFormsDirectEmbed,
        forPaint: [LazyProformaIssueFormsDirectEmbed],
        perfMetricKey: 'issue-forms-direct-embed'
    })),
];

export const proformaRouteEntries: Route[] = [
	...serviceDeskProformaRouteEntries,
	...classicBusinessProformaRouteEntries,
	...softwareProformaRouteEntries,
];
