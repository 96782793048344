import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskQueuesIssueViewType from '@atlassian/jira-spa-apps-servicedesk-queues-issue-view';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyServicedeskQueuesIssueView = lazyForPaint<typeof ServicedeskQueuesIssueViewType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-queues-issue-view", jiraSpaEntry: "async-servicedesk-queues-issue-view" */ '@atlassian/jira-spa-apps-servicedesk-queues-issue-view'
		),
	{
		ssr: false,
	},
);
type Props = RouteContext;
/**
 * Constructs a URL path for navigating to a specific issue view within a service desk queue, incorporating optional parameters for issue identification and view customization.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: Props) => {
	const issueKey = `/${(match.params && match.params.issueKey) || ''}`;
	const queueId = `/${(match.params && match.params.queueId) || ''}`;
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;
	return (
		<LazyPage
			Page={LazyServicedeskQueuesIssueView}
			pageId={`spa-apps/servicedesk/queues/issue${queueId}${issueKey}${oldIssueView}`}
			shouldShowSpinner
		/>
	);
};
