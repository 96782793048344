import { token } from '@atlaskit/tokens';
import type { JiraConfigStateConfigurationStatus } from '@atlassian/jira-relay/src/__generated__/mainCodeInJiraUpdatedQuery.graphql';
import type { HelpArticle } from './types';

// Known errors where that are recoverable.
export const nonCriticalGqlErrors = [
	'HTTP status [403] while retrieving Bitbucket repository.',
	'HTTP status [404] while retrieving Bitbucket repository.',
	"Failed to retrieve Bitbucket repository because the user wasn't authorized.",
] as const;

// Known errors that the spa will handle and not log as a bad event.
export const nonReliabilityCriticalErrors = [
	'HTTP status [429] while retrieving Bitbucket repository.',
	"The user does not have 'View Development Tools' permission for the Jira project or the project does not exist",
	'The user does not have admin permission for the site, Jira, project or the project does not exist.',
	'Unable to get providers when contacting Jira due to 401 Unauthorized',
] as const;

export const cardBoxShadow = token(
	'elevation.shadow.overflow',
	'0 1px 1px rgb(9 30 66 / 25%), 0 0 1px 1px rgb(9 30 66 / 13%)',
);
export const cardBoxHoverShadow = token(
	'elevation.shadow.overflow',
	'rgba(9, 30, 66, 0.31) 0px 0px 1px 0px, rgba(9, 30, 66, 0.25) 0px 8px 16px -6px',
); // we use  in package.json file, so box-shadow here must be a value, cannot use elevation

export const MAX_NUMBER_OF_PULL_REQUESTS_TO_QUERY = 1000;
export const PULL_REQUESTS_TABLE_PAGE_SIZE = 12;
export const PULL_REQUESTS_UPDATED_FROM_NUMBER_OF_DAYS = 30;
export const COLLAPSIBLE_REGION_ID = 'collapsible-repository-list';
export const REPOSITORIES_HEADER_ID = 'repository-list-header';
export const COLLAPSIBLE_REGION_DESCRIPTION_ID = 'repository-list-description';

export const featureIdToHelpMap: Record<string, HelpArticle> = {
	'repository-list.pull-requests.empty-state': {
		helpArticleId: '5WUm7jlQnN9aiw9KOp1Ks1',
		href: 'https://support.atlassian.com/jira-software-cloud/docs/view-development-information-for-an-issue/',
	},
	'repository-list.pull-requests.description': {
		helpArticleId: '5WUm7jlQnN9aiw9KOp1Ks1',
		href: 'https://support.atlassian.com/jira-software-cloud/docs/view-development-information-for-an-issue/',
	},
};

export const FILTER_CONTAINER_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filters.filter-container';
export const NO_RESULTS_CLEAR_FILTERS_BUTTON_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filtered-table.pull-request-table.no-results.clearfiltersbutton';
export const PULL_REQUESTS_TABLE_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.pull-requests-table';
export const PULL_REQUESTS_DYNAMIC_TABLE_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.pull-requests-table.dynamic-table';
export const PULL_REQUESTS_EMPTY_STATE_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.pull-requests-empty-state';
export const SUMMARY_CELL_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filtered-table.pull-request-table.summary-cell';
export const SUMMARY_CELL_TITLE_LINK_TEST_ID = `${SUMMARY_CELL_TEST_ID}.title-link`;
export const SUMMARY_CELL_TARGET_BRANCH_LINK_TEST_ID = `${SUMMARY_CELL_TEST_ID}.target-branch-link`;
export const SUMMARY_CELL_REPOSITORY_LINK_TEST_ID = `${SUMMARY_CELL_TEST_ID}.repository-link`;
export const REVIEWERS_CELL_TEST_ID =
	'software-code-page.ui.pages.repository-list.pull-requests.filtered-table.pull-request-table.reviewers-cell';
export const STATUS_CELL_TEST_ID =
	'software-code-page.ui.pages.repository-list.pull-requests.filtered-table.pull-request-table.status-cell';
export const COMMENTS_CELL_TEST_ID =
	'software-code-page.ui.pages.repository-list.pull-requests.filtered-table.pull-request-table.comments-cell';
export const LAST_UPDATED_CELL_TEST_ID =
	'software-code-page.ui.pages.repository-list.pull-requests.filtered-table.pull-request-table.last-updated-cell';
export const PULL_REQUESTS_ERROR_STATE_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-error-state';
export const REPOSITORIES_ERROR_STATE_TEST_ID =
	'software-code-page.ui.pages.main-page.repositories.repository-error-state';
export const REPOSITORIES_FILTER_BUTTON_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filters.repository-filter.filter-button';
export const AUTHOR_FILTER_BUTTON_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filters.author-filter.filter-button';
export const REVIEWERS_FILTER_BUTTON_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filters.reviewers-filter.filter-button';
export const STATUS_FILTER_BUTTON_TEST_ID =
	'software-code-page.ui.pages.main-page.pull-requests.pull-request-list.filters.status-filter.filter-button';
export const QUERY_PARAM_SUMMARY = 'summaryFilter' as const;
export const QUERY_PARAM_AUTHORS = 'authorsFilter' as const;
export const QUERY_PARAM_REVIEWERS = 'reviewersFilter' as const;
export const QUERY_PARAM_STATUS = 'statusFilter' as const;
export const QUERY_PARAM_REPOSITORIES = 'repositoriesFilter' as const;
export const QUERY_PARAM_SORT_KEY = 'sortKey' as const;
export const QUERY_PARAM_SORT_ORDER = 'sortOrder' as const;
export const queryParamsToWatch = [
	QUERY_PARAM_SUMMARY,
	QUERY_PARAM_AUTHORS,
	QUERY_PARAM_REVIEWERS,
	QUERY_PARAM_STATUS,
	QUERY_PARAM_REPOSITORIES,
	QUERY_PARAM_SORT_KEY,
	QUERY_PARAM_SORT_ORDER,
];

export const VcsProviderIds = {
	BITBUCKET: 'bitbucket',
} as const;

export const DataDepotProviderIds = {
	BITBUCKET_PROD: 'kRiZ7boFApjBINFF1L7wPrCa4iF8O6R9',
	BITBUCKET_STG: 'cOwtZiBfejjJAryJH0WPb1otsa4HrI1N',
	GITHUB_PROD: 'com.github.integration.production',
	GITHUB_STG: 'com.github.integration.staging',
} as const;

export const FEEDBACK_COLLECTOR_LOCATION = 'jsw.code.page.header';

// only for use in jira_improve_scm_onboarding experiment
export const eligibleForConfigurationScreenStatuses: JiraConfigStateConfigurationStatus[] = [
	'NOT_CONFIGURED',
	'NOT_SET',
	'PARTIALLY_CONFIGURED',
];
export const notEligibleForConfigurationScreenStatuses: JiraConfigStateConfigurationStatus[] = [
	'CONFIGURED',
	'PROVIDER_ACTION_CONFIGURED',
	'PROVIDER_ACTION_NOT_CONFIGURED',
];
