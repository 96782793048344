import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	labelNonFinal: {
		id: 'periscope.skeleton.label-non-final',
		defaultMessage: 'Loading',
		description: 'Label associated to the page skeleton while data is being requested.',
	},
	suggestionsLabelNonFinal: {
		id: 'periscope.suggestions-skeleton.label-non-final',
		defaultMessage: 'Suggestions loading',
		description: 'Label associated to the page suggestions skeleton while data is being requested.',
	},
	insightsLabelNonFinal: {
		id: 'periscope.insights-skeleton.label-non-final',
		defaultMessage: 'Developer insights loading',
		description: 'Label associated to the page insights skeleton while data is being requested.',
	},
	workItemsLabelNonFinal: {
		id: 'periscope.work-items-skeleton.work-items-label-non-final',
		defaultMessage: 'Work items loading',
		description: 'Label associated to the page work items skeleton while data is being requested.',
	},
	workItemsVulnTabLabelNonFinal: {
		id: 'periscope.work-items-skeleton.work-items-vuln-tab-label-non-final',
		defaultMessage: 'Work items vulnerability tab loading',
		description:
			'Label associated to the page vulnerability tab work items skeleton while data is being requested.',
	},
});
