import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { polarisProjectLayout } from '@atlassian/jira-polaris-project-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import {
	polarisCollectionsRoute,
	polarisIdeasRoute,
	polarisOnboardingRoute,
	polarisSettingsAccessRoute,
	polarisSettingsGlobalFieldsRoute,
	polarisUnlicensedRoute,
} from '@atlassian/jira-router-routes-polaris-routes';
import type { Route } from '@atlassian/react-resource-router';
import CollectionsPage, { LazyCollectionsPage } from './ui/collections';
import IdeasPage, { LazyIdeasPage } from './ui/ideas';
import OnboardingPage, { LazyOnboardingPage } from './ui/onboarding';
import { AccessSettingsPage, LazyAccessSettingsPage } from './ui/settings/access/index.tsx';
import { polarisProductGlobalFieldsSettingsPageEntryPoint } from './ui/settings/global-fields/index.tsx';
import UnlicensedPage, { LazyUnlicensedPage } from './ui/unlicensed';

const commonResources = [projectCreatePermissionsResource];

const getPolarisProjectPermissionsResource = () => [...commonResources];

export const polarisRouteEntries: Route[] = [
	createEntry(polarisIdeasRoute, () => ({
        ufoName: 'jpd.project',
        component: IdeasPage,
        layout: polarisProjectLayout,

        canTransitionIn: (current, next) => {
			if (
				getWillShowNav4() &&
				!fg('jpd-nav-v4-disable-page-refresh-from-other-routes') &&
				next?.match?.params?.projectKey &&
				current.match.params.projectKey !== next.match.params.projectKey
			) {
				return false;
			}

			if (
				getWillShowNav4() &&
				!fg('jpd-nav-v4-disable-page-refresh-between-projects') &&
				current?.match?.params?.projectKey &&
				next?.match?.params?.projectKey &&
				current.match.params.projectKey !== next.match.params.projectKey
			) {
				return false;
			}

			return true;
		},

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
        forPaint: [LazyAtlassianNavigation, LazyIdeasPage]
    })),
	createEntry(polarisCollectionsRoute, () => ({
        ufoName: 'jpd.collections',
        component: CollectionsPage,
        layout: globalLayout,

        canTransitionIn: (current, next) => {
			if (fg('jira_nav4')) {
				if (!fg('jpd-nav-v4-disable-page-refresh-to-collection')) {
					if (fg('jpd-sidebar-v4-for-roadmaps-overview')) {
						// only refresh when we are transitioning from collections overview to collections detail or vice versa
						if (
							(current?.match?.params?.collectionUUID && !next?.match?.params?.collectionUUID) ||
							(!current?.match?.params?.collectionUUID && next?.match?.params?.collectionUUID)
						) {
							return false;
						}
						return true;
					}
					// with gate off refresh when transitioning to or from collections app
					if (current.match.path !== next.match.path) {
						// refresh if we transition to the collections app from somewhere else or vice versa
						return false;
					}
				}
			}
			// default is no-refresh
			return true;
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
        forPaint: [LazyAtlassianNavigation, LazyCollectionsPage]
    })),
	createEntry(polarisOnboardingRoute, () => ({
        component: OnboardingPage,
        layout: globalLayout,
        canTransitionIn: () => true,
        resources: [...getNavigationResources(), ...getPolarisProjectPermissionsResource()],
        forPaint: [LazyAtlassianNavigation, LazyOnboardingPage]
    })),
	createEntry(polarisSettingsAccessRoute, () => ({
        component: AccessSettingsPage,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
        forPaint: [LazyAtlassianNavigation, LazyAccessSettingsPage]
    })),
	createEntry(polarisSettingsGlobalFieldsRoute, () => ({
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],

        entryPoint() {
			return polarisProductGlobalFieldsSettingsPageEntryPoint;
		}
    })),
	createEntry(polarisUnlicensedRoute, () => ({
        component: UnlicensedPage,
        layout: globalLayout,
        canTransitionIn: () => true,
        resources: [...getNavigationResources(), ...getPolarisProjectPermissionsResource()],
        forPaint: [LazyAtlassianNavigation, LazyUnlicensedPage]
    })),
];
