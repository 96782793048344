import { version } from 'react';
import getMeta from '@atlassian/jira-get-meta';
import type { InteractionMetrics } from '@atlassian/react-ufo/interaction-metrics';
import { getAssetEncoding } from './utils/asset-encoding';
import { getCacheHitRatio } from './utils/cache-hit-ratio';
import { getCHRInfo } from './utils/chr';
import { getLighthouseMetrics } from './utils/lighthouse-metrics';
import { getMarks } from './utils/marks';
import { getMemoryUsageReport } from './utils/memory-usage';
import { getPageSizeMetrics, getExtraNavigationTimings, getPageSizeInfo } from './utils/page-size';
import { getRouterResourceMetrics } from './utils/router-resource-metrics';
import { getServerDurationMetrics } from './utils/server-duration';
import { getSSRStateInformation } from './utils/ssr/ssr.tsx';
import { getWRMStats } from './utils/wrm-stats';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { startLighthouseObserver, getLighthouseMetrics } from './utils/lighthouse-metrics';

export function getJiraCustomData(interaction: InteractionMetrics) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let customData: Record<string, any> = {};
	const shard = getMeta('ajs-shard');
	if (shard !== null && shard !== '') {
		customData.shard = shard;
	}

	customData['network:assetEncoding'] = getAssetEncoding();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const buildVariant = window.BUILD_VARIANT;
	if (buildVariant) {
		customData['app:buildVariant'] = buildVariant;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const bundlerVersion = window.BUNDLER_VERSION;
	if (bundlerVersion) {
		customData['app:bundlerVersion'] = bundlerVersion;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const useBifrostGateway = window.USE_BIFROST_GATEWAY;
	if (useBifrostGateway) {
		customData['app:useBifrostGateway'] = useBifrostGateway;
	}

	customData.httpProtocol =
		performance
			.getEntriesByType('resource')
			// @ts-expect-error - TS2339  - Property 'nextHopProtocol' does not exist on type 'PerformanceEntry'. | TS2339 - Property 'nextHopProtocol' does not exist on type 'PerformanceEntry'.
			.find(({ nextHopProtocol }) => !!nextHopProtocol)?.nextHopProtocol ?? 'unknown';

	const { start, end, apdex } = interaction;

	const stop = apdex?.[0]?.stopTime ?? end;

	customData.memory = getMemoryUsageReport({ start, stop });

	const lightHouseMetrics = getLighthouseMetrics({
		start,
		stop,
	});

	customData = {
		...customData,
		...lightHouseMetrics,
	};

	if (interaction.type === 'page_load') {
		customData = {
			...customData,
			...getMarks({ start }),
			...getPageSizeMetrics(),
			...getExtraNavigationTimings(),
			...getServerDurationMetrics(),
			...getRouterResourceMetrics(),
			...getWRMStats(),
			...getPageSizeInfo({ start, stop }),
			...getCacheHitRatio({ start }),
			...getCHRInfo({ start }),
			...getSSRStateInformation(),

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			'app:version:web': window.BUILD_KEY,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			'app:version:ssr': window.SSR_BUILD_KEY,
			'app:framework:name': 'react',
			'app:framework:version': version,
		};
	}
	return customData;
}
