import isEqual from 'lodash/isEqual';
import { CONNECT_ROUTES_SET } from '@atlassian/jira-common-constants/src/spa-routes-sets';
import type { Location } from '@atlassian/react-resource-router';
import type { ConnectDataItem } from '../../types';

// JSPA-1062 remove qs items that might not always be there
const splitRelevantQs = (s: string) =>
	s
		.replace('?', '')
		.split('&')
		.filter((v) => v && !/^(ac\.|project|board|user|servicedesk)/.test(v));

// match query strings, but allow case where query param "s" is present in the menu item, but not the window url
const isSameQuery = (locationQuery: Array<string>, itemQuery: Array<string>) =>
	isEqual(locationQuery, itemQuery) ||
	(!locationQuery.some((q) => q.startsWith('s=')) &&
		isEqual(
			itemQuery.filter((q) => !q.startsWith('s=')),
			locationQuery,
		));

type Return = {
	stack: string[];
	selected: ConnectDataItem;
};

export const findItemAndStack = (
	items: ConnectDataItem[],
	routeName: string,
	location: Location,
	parentStack: string[] = [],
): Return | null => {
	let found: Return | null = null;

	if (!CONNECT_ROUTES_SET.has(routeName)) return found;

	const locationQuery = splitRelevantQs(location.search);

	items.some((item) => {
		const [itemPath, itemQs = ''] = (item.url || '').split('#')[0].split('?');
		const itemQuery = splitRelevantQs(itemQs);
		const sameQuery = isSameQuery(locationQuery, itemQuery);
		if (item.section !== true && itemPath === location.pathname && sameQuery) {
			found = { stack: parentStack, selected: item };
			return true;
		}
		if (item.items) {
			const stack = item.section === true ? parentStack : parentStack.concat(item.id);
			found = findItemAndStack(item.items, routeName, location, stack);
		}
		return Boolean(found);
	});
	return found;
};
