import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_DEVOPS_PROVIDERS } from '../constants';
import type { DevOpsProviderResult } from '../types';

export const devOpsProviderResource = createResource<DevOpsProviderResult>({
	type: RESOURCE_TYPE_DEVOPS_PROVIDERS,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-devops-providers", jiraSpaEntry: "async-resource-devops-providers" */ './get-data'
		),
	maxAge: 60 * 1000,
});

export const useDevOpsProviderResource = () =>
	useResource<DevOpsProviderResult>(devOpsProviderResource);
