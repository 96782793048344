import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	AsyncHorizontalProjectNav,
	AsyncHorizontalOverviewNav,
} from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
// Planning to create a new layout for Jira business. Can remove if team decides to use existing layout
import { businessProjectLayout } from '@atlassian/jira-business-project-layout';
import { ThemedLazySkeleton } from '@atlassian/jira-business-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { workflowIssuesResource } from '@atlassian/jira-router-resources-business-board-workflow-issues';
import {
	getBusinessProjectResources,
	getProjectViewResources,
	getOverviewViewResources,
} from '@atlassian/jira-router-resources-business-common';
import { activityFeedResource } from '@atlassian/jira-router-resources-business-summary-activity-feed';
import { summaryDataResource } from '@atlassian/jira-router-resources-business-summary-data';
import {
	getNavigationSidebarProjectResource,
	getNavigationResources,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { jiraBusinessBoardRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/ui/index.tsx';
import {
	jiraBusinessProjectRedirectRouteEntry,
	jiraBusinessOverviewRedirectRouteEntry,
} from '@atlassian/jira-router-routes-business-redirect-entries/src/ui/index.tsx';
import {
	classicBusinessProjectSummaryRoute,
	classicBusinessProjectCalendarRoute,
	classicBusinessProjectShortcutRoute,
	classicBusinessProjectListRoute,
	classicBusinessProjectFormRoute,
	classicBusinessProjectFormSubmitRoute,
	classicBusinessProjectFormBuilderRoute,
	classicBusinessProjectTimelineRoute,
	classicBusinessProjectApprovalsRoute,
	projectPagesRoute,
	classicBusinessProjectTimelineEmbedRoute,
	classicBusinessProjectCalendarEmbedRoute,
	classicBusinessProjectListEmbedRoute,
	classicBusinessProjectBoardEmbedRoute,
	classicBusinessProjectFormSubmitEmbedRoute,
	classicBusinessProjectSummaryEmbedRoute,
	classicBusinessProjectSampleOnboardingRoute,
	classicBusinessOverviewTimelineRoute,
	classicBusinessOverviewCalendarRoute,
	classicBusinessOverviewSummaryRoute,
	classicBusinessProjectAttachmentsRoute,
} from '@atlassian/jira-router-routes-business-routes';
import type { Route } from '@atlassian/react-resource-router';
import ApprovalsView, { LazyApprovalsView } from './ui/spa/business/approvals';
import { attachmentsPageEntryPoint } from './ui/spa/business/attachments';
import BoardEmbedView, { LazyBoardEmbedView } from './ui/spa/business/board-embed';
import CalendarView, {
	LazyCalendarView,
	businessCalendarRouteEntry,
} from './ui/spa/business/calendar';
import CalendarEmbedView, {
	businessCalendarEmbedRouteEntry,
	LazyCalendarEmbedView,
} from './ui/spa/business/calendar-embed';
import FormView, { LazyFormView } from './ui/spa/business/form';
import FormBuilderView, { LazyFormBuilderView } from './ui/spa/business/form-builder';
import FormSubmitView, { LazyFormSubmitView } from './ui/spa/business/form-submit';
import FormSubmitEmbedView, { LazyFormSubmitEmbedView } from './ui/spa/business/form-submit-embed';
import ListView, { listPageEntryPoint, LazyListView } from './ui/spa/business/list';
import ListEmbedView, { LazyListEmbedView } from './ui/spa/business/list-embed';
import OverviewCalendarView, {
	LazyOverviewCalendarView,
} from './ui/spa/business/overview-calendar';
import OverviewSummaryView, { LazyOverviewSummaryView } from './ui/spa/business/overview-summary';
import OverviewTimelineView, {
	LazyOverviewTimelineView,
} from './ui/spa/business/overview-timeline';
import Pages, { LazyProjectPages } from './ui/spa/business/pages';
import SampleOnboardingView, {
	LazySampleOnboardingView,
} from './ui/spa/business/sample-onboarding';
import ShortcutsView, { LazyShortcutsView } from './ui/spa/business/shortcut';
import SummaryView, { LazySummaryView } from './ui/spa/business/summary';
import SummaryEmbedView, { LazySummaryEmbeddedView } from './ui/spa/business/summary-embed';
import TimelineView, { LazyTimelineView } from './ui/spa/business/timeline';
import TimelineEmbedView, { LazyTimelineEmbedView } from './ui/spa/business/timeline-embed';

export const jiraBusinessRouteEntries: Route[] = [
	createEntry(classicBusinessProjectSummaryRoute, () => ({
        ufoName: 'jwm.summary-view',
        component: SummaryView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: [...getProjectViewResources(), summaryDataResource, activityFeedResource],
        forPaint: [LazyAtlassianNavigation, LazySummaryView]
    })),
	jiraBusinessBoardRouteEntry,
	createEntry(classicBusinessProjectCalendarEmbedRoute, () => ({
        ufoName: 'jwm.calendar-embed-view',
        component: CalendarEmbedView,

        entryPoint() {
			return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
				? businessCalendarEmbedRouteEntry
				: undefined;
		},

        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: getProjectViewResources(),
        forPaint: [LazyCalendarEmbedView]
    })),
	createEntry(classicBusinessProjectCalendarRoute, () => ({
        ufoName: 'jwm.calendar-view',
        component: CalendarView,

        entryPoint() {
			return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
				? businessCalendarRouteEntry
				: undefined;
		},

        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyCalendarView]
    })),
	createEntry(classicBusinessProjectShortcutRoute, () => ({
        component: ShortcutsView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyShortcutsView]
    })),
	createEntry(classicBusinessProjectListRoute, () => ({
        ufoName: 'jwm.list-view',
        component: ListView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        entryPoint: () =>
			expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
				? listPageEntryPoint
				: undefined,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyListView]
    })),
	createEntry(classicBusinessProjectFormRoute, () => ({
        ufoName: 'jwm.form-directory-view',
        component: FormView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyFormView]
    })),
	createEntry(classicBusinessProjectFormSubmitRoute, () => ({
        ufoName: 'jwm.form-submit-view',
        component: FormSubmitView,
        layout: chromelessLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyFormSubmitView]
    })),
	createEntry(classicBusinessProjectFormBuilderRoute, () => ({
        ufoName: 'jwm.form-view',
        component: FormBuilderView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyFormBuilderView]
    })),
	createEntry(classicBusinessProjectTimelineRoute, () => ({
        ufoName: 'jwm.timeline-view',
        component: TimelineView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyTimelineView]
    })),
	createEntry(classicBusinessProjectApprovalsRoute, () => ({
        ufoName: 'jwm.approvals-view',
        component: ApprovalsView,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyApprovalsView]
    })),
	createEntry(projectPagesRoute, () => ({
        resources: [
			...getBusinessProjectResources(),
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			projectContextResource,
		],

        component: Pages,
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        forPaint: [LazyAtlassianNavigation, LazyProjectPages],
        ufoName: 'jwm.jwm-pages'
    })),
	createEntry(classicBusinessProjectAttachmentsRoute, () => ({
        ufoName: 'jwm.attachments-view',
        layout: businessProjectLayout,
        skeleton: ThemedLazySkeleton,
        entryPoint: () => attachmentsPageEntryPoint,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},

        resources: getProjectViewResources()
    })),
	createEntry(classicBusinessProjectTimelineEmbedRoute, () => ({
        ufoName: 'jwm.timeline-embed-view',
        component: TimelineEmbedView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: getProjectViewResources(),
        forPaint: [LazyTimelineEmbedView]
    })),
	createEntry(classicBusinessProjectListEmbedRoute, () => ({
        ufoName: 'jwm.list-embed-view',
        component: ListEmbedView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,

        entryPoint: () =>
			expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
				? listPageEntryPoint
				: undefined,

        resources: getProjectViewResources(),
        forPaint: [LazyListEmbedView]
    })),
	createEntry(classicBusinessProjectBoardEmbedRoute, () => ({
        ufoName: 'jwm.jwm-board-embed',
        component: BoardEmbedView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: [...getProjectViewResources(), workflowIssuesResource],
        forPaint: [LazyBoardEmbedView]
    })),
	createEntry(classicBusinessProjectFormSubmitEmbedRoute, () => ({
        ufoName: 'jwm.form-submit-embed-view',
        component: FormSubmitEmbedView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: getProjectViewResources(),
        forPaint: [LazyFormSubmitEmbedView]
    })),
	createEntry(classicBusinessProjectSummaryEmbedRoute, () => ({
        ufoName: 'jwm.summary-view-embed',
        component: SummaryEmbedView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: [...getProjectViewResources(), summaryDataResource, activityFeedResource],
        forPaint: [LazySummaryEmbeddedView]
    })),
	createEntry(classicBusinessProjectSampleOnboardingRoute, () => ({
        component: SampleOnboardingView,
        layout: chromelessLayout,
        skeleton: ThemedLazySkeleton,
        resources: getNavigationResources(),
        forPaint: [LazySampleOnboardingView]
    })),
	createEntry(classicBusinessOverviewTimelineRoute, () => ({
        component: OverviewTimelineView,
        layout: globalLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

        resources: getOverviewViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyOverviewTimelineView]
    })),
	createEntry(classicBusinessOverviewCalendarRoute, () => ({
        component: OverviewCalendarView,
        layout: globalLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

        resources: getOverviewViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyOverviewCalendarView]
    })),
	createEntry(classicBusinessOverviewSummaryRoute, () => ({
        component: OverviewSummaryView,
        layout: globalLayout,
        skeleton: ThemedLazySkeleton,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

        resources: getOverviewViewResources(),
        forPaint: [LazyAtlassianNavigation, LazyOverviewSummaryView]
    })),
	jiraBusinessProjectRedirectRouteEntry,
	jiraBusinessOverviewRedirectRouteEntry,
];
