import React, { memo } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useProjectCleanupSpotlight } from '../../../../../../controllers/project-cleanup-spotlight';
import messages from './messages';

export const PROJECT_CLEANUP_SPOTLIGHT_NAME = 'site-optimiser-project-cleanup-spotlight-nav';

export const ProjectCleanupSpotlight = memo(() => {
	const { formatMessage } = useIntl();
	const [isProjectCleanupSpotlightActive, setIsProjectCleanupSpotlightActive] =
		useProjectCleanupSpotlight();

	return (
		<SpotlightTransition>
			{isProjectCleanupSpotlightActive && (
				<JiraSpotlight
					actions={[
						{
							onClick: () => setIsProjectCleanupSpotlightActive(false),
							text: formatMessage(messages.spotlightAction),
						},
					]}
					heading={formatMessage(messages.spotlightHeading)}
					target={PROJECT_CLEANUP_SPOTLIGHT_NAME}
					key={PROJECT_CLEANUP_SPOTLIGHT_NAME}
					targetRadius={3}
					targetBgColor={token('elevation.surface')}
					dialogPlacement="right top"
					messageId="navigation-apps-sidebar-global-settings-system.ui.menu.troubleshooting-and-support.performance-menu-item.performance-and-scale-menu.spotlight.jira-spotlight"
					messageType="engagement"
				>
					{formatMessage(messages.spotlightDescription)}
				</JiraSpotlight>
			)}
		</SpotlightTransition>
	);
});
