import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_UNLOCK_OPERATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const unlockOperationsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_UNLOCK_OPERATIONS,
	path: '/jira/settings/products/ops/unlock',
	exact: true,
} as const as Route;
