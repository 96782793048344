import isEqual from 'lodash/isEqual';
import type { ProjectTheme } from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { themeManager } from '@atlassian/jira-custom-theme-constants/src/utils.tsx';
import {
	createStore,
	createHook,
	createContainer,
	createSelector,
} from '@atlassian/react-sweet-state';

type State = ProjectTheme;

type StoreAPI = {
	setState: (state: State) => void;
	getState: () => State;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: (arg1?: any) => void;
};

const setTheme =
	(newTheme: NonNullable<ProjectTheme['themeSetting']>) =>
	({ getState, setState }: StoreAPI) => {
		const { entityId, themeSetting, selectedThemeSetting } = getState();

		if (themeManager.isValidTheme(newTheme) && !isEqual(themeSetting?.value, newTheme?.value)) {
			setState({
				entityId,
				selectedThemeSetting,
				themeSetting: newTheme,
			});
		}
	};

const setSelectedThemeSetting =
	(newTheme: NonNullable<ProjectTheme['themeSetting']> | null) =>
	({ getState, setState }: StoreAPI) => {
		const { entityId, themeSetting, selectedThemeSetting } = getState();

		if (
			newTheme === null ||
			(themeManager.isValidTheme(newTheme) &&
				!isEqual(
					selectedThemeSetting?.type === 'image'
						? selectedThemeSetting?.id
						: selectedThemeSetting?.value,
					newTheme?.type === 'image' ? newTheme?.id : newTheme?.value,
				))
		) {
			setState({
				entityId,
				themeSetting,
				selectedThemeSetting: newTheme,
			});
		}
	};

const actions = { setTheme, setSelectedThemeSetting };

const initialState: State = {
	themeSetting: null,
	selectedThemeSetting: null,
	entityId: '',
};

const Store = createStore({
	initialState,
	actions,
	name: 'BusinessTheme',
});

// delete on cleanup of getWillShowNav4
export const ThemeContainerOld = createContainer(Store, {
	onInit:
		() =>
		({ setState }, entityTheme: ProjectTheme) =>
			setState(entityTheme),
	onUpdate:
		() =>
		({ setState }, entityTheme: ProjectTheme) =>
			setState(entityTheme),
});

type ThemeContainerProps = {
	entityTheme: ProjectTheme | null;
};

export const ThemeContainer = createContainer(Store, {
	onInit:
		() =>
		({ setState }, { entityTheme }: ThemeContainerProps) => {
			setState(entityTheme ?? initialState);
		},
	onUpdate:
		() =>
		({ setState }, { entityTheme }: ThemeContainerProps) => {
			setState(entityTheme ?? initialState);
		},
});

export const useThemeHook = createHook(Store);

const getThemeSetting = createSelector(
	(state: State) => state.themeSetting,
	(themeSetting) => themeSetting,
);

export const useThemeSetting = createHook(Store, {
	selector: getThemeSetting,
});

const getSelectedThemeSetting = createSelector(
	(state: State) => state.selectedThemeSetting,
	(selectedThemeSetting) => selectedThemeSetting,
);

export const useSelectedThemeSetting = createHook(Store, {
	selector: getSelectedThemeSetting,
});
