import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound, {
	LazyNotFoundErrorPage,
} from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { errorsNotFoundRoute } from '@atlassian/jira-router-routes-errors-not-found-routes';

export const errorsNotFoundRouteEntry = createEntry(errorsNotFoundRoute, () => ({
    component: ErrorPagesNotFound,
    layout: globalLayout,
    resources: [...getNavigationResources()],
    forPaint: [LazyAtlassianNavigation, LazyNotFoundErrorPage]
}));
