/**
 * @generated SignedSource<<e197bd923e340d7daa01873009ed625a>>
 * @relayHash e10d633a35508f063c42459a588ec505
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1205dd927cf641e9acdbb06e62c9965f4be6e12f04a3ee129a8074a3a99f2ddd

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

import shouldSkipReleaseNoteField_provider from '@atlassian/jira-relay-provider/src/version-details-should-skip-release-note-field.relayprovider';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1205dd927cf641e9acdbb06e62c9965f4be6e12f04a3ee129a8074a3a99f2ddd",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcversiondetailsshouldskipreleasenotefieldrelayprovider": shouldSkipReleaseNoteField_provider
    }
  }
};

export default node;
