import { ff } from '@atlassian/jira-feature-flagging';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	buildNonCriticalGiraQuery,
	ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-non-critical-data/index.tsx';
import {
	sendExperienceAnalytics,
	type ExperienceDescription,
} from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getClient } from '../../common/utils/client';
import type {
	GiraNonCriticalGraphQlResponse,
	GiraNonCriticalExperienceDescription,
	GiraNonCriticalExperienceTrackingEvent,
} from './types';

const nonCriticalGiraQueryExperienceDescription = ({
	wasExperienceSuccessful,
	errorMessage,
	traceId,
	isEarlyFetch,
}: GiraNonCriticalExperienceDescription): ExperienceDescription => {
	const errorMessageItem: { errorMessage: string } | {} =
		errorMessage != null ? { errorMessage } : {};
	const traceIdItem: { traceId: string } | {} = traceId != null ? { traceId } : {};

	return {
		experience: isEarlyFetch ? 'issueEarlyFetchNonCriticalGiraQuery' : 'issueNonCriticalGiraQuery',
		wasExperienceSuccesful: wasExperienceSuccessful,
		analyticsSource: `Issue View ${isEarlyFetch ? 'Early Fetch ' : ''}Non Critical Gira Query`,
		additionalAttributes: { method: 'GET', ...errorMessageItem, ...traceIdItem },
		application: null,
		edition: null,
	};
};

export const nonCriticalGiraQueryExperienceTracking = ({
	wasExperienceSuccessful,
	isEarlyFetch,
	error,
}: GiraNonCriticalExperienceTrackingEvent) => {
	sendExperienceAnalytics(
		nonCriticalGiraQueryExperienceDescription({
			wasExperienceSuccessful,
			...(error instanceof FetchError && error.message != null
				? { errorMessage: error.message }
				: {}),
			...(error instanceof FetchError && error.traceId != null ? { traceId: error.traceId } : {}),
			isEarlyFetch,
		}),
	);
};

const isNonCriticalGiraQueryExperienceTrackingEnabled = () =>
	ff('issue.details.non-critical-gira-query-experience-tracking', false);

export const fetchIssueGiraNonCriticalData = async (issueKey: IssueKey) => {
	const variables = {
		issueKey,
	};

	const payload = {
		query: buildNonCriticalGiraQuery(),
		variables,
		operationName: ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY,
	};

	if (!isNonCriticalGiraQueryExperienceTrackingEnabled()) {
		const response = await getClient('').query<GiraNonCriticalGraphQlResponse>(payload);
		return response;
	}

	try {
		const response = await getClient('').query<GiraNonCriticalGraphQlResponse>(payload);
		nonCriticalGiraQueryExperienceTracking({
			wasExperienceSuccessful: true,
			isEarlyFetch: false,
		});
		return response;
	} catch (error) {
		nonCriticalGiraQueryExperienceTracking({
			wasExperienceSuccessful: false,
			isEarlyFetch: false,
			error: error instanceof FetchError ? error : undefined,
		});
		throw error;
	}
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	GiraNonCriticalGraphQlResponse,
	GiraEcosystemOperation,
	GiraEcosystemBackgroundScript,
	GiraViewIssue,
} from './types';
