import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { LOADER_STYLES_CSS_SELECTOR, LOADER_STYLESHEET_ID, getLoadingBarColor } from './constants';

export const generateLoadingBarStyles = (initalProgressValue: number, loadingBarColor: string) => `
    content: '';
    display: block;
    background: ${loadingBarColor};
    height: 3px;
    z-index: ${layers.navigation + 10};
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    transform: scaleX(${initalProgressValue});
    transform-origin: 0 0;
    will-change: transform;
`;

export const generateLoadingBarStylesheet = (initialProgressValue = 0.3) => `
        <style id=${LOADER_STYLESHEET_ID}>
            ${LOADER_STYLES_CSS_SELECTOR} {${generateLoadingBarStyles(
							initialProgressValue,
							getLoadingBarColor(),
						)}}
        </style>`;
