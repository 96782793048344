import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as unlockOperationResources } from '@atlassian/jira-router-resources-eoc-unlock-operations';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { unlockOperationsRoute } from '@atlassian/jira-router-routes-eoc-unlock-operations-routes';
import UnlockOperations, { LazyUnlockOperations } from './ui';

export const unlockOperationsRouteEntry = createEntry(unlockOperationsRoute, () => ({
    layout: eocChangeboardingLayout,

    component: componentWithCondition(
		() => ff('opsgenie-consolidation-transitioning-state_d7vm9'),
		UnlockOperations,
		ErrorPagesNotFound,
	),

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...unlockOperationResources,
	],

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    forPaint: [LazyAtlassianNavigation, LazyUnlockOperations]
}));
