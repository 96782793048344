import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { jsmEmailDomainsListResource } from '@atlassian/jira-router-resources-jsm-get-email-domains-list';
import { productsJsmOrganizationDetailsResource } from '@atlassian/jira-router-resources-jsm-organization-details';
import { productsJsmOrganizationMembersResource } from '@atlassian/jira-router-resources-jsm-organization-members';
import { productsJsmOrganizationListResource } from '@atlassian/jira-router-resources-jsm-organizations-list';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	productsJsmOrganizationsRoute,
	productsJsmOrganizations1Route,
} from '@atlassian/jira-router-routes-products-jsm-organizations-routes';
import ProductsJsmOrganizationDetails, {
	LazyProductsJsmOrganizationDetails,
} from './ui/admin-pages/products-jsm-organization-details';
import ProductsJsmOrganizations, {
	LazyProductsJsmOrganizations,
} from './ui/admin-pages/products-jsm-organizations';

export const productsJsmOrganizationsRouteEntry = createEntry(productsJsmOrganizationsRoute, () => ({
    ufoName: 'admin.jsm-organization-page-list',
    isRedirect: false,
    component: ProductsJsmOrganizations,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		productsJsmOrganizationListResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyProductsJsmOrganizations]
}));

export const productsJsmOrganizations1RouteEntry = createEntry(productsJsmOrganizations1Route, () => ({
    ufoName: 'admin.jsm-organization-page-list',
    isRedirect: false,
    component: ProductsJsmOrganizationDetails,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		productsJsmOrganizationDetailsResource,
		productsJsmOrganizationMembersResource,
		productsJsmOrganizationListResource,
		jsmEmailDomainsListResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyProductsJsmOrganizationDetails]
}));
