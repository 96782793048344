import cloneDeep from 'lodash/cloneDeep';
import { getMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { SPA_SSR_RENDERED } from '@atlassian/jira-providers-spa-apdex-analytics/src/constants';
import type { ReportedTimings, SSRConfig, SSRFeatureFlags } from '@atlassian/react-ufo/ssr';

function getTimings(): ReportedTimings | null {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!window.SPA_STATE) {
		return null;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const detailRenderTrace = window.SPA_STATE['ssr/detail_render_trace'];

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const detailRequestTrace = window.SPA_STATE['ssr/detail_request_trace'];

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const highLevelMetrics = window.SPA_STATE['ssr/metrics'];

	const timings = cloneDeep(detailRenderTrace?.data?.traces) || {};
	if (highLevelMetrics?.data) {
		if ('ssrTotal' in highLevelMetrics.data) {
			timings.total = { startTime: 0, duration: highLevelMetrics.data.ssrTotal };
		}
		if ('ssrGetStyle' in highLevelMetrics.data) {
			timings.styles = {
				startTime: highLevelMetrics.data.ssrGetStyle - highLevelMetrics.data.ssrGetStyle,
				duration: highLevelMetrics.data.ssrGetStyle,
			};
		}
		if ('ssrPrefetch' in highLevelMetrics.data) {
			const prefetchStartTime = Object.values(detailRenderTrace?.data?.traces || {}).reduce<{
				startTime: number;
				// @ts-expect-error - TS2345 - Argument of type '(min: { startTime: number; }, { startTime }: { startTime: any; }) => any' is not assignable to parameter of type '(previousValue: { startTime: number; }, currentValue: unknown, currentIndex: number, array: unknown[]) => { startTime: number; }'. | TS2367 - This condition will always return 'false' since the types '{ startTime: number; }' and 'number' have no overlap.
			}>((min, { startTime }) => (min === -1 || min > startTime ? startTime : min), -1);

			timings.prefetch = {
				startTime: prefetchStartTime,
				duration: highLevelMetrics.data.ssrPrefetch,
			};
		}
	}
	if (detailRequestTrace?.data) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(detailRequestTrace.data).forEach(([key, value]: [any, any]) => {
			timings[`prefetch/${key}`] = value;
		});
	}

	return timings;
}

const SSR_FF_META_TAG = 'spa-service-flags';

let ffCache: SSRFeatureFlags | null;
let ffCacheInitialiser: Function | null = () => {
	try {
		const metaTagValue = getMeta(SSR_FF_META_TAG);
		if (metaTagValue === null) return null;
		ffCache = JSON.parse(metaTagValue) || null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		ffCache = null;
	}
	ffCacheInitialiser = null;
};

function getFeatureFlags(): SSRFeatureFlags | null {
	if (ffCacheInitialiser != null) ffCacheInitialiser();
	return ffCache;
}

export const jiraSsrConfig: SSRConfig = {
	getDoneMark: () => {
		if (fg('ssr_success_when_spa_success')) {
			const SPASSRRenderedMark = getMark(SPA_SSR_RENDERED)?.startTime;
			if (SPASSRRenderedMark && getMark('ssr.spa.state')?.startTime) {
				return SPASSRRenderedMark;
			}
			return null;
		}
		return getMark(SPA_SSR_RENDERED)?.startTime || null;
	},
	getTimings,
	getFeatureFlags,
} as const;
