import React, { useMemo } from 'react';
import flow from 'lodash/flow';
import AvatarDI from '@atlaskit/avatar';
import AddonIconDI from '@atlaskit/icon/glyph/addon';
import { Header as HeaderDI } from '@atlaskit/side-navigation';
import {
	EnvironmentLozenge as EnvironmentLozengeDI,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import { useSidebarTheme } from '../../controllers/sidebar-theme/index.tsx';
import { generateCustomThemeCssFn } from '../../utils/css-fn-utils/custom-theme-css-fn.tsx';
import { CssFixContainerDI } from './styled';
import type { ForgePageHeaderProps } from './types';

export const ForgePageHeader = ({
	Avatar = AvatarDI,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	AddonIcon = AddonIconDI,
	CssFixContainer = CssFixContainerDI,
	Header = HeaderDI,
	cssFn,
	EnvironmentLozenge = EnvironmentLozengeDI,
	avatarUrl,
	environmentType,
	environmentKey,
	name,
}: ForgePageHeaderProps) => {
	const theme = useSidebarTheme();

	const headerCssFn = useMemo(
		() =>
			cssFn ? flow([cssFn, generateCustomThemeCssFn(theme)]) : generateCustomThemeCssFn(theme),
		[cssFn, theme],
	);

	return (
		<CssFixContainer>
			<Header
				cssFn={headerCssFn}
				iconBefore={
					avatarUrl?.length ? (
						<Avatar
							appearance="square"
							borderColor="transparent"
							size="small"
							src={`${avatarUrl}?size=xxlarge`}
						/>
					) : (
						<AddonIcon label="" size="medium" />
					)
				}
				// @ts-expect-error - TS2769 - No overload matches this call.
				iconAfter={<EnvironmentLozenge environmentType={environmentType} />}
			>
				{nameWithCustomEnvironment(name, environmentType, environmentKey)}
			</Header>
		</CssFixContainer>
	);
};
