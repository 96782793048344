import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	PeriscopePageSkeleton,
	PeriscopeInsightsSkeleton,
	PeriscopeSuggestionsSkeleton,
	PeriscopeWorkItemsSkeleton,
	DevelopmentPageWorkItemsVulnTabSkeleton,
} from '@atlassian/jira-skeletons/src/ui/periscope-page/index.tsx';
import messages from './messages';

// Extend this for loading screen
export const Skeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SpinnerContainerStyles}
			data-testId="software-periscope-periscope-page.ui.loading"
			aria-label={formatMessage(messages.labelNonFinal)}
			padding="space.500"
		>
			<PeriscopePageSkeleton />
		</Box>
	);
};

export const InsightsSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={
				fg('jira_periscope_new_widgets') ? InsightsNewContainerStyles : InsightsOldContainerStyles
			}
			data-testId="software-periscope-periscope-page.insights-ui.loading"
			aria-label={formatMessage(messages.insightsLabelNonFinal)}
			paddingBlockStart="space.400"
			paddingInlineStart="space.500"
			paddingInlineEnd="space.500"
		>
			<PeriscopeInsightsSkeleton />
		</Box>
	);
};

export const SuggestionsSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={SuggestionsContainerStyles}
			data-testId="software-periscope-periscope-page.suggestions-ui.loading"
			aria-label={formatMessage(messages.suggestionsLabelNonFinal)}
			paddingBlockStart="space.400"
			paddingInlineStart="space.500"
			paddingInlineEnd="space.500"
		>
			<PeriscopeSuggestionsSkeleton />
		</Box>
	);
};

// Extend this for loading screen
export const WorkItemsSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={WorkItemsSpinnerContainerStyles}
			aria-label={formatMessage(messages.workItemsLabelNonFinal)}
			padding="space.500"
		>
			<PeriscopeWorkItemsSkeleton />
		</Box>
	);
};

export const WorkItemsVulnTabSkeleton = () => {
	const { formatMessage } = useIntl();
	return (
		<Box
			xcss={WorkItemsVulnTabSpinnerContainerStyles}
			aria-label={formatMessage(messages.workItemsVulnTabLabelNonFinal)}
		>
			<DevelopmentPageWorkItemsVulnTabSkeleton />
		</Box>
	);
};

const SpinnerContainerStyles = xcss({
	width: '100%',
	height: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
});

const SuggestionsContainerStyles = xcss({
	width: '100%',
	overflow: 'hidden',
	height: '650px',
	boxSizing: 'border-box',
});

const InsightsOldContainerStyles = xcss({
	overflow: 'hidden',
	width: '100%',
	height: '315px',
	boxSizing: 'border-box',
});

const InsightsNewContainerStyles = xcss({
	overflow: 'hidden',
	width: '100%',
	height: '385px',
	boxSizing: 'border-box',
});

const WorkItemsSpinnerContainerStyles = xcss({
	width: '100%',
	minWidth: '800px',
	height: '600px',
	boxSizing: 'border-box',
	overflow: 'hidden',
});

const WorkItemsVulnTabSpinnerContainerStyles = xcss({
	width: '100%',
	minWidth: '800px',
	height: '420px',
	boxSizing: 'border-box',
	overflow: 'hidden',
});
