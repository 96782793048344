import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJsmFeatureUsageRoute } from '@atlassian/jira-router-routes-products-jsm-feature-usage-routes';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import ProductsJsmFeatureUsage, { LazyProductsJsmFeatureUsage } from './ui';

export const productsJsmFeatureUsageRouteEntry = createEntry(productsJsmFeatureUsageRoute, () => ({
    component: ProductsJsmFeatureUsage,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		workspaceResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyProductsJsmFeatureUsage]
}));
