/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, type ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { cssMap, jsx } from '@compiled/react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { PanelSplitterContext } from './context';
import { getPixelWidth, getWidthFromDragLocation } from './get-width';
import { useTextDirection } from './use-text-direction';

const containerStyles = cssMap({
	root: {
		display: 'none',
		position: 'absolute',
		insetBlockEnd: 0,
		insetBlockStart: 0,
		outline: 'none',
		'@media (min-width: 48rem)': {
			display: 'block',
		},
		// We need to make sure the panel splitter stays above other elements.
		// Concrete examples of this are when elements inside the side nav are sticky and have their own zindex set.
		// For now we set this to the smallest possible value. We might need to increase this in the future.
		zIndex: 1,
	},
	positionEnd: {
		// Offset 8px to the left of the container's right (inline-end) side, to position the grab area.
		insetInlineEnd: '8px',
	},
	positionStart: {
		// Offset 9px to the left of the container's left (inline-start) side, to position the grab area.
		// 9px is used instead of 8px (an extra 1px) to go over the container element's border and centre it.
		insetInlineStart: '-9px',
	},
});

const buttonStyles = cssMap({
	grabArea: {
		/**
		 * The interactive grab area is centered on the container element's border. `17px` is used as the grab area covers `8px` on one side
		 * and `8px` on the other side, plus an extra `1px` to account for the container element's border.
		 */
		width: '17px',
		height: '100%',
		position: 'absolute',
		padding: token('space.0'),
		color: 'transparent',
		background: 'transparent',
		transition: 'color 100ms',
		transitionDelay: '0ms',
		'&:hover': {
			// We are setting the cursor within the :hover pseudo to ensure the specifity is higher than Pressable's cursor.
			cursor: 'ew-resize',
			transitionDelay: '200ms',
		},
		'&:hover, &:focus-visible': {
			color: token('color.text.selected'),
			transition: 'color 200ms',
		},
		// We are using the `:active` state to update the line color when dragging, instead of using state. This works as we aren't using
		// drag previews and instead are moving and styling the dragged element. There were also issues with the Compiled styles in test environments.
		'&:active': {
			color: token('color.link.pressed'),
			// Removing the color transition so we instantly change from hovered to dragged colors.
			transition: 'none',
		},
	},
	content: {
		width: '100%',
		height: '100%',
	},
});

const lineStyles = cssMap({
	root: {
		position: 'absolute',
		display: 'block',
		width: token('border.width.indicator'),
		height: '100%',
		color: 'inherit',
		backgroundColor: 'currentcolor',
		// 7px is used instead of 8px to account for the container element's border and ensure the line remains visually centered.
		insetInlineStart: '7px',
	},
});

type PanelSplitterProps = {
	/**
	 * The accessible label for the panel splitter. It is visually hidden, but is required for accessibility.
	 */
	label: React.ReactNode;

	/**
	 * Called when the user begins resizing the panel.
	 * Intended for analytics.
	 */
	onResizeStart?: ({ initialWidth }: { initialWidth: number }) => void;

	/**
	 * Called when the user finishes resizing the panel.
	 */
	onResizeEnd?: ({
		initialWidth,
		finalWidth,
	}: {
		initialWidth: number;
		finalWidth: number;
	}) => void;

	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;

	testId?: string;
};

const PortaledPanelSplitter = ({
	label,
	onResizeStart,
	onResizeEnd,
	interactionName,
	testId,
}: PanelSplitterProps): JSX.Element | null => {
	const splitterRef = useRef<HTMLButtonElement | null>(null);
	const panelSplitterContext = useContext(PanelSplitterContext);
	invariant(panelSplitterContext, 'Panel splitter context must be set');
	const {
		panelWidth,
		onCompleteResize,
		resizeBounds,
		panelRef,
		resizingCssVar,
		portalRef,
		position,
		isEnabled,
	} = panelSplitterContext;
	invariant(portalRef.current, 'Portal ref must be set');

	const direction = useTextDirection(portalRef.current);

	useEffect(() => {
		if (!isEnabled) {
			return;
		}

		const splitter = splitterRef.current;
		invariant(splitter, 'Splitter ref must be set');

		return draggable({
			element: splitter,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				// We will be moving the line to indicate a drag. We can disable the native drag preview
				disableNativeDragPreview({ nativeSetDragImage });
				// We don't want any native drop animation for when the user does not drop on a drop target. We want the drag to finish immediately
				preventUnhandled.start();
			},
			getInitialData() {
				invariant(panelRef.current, 'Panel ref must be set');

				/**
				 * The drag calculations require the actual computed width of the element
				 * For example, if the panel has loaded with a width of 2000px, but the max bound is 1000px and is visually 1000px (due to the CSS `clamp()`),
				 * the drag calculations should use the actual width of 1000px, not the width in state of 2000px.
				 */
				return {
					initialWidth: getPixelWidth(panelRef.current),
				};
			},
			onDragStart({ source }) {
				invariant(
					typeof source.data.initialWidth === 'number',
					'expected initialWidth to be a number',
				);

				onResizeStart?.({ initialWidth: source.data.initialWidth });
			},
			onDrag({ location, source }) {
				invariant(
					typeof source.data.initialWidth === 'number',
					'expected initialWidth to be a number',
				);

				panelRef.current?.style.setProperty(
					resizingCssVar,
					`clamp(${resizeBounds.min}, ${getWidthFromDragLocation({ initialWidth: source.data.initialWidth, location, direction, position })}px, ${resizeBounds.max})`,
				);
			},
			onDrop({ source }) {
				preventUnhandled.stop();
				invariant(panelRef.current, 'Panel ref must be set');
				invariant(
					typeof source.data.initialWidth === 'number',
					'expected initialWidth to be a number',
				);

				const finalWidth = getPixelWidth(panelRef.current);
				onCompleteResize(finalWidth);
				onResizeEnd?.({
					initialWidth: source.data.initialWidth,
					finalWidth,
				});

				panelRef.current.style.removeProperty(resizingCssVar);
			},
		});
	}, [
		onCompleteResize,
		onResizeStart,
		onResizeEnd,
		panelRef,
		resizingCssVar,
		panelWidth,
		resizeBounds,
		direction,
		position,
		isEnabled,
	]);

	if (!isEnabled) {
		return null;
	}

	return createPortal(
		<div
			css={[
				containerStyles.root,
				position === 'start' && containerStyles.positionStart,
				position === 'end' && containerStyles.positionEnd,
			]}
			data-testid={testId ? `${testId}-container` : undefined}
		>
			{/**
			 * A `<Pressable>` is no longer needed as we are no longer supporting "click to collapse"
			 * → https://jplat.atlassian.net/browse/BLU-4348
			 */}
			<Pressable
				// To win the specificity war we need to use inline styles so Compiled wins over Emotion.
				// Sorry Kylor!
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ outline: 'none' }}
				ref={splitterRef}
				xcss={buttonStyles.grabArea}
				interactionName={interactionName}
				testId={testId}
			>
				<div
					// Due to a Firefox bug with button element drag events we need to make the button
					// content (children) fill up the entire space of the button.
					// See: https://bugzilla.mozilla.org/show_bug.cgi?id=568313#c16
					css={buttonStyles.content}
				>
					<VisuallyHidden>{label}</VisuallyHidden>
					<span css={lineStyles.root} />
				</div>
			</Pressable>
		</div>,
		portalRef.current,
	);
};

// Ensures that the component is only rendered on a client. Uses a `useEffect`, which is not run on servers.
const ClientOnly = ({ children }: { children: ReactNode }): JSX.Element => {
	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	return <Fragment>{hasMounted ? children : null}</Fragment>;
};

/**
 * _PanelSplitter_
 *
 * A component that allows the user to resize a panel.
 * It can be used within page layout slots like SideNav. The page layout component should provide the context for it,
 * using `<PanelSplitterProvider>`.
 *
 * Example usage in products:
 * ```tsx
 * <SideNav>
 *   {/* other side nav content *}
 *   <PanelSplitter label="Resize Side Nav" />
 * </SideNav>
 * ```
 */
export const PanelSplitter = ({
	label,
	onResizeStart,
	onResizeEnd,
	interactionName,
	testId,
}: PanelSplitterProps): JSX.Element => (
	<ClientOnly>
		<PortaledPanelSplitter
			label={label}
			onResizeStart={onResizeStart}
			onResizeEnd={onResizeEnd}
			interactionName={interactionName}
			testId={testId}
		/>
	</ClientOnly>
);
