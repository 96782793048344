import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { compassProductSettingsResource } from '@atlassian/jira-router-resources-compass-product-settings';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { compassProductSettingsRoute } from '@atlassian/jira-router-routes-compass-product-settings';
import type CompassProductSettingsType from '@atlassian/jira-spa-apps-compass-product-settings';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyCompassProductSettingsPage = lazyForPaint<typeof CompassProductSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-compass-product-settings", jiraSpaEntry: "async-compass-product-settings" */ '@atlassian/jira-spa-apps-compass-product-settings'
		),
	),
);

export const CompassProductSettingsComponent = () => (
	<LazyPage
		Page={LazyCompassProductSettingsPage}
		pageId="async-compass-product-settings"
		shouldShowSpinner
	/>
);

export const compassProductSettingsRouteEntry = createEntry(compassProductSettingsRoute, () => ({
    component: CompassProductSettingsComponent,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		compassProductSettingsResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyCompassProductSettingsPage]
}));
