/** @jsx jsx */
import React, { useCallback, type ReactNode } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import { useBusinessLookAndFeel } from '@atlassian/jira-business-theme-page-container/src/controllers/business-look-and-feel/index.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIsChromeless } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/chromeless/index.tsx';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { LayoutRemoteStateUpdater } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/layout-remote-state-updater/index.tsx';
import { GlobalBottomRightCornerContainer } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/container/index.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { PageLayout, Content, LeftPanel, Main } from '@atlassian/jira-navigation-system';
import {
	GLOBAL_CONTAINERS_MARK,
	GLOBAL_CONTAINERS_START_MARK,
	GLOBAL_CONTAINERS_END_MARK,
} from '@atlassian/jira-page-container-constants';
import { useBannerJsmPackagingUpdates } from '@atlassian/jira-platform-ui-banners-jsm-packaging-updates/src/controllers/index.tsx';
import { BannerJsmPackagingUpdateAsync } from '@atlassian/jira-platform-ui-banners-jsm-packaging-updates/src/ui/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { sharedLayout } from '@atlassian/jira-shared-layout';
import { setInitialPageLoadTimingFromPerformanceMarks } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { Banner } from '../banner';
import { GlobalComponents } from '../global-components';
import { GlobalContainers } from '../global-containers/main.tsx';
import { HorizontalNavWrapper, HorizontalNav } from '../horizontal-nav';
import { RightSidebar } from '../right-sidebar';
import { Sidebar } from '../sidebar';
import { TopNav } from '../topnav';
import { FullHeight } from './styled';
import { ThemeSetter } from './theme-setter';
import type { PageContainerProps } from './types';

const mainWrapperStyles = cssMap({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		' > span[data-ep-placeholder-id="route_entry_point"]': {
			// EntryPointPlaceholder span element will stop the height from being 100%.
			// this makes content height can be set to 100% even if the span is present.
			// https://jplat.jira.atlassian.cloud/browse/BLU-3362
			display: 'contents',
		},
	},
	themed: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: Tokens.ELEVATION_SURFACE,
		// When an image background is applied via the background css var the image
		// will be darkened in dark mode by blending with a black transparent background.
		backgroundBlendMode: 'darken',
	},
});

/**
 * These test IDs are used in pollinator tests. Do not change lightly.
 */
const ROOT_TEST_ID = 'page-layout.root';
const MAIN_TEST_ID = 'page-layout.main';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const PageContainerNew = ({
	id,
	locale,
	baseUrl,
	children,
	messages,
	Router,
	routerLinkComponent,
}: PageContainerProps) => {
	const [isChromeless] = useIsChromeless();
	const [isFullscreen] = useIsFullscreen();
	const { hasTheming } = useBusinessLookAndFeel();
	const { isEnabled: isJsmPackagingUpdatesEnabled } = useBannerJsmPackagingUpdates();
	const pageContainerPerformanceTiming = useCallback(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			GLOBAL_CONTAINERS_MARK,
			GLOBAL_CONTAINERS_START_MARK,
			GLOBAL_CONTAINERS_END_MARK,
			true,
			false,
		);
	}, []);

	const mainWrapper = (content: ReactNode) => (
		<Main isFixed testId={MAIN_TEST_ID}>
			<div css={[mainWrapperStyles.root, hasTheming && mainWrapperStyles.themed]}>
				{isJsmPackagingUpdatesEnabled && <BannerJsmPackagingUpdateAsync />}
				{content}
			</div>
		</Main>
	);

	const showBanner = !isChromeless && !isFullscreen;
	const showTopNav = !isChromeless && !isFullscreen;
	const showSidebar = !isChromeless && !isFullscreen;
	const showHorizontalNav = !isChromeless;
	const showRightSidebar = !isChromeless && !isFullscreen;
	const showLayoutRemoteStateUpdater = !isChromeless && !isFullscreen;

	return (
		<>
			<PerformanceMark metricKey={GLOBAL_CONTAINERS_START_MARK} />
			<ThemeSetter />
			<GlobalContainers
				id={id}
				locale={locale}
				baseUrl={baseUrl}
				messages={messages}
				Router={Router}
				routerLinkComponent={routerLinkComponent}
			>
				<PerformanceMark
					metricKey={GLOBAL_CONTAINERS_END_MARK}
					onSet={pageContainerPerformanceTiming}
				/>
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="navigationNext">
					<PageLayout skipLinksLabel="" testId={ROOT_TEST_ID}>
						{showBanner && (
							<JSErrorBoundary id="banner" packageName="jiraPageContainerV2" fallback="unmount">
								<Banner />
							</JSErrorBoundary>
						)}
						{showTopNav && (
							<JSErrorBoundary id="top-nav" packageName="jiraPageContainerV2" fallback="unmount">
								<TopNav />
							</JSErrorBoundary>
						)}
						{showSidebar && (
							<JSErrorBoundary id="sidebar" packageName="jiraPageContainerV2" fallback="unmount">
								<Sidebar />
							</JSErrorBoundary>
						)}
						{mainWrapper(
							<>
								{showHorizontalNav && <HorizontalNav />}
								{children}
							</>,
						)}
						{showRightSidebar && (
							<JSErrorBoundary
								id="right-sidebar"
								packageName="jiraPageContainerV2"
								fallback="unmount"
							>
								<RightSidebar />
							</JSErrorBoundary>
						)}
						{showLayoutRemoteStateUpdater && (
							<JSErrorBoundary
								packageName="jiraPageContainerV2"
								id="layout-remote-state-updater"
								fallback="unmount"
							>
								<LayoutRemoteStateUpdater />
							</JSErrorBoundary>
						)}
					</PageLayout>
					<GlobalComponents sharedGlobalComponents={sharedLayout.globalComponents} />
					<GlobalBottomRightCornerContainer />
				</ContextualAnalyticsData>
			</GlobalContainers>
		</>
	);
};

/**
 * Serves as a comprehensive layout manager, orchestrating the assembly of global containers,
 * navigation components, and performance metrics into a cohesive page structure. It enhances user
 * interface by conditionally incorporating additional UI elements and ensures a uniform
 * appearance and behavior across pages through global styling. Moreover, it facilitates
 * performance optimization and analytics integration for improved user experience and
 * insights.
 */
const PageContainerOld = ({
	id,
	locale,
	baseUrl,
	children,
	messages,
	Router,
	routerLinkComponent,
}: PageContainerProps) => {
	const HorizontalNavComponent = useHorizontalNavComponent();
	const { isEnabled: isJsmPackagingUpdatesEnabled } = useBannerJsmPackagingUpdates();
	const pageContainerPerformanceTiming = useCallback(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			GLOBAL_CONTAINERS_MARK,
			GLOBAL_CONTAINERS_START_MARK,
			GLOBAL_CONTAINERS_END_MARK,
			true,
			false,
		);
	}, []);

	let contentChildren = isJsmPackagingUpdatesEnabled ? (
		<Box>
			<BannerJsmPackagingUpdateAsync />
			{children}
		</Box>
	) : (
		<>{children}</>
	);

	if (HorizontalNavComponent != null) {
		contentChildren = (
			<HorizontalNavWrapper navComponent={HorizontalNavComponent}>
				{isJsmPackagingUpdatesEnabled && <BannerJsmPackagingUpdateAsync />}
				{children}
			</HorizontalNavWrapper>
		);
	}

	const panelContent = (
		<>
			<FullHeight>
				<PageLayout skipLinksLabel="">
					<JSErrorBoundary id="banner" packageName="page-container" fallback="unmount">
						<Banner />
					</JSErrorBoundary>
					<JSErrorBoundary id="top-nav" packageName="page-container" fallback="unmount">
						<TopNav />
					</JSErrorBoundary>
					<LeftPanel width={0}>
						<></>
					</LeftPanel>
					<Content>
						<JSErrorBoundary id="sidebar" packageName="page-container" fallback="unmount">
							<Sidebar />
						</JSErrorBoundary>
						{contentChildren}
						<JSErrorBoundary id="right-sidebar" packageName="page-container" fallback="unmount">
							<RightSidebar />
						</JSErrorBoundary>
					</Content>
					<JSErrorBoundary
						packageName="page-container"
						id="layout-remote-state-updater"
						fallback="unmount"
					>
						<LayoutRemoteStateUpdater />
					</JSErrorBoundary>
				</PageLayout>
			</FullHeight>
			<GlobalComponents sharedGlobalComponents={sharedLayout.globalComponents} />
			<GlobalBottomRightCornerContainer />
		</>
	);

	return (
		<>
			<PerformanceMark metricKey={GLOBAL_CONTAINERS_START_MARK} />
			<ThemeSetter />
			<GlobalContainers
				id={id}
				locale={locale}
				baseUrl={baseUrl}
				messages={messages}
				Router={Router}
				routerLinkComponent={routerLinkComponent}
			>
				<PerformanceMark
					metricKey={GLOBAL_CONTAINERS_END_MARK}
					onSet={pageContainerPerformanceTiming}
				/>
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="navigationNext">
					{panelContent}
				</ContextualAnalyticsData>
			</GlobalContainers>
		</>
	);
};

export const PageContainer = componentWithCondition(
	getWillShowNav4,
	PageContainerNew,
	PageContainerOld,
);
