import {
	AsyncHorizontalProjectNav,
	AsyncHorizontalOverviewNav,
} from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import {
	ROUTE_GROUPS_CLASSIC_BUSINESS,
	ROUTE_NAMES_ISSUE,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';

export const useBusinessLookAndFeel = () => {
	const route = useCurrentRoute();
	const HorizontalNavComponent = useHorizontalNavComponent();

	const isBusinessRouteGroup = route.group === ROUTE_GROUPS_CLASSIC_BUSINESS;

	const hasProjectViewNavigation = getWillShowNav4()
		? HorizontalNavComponent != null
		: HorizontalNavComponent === AsyncHorizontalProjectNav ||
			HorizontalNavComponent === AsyncHorizontalOverviewNav ||
			HorizontalNavComponent === AsyncHorizontalSoftwareProjectNav ||
			HorizontalNavComponent === AsyncHorizontalNavPlans;

	const hasTheming = getWillShowNav4()
		? HorizontalNavComponent === AsyncHorizontalProjectNav ||
			HorizontalNavComponent === AsyncHorizontalOverviewNav ||
			route.name === ROUTE_NAMES_ISSUE
		: hasProjectViewNavigation;

	// Routes showing a themed insetlayout are:
	// - all routes with the business project view navigation
	// - except for the routes with business group (as they already cater for the theme)
	const isInset = hasTheming && !isBusinessRouteGroup;

	return {
		isInset,
		hasTheming,
		hasProjectViewNavigation,
	};
};
