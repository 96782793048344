import React from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import { Redirect, type RouteContext } from '@atlassian/react-resource-router';

type NotificationsUrlFragments = 'customer-notifications';

export const notificationsRedirect =
	(pageUrlFragment: NotificationsUrlFragments) =>
	({ match }: RouteContext) => {
		const { projectKey } = match.params;

		const url =
			projectKey !== null && projectKey !== undefined
				? `${getNextgenSettingsUrl(
						SERVICE_DESK_PROJECT,
						projectKey,
					)}/notifications/${pageUrlFragment}`
				: `/projects/${String(projectKey)}/settings/notifications/${pageUrlFragment}`;

		return <Redirect to={url} push={false} />;
	};
