import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import { productsJswConfigurationResource } from '@atlassian/jira-router-resources-products-jsw-configuration';
import { productsJswStorageManagementResource } from '@atlassian/jira-router-resources-products-jsw-storage-management';
import {
	productsJswConfigurationRoute,
	productsJswConfigurationStorageManagementRoute,
} from '@atlassian/jira-router-routes-products-jsw-configuration-routes';
import ProductsJswConfiguration, { LazyProductsJswConfiguration } from './ui';
import ProductsJswStorageManagement, {
	LazyProductsJswStorageManagement,
} from './ui/products-jsw-storage-management';

export const productsJswConfigurationRouteEntry = createEntry(productsJswConfigurationRoute, () => ({
    ufoName: 'admin.jsw-configuration',
    component: ProductsJswConfiguration,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		productsJswConfigurationResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyProductsJswConfiguration]
}));

export const productsJswStorageManagementRouteEntry = createEntry(
	productsJswConfigurationStorageManagementRoute,
	() => ({
        ufoName: 'admin.jsw-configuration.storage-management',
        component: ProductsJswStorageManagement,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			productsJswStorageManagementResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyProductsJswStorageManagement]
    }),
);
