import { fetchQuery, graphql } from 'react-relay';
import {
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
	FAILED_TO_FETCH,
	getErrorType,
} from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import { measureExecutionTimeMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/utils/measure-execution-time';
import { FORGE_SAMPLING_RATE } from '@atlassian/jira-forge-ui-analytics/src/constants';
import {
	fireInitializationFetchFailedEvent,
	fireInitializationFetchFinishedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/fetch-modules';
import { SOURCE_UNKNOWN } from '@atlassian/jira-forge-ui-constants';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type {
	AggExtension,
	ExtensionContextsMapNew,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type { fetchModulesV2Query$data } from '@atlassian/jira-relay/src/__generated__/fetchModulesV2Query.graphql';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { canFetchForgeModules } from '../can-fetch-forge';
import { toMappedExtensions } from './data-transformer';

type FetchParams<ModuleType> = {
	cloudId: CloudId;
	types: ModuleType[];
	context: { issueKey?: string; projectKey?: string };
	includeHidden: boolean;
	includeScopes?: boolean;
	source?: Source;
};

export const fetchModules = async <
	ModuleType extends ExtensionPointModule,
	Response = {
		[Key in ModuleType]: ExtensionContextsMapNew[Key][];
	},
>(
	params: FetchParams<ModuleType>,
): Promise<Response> => {
	const { types, source = SOURCE_UNKNOWN } = params;

	if (!canFetchForgeModules()) {
		return toMappedExtensions(types, []);
	}

	try {
		const extensions = await measureExecutionTimeMetrics(() => doFetch(params));

		if (Math.random() * 100 < FORGE_SAMPLING_RATE) {
			fireInitializationFetchFinishedEvent(source);
		}

		return toMappedExtensions(types, extensions);
	} catch (error: unknown) {
		const errorType = getErrorType(error);

		fireInitializationFetchFailedEvent(source, {
			isSSR: __SERVER__,
			errorType,
			error,
		});

		if (EXCLUDED_ERROR_TYPES.includes(errorType)) {
			return toMappedExtensions(types, []);
		}

		throw error;
	}
};

export function doFetch<ModuleType extends ExtensionPointModule>(
	props: FetchParams<ModuleType>,
): Promise<AggExtension[]> {
	const { cloudId, types, context, includeHidden, includeScopes = false } = props;

	if (types.length === 0) {
		throw new Error('No modules to fetch provided');
	}

	return fetchQuery(
		getRelayEnvironment(),
		/* eslint-disable @atlassian/relay/unused-fields */
		graphql`
			query fetchModulesV2Query(
				$cloudId: ID!
				$context: JiraExtensionRenderingContextInput!
				$types: [String!]!
				$includeHidden: Boolean!
				$includeScopes: Boolean!
			) {
				jira @required(action: THROW) {
					forge @required(action: THROW) {
						extensions(
							cloudId: $cloudId
							context: $context
							types: $types
							includeHidden: $includeHidden
						) @required(action: THROW) {
							hiddenBy @include(if: $includeHidden)
							scopes @include(if: $includeScopes)
							type
							id
							environmentId
							environmentKey
							environmentType
							installationId
							appVersion
							consentUrl
							properties
							egress {
								addresses
								type
							}
							license {
								active
								type
								supportEntitlementNumber
								trialEndDate
								subscriptionEndDate
								isEvaluation
								billingPeriod
								ccpEntitlementId
								ccpEntitlementSlug
								capabilitySet
							}
						}
					}
				}
			}
		`,
		/* eslint-enable @atlassian/relay/unused-fields */
		{
			cloudId,
			context,
			types,
			includeHidden,
			includeScopes,
		},
	)
		.toPromise()
		.then((response) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (response as fetchModulesV2Query$data).jira.forge.extensions.concat();
		});
}

export const EXCLUDED_ERROR_TYPES = [
	FAILED_TO_FETCH,
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
];
