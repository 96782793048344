import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { devopsConnectedProjectRoute } from '@atlassian/jira-router-routes-devops-connected-project-routes';
import DevopsConnectedProject, {
	LazyDevopsConnectedProject,
} from './ui/spa/devops/connected-project';

export const devopsConnectedProjectRouteEntry = createEntry(devopsConnectedProjectRoute, () => ({
    ufoName: 'devops.connected-project',
    component: DevopsConnectedProject,
    layout: chromelessLayout,
    resources: [...getNavigationResources()],
    forPaint: [LazyAtlassianNavigation, LazyDevopsConnectedProject]
}));
