import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const journeyConfigEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-journey-config", jiraSpaEntry: "async-servicedesk-journey-config" */ './src'
			),
	),
	getPreloadProps: () => ({}),
});
