import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import {
	getNavigationResources,
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common';
import { routeGroupsProjectSettingsWorkflowSchemeConfigRoute } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { workflowSchemeConfigEntrypoint } from '@atlassian/jira-spa-apps-project-settings-workflow-scheme-config/entrypoint';

const workflowSchemeConfigPageEntrypoint = createPageEntryPoint({
	main: workflowSchemeConfigEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const CORE_PRODUCT = 'core';
const SERVICE_DESK_PRODUCT = 'servicedesk';

/**
 * Get the appropriate settings resources based on the product.
 */
export const getSettingsNavigationResources = (product: string) => {
	if (product === SERVICE_DESK_PRODUCT) {
		return getServiceDeskSettingsResources();
	}

	const resources = [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getNavigationSidebarProjectSettingsResource(),
	];

	if (product === CORE_PRODUCT) {
		return [...resources, ...getBusinessProjectResources()];
	}

	return resources;
};

export const servicedeskWorkflowConfigRouteEntry = createEntry(
	routeGroupsProjectSettingsWorkflowSchemeConfigRoute,
	() => ({
        entryPoint() {
			return workflowSchemeConfigPageEntrypoint;
		},

        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SERVICE_DESK_PRODUCT), projectContextResource],

        meta: {
			reporting: {
				id: 'project-settings.workflow-scheme-config',
				packageName: 'projectSettingsWorkflowSchemeConfig',
				teamName: 'jsd-shield',
			},
		},

        ufoName: 'project-settings-workflow-scheme-config'
    }),
);
