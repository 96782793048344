import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { KBSettingsAppWithLayout as helpCenterSettingsKnowledgeBaseType } from '@atlassian/jira-spa-apps-help-center-settings-knowledge-settings/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsKnowledgeBase = lazyForPaint<
	typeof helpCenterSettingsKnowledgeBaseType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-help-center-settings-knowledge-base", jiraSpaEntry: "async-help-center-settings-knowledge-base" */ '@atlassian/jira-spa-apps-help-center-settings-knowledge-settings'
		),
	),
);

export const HelpCenterSettingsKnowledgeBase = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsKnowledgeBase}
		pageId="help-center-settings-knowledge-base"
		shouldShowSpinner
	/>
);
