import React from 'react';
import { styled } from '@compiled/react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { AppSkeletonImage } from '../../common/ui';
import imgSVG from './assets/next-gen-backlog-app-skeleton.tpl.svg';

export const NextGenBacklogSkeletonOld = () => <AppSkeletonImage src={imgSVG} />;

export const NextGenBacklogSkeletonNew = () => (
	<Wrapper>
		<AppSkeletonImage src={imgSVG} />
	</Wrapper>
);

export const NextGenBacklogSkeleton = componentWithCondition(
	isVisualRefreshEnabled,
	NextGenBacklogSkeletonNew,
	NextGenBacklogSkeletonOld,
);

// Adding this wrapper to override the border width from AppSkeletonImage
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Wrapper = styled.div({
	display: 'contents',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'>div > img, > div > object': {
		borderLeftWidth: '24px',
		borderRightWidth: '24px',
	},
});
