import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyGroupDetail = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-group-detail", jiraSpaEntry: "async-jira-eoc-group-detail" */ '@atlassian/jira-eoc-group-detail/src/ui/index.tsx'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyGroupDetail} pageId="async-jira-eoc-group-detail" shouldShowSpinner={false} />
);
