import { CONNECT_GENERAL_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-general-query-params';
import { CONNECT_PROJECT_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-project-query-params';
import {
	ROUTE_GROUPS_CONNECT,
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_CONNECT_PROJECT,
	ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes';

// Don't forget to update CONNECT_ROUTES_SET in @atlassian/jira-common-constants/src/spa-routes

export const connectGeneralRoute = {
	name: ROUTE_NAMES_CONNECT_GENERAL,
	group: ROUTE_GROUPS_CONNECT,
	path: '/plugins/servlet/ac/:addonKey/:moduleKey',
	query: CONNECT_GENERAL_QUERY_PARAMS,
};

export const connectGeneralInvalidRoute = {
	name: ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	group: ROUTE_GROUPS_CONNECT,
	path: '/plugins/servlet/ac/:addonKey//:moduleKey',
	query: CONNECT_GENERAL_QUERY_PARAMS,
};

export const connectGeneralServiceDeskProjectRoute = {
	name: ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	group: ROUTE_GROUPS_CONNECT,
	path: '/plugins/servlet/ac/:addonKey/:moduleKey',
	query: ['project.key', 'servicedesk.serviceDeskId'],
};

export const connectProjectRoute = {
	group: ROUTE_GROUPS_CONNECT,
	name: ROUTE_NAMES_CONNECT_PROJECT,
	path: '/projects/:projectKey',
	query: CONNECT_PROJECT_QUERY_PARAMS,
};

export const servicedeskConnectProjectRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT,
	path: '/jira/servicedesk/projects/:projectKey',
	query: CONNECT_PROJECT_QUERY_PARAMS,
	exact: true,
};
