import { useEffect, useMemo } from 'react';
import {
	shouldRenderBannerPrefetched,
	type PrefetchedConsentPreferenceData,
	ATLASSIAN_SHOW_BANNER_COOKIE_KEY,
} from '@atlassian/browser-storage-controls';
import { getCookie } from '@atlassian/jira-browser-storage-controls/src/utils/get-cookie/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useConsentBannerUserPreferencesResource } from '@atlassian/jira-router-resources-cookie-consent';
import { BANNER_NAME } from '../constants';
import { isInIframe, useCookieConsentBannerSettings } from '../services/preferences';

export const shouldRenderBanner = (data: PrefetchedConsentPreferenceData | null) => {
	try {
		const shouldShowBannerBit = getCookie(ATLASSIAN_SHOW_BANNER_COOKIE_KEY);
		const shouldShowBannerBitState = shouldShowBannerBit ? !!Number(shouldShowBannerBit) : false;
		return (data && shouldRenderBannerPrefetched(data)) || shouldShowBannerBitState;
	} catch (e) {
		return true;
	}
};

const useBannerCookieConsentV2 = () => {
	const [{ isDismissed, height }, { onShow }] = useCookieConsentBannerSettings();

	const { data } = useConsentBannerUserPreferencesResource();

	useEffect(() => {
		const shouldRender = shouldRenderBanner(data);
		// only show banner if banner should be shown and not in iframe
		if (shouldRender && !isInIframe()) {
			onShow();
		}
	}, [data, onShow]);

	return useMemo(
		() => ({
			isEnabled: !isDismissed,
			name: BANNER_NAME,
			height,
		}),
		[isDismissed, height],
	);
};

const useBannerCookieConsentV1 = () => {
	const [{ isDismissed, height }] = useCookieConsentBannerSettings();

	return useMemo(
		() => ({
			isEnabled: !isDismissed,
			name: BANNER_NAME,
			height,
		}),
		[isDismissed, height],
	);
};

export const useBannerCookieConsent = functionWithCondition(
	() => ff('platform.moonjelly.browser-storage-controls-v2'),
	useBannerCookieConsentV2,
	useBannerCookieConsentV1,
);
