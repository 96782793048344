import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_CUSTOMER_PERMISSIONS } from '../../constants';
import type { RequestSecuritySettingsResponse } from './get-data/types.tsx';

export const customerPermissionsPageDataResource = createResource<RequestSecuritySettingsResponse>({
	type: RESOURCE_TYPE_CUSTOMER_PERMISSIONS,
	getKey: ({ match }) => match.params.projectKey || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-customer-permissions", jiraSpaEntry: "async-resource-customer-permissions" */ './get-data'
		),
	maxAge: Infinity,
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RequestSecuritySettingsResponse } from './get-data/types.tsx';
