/**
 * @generated SignedSource<<6b497dc8df78f1dce45893182e4dc7c8>>
 * @relayHash 30dd32919cc01646c2f07a87579c7d90
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c5bf480cfcc7e1667e0f3ca7923ae0e7fb82b6dc49abd4df64797411cc629cfd

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from './pageLayout_projectsDirectoryLayout_RootQuery.graphql';

const node: PreloadableConcreteRequest<pageLayout_projectsDirectoryLayout_RootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c5bf480cfcc7e1667e0f3ca7923ae0e7fb82b6dc49abd4df64797411cc629cfd",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
