import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../model/types';
import { getQuickstartTypeSelector } from '../../model/utils';
import { getLocalStorageItem } from '../../utils';

export const syncVisibilityWithLocal =
	(): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		const visibilityLocal = getLocalStorageItem(`${quickstartType}.visibility`);
		if (visibilityLocal === state.visibility[quickstartType]) {
			return;
		}
		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: visibilityLocal,
			},
		});
	};
