import { createClient, type Client } from 'graphql-ws';
import killswitch from '@atlassian/jira-killswitch';
import RelayExperimentalApis from '@atlassian/jira-relay-experimental-apis';
import { WS_CLIENT_KEEP_ALIVE, WS_CLIENT_RETRIES } from '../../constants';
import { isSSR, getAggSubscriptionEndpoint } from '../index';

let client: Client | null;

export const getClient = (): Client | null => {
	if (isSSR() || killswitch('graphql_subscriptions')) {
		return null;
	}

	if (!client) {
		client = createClient({
			url: getAggSubscriptionEndpoint(),
			lazy: true,
			keepAlive: WS_CLIENT_KEEP_ALIVE,
			retryAttempts: WS_CLIENT_RETRIES,
			connectionParams: {
				'atl-client-name': 'jira-frontend',

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				'atl-client-version': `${window?.BUILD_KEY ?? ''}::${window?.SSR_BUILD_KEY ?? ''}`,
				'X-ExperimentalApi': RelayExperimentalApis.join(','),
			},
		});
	}
	return client;
};

export const cleanUpClient = () => {
	client = null;
};
