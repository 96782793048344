import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskCmdbAssetsReports from '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports';
import type ServicedeskCmdbAssetsReportsUnsubscribe from '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports-unsubscribe';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskCmdbAssetsReports = lazyForPaint<typeof ServicedeskCmdbAssetsReports>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-cmdb-assets-reports", jiraSpaEntry: "async-servicedesk-assets-reports" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports'
		).then((exportedMembers) => exportedMembers.CmdbAssetsReports),
);

export const LazyServicedeskCmdbDefaultReportsRedirect = lazyForPaint<
	typeof ServicedeskCmdbAssetsReports
>(() =>
	import(
		/* webpackChunkName: "async-servicedesk-cmdb-assets-reports-default-redirect", jiraSpaEntry: "async-servicedesk-assets-reports-default-redirect" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports'
	).then((exportedMembers) => exportedMembers.CmdbDefaultReportsRedirect),
);

export const LazyServicedeskCmdbAssetsReportsUnsubscribe = lazyForPaint<
	typeof ServicedeskCmdbAssetsReportsUnsubscribe
>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-cmdb-assets-reports-unsubscribe", jiraSpaEntry: "async-servicedesk-assets-reports-unsubscribe" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports-unsubscribe'
		),
);

export const LazyServicedeskCmdbAssetsReportsNav4HomePage = lazyForPaint<
	typeof ServicedeskCmdbAssetsReports
>(() =>
	import(
		/* webpackChunkName: "async-servicedesk-cmdb-assets-reports-home-page", jiraSpaEntry: "async-servicedesk-assets-reports-home-page" */ '@atlassian/jira-spa-apps-servicedesk-cmdb-assets-reports'
	).then((exportedMembers) => exportedMembers.CmdbAssetsReportsNav4HomePage),
);

export const AssetsReports = () => (
	<LazyPage
		Page={LazyServicedeskCmdbAssetsReports}
		pageId="spa-apps/servicedesk/insight/cmdb-assets-reports"
		shouldShowSpinner
	/>
);

export const AssetsReportsUnsubscribe = () => (
	<LazyPage
		Page={LazyServicedeskCmdbAssetsReportsUnsubscribe}
		pageId="spa-apps/servicedesk/insight/cmdb-assets-reports-unsubscribe"
		shouldShowSpinner
	/>
);

export const AssetsReportingDefaultRedirect = () => (
	<LazyPage
		Page={LazyServicedeskCmdbDefaultReportsRedirect}
		pageId="spa-apps/servicedesk/insight/cmdb-assets-reports-default-redirect"
		shouldShowSpinner
	/>
);

export const AssetsReportsNav4HomePage = () => (
	<LazyPage
		Page={LazyServicedeskCmdbAssetsReportsNav4HomePage}
		pageId="spa-apps/servicedesk/insight/cmdb-assets-reports-home-page"
		shouldShowSpinner
	/>
);
