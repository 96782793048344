import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	AsyncJSMOperationsHorizontalNav,
	LazyJSMOperationsHorizontalNav,
} from '@atlassian/jira-horizontal-nav-jsm-operations/src';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { servicedeskOncallScheduleRoute } from '@atlassian/jira-router-routes-servicedesk-oncall-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import ServiceDeskOpsgenieSchedule, {
	LazyServicedeskOpsgenieSchedule,
} from './ui/opsgenie-schedule';

export const oncallRouteEntries: Route[] = [
	createEntry(servicedeskOncallScheduleRoute, () => ({
        ufoName: 'service-management.on-call-schedule',
        component: ServiceDeskOpsgenieSchedule,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncJSMOperationsHorizontalNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [
			LazyAtlassianNavigation,
			LazyServicedeskOpsgenieSchedule,
			LazyJSMOperationsHorizontalNav,
		]
    })),
];
