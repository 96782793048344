import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectShortcut } from '../../types';

export const fetchShortcuts = (projectKeyOrId: string): Promise<ProjectShortcut[]> => {
	try {
		return fetchJson(`/rest/projects/1.0/project/${projectKeyOrId}/shortcut`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		trackFetchErrors({
			error,
			id: 'fetchProjectShortcuts',
			packageName: 'jiraNavigationAppsResources',
		});
		throw error;
	}
};
