import type {
	EventId,
	Source,
} from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { AnalyticsAttributes, Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { PACKAGE_NAME_GASV3 } from '../../../constants';
import { createAttributes, parseSource } from '../create-attributes';
import { ANALYTICS_UTIL, logError } from '../logger';
import { isSynthetic } from '../synthetic';

type EventProps = {
	id: EventId;
	action: string;
	source?: Source;
	attributes?: AnalyticsAttributes;
};

export type EventInputProps = Flow.Diff<
	EventProps,
	{
		action: string;
	}
>;

const createAnalyticsProps = ({
	id,
	// Since source is required in sendOperationalEvent and sendUIEvent we need to pass here a fallback
	// in case source is not provided
	source = 'unknownSource',
	action,
	attributes,
}: EventProps) => ({
	actionSubject: `${PACKAGE_NAME_GASV3}.${id}`,
	attributes: createAttributes(attributes),
	source,
	action,
});

const getAnalyticsClient = async () => (await getAnalyticsWebClientPromise()).getInstance();

const sendOperationalEvent = async (props: EventProps) =>
	(await getAnalyticsClient()).sendOperationalEvent(createAnalyticsProps(props));

const sendUiEvent = async (props: EventProps) =>
	(await getAnalyticsClient()).sendUIEvent(createAnalyticsProps(props));

const sendTrackEvent = async (props: EventProps) =>
	(await getAnalyticsClient()).sendTrackEvent(createAnalyticsProps(props));

export const fireOperationalEvent = async (
	action: string,
	{ source, ...eventProps }: EventInputProps,
) =>
	sendOperationalEvent({
		...eventProps,
		source: parseSource(source),
		action,
	});

export const fireUiEvent = async (action: string, { source, ...eventProps }: EventInputProps) =>
	sendUiEvent({
		...eventProps,
		source: parseSource(source),
		action,
	});

export const fireTrackEvent = async (action: string, { source, ...eventProps }: EventInputProps) =>
	sendTrackEvent({
		...eventProps,
		source: parseSource(source),
		action,
	});

export type InitializationProps = {
	id: EventId;
	source?: Source;
	attributes?: Attributes;
};

export const fireInitializationEvent = async (
	action: string,
	{ id, attributes, source }: InitializationProps,
) =>
	Promise.all([
		fireOperationalEvent(action, {
			id,
			attributes,
			source,
		}),
		(attributes?.error &&
			logError(
				ANALYTICS_UTIL,
				`Something went wrong with Forge initialization, isSynthetic=${isSynthetic().toString()}, id=${id}, source=${parseSource(
					source,
				)}, action=${action}`,
				attributes.error,
			)) ||
			Promise.resolve(),
	]);
