import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import GLOBAL_FILTERS_QUERY, {
	type menuNavigationAppsSidebarFiltersGlobalScopeQuery,
} from '@atlassian/jira-relay/src/__generated__/menuNavigationAppsSidebarFiltersGlobalScopeQuery.graphql';
import { withGiraAggAnonSsrFix } from '../../../common/utils';

export const sidebarFiltersGlobalResource = withGiraAggAnonSsrFix(
	createRelayResource<menuNavigationAppsSidebarFiltersGlobalScopeQuery>({
		type: 'NAVIGATION_SIDEBAR_FILTERS_GLOBAL',
		getQuery: (_, { tenantContext }) => ({
			parameters: GLOBAL_FILTERS_QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
	}),
);
