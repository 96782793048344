import { useMemo } from 'react';
import {
	standardizeLocale,
	defaultLocale,
} from '@atlassian/jira-common-constants/src/supported-locales';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useTenantContext } from '../tenant-context';

/**
 * The `useLocale` hook will return the user's locale in the format
 * 'fr_FR', if you need the locale format in 'fr-FR' (e.g. to pass to
 * Atlaskit or react-intl), use {@linkcode useNativeLocale}.
 */
export const useLocale = (): Locale => {
	const tenantContext = useTenantContext();
	return (tenantContext && tenantContext.locale) || defaultLocale;
};

/**
 * The `useNativeLocale` hook will return the user's locale in the format
 * 'fr-FR' (which is used for react-intl and some Atlaskit components).
 * If you do not need to need this specific format, use {@linkcode useLocale}.
 */
export const useNativeLocale = (): string => {
	const locale = useLocale();

	const standardizedLocale = useMemo(() => standardizeLocale(locale), [locale]);

	return standardizedLocale;
};
