import React, { useEffect, useRef, useMemo, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { EntryPointContainer } from 'react-relay';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout';
import { RIGHT_SIDEBAR } from '@atlassian/jira-entry-point-page-layout-fields';
import { EntryPointPlaceholder } from '@atlassian/jira-entry-point-placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { RightSidebar as RightSidebarPanel } from '@atlassian/jira-navigation-system';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { renderGlobalComponents } from '@atlassian/jira-route-layout';
import { sharedLayout } from '@atlassian/jira-shared-layout';
import { ProjectListSidebarSkeleton } from '@atlassian/jira-skeletons/src/ui/project-list-sidebar/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

/**
 * This test ID is used in pollinator tests. Do not change lightly.
 * It's only added once the component is rendered client-side.
 */
const TEST_ID = 'page-layout.aside';

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const routeComponents: ComponentType[] = [];
const SharedRightSidebars = () => <>{renderGlobalComponents(sharedLayout.rightSidebars)}</>;
const RightSidebarRouteComponents = () => {
	const route = useCurrentRoute();
	return <>{renderGlobalComponents(route.layout?.rightSidebars || routeComponents)}</>;
};

const RightSidebarThroughEntrypoint = () => {
	const rightSideBarEntryPoint = usePageLayoutEntryPoint(RIGHT_SIDEBAR);
	const route = useCurrentRoute();
	const entrypoint = useMemo(() => route.entryPoint?.(), [route]);

	if (!entrypoint || !rightSideBarEntryPoint) return null;

	return (
		<UFOSegment name="right-sidebar-through-entrypoint">
			<JSErrorBoundary
				fallback="unmount"
				id="right-sidebar-through-entrypoint"
				packageName="page-container"
			>
				<EntryPointPlaceholder
					name="right-sidebar-through-entrypoint"
					fallback={<ProjectListSidebarSkeleton />}
					/* @ts-expect-error - TS2322 - We're using JSResourceReference from @atlassian/react-async. */
					entryPoint={entrypoint}
				>
					<EntryPointContainer
						entryPointReference={rightSideBarEntryPoint}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						props={{} as never}
					/>
				</EntryPointPlaceholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
};

const NewRightSidebar = () => {
	const lastPanelIdRef = useRef<unknown>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const rightSidebarCurrentState = useActiveRightSidebarState();

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'rightSidebar change', {
			panelId: rightSidebarCurrentState?.panelId || lastPanelIdRef.current,
			isClosed: rightSidebarCurrentState?.panelId == null,
			isMinimised: !!rightSidebarCurrentState?.isMinimised,
			isCollapsed: !!rightSidebarCurrentState?.isCollapsed,
		});
		lastPanelIdRef.current = rightSidebarCurrentState?.panelId;
	}, [
		createAnalyticsEvent,
		rightSidebarCurrentState?.panelId,
		rightSidebarCurrentState?.isMinimised,
		rightSidebarCurrentState?.isCollapsed,
	]);

	if (rightSidebarCurrentState && !rightSidebarCurrentState.shouldRender) {
		return null;
	}

	return (
		<UFOSegment name="right-sidebar">
			<RightSidebarPanel
				width={
					rightSidebarCurrentState &&
					!rightSidebarCurrentState.isCollapsed &&
					!rightSidebarCurrentState.isMinimised
						? rightSidebarCurrentState.width
						: 0
				}
				testId={TEST_ID}
			>
				<>
					<JSErrorBoundary
						fallback="unmount"
						id="shared-right-sidebars"
						packageName="page-container"
					>
						<SharedRightSidebars />
					</JSErrorBoundary>
					<JSErrorBoundary
						fallback="unmount"
						id="right-sidebar-route-components"
						packageName="page-container"
					>
						<RightSidebarRouteComponents />
					</JSErrorBoundary>
					{ff('server-render-projects-directory-sidebar_rj9ki') && (
						<RightSidebarThroughEntrypoint />
					)}
				</>
			</RightSidebarPanel>
		</UFOSegment>
	);
};

/**
 * Manages visibility and behavior of the right sidebar in the user interface, adapting to user interactions and system events. It enhances user experience by providing dynamic control over the sidebar's presentation, including features for collapsing and minimizing, alongside tracking user interactions for analytical purposes.
 */
const OldRightSidebar = () => {
	const lastPanelIdRef = useRef<unknown>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const rightSidebarCurrentState = useActiveRightSidebarState();
	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'rightSidebar change', {
			panelId: rightSidebarCurrentState?.panelId || lastPanelIdRef.current,
			isClosed: rightSidebarCurrentState?.panelId == null,
			isMinimised: !!rightSidebarCurrentState?.isMinimised,
			isCollapsed: !!rightSidebarCurrentState?.isCollapsed,
		});
		lastPanelIdRef.current = rightSidebarCurrentState?.panelId;
	}, [
		createAnalyticsEvent,
		rightSidebarCurrentState?.panelId,
		rightSidebarCurrentState?.isMinimised,
		rightSidebarCurrentState?.isCollapsed,
	]);
	if (rightSidebarCurrentState && !rightSidebarCurrentState.shouldRender) {
		return null;
	}
	return (
		<UFOSegment name="right-sidebar">
			<RightSidebarPanelWrapper id="right-sidebar-panel-wrapper">
				<RightSidebarPanel
					width={
						rightSidebarCurrentState &&
						!rightSidebarCurrentState.isCollapsed &&
						!rightSidebarCurrentState.isMinimised
							? rightSidebarCurrentState.width
							: 0
					}
				>
					<>
						<JSErrorBoundary
							fallback="unmount"
							id="shared-right-sidebars"
							packageName="page-container"
						>
							<SharedRightSidebars />
						</JSErrorBoundary>
						<JSErrorBoundary
							fallback="unmount"
							id="right-sidebar-route-components"
							packageName="page-container"
						>
							<RightSidebarRouteComponents />
						</JSErrorBoundary>
						{ff('server-render-projects-directory-sidebar_rj9ki') && (
							<RightSidebarThroughEntrypoint />
						)}
					</>
				</RightSidebarPanel>
			</RightSidebarPanelWrapper>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSidebarPanelWrapper = styled.div({
	display: 'contents',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :first-of-type': {
		zIndex: 100,
		transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
	},
});

export const RightSidebar = componentWithCondition(
	getWillShowNav4,
	NewRightSidebar,
	OldRightSidebar,
);
