import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { projectContextResource } from '../../services/project-context';
import type { UseProjectKeyByProjectResourceReponse } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	UseProjectKeyByProjectResourceReponseError,
	UseProjectKeyByProjectResourceReponseLoading,
	UseProjectKeyByProjectResourceReponseLoaded,
	UseProjectKeyByProjectResourceReponse,
} from './types';

// Project key is a part of standard URL, but often in legacy URLs, you only have project id, but not project key. Use this utility when you need projectKey in this situation.
export const useProjectKeyByProjectResource = (
	teamName?: string,
): UseProjectKeyByProjectResourceReponse => {
	const { data, error: resourceError, loading } = useResource(projectContextResource);

	if (resourceError && data == null) {
		const isErrorErrorType = resourceError instanceof Error;
		const error = isErrorErrorType
			? resourceError
			: new Error(
					`projectContextResource returned error resourceError: ${JSON.stringify(
						resourceError,
					)}, loading: ${loading}, data: null`,
				);
		fireErrorAnalytics({
			meta: {
				id: 'useResourceProjectContext',
				packageName: 'jiraRouterResourcesProjectContext',
				teamName: teamName ?? 'magma',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		return { loading: false, error, projectKey: null };
	}

	if (loading) {
		return { loading: true, error: null, projectKey: null };
	}

	// This case never happens by type definition, which you can't even mock test data for this case due to type definition. But if you don't check this, TS is not happy
	if (data == null) {
		return {
			loading: false,
			error: new Error('projectContextResource returned data=null when loading false'),
			projectKey: null,
		};
	}

	return { loading: false, projectKey: data.projectKey, error: null };
};
