import React from 'react';
import camelCase from 'lodash/camelCase';
import { lazyForPaint } from 'react-loosely-lazy';
import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	ROUTE_NAMES_SOFTWARE_PERISCOPE,
	ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { Skeleton } from '@atlassian/jira-periscope/src/ui/skeleton/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	softwarePeriscopeRoute,
	softwarePeriscopeClassicRoute,
} from '@atlassian/jira-router-routes-software-periscope-routes';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import {
	classicSoftwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';
import type { PeriscopePage as SoftwarePeriscopeType } from '@atlassian/jira-spa-apps-software-periscope/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyPeriscope = lazyForPaint<typeof SoftwarePeriscopeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-periscope", jiraSpaEntry: "async-periscope" */ '@atlassian/jira-spa-apps-software-periscope'
		),
	),
);

export const PeriscopeNextGen = () => (
	<LazyPage Page={LazyPeriscope} pageId="softwarePeriscopeNextGen" shouldShowSpinner />
);

export const PeriscopeClassic = () => (
	<LazyPage Page={LazyPeriscope} pageId="softwarePeriscopeClassic" shouldShowSpinner />
);

export const softwarePeriscopeNextGenRouteEntry: Route = createEntry(softwarePeriscopeRoute, () => ({
    component: componentWithCondition(isFedRamp, ErrorPagesNotFound, PeriscopeNextGen),
    skeleton: Skeleton,
    layout: softwareProjectLayoutNoOnboarding,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyPeriscope],

    meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_PERISCOPE,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_PERISCOPE),
			teamName: 'nova',
		},
	}
}));

export const softwarePeriscopeClassicRouteEntry: Route = createEntry(
	softwarePeriscopeClassicRoute,
	() => ({
        skeleton: Skeleton,

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			projectContextResource,
		],

        component: componentWithCondition(isFedRamp, ErrorPagesNotFound, PeriscopeClassic),
        layout: classicSoftwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPeriscope],

        meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC),
				teamName: 'nova',
			},
		}
    }),
);
