import { ProjectStyle, type Project } from '@atlassian/jira-common-constants/src/project-types';
import type {
	ProjectBean,
	ProjectSidebarNavInfo,
} from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';

export const getStyle = (isSimplifiedProject: boolean): ProjectStyle =>
	isSimplifiedProject ? ProjectStyle.NEXT_GEN : ProjectStyle.CLASSIC;

const getProject = (project: ProjectBean): Project => ({
	avatarUrl: project.avatarUrls['48x48'],
	id: Number(project.id),
	isSimplified: project.simplified,
	key: project.key,
	name: project.name,
	style: getStyle(project.simplified),
	type: project.projectTypeKey,
});

export const extractData = (data: ProjectSidebarNavInfo): Project | null => {
	const {
		projectType,
		jiraWorkManagementNavData,
		jiraSoftwareNavData,
		jiraServiceManagementNavData,
		jiraProductDiscoveryNavData,
	} = data || {};

	switch (projectType) {
		case 'jira_work_management':
			return jiraWorkManagementNavData?.project
				? getProject(jiraWorkManagementNavData.project)
				: null;
		case 'jira_software':
			return jiraSoftwareNavData?.project ? getProject(jiraSoftwareNavData.project) : null;
		case 'jira_service_management':
		case 'jira_customer_service':
			return jiraServiceManagementNavData?.project
				? getProject(jiraServiceManagementNavData.project)
				: null;
		case 'jira_product_discovery':
			return jiraProductDiscoveryNavData?.project
				? getProject(jiraProductDiscoveryNavData.project)
				: null;
		case null:
		default:
			return null;
	}
};
