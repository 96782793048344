import { resources as onCallResources } from '@atlassian/eoc-on-call/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	routeNamesEocOnCallRedirectRoute,
	eocOnCallRoute,
} from '@atlassian/jira-router-routes-eoc-on-call-routes';
import OnCall, { LazyOnCall, OnCallRedirect } from './ui/spa/eoc/on-call';

export const eocOnCallRouteEntry = createEntry(routeNamesEocOnCallRedirectRoute, () => ({
    component: OnCallRedirect,
    layout: opsTeamLayout,
    resources: [...getNavigationResources(), ...teamDashboardResources],

    navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},

    forPaint: [LazyAtlassianNavigation, LazyTeamDashboardSidebar],
    isRedirect: true
}));

export const eocOnCall1RouteEntry = createEntry(eocOnCallRoute, () => ({
    component: OnCall,
    layout: opsTeamLayout,

    resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...onCallResources,
		getConsolidationStateResource(),
	],

    navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyOnCall,
		LazyTeamDashboardSidebar,
	]
}));
