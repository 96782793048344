{
  "post-office.components.in-app.step-context.default-next-button.main": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "post-office.components.in-app.step-context.default-previous-button.main": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "post-office.components.in-app.step-context.next-button.step-state": [
    {
      "type": 1,
      "value": "currentStep"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "totalSteps"
    }
  ],
  "post-office.ipm-renderer.component.button.opens-new-window-accessible-message": [
    {
      "type": 0,
      "value": "opens new window"
    }
  ]
}