// eslint-disable-next-line @typescript-eslint/no-unused-vars -- PLEASE FIX - ENABLING FLAT LINT CONFIG
import React, { useEffect, useMemo } from 'react';
import { Section } from '@atlaskit/side-navigation';
import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/project-page';
import { PROJECT_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import type { ForgeProjectSectionProps } from '../../common/types';
import { ForgeItem } from '../../common/ui/forge-item/index.tsx';
import { toSections } from '../../common/utils/subpages/index.tsx';
import { useProjectForgeApps } from '../../controllers/project/index.tsx';
import { messages } from './messages';

// @ts-expect-error - It's genuinely missing Jira Product Discovery.
const projectTypeRouteMap: Record<ProjectType, string> = {
	[CORE_PROJECT]: 'core',
	[SOFTWARE_PROJECT]: 'software',
	[SERVICE_DESK_PROJECT]: 'servicedesk',
};

export const getItemHref = (
	extension: ProjectPage,
	projectType: ProjectType,
	projectKey: string,
) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `/jira/${projectTypeRouteMap[projectType]}/projects/${projectKey}/apps/${appId}/${envId}`;
};

export const ForgeProjectSection = ({ nestedMenuActive, overrides }: ForgeProjectSectionProps) => {
	const { data } = useProjectForgeApps();
	const { data: projectContextData } = useProjectContext();
	const { formatMessage } = useIntl();

	const filteredForgeAppsData = useMemo(
		() =>
			overrides?.showSelectedAppMenuOnly && overrides?.selectedApp != null
				? [overrides.selectedApp]
				: data || [],
		[data, overrides],
	);

	if (!data?.length || !projectContextData) return null;

	const { projectKey, projectType } = projectContextData;

	return (
		<TriggerAnalyticsWrapper>
			<Section
				title={
					overrides?.showSelectedAppMenuOnly ? undefined : formatMessage(messages.forgeSectionTitle)
				}
			>
				{filteredForgeAppsData.map((extension) => (
					<ForgeItem
						key={extension.id}
						id={extension.id}
						environmentType={extension.environmentType}
						environmentKey={extension.environmentKey}
						url={getItemHref(extension, projectType, projectKey)}
						name={extension.properties.title}
						sections={toSections(extension)}
						moduleName={PROJECT_PAGE_MODULE}
						iconUrl={extension.properties.icon}
						showIcon
						nestedMenuActive={nestedMenuActive}
						overrides={overrides}
					/>
				))}
				<ForgeScreenEvent
					attributes={{
						moduleType: PROJECT_PAGE_MODULE,
					}}
				/>
			</Section>
		</TriggerAnalyticsWrapper>
	);
};
