import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareFormSubmitRoute } from '@atlassian/jira-router-routes-software-form-submit-routes';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { FormSubmitPage, LazyFormSubmitView } from './ui';

export const softwareFormSubmitRouteEntry: Route = createEntry(softwareFormSubmitRoute, () => ({
    ufoName: 'next-gen-form-submit',
    component: componentWithFG('jwm_forms_to_jsw', FormSubmitPage, ErrorPagesNotFound),
    layout: chromelessLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectDetailsResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyFormSubmitView]
}));
