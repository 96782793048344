import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type SoftwareCodeType from '@atlassian/jira-spa-apps-software-code/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';
import { getBoardId } from '../../../common';

export const LazyCode = lazyForPaint<typeof SoftwareCodeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-code-simplified", jiraSpaEntry:  "async-code-simplified" */ '@atlassian/jira-spa-apps-software-code'
		),
	),
);

export const CodePageNextGen = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazyCode}
		pageId={`next-gen-code-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

export const CodePageClassic = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazyCode}
		pageId={`classic-code-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);
