import { createHook } from '@atlassian/react-sweet-state';
import { layoutStore } from '../../store';

/**
 * Hook to get the current fullscreen state
 * Usage:
 * ```
 * import { useIsFullscreen } from './index.tsx';
 * const [isFullscreen, { setIsFullscreen }] = useIsFullscreen();
 * const [isFullscreen] = useIsFullscreen();
 * ```
 */
export const useIsFullscreen = createHook(layoutStore, {
	selector: (state) => state.isFullscreen,
});
