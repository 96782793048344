// valid hex color (with no alpha channel) - 3 or 6 digits
const isValidHex = (hex: string): boolean => /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);

type RGB = { r: number; g: number; b: number };

export function toRGBAString({ r, g, b }: RGB, alpha: number) {
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function hexToRGB(hex: string): RGB {
	if (!isValidHex(hex)) {
		throw new Error('Invalid HEX');
	}

	let channels: { [Key in keyof RGB]: string };
	if (hex.length === 4) {
		// Hex code is shorthand - RGB channels are one digit
		channels = {
			r: hex[1].repeat(2),
			g: hex[2].repeat(2),
			b: hex[3].repeat(2),
		};
	} else {
		// Hex code is longhand - RGB channels are two digits
		channels = {
			r: hex.slice(1, 3),
			g: hex.slice(3, 5),
			b: hex.slice(5, 7),
		};
	}

	return {
		r: parseInt(channels.r, 16),
		g: parseInt(channels.g, 16),
		b: parseInt(channels.b, 16),
	};
}

/**
 * This formula is derived from WCAG2 guidelines:
 * https://www.w3.org/WAI/WCAG22/Techniques/general/G18.html#tests
 */
function linearizeRGBChannel(channel: number): number {
	return channel <= 0.04045 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
}

/**
 * This formula is derived from WCAG2 guidelines:
 * https://www.w3.org/WAI/WCAG22/Techniques/general/G18.html#tests
 */
function relativeLuminanceW3C({ r, g, b }: RGB): number {
	/**
	 * Normalized sRGB - each channel is between 0 and 1
	 */
	const normal: RGB = {
		r: r / 255,
		g: g / 255,
		b: b / 255,
	};

	/**
	 * linear RGB - the gamma correction of sRGB is removed and
	 * the channels correspond directly to subpixel intensity
	 */
	const linear: RGB = {
		r: linearizeRGBChannel(normal.r),
		g: linearizeRGBChannel(normal.g),
		b: linearizeRGBChannel(normal.b),
	};

	// For linear RGB, the relative luminance of a color is defined as:
	const L = 0.2126 * linear.r + 0.7152 * linear.g + 0.0722 * linear.b;

	return L;
}

/**
 * This formula is derived from WCAG2 guidelines:
 * https://www.w3.org/WAI/WCAG22/Techniques/general/G18.html#tests
 */
export function getContrastRatio(foreground: string, background: string): number {
	if (!isValidHex(foreground) || !isValidHex(background)) {
		throw new Error('Invalid HEX');
	}

	const foregroundRgb = hexToRGB(foreground);
	const backgroundRgb = hexToRGB(background);
	const foregroundLuminance = relativeLuminanceW3C(foregroundRgb);
	const backgroundLuminance = relativeLuminanceW3C(backgroundRgb);
	// calculate the color contrast ratio
	const brightest = Math.max(foregroundLuminance, backgroundLuminance);
	const darkest = Math.min(foregroundLuminance, backgroundLuminance);
	return (brightest + 0.05) / (darkest + 0.05);
}

/**
 * This is the intersection point for W3C contrast ratio against:
 *
 * 1. black
 * 2. white
 *
 * In other words, the background luminance for which W3C contrast is equal
 * for both white and black text.
 *
 * Using a precomputed flip point means we can save a lot of calculations.
 *
 * This is only the theoretical flip point, and we can adjust it as needed.
 */
const flipLuminance = 0.179129;

export function isLight(color: string): boolean {
	const rgb = hexToRGB(color);
	return relativeLuminanceW3C(rgb) >= flipLuminance;
}

export function getTextColor(backgroundColor: string): string {
	if (isLight(backgroundColor)) {
		return '#000';
	}
	return '#FFF';
}
