import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_PROJECT_SUMMARY,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskProjectSummaryRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	path: '/jira/servicedesk/projects/:projectKey/summary',
	name: ROUTE_NAMES_SERVICEDESK_PROJECT_SUMMARY,
};
