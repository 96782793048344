import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmEmailDomainsListResource } from '@atlassian/jira-router-resources-jsm-get-email-domains-list';
import { productsJsmOrganizationDetailsResource } from '@atlassian/jira-router-resources-jsm-organization-details';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { serviceDeskOrganizationPanelForgeDataResource } from '@atlassian/jira-router-resources-service-desk-org-panel-forge-data/src/controllers/index.tsx';
import { servicedeskOrganizationV2Route } from '@atlassian/jira-router-routes-servicedesk-organization-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import { ServiceDeskOrganizationV2, LazyServicedeskOrganizationV2 } from './ui';

export const organizationRouteEntries: Route[] = [
	createEntry(servicedeskOrganizationV2Route, () => ({
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			serviceDeskOrganizationPanelForgeDataResource,
			getConsolidationStateResource(),
			productsJsmOrganizationDetailsResource,
			jsmEmailDomainsListResource,
		],

        component: ServiceDeskOrganizationV2,
        forPaint: [LazyAtlassianNavigation, LazyServicedeskOrganizationV2],
        perfMetricKey: 'jsm-organizations-component-load'
    })),
];
