import { useEffect, useCallback, useState } from 'react';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { waitForHeritage } from '@atlassian/jira-common-bridge/src';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { jiraSuperBatchDependencies } from '@atlassian/jira-common-bridge/src/constants';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useSpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/page-ready-state/index.tsx';
import { asyncWrmRequire } from '../async-wrm';
import { getJiraHeritageDependencies } from './constants';
import { useWrmStatsEffects } from './controllers/wrm-stats';

export const JiraHeritageLoader = () => {
	const route = useCurrentRoute();
	const [{ isReady }] = useSpaStatePageReady();
	const [hasHeritageDownloaded, setHasHeritageDownloaded] = useState(false);

	useWrmStatsEffects();

	/* eslint-disable jira/wrm/no-load-bridge */
	const loadJiraHeritageDependencies = useCallback(() => {
		setHasHeritageDownloaded(true);
		asyncWrmRequire([
			...jiraSuperBatchDependencies,
			...getJiraHeritageDependencies(),
			// @ts-expect-error - TS2339 - Property 'resolve' does not exist on type 'Promise<undefined> | Deferred<undefined>'.
		]).then(() => waitForHeritage()?.resolve?.());
	}, []);

	useEffect(() => {
		if (isReady && !hasHeritageDownloaded) {
			loadJiraHeritageDependencies();
		}
	}, [hasHeritageDownloaded, isReady, loadJiraHeritageDependencies, route]);

	return null;
};

JiraHeritageLoader.displayName = 'JiraHeritageLoader';
