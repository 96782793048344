import get from 'lodash/get';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import type { IssueListResponse } from '@atlassian/jira-servicedesk-queues-common/src/rest/issue/types';
// eslint-disable-next-line jira/import-whitelist
import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import {
	ONE_DAY_IN_MILLIS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
	RESOURCE_TYPE_SERVICEDESK_QUEUES_ISSUE_LIST_SSR,
} from '../constants';
import type { CategorizedGroupData } from '../types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-nav-items", jiraSpaEntry: "async-resource-servicedesk-queues-nav-items" */ './get-data'
	);

const getPriorityGroupDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-priority-group-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-priority-group-data-loader" */ './get-priority-group-data'
	);

const fetchIssueListDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-queues-issue-list-data-loader", jiraSpaEntry: "async-resource-servicedesk-queues-issue-list-data-loader" */ './fetch-issue-list-ssr-data'
	);

const getCombinedProjectKeyAndQueueId = ({ match, query }: RouterContext) =>
	`${getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0]}-${get(match, 'params.queueId', '')}`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queuesNavigationItems = createResource<any>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS,
	getKey: ({ match, query }) =>
		// projectKey from url path/query or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

export const priorityGroupResource = createResource<CategorizedGroupData>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS,
	getKey: ({ match, query }) =>
		// projectKey from url path or browse/{issueKey}
		getProjectKeyId(match, query).projectKey || get(match, 'params.issueKey', '').split('-')[0],
	getDataLoader: getPriorityGroupDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
	isBrowserOnly: true,
});

export const issueListSsrResource = createResource<IssueListResponse | undefined>({
	type: RESOURCE_TYPE_SERVICEDESK_QUEUES_ISSUE_LIST_SSR,
	getKey: getCombinedProjectKeyAndQueueId,
	getDataLoader: fetchIssueListDataLoader,
	maxAge: 0,
	// run this resource from server only
	skipBrowser: true,
});
