import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { resources as heartbeatsResources } from '@atlassian/eoc-heartbeats/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { heartbeatsRoute } from '@atlassian/jira-router-routes-eoc-heartbeats-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyHeartbeats = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-heartbeats", jiraSpaEntry: "async-jira-eoc-heartbeats" */ '@atlassian/jira-eoc-heartbeats'
		),
);

const HeartbeatsComponent = () => (
	<LazyPage Page={LazyHeartbeats} pageId="async-jira-eoc-heartbeats" shouldShowSpinner={false} />
);

export const eocHeartbeatsRouteEntry = createEntry(heartbeatsRoute, () => ({
    component: HeartbeatsComponent,
    layout: opsTeamLayout,

    resources: [
		...getNavigationResources(),
		...heartbeatsResources,
		...teamDashboardResources,
		getConsolidationStateResource(),
	],

    navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyHeartbeats,
		LazyTeamDashboardSidebar,
	]
}));
