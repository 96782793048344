import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { kbConfluenceContextResource } from '@atlassian/jira-router-resources-confluence-context/src/services/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { servicedeskAtlassianAnalyticsResource } from '@atlassian/jira-router-resources-reports-atlassian-analytics';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	servicedeskReportsSummaryRoute,
	servicedeskReports1Route,
	servicedeskAtlassianAnalyticsRoute,
	servicedeskReportsWorkloadRoute,
	servicedeskReportsSatisfactionIssueRoute,
	servicedeskReportsSatisfactionTimeScaleRoute,
	servicedeskReportsSatisfaction2Route,
	servicedeskKbRequestsReportsIssueRoute,
	servicedeskKbRequestsReportsTimeScaleRoute,
	servicedeskKbRequestsReports2Route,
	servicedeskReportsCustomEditRoute,
	servicedeskReportsCustomCreateRoute,
	servicedeskReportsCustomIssueRoute,
	servicedeskReportsCustomTimeScaleRoute,
	servicedeskReportsCustom4Route,
	servicedeskReportsTypeRoute,
} from '@atlassian/jira-router-routes-servicedesk-reports-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { Redirect, type RouteContext, type Route } from '@atlassian/react-resource-router';
import { WORKLOAD_ROUTE_SUFFIX } from './constants';
import ServiceDeskReports, { LazyServicedeskReports } from './ui/async-page';
import { AtlassianAnalytics, LazyAtlassianAnalytics } from './ui/atlassian-analytics';
import CustomReports, { LazyServicedeskReportsCustom } from './ui/custom';
import CustomCreate, { LazyServicedeskReportsCustomCreate } from './ui/custom-create-view';
import CustomEdit, { LazyServicedeskReportsCustomEdit } from './ui/custom-edit-view';
import HomePage, { LazyServicedeskReportsHomePage } from './ui/home-page';
import ReportsIssueView, { LazyServicedeskReportsIssueView } from './ui/issue-view';
import RequestsReports, { LazyServicedeskKbRequestsReports } from './ui/kb-requests';
import SatisfactionReports, { LazyServicedeskReportsSatisfaction } from './ui/satisfaction';
import WorkloadReports, { LazyServicedeskReportsWorkload } from './ui/workload';

const DefaultReportsRedirect = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/servicedesk/projects/${String(match.params.projectKey)}${WORKLOAD_ROUTE_SUFFIX}`}
	/>
);

const returnToSummary = ({ match }: RouteContext) => <Redirect to={String(match.params['0'])} />;

const getReportsWithKbResources = () => [
	...getServicedeskPageContextResources(),
	kbConfluenceContextResource,
];

export const reportsRouteEntries: Route[] = [
	createEntry(servicedeskReportsSummaryRoute, () => ({
        component: returnToSummary,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(servicedeskReports1Route, () => ({
        component: componentWithCondition(getWillShowNav4, HomePage, DefaultReportsRedirect),
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getReportsWithKbResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsHomePage]
    })),
	createEntry(servicedeskAtlassianAnalyticsRoute, () => ({
        component: AtlassianAnalytics,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			servicedeskAtlassianAnalyticsResource,
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyAtlassianAnalytics],

        meta: {
			reporting: {
				id: APP_NAMES.ATLASSIAN_ANALYTICS,
				packageName: toPackageName(APP_NAMES.ATLASSIAN_ANALYTICS),
				teamName: 'ITSM Saga',
			},
		}
    })),
	createEntry(servicedeskReportsWorkloadRoute, () => ({
        component: WorkloadReports,
        perfMetricKey: 'service-management.reports.workload',
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsWorkload]
    })),
	createEntry(servicedeskReportsSatisfactionIssueRoute, () => ({
        component: ReportsIssueView,
        layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsIssueView],
        ufoName: 'service-management.reports.issue'
    })),
	createEntry(servicedeskReportsSatisfactionTimeScaleRoute, () => ({
        perfMetricKey: 'service-management.reports.satisfaction',
        component: SatisfactionReports,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsSatisfaction]
    })),
	createEntry(servicedeskReportsSatisfaction2Route, () => ({
        perfMetricKey: 'service-management.reports.satisfaction',
        component: SatisfactionReports,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsSatisfaction]
    })),
	createEntry(servicedeskKbRequestsReportsIssueRoute, () => ({
        component: ReportsIssueView,
        layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsIssueView],
        ufoName: 'service-management.reports.issue'
    })),
	createEntry(servicedeskKbRequestsReportsTimeScaleRoute, () => ({
        perfMetricKey: 'service-management.reports.kb-requests',
        component: RequestsReports,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskKbRequestsReports]
    })),
	createEntry(servicedeskKbRequestsReports2Route, () => ({
        perfMetricKey: 'service-management.reports.kb-requests',
        component: RequestsReports,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskKbRequestsReports]
    })),
	createEntry(servicedeskReportsCustomEditRoute, () => ({
        perfMetricKey: 'service-management.reports.custom-edit-view',
        component: CustomEdit,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsCustomEdit]
    })),
	createEntry(servicedeskReportsCustomCreateRoute, () => ({
        perfMetricKey: 'service-management.reports.custom-create',
        component: CustomCreate,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsCustomCreate]
    })),
	createEntry(servicedeskReportsCustomIssueRoute, () => ({
        component: ReportsIssueView,
        layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsIssueView],
        ufoName: 'service-management.reports.issue'
    })),
	createEntry(servicedeskReportsCustomTimeScaleRoute, () => ({
        component: CustomReports,
        perfMetricKey: 'service-management.reports.custom',
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsCustom],
        ufoName: 'service-management-custom-reports'
    })),
	createEntry(servicedeskReportsCustom4Route, () => ({
        component: CustomReports,
        perfMetricKey: 'service-management.reports.custom',
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsCustom],
        ufoName: 'service-management-custom-reports'
    })),
	createEntry(servicedeskReportsTypeRoute, () => ({
        component: ServiceDeskReports,

        layout: composeLayouts(
			serviceProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ params: [{ key: 'issueKey' }] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getServiceDeskNavigationResources(),
			...getReportsWithKbResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskReports]
    })),
];

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export default ServiceDeskReports;
