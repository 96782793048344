import {
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import {
	routeGroupsProjectSettingsServicedeskAccessRoute,
	routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute,
	routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute,
	routeGroupsProjectSettingsServicedeskFeedbackRoute,
	routeGroupsProjectSettingsServicedeskFieldsRoute,
	routeGroupsProjectSettingsServicedeskProformaProjectFormsRoute,
} from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes';
import type { RouterContext } from '@atlassian/react-resource-router';
import { MENU_ID_SD_SETTINGS_ACCESS } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS,
	ROUTE_NAMES_SERVICEDESK_SLA_SETTINGS,
	routeGroupsProjectSettingsServicedeskAccessRoute.name,
	routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute.name,
	routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute.name,
	routeGroupsProjectSettingsServicedeskFeedbackRoute.name,
	routeGroupsProjectSettingsServicedeskFieldsRoute.name,
	routeGroupsProjectSettingsServicedeskProformaProjectFormsRoute.name,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;

	const path: string[] = [];

	switch (routeName) {
		// Access Menu
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PEOPLE:
		case routeGroupsProjectSettingsServicedeskAccessRoute.name:
		case routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute.name:
		case routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute.name:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_PERMISSIONS:
		case ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_ISSUE_SECURITY:
			path.push(MENU_ID_SD_SETTINGS_ACCESS);
			break;

		default:
			break;
	}

	return path;
}

export const serviceManagementProjectSettingsConverter = {
	routes,
	convert,
};
