import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { legacyRedirectRoute } from '@atlassian/jira-router-routes-legacy-redirect-routes';
import RedirectToLegacyRouting from '@atlassian/jira-spa-apps-common/src/redirect/legacy-routing';

// @ts-expect-error - Type '{}' is missing the following properties from type 'Pick<Omit<Omit<RouteContext & { flagService: FlagService; createAnalyticsEvent: (arg1: { action: string; }) => UIAnalyticsEvent; }, keyof WithAnalyticsEventsProps> & RefAttributes<...>, "flagService">, keyof RouteContext | keyof RefAttributes<...>>': query, location, route, match, action
const RedirectComponent = () => <RedirectToLegacyRouting />;

export const legacyRedirectRouteEntry = createEntry(legacyRedirectRoute, () => ({
    component: RedirectComponent,
    layout: globalLayout,
    resources: [],
    isRedirect: true,
    canTransitionIn: () => false,
    forPaint: [LazyAtlassianNavigation]
}));
