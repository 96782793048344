import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import {
	createHook,
	createSubscriber,
	type SubscriberComponent,
} from '@atlassian/react-sweet-state';
import Store from '../../store';
import type { State, Actions, TransitionState, PageTiming, VisitedPages } from '../../types';

const getNavigationStart = () => {
	// @ts-expect-error - TS2339 - Property 'timing' does not exist on type 'Performance | { now: () => number; mark: (...args: any[]) => void; clearMarks: (...args: any[]) => void; clearResourceTimings: (...args: any[]) => void; getEntriesByType: (type: string) => never[]; getEntriesByName: (name: string) => never[]; }'. | TS2339 - Property 'timing' does not exist on type 'Performance | { now: () => number; mark: (...args: any[]) => void; clearMarks: (...args: any[]) => void; clearResourceTimings: (...args: any[]) => void; getEntriesByType: (type: string) => never[]; getEntriesByName: (name: string) => never[]; }'.
	const timestamp = performance && performance.timing && performance.timing.navigationStart;
	return timestamp ? new Date(timestamp).toISOString() : undefined;
};

const getLastTransition = (currentPage: PageTiming, visitedPages: VisitedPages) => {
	if (visitedPages.length === 0) {
		return undefined;
	}

	const collectAppNames = (page: PageTiming) => Object.keys(page.apps || {}).sort();
	const appNamesToStr = (appNames: Array<string>) => appNames.join('+') || '?';

	const prevPage = appNamesToStr(collectAppNames(visitedPages.slice(-1)[0]));
	const currPage = appNamesToStr(collectAppNames(currentPage));

	return `${prevPage} > ${currPage}`;
};

const getLastTransitionStartTime = (currentPage: PageTiming, visitedPages: VisitedPages) => {
	// If there are no previously visited pages the transition didn't have a place
	if (visitedPages.length === 0) {
		return undefined;
	}

	return currentPage.startTime;
};

export const selector = ({ visitedPages, currentPage }: State): TransitionState => ({
	isInitialRender: visitedPages.length === 0,
	transitionsCount: visitedPages.length,
	lastTransition: getLastTransition(currentPage, visitedPages),
	lastTransitionStartTime: getLastTransitionStartTime(currentPage, visitedPages),
	navigationStart: getNavigationStart(),
	currentPageId: currentPage.uuid,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Subscriber = SubscriberComponent<TransitionState, Actions, Record<any, any>>;
// @ts-expect-error - TS2322 - Type 'SubscriberComponent<TransitionState, BoundActions<State, typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/controllers/spa-state/src/actions/index")>, Record<...>>' is not assignable to type 'Subscriber'.
const SpaStateTransitionStateSubscriber: Subscriber = createSubscriber<
	State,
	Actions,
	TransitionState,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store, {
	displayName: 'SpaStateTransitionStateSubscriber',
	selector,
});

export const useSpaStateTransition = createHook(Store, {
	selector,
});

export default SpaStateTransitionStateSubscriber;
