import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_ONBOARDING,
	ROUTE_NAMES_SOFTWARE_ONBOARDING_GET_STARTED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwareOnboardingRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ONBOARDING,
	path: '/welcome/software',
	exact: true,
};

export const softwareOnboardingGetStartedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ONBOARDING_GET_STARTED,
	path: '/jira/software/projects/:projectKey/get-started',
	exact: true,
};
