import type { articleId as articleIdType } from '@atlaskit/help';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, HistoryItem } from '../model/types';

const iphStorage = createSessionStorageProvider('iph');

export const setNavigationData =
	(navigationData: { articleId?: articleIdType; history?: HistoryItem[] }): Action<State> =>
	({ setState, getState }) => {
		const currentState = getState();
		setState({
			navigationData: {
				articleId: navigationData.articleId
					? navigationData.articleId
					: currentState.navigationData.articleId,
				history: navigationData.history
					? navigationData.history
					: currentState.navigationData.history,
			},
		});

		iphStorage.set('history', []);
	};

export const setReleaseNotesNotifications =
	(releaseNotesNotifications: number): Action<State> =>
	({ setState }) => {
		setState({
			releaseNotesNotifications,
		});
	};
