import workflowsResource from '@atlassian/jira-admin-pages-list-workflows/src/services/resource/list-workflows/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsIssueWorkflowEditRoute,
	globalSettingsIssueWorkflow1Route,
} from '@atlassian/jira-router-routes-admin-pages-workflows-routes';
import type { Route } from '@atlassian/react-resource-router';
import EditWorkflow, { LazyEditWorkflow } from './ui/spa/admin-pages/workflows/edit-workflow';
import ListWorkflows, { LazyListWorkflows } from './ui/spa/admin-pages/workflows/list-workflows';

export const workflowPagesRouteEntries: Route[] = [
	createEntry(globalSettingsIssueWorkflowEditRoute, () => ({
        ufoName: 'global-admin.issues.edit-workflow',
        component: EditWorkflow,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyEditWorkflow]
    })),
	createEntry(globalSettingsIssueWorkflow1Route, () => ({
        ufoName: 'global-admin.issues.view-workflows',
        component: ListWorkflows,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource, workflowsResource],
        forPaint: [LazyAtlassianNavigation, LazyListWorkflows]
    })),
];
