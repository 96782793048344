import { useMemo } from 'react';
import type {
	ProjectIssueType as IssueType,
	ProjectStatus,
} from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	getApplicationForProject,
	type Application,
} from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, type ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	type IssueKey,
	type ProjectKey,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	useProjectContextActionStore,
	useProjectContextProjectTypeStore,
	useProjectContextStore,
	useProjectContextProjectNameStore,
	useProjectContextIssueTypesStore,
} from './context';
import type { ProjectContextServiceActions, IssueAppConfiguration } from './types';
import { getIssueConfiguration } from './utils';

/**
 * This hook generates an issue's project key from the provided issue key.
 * @param issueKey
 * @returns {ProjectKey}
 */
export const useProjectKey = (issueKey: IssueKey | string) => toProjectKey(issueKey.split('-')[0]);

export const useProjectContextActions = (): [null, ProjectContextServiceActions] => {
	const [, { setProjectContext, mergeProjectProperty }] = useProjectContextActionStore();
	const actions = useMemo(
		() => ({ setProjectContext, mergeProjectProperty }),
		[setProjectContext, mergeProjectProperty],
	);
	return [null, actions];
};

export const useProjectType = (projectKey: ProjectKey) => {
	const [projectType] = useProjectContextProjectTypeStore({ projectKey });
	return projectType;
};

export const useProjectContext = (projectKey: ProjectKey) => {
	const [projectContext] = useProjectContextStore({ projectKey });
	return projectContext;
};

export const useApplication = (
	projectKey: ProjectKey,
	failSafely = false,
): Application | undefined => {
	const projectType = useProjectType(projectKey);

	if (projectType === undefined) {
		if (failSafely) {
			return undefined;
		}
		throw new Error(`No project type for project key ${projectKey} in Project Context Service`);
	}
	return getApplicationForProject(projectType);
};

export const useEdition = (
	projectKey: ProjectKey,
	failSafely = false,
): ApplicationEdition | undefined => {
	const { appEditions } = useTenantContext();

	const application = useApplication(projectKey, failSafely);
	if (!application) {
		return undefined;
	}
	return getEdition(application, appEditions);
};

export const useIsSimplifiedProject = (projectKey: ProjectKey): boolean => {
	const [projectContext] = useProjectContextStore({ projectKey });
	return projectContext?.isSimplified || false;
};

export const useIsClassicProject = (projectKey: ProjectKey): boolean => {
	const [projectContext] = useProjectContextStore({ projectKey });
	return !projectContext?.isSimplified;
};

export const useProjectId = (projectKey: ProjectKey): string | undefined => {
	const [projectContext] = useProjectContextStore({ projectKey });
	return projectContext?.projectId;
};

export const useProjectStatus = (projectKey: ProjectKey): ProjectStatus | undefined => {
	const [projectContext] = useProjectContextStore({ projectKey });
	return projectContext?.projectStatus;
};

export const useProjectConfiguration = (projectKey: ProjectKey): IssueAppConfiguration => {
	const projectType = useProjectType(projectKey);
	const isSimplified = useIsSimplifiedProject(projectKey);
	return getIssueConfiguration({ isSimplified, projectType });
};

export const useProjectName = (projectKey: ProjectKey) => {
	const [projectName] = useProjectContextProjectNameStore({ projectKey });
	return projectName;
};

export const useProjectIssueTypes = (projectKey: ProjectKey): IssueType[] | undefined => {
	const [issueTypes] = useProjectContextIssueTypesStore({ projectKey });
	return issueTypes;
};
