import {
	ROUTE_NAMES_FILTERS_DIRECTORY,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_LEGACY_GIN,
	ROUTE_NAMES_LEGACY_GIN_ISSUE,
	ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/react-resource-router';
import {
	ENTITY_ID,
	MENU_ID_FILTERS,
	MENU_ID_FILTERS_SEARCH,
	MENU_ID_FILTERS_VIEW_ALL,
	MENU_ID_PROJECTS,
} from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_FILTERS_DIRECTORY,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR_BROWSE_ISSUE_REDIRECT,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_LEGACY_GIN, // Same as ROUTE_NAMES_ISSUE but without filter querystring
	ROUTE_NAMES_LEGACY_GIN_ISSUE,
	ROUTE_NAMES_LEGACY_GIN_SUBSCRIPTIONS,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { query, match } = routeContext;
	const path: string[] = [MENU_ID_FILTERS];

	switch (routeName) {
		case ROUTE_NAMES_FILTERS_DIRECTORY:
			path.push(MENU_ID_FILTERS_VIEW_ALL);
			break;

		case ROUTE_NAMES_ISSUE: {
			const filterId = query.filter || query.filterId;

			if (filterId) {
				path.push(ENTITY_ID.FILTER(filterId));
			} else if (match.params?.issueKey) {
				const [projectKey] = (match.params?.issueKey || '').split('-'); // For this route to match, the issueKey will never be blank
				return [MENU_ID_PROJECTS, ENTITY_ID.PROJECT(projectKey)];
			}
			break;
		}

		default: {
			const filterId = query.filter || query.filterId;

			// Search filters URL
			if (filterId === undefined) {
				path.push(MENU_ID_FILTERS_SEARCH);
			} else {
				// The rest of the URLs are using a filter ID.
				// Test this case: https://buglow-jsw.jira-dev.com/secure/ViewSubscriptions.jspa?filterId=10007
				path.push(ENTITY_ID.FILTER(filterId));
			}

			break;
		}
	}

	return path;
}

export const filtersConverter = { routes, convert };
