import omit from 'lodash/omit';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { useQueryParam, usePathParam } from '@atlassian/react-resource-router';
import type { ContextProviderProps } from './types';

export const ContextProvider = ({ children }: ContextProviderProps) => {
	const [boardId] = usePathParam('boardId');
	const [rapidView] = useQueryParam('rapidView');
	const [{ data: ctxData, loading, error }] = useContainerContext();

	/* Don't provide scope data if boardId does not exist in the URL as
	 * we don't want to show the board breadcrumb in this case since it
	 * indicates that we aren't in a scope context even though one may
	 * exist. */
	const data =
		ctxData == null || boardId != null || rapidView != null ? ctxData : omit(ctxData, 'scope');

	// @ts-expect-error - TS2322 - Type 'Pick<ProjectContext | UserContext, "contextType"> | null' is not assignable to type 'ContainerContext | null'. | TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to type 'Error | null'.
	return children({ data, loading, error });
};
export default ContextProvider;
