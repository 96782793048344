import { PROJECTS_COMPONENTS_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/projects-components-query-params';
import {
	ROUTE_GROUPS_COMPONENTS,
	ROUTE_NAMES_PROJECT_COMPONENTS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const projectComponentsRedirectRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: `${ROUTE_NAMES_PROJECT_COMPONENTS}-redirect`,
	path: '/projects/:projectKey',
	query: PROJECTS_COMPONENTS_QUERY_PARAMS,
} as const as Route;

export const projectComponentsRoute = {
	group: ROUTE_GROUPS_COMPONENTS,
	name: ROUTE_NAMES_PROJECT_COMPONENTS,
	path: '/jira/:projectType(software|software/c|servicedesk|core)/projects/:projectKey/components',
};
