/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { AppSkeletonImage } from '../../common/ui';
import imgSVGNavRefresh from './assets/roadmap-app-skeleton-nav-refresh.tpl.svg';
import imgSVG from './assets/roadmap-app-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: 0,
	},
});

export const RoadmapSkeleton = () =>
	getWillShowNav4() ? (
		<AppSkeletonImage src={imgSVGNavRefresh} css={customCssStyles} />
	) : (
		<AppSkeletonImage src={imgSVG} css={customCssStyles} />
	);
