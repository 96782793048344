import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SidebarType, ProjectSidebarAddonNavItem } from './types';

export const fetchProjectSidebarConnectNavigationInfo = (
	projectKeyOrId: string,
	sidebarType: SidebarType,
): Promise<ProjectSidebarAddonNavItem[]> => {
	if (projectKeyOrId == null) {
		throw new Error('invalid identifier specified');
	}
	return performGetRequest(
		`/rest/navigation/4/sidebar/project/${projectKeyOrId}/addons?type=${sidebarType}`,
	);
};
