import { isLoaderError } from 'react-loosely-lazy';
import {
	BAD_REQUEST,
	REQUEST_TIMEOUT,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';

const commonErrors = {
	MUST_INCLUDE_ACCOUNT_ID:
		'Response ended with an error message: Requests from this source must include an accountId',
	OPERATION_ABORTED: 'The operation was aborted. ',
	CANCELLED: 'cancelled',
	UNEXPECTED_ERROR_OCCURRED: 'Response ended with an error message: An unexpected error occurred',
	NETWORK_CONNECTION_LOST: 'The network connection was lost.',
	TIMEOUT: 'The request timed out.',
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS:
		'Response ended with an error message: Authorization failed: Principal has insufficient permissions',
	GATEWAY_TIMEOUT:
		'Response ended with an error message: The underlying service call failed. The underlying service cs_extensions status code is : 504',
	GADGET_PREFERENCES: 'failed to put gadget preference: Error server response: 429',
} as const;

export const DATA_CLASSIFICATION_NO_ACCESS = 'DATA_CLASSIFICATION_NO_ACCESS';
export const FAILED_TO_FETCH = 'FAILED_TO_FETCH';
export const AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS =
	'AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS';
export const RATE_LIMITING = 'RATE_LIMITING';
export const INVOKE_VALUE_FUNCTION_FAILED = 'Invoke value functions failed:';

export const userConsentErrorRegex = new RegExp('The user did not consent to use the app', 'i');

export const isChunkLoadError = (error: Error) => {
	// React loosely lazy chunk load error type
	if (isLoaderError(error) || (typeof error === 'object' && error.name === 'ChunkLoadError'))
		return true;

	// Other chunk load error types
	// In theory it should be handled by a common isClientFetchError, but it ignores TypeError error types
	const KNOWN_NETWORK_ERRORS = [
		'Failed to fetch dynamically imported module',
		'error loading dynamically imported module',
		'Importing a module script failed',
	];

	const isClientFetchErrorMessage = (message: string): boolean =>
		Boolean(message) &&
		!!KNOWN_NETWORK_ERRORS.find((networkErrorMessage) =>
			// Using `includes` here because the underlying fetch error messages
			// are sometimes wrapped to provide additional context, e.g.
			// "Something went wrong: ${error.message}"
			message.includes(networkErrorMessage),
		);

	return (
		Boolean(error) &&
		typeof error === 'object' &&
		(isClientFetchErrorMessage(error.message) ||
			(typeof error.cause === 'object' &&
				error.cause &&
				'message' in error.cause &&
				isClientFetchErrorMessage(String(error.cause.message))))
	);
};

export const getErrorType = (_error: unknown) => {
	if (_error == null) {
		return 'NULL_OR_UNDEFINED';
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const error = _error as Error;

	const message = String(error instanceof Error ? error.message : error).trim();
	const isTimeoutError = (timedOutResource: string) =>
		Boolean(
			typeof error === 'object' &&
				error.name === 'TimeoutError' &&
				error.stack?.includes(timedOutResource),
		);

	const circuitBreakerErrorRegex = new RegExp("We couldn't show this extension", 'i');
	if (circuitBreakerErrorRegex.test(message)) {
		return 'CIRCUIT_BREAKER_ERROR';
	}

	const rateLimitingRegex = new RegExp(
		"Response ended with an error message: You are trying to access '.*' too often",
		'i',
	);

	const rateLimitingDataClassificationRegex = new RegExp(
		'Data classification request failed: Error server response: 429',
	);
	if (rateLimitingRegex.test(message) || rateLimitingDataClassificationRegex.test(message)) {
		return RATE_LIMITING;
	}

	const serverErrorRegex = new RegExp('^Error server response: (\\d+)$', 'i');
	if (serverErrorRegex.test(message)) {
		const match = message.match(serverErrorRegex);
		const statusCode = match ? match[1] : 'NO_STATUS_CODE';

		return `ERROR_SERVER_RESPONSE_${statusCode}`;
	}

	const aggGiraServerErrorRegex = new RegExp(
		'The underlying service gira status code is: (\\d+)$',
		'im',
	);
	if (aggGiraServerErrorRegex.test(message)) {
		const match = message.match(aggGiraServerErrorRegex);
		const statusCode = match?.[1] ?? 'NO_STATUS_CODE';

		return `AGG_GIRA_ERROR_${statusCode}`;
	}

	const dataClassification404ErrorRegex = new RegExp(
		'Data classification request failed: Error server response: 404',
		'i',
	);
	const dataClassification401ErrorRegex = new RegExp(
		'Data classification request failed: Error server response: 401',
		'i',
	);

	if (
		dataClassification401ErrorRegex.test(message) ||
		dataClassification404ErrorRegex.test(message)
	) {
		return DATA_CLASSIFICATION_NO_ACCESS;
	}

	const graphqlErrorRegex = new RegExp('^GraphQL error', 'i');
	if (graphqlErrorRegex.test(message)) {
		return 'GRAPHQL_ERROR';
	}

	if (userConsentErrorRegex.test(message)) {
		return 'USER_DIDNT_CONSENT_ERROR';
	}

	const appValueFunction = new RegExp("The app's value function failed", 'i');
	const serverValueFunction408ErrorRegex = new RegExp(
		'Invoke value functions failed: Error server response: 408',
		'i',
	);
	const invokeValueFunctionFailed = new RegExp(INVOKE_VALUE_FUNCTION_FAILED, 'i');

	/**
	 * In case when we get bad request(400) from the BE it should be treated as an app error as there
	 * are many ways to fail the value function
	 */
	const valueFunctionAppsBadRequest =
		invokeValueFunctionFailed.test(message) &&
		'statusCode' in error &&
		error.statusCode === BAD_REQUEST;

	/**
	 * In case when we get request timeout(408) from the XIS invocation it should be treated as an app error.
	 * Error occurs when invocation exceeds 25s threshold e.g.too long time to execute request to external API
	 */

	const valueFunctionTimeoutError =
		serverValueFunction408ErrorRegex.test(message) &&
		'statusCode' in error &&
		error.statusCode === REQUEST_TIMEOUT;

	if (appValueFunction.test(message) || valueFunctionTimeoutError || valueFunctionAppsBadRequest) {
		return 'VALUE_FUNCTION_APP_ERROR';
	}

	// The full message states:
	// Extension "extension name" does not exist, the app may have changed or no longer be installed in this context
	const extensionDoesntExistRegex = new RegExp('^Extension "(.*)" does not exist', 'i');
	if (extensionDoesntExistRegex.test(message)) {
		return 'EXTENSION_DOESNT_EXIST';
	}

	// In case of React error message:
	// Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
	const removeChildOnNodeRegex = new RegExp("^Failed to execute 'removeChild' on 'Node'", 'i');

	if (removeChildOnNodeRegex.test(message)) {
		return 'REMOVE_CHILD_ERROR';
	}

	if (isChunkLoadError(error)) {
		return 'CHUNK_LOAD';
	}

	if (isTimeoutError('FORGE_PROJECT_MODULE')) {
		return 'TIMEOUT_PROJECT_MODULE';
	}

	if (isTimeoutError('FORGE_PROJECT_SETTINGS_MODULE')) {
		return 'TIMEOUT_PROJECT_SETTINGS_MODULE';
	}

	if (isTimeoutError('PROJECT_CONTEXT')) {
		return 'TIMEOUT_PROJECT_CONTEXT';
	}

	const entry = Object.entries(commonErrors).find(
		(arrayItem) => String(arrayItem[1]).toLowerCase() === message.toLowerCase(),
	);
	if (entry) return entry[0];

	if (isClientFetchError(error)) {
		return FAILED_TO_FETCH;
	}

	return 'OTHER';
};
