import logger from '@atlassian/jira-common-util-logging/src/log';
// eslint-disable-next-line jira/import-whitelist
import {
	WEB_RESOURCE_MAP,
	CONNECT_ITEM_AMD_MODULE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type {
	AmdGadgetClass,
	LoadedAmdModules,
	WebResourceName,
} from '@atlassian/jira-dashboard-common/src/types.tsx';

/**
 * Dummy implementation for a Connect gadget, as rendering and editing are
 * managed externally.
 */
class ConnectDashboardItem {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	render() {}

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	renderEdit() {}
}

export const requireAmdModule = (
	moduleName: string,
	retries = 5,
): AmdGadgetClass | Promise<AmdGadgetClass> => {
	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (typeof window.require !== 'function') {
			throw new TypeError('window.require is not a function');
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return window.require(moduleName);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// amd module could throw a non-Error
		if (error instanceof Error && /^No \S+$|^\S+ missing \S+$/.test(error.message)) {
			// some AMD modules have define() inside a require() block in which case the module is not immediately ready to be window.require()'d
			// this is due to the 4ms timeout in requirejs' require(), we need a retry logic here to make sure the module is loaded.
			// see the comment section for https://jdog.jira-dev.com/browse/WRM-71
			if (retries > 0) {
				return new Promise((r: (result: Promise<undefined> | undefined) => void) =>
					setTimeout(r, 100),
				).then(() => requireAmdModule(moduleName, retries - 1));
			}
		}
		throw error;
	}
};

export const loadAmdModules = async (
	wrmRequire: (arg1: string[]) => Promise<void>,
	moduleNames: WebResourceName[],
): Promise<boolean> => {
	if (moduleNames.length === 0) {
		return false;
	}
	const prefixedAmdResources = moduleNames
		.map((amdModule) => WEB_RESOURCE_MAP[amdModule])
		.map((resourceName) => `wrc!${resourceName}`);

	try {
		// no result from the promise only the side effect of having loaded the AMD module via WRM
		await wrmRequire(prefixedAmdResources);
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logger.safeErrorWithoutCustomerData(
			'spa-apps.dashboard.gadget.resource',
			`Failed to load web resources [${prefixedAmdResources.join(',')}]`,
			error,
		);
		return false;
	}
};

type AmdModuleTuple = [string, AmdGadgetClass | null];

const getSortedModuleNamesFromTuples = (tuples: AmdModuleTuple[]): string =>
	tuples
		.map(([moduleName]) => moduleName)
		.sort((a, b) => a.localeCompare(b))
		.join(',');

export const evalAmdModules = (
	amdModuleNames: string[],
): Promise<LoadedAmdModules> | LoadedAmdModules => {
	const tuplesOrPromises: (AmdModuleTuple | Promise<AmdModuleTuple>)[] = amdModuleNames.map(
		(amdModuleName) => {
			try {
				const module =
					amdModuleName === CONNECT_ITEM_AMD_MODULE
						? ConnectDashboardItem
						: requireAmdModule(amdModuleName);
				return module instanceof Promise
					? module.then((v) => [amdModuleName, v])
					: [amdModuleName, module];
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				return Promise.reject(error);
			}
		},
	);
	const hasPromise = tuplesOrPromises.some((entry) => entry instanceof Promise);

	if (!hasPromise) {
		logger.safeInfoWithoutCustomerData(
			'spa-apps.dashboard.resource',
			'Successful synchronous bulk eval AMD modules',
		);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return Object.fromEntries(tuplesOrPromises as AmdModuleTuple[]);
	}

	return Promise.allSettled(tuplesOrPromises).then((results) => {
		const tuples: AmdModuleTuple[] = results.map((result, i): AmdModuleTuple => {
			if (result.status === 'fulfilled') {
				return result.value;
			}
			const { reason: error } = result;
			const amdModuleName = amdModuleNames[i];
			const message = error instanceof Error ? error.message : String(error);
			logger.safeErrorWithoutCustomerData(
				'spa-apps.dashboard.resource',
				`Failed bulk eval AMD module [${amdModuleName}] with error [${message}]`,
				error,
			);
			return [amdModuleName, null];
		});

		const failedTuples = tuples.filter((module) => module[1] === null);

		failedTuples.length > 0
			? logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.resource',
					`Failed bulk eval AMD modules [${getSortedModuleNamesFromTuples(failedTuples)}]`,
				)
			: logger.safeInfoWithoutCustomerData(
					'spa-apps.dashboard.resource',
					'Successful asynchronous bulk eval AMD modules',
				);

		return Object.fromEntries(tuples);
	});
};
