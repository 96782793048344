/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment } from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import { contentHeightWhenFixed, contentInsetBlockStart, localSlotLayers } from '../constants';
import { useSideNavVisibility } from '../side-nav/use-side-nav-visibility';
import { usePrefixedUID } from '../use-prefixed-id';

import { MainStickyContext } from './main-sticky-context';

const styles = cssMap({
	root: {
		gridArea: 'main',
		isolation: 'isolate',
		'@media (min-width: 64rem)': {
			isolation: 'auto',
		},
	},
	mainCurvedCorner: {
		'@media (min-width: 64rem)': {
			borderStartStartRadius: '6px',
		},
	},
	fixedContentArea: {
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		overflow: 'auto',
		'@media (min-width: 64rem)': {
			// Height is set so it takes up all of the available viewport space minus top bar + banner.
			// This is only set on larger viewports meaning stickiness only occurs on them.
			// On small viewports it is not sticky.
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			height: contentHeightWhenFixed,
			position: 'sticky',
		},
	},
});

const contentBorderStyles = cssMap({
	largeViewportTop: {
		boxSizing: 'border-box',
		borderBlockStart: `1px solid ${token('color.border')}`,
		display: 'none',
		// Specifically not using the `grid-area` shorthand property here to prevent issues due to Compiled's non-deterministic style ordering.
		// https://hello.atlassian.net/wiki/spaces/DST/pages/4149184745/Longer+version+Risks+of+Compiled+s+determinism+in+shorthand+properties
		gridRowStart: 'main',
		gridRowEnd: 'main',
		gridColumnStart: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
		zIndex: localSlotLayers.contentBlockBorder,
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	largeViewportLeft: {
		boxSizing: 'border-box',
		borderBlock: `1px solid transparent`,
		borderInlineStart: `1px solid ${token('color.border')}`,
		borderStartStartRadius: '6px',
		display: 'none',
		// Specifically not using the `grid-area` shorthand property here to prevent issues due to Compiled's non-deterministic style ordering.
		// https://hello.atlassian.net/wiki/spaces/DST/pages/4149184745/Longer+version+Risks+of+Compiled+s+determinism+in+shorthand+properties
		gridRowStart: 'main',
		gridRowEnd: 'main',
		gridColumnStart: 'main',
		// This makes this grid item span all available columns so if aside or panel are mounted it spans to them.
		gridColumnEnd: -1,
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		// Because we are rendering this ontop of all content grid areas we set pointer events to none so
		// any content rendered inside the top bar remains interactive.
		pointerEvents: 'none',
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
		zIndex: localSlotLayers.contentInlineBorder,
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	curvedBorder: {
		borderStartStartRadius: '6px',
		borderInline: `1px solid transparent`,
	},
});

export function Main({
	children,
	isFixed,
	xcss,
	label = 'Main Content',
	testId,
	id,
}: {
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	children: React.ReactNode;
	isFixed?: boolean;
	label?: string;
	testId?: string;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	const { visibleOnDesktop: isSideNavVisibleOnDesktop } = useSideNavVisibility();

	useSkipLink(CID, label);

	return (
		<Fragment>
			<div
				id={CID}
				data-layout-slot
				aria-label={label}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={xcss}
				role="main"
				css={[
					styles.root,
					isSideNavVisibleOnDesktop && styles.mainCurvedCorner,
					isFixed && styles.fixedContentArea,
				]}
				data-testid={testId}
			>
				<MainStickyContext.Provider value={Boolean(isFixed)}>{children}</MainStickyContext.Provider>
			</div>
			{isSideNavVisibleOnDesktop && (
				<div
					// CALL OUT: If this component is NOT mounted then the border around the
					// content area on large viewports will NOT be rendered.
					data-layout-slot
					css={contentBorderStyles.largeViewportLeft}
				/>
			)}
			<div
				data-layout-slot
				css={[
					contentBorderStyles.largeViewportTop,
					isSideNavVisibleOnDesktop && contentBorderStyles.curvedBorder,
				]}
			/>
		</Fragment>
	);
}
