import React from 'react';
import { RedirectToLoginPage } from '@atlassian/jira-spa-apps-common/src/redirect/login';
import {
	JIRA_LOGIN_PERMISSION_VIOLATION,
	ARJ_LICENSE_VIOLATION,
	ARJ_PLAN_NOT_FOUND,
	ARJ_SCENARIO_NOT_FOUND,
	ARJ_REPORT_NOT_FOUND,
	ARJ_NO_PLAN_PERMISSION,
	PLAN,
	SCENARIO,
} from '../../common/constants';
import { AsyncLicenseViolationErrorPage } from './license-violation/async';
import { AsyncNoPlanPermissionErrorPage } from './no-plan-permission/async';
import { AsyncNotFoundErrorPage } from './not-found/async';

export const ErrorScreen = ({
	errorMessage,
	isEmbedded = false,
	planId,
}: {
	errorMessage: string | undefined;
	isEmbedded?: boolean;
	planId?: number;
}) => {
	/**
	 * Clearing the early-preload promises here otherwise they get carried on to the following plan loads.
	 *
	 * The SPA app renders the ErrorScreen instead of the PlanApp when the validation fails,
	 * hence the early-preload promises did not get cleaned up.
	 */

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window.__ARJ_BACKLOG_PROMISE__;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window.__ARJ_INFO_METADATA_PROMISE__;

	switch (errorMessage) {
		case JIRA_LOGIN_PERMISSION_VIOLATION: {
			return <RedirectToLoginPage />;
		}
		case ARJ_LICENSE_VIOLATION: {
			return <AsyncLicenseViolationErrorPage isEmbedded={isEmbedded} />;
		}
		case ARJ_PLAN_NOT_FOUND:
		case ARJ_REPORT_NOT_FOUND: {
			return <AsyncNotFoundErrorPage entity={PLAN} isEmbedded={isEmbedded} />;
		}
		case ARJ_SCENARIO_NOT_FOUND: {
			return <AsyncNotFoundErrorPage entity={SCENARIO} isEmbedded={isEmbedded} planId={planId} />;
		}
		case ARJ_NO_PLAN_PERMISSION: {
			return <AsyncNoPlanPermissionErrorPage isEmbedded={isEmbedded} />;
		}
		default: {
			return null;
		}
	}
};
