import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { archivedProjectsDirectoryRoute } from '@atlassian/jira-router-routes-archived-projects-directory-routes';
import ArchivedProjectsDirectory, {
	LazyArchivedProjectsAdmin,
} from './ui/spa/admin-pages/archived-projects';

export const archivedProjectsDirectoryRouteEntry = createEntry(archivedProjectsDirectoryRoute, () => ({
    component: ArchivedProjectsDirectory,
    layout: globalSettingsLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProjects,
	},

    resources: [...getNavigationResources(), navigationSidebarGlobalResource],
    forPaint: [LazyAtlassianNavigation, LazyArchivedProjectsAdmin],
    ufoName: 'admin.archived-projects'
}));
