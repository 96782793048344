import { type PathParameters } from '../../types';

const urls = Object.freeze({
	alerts: {
		responders: {
			names: {
				url: () => '/v1/alerts/responders/names',
			},
			autoComplete: {
				url: () => '/v1/alerts/responders/auto-complete',
			},
		},
	},
	schedules: {
		url: () => `/v1/schedules`,
		scheduleId: {
			url: (
				{ scheduleId }: PathParameters<'scheduleId'> = {
					scheduleId: ':scheduleId',
				},
			) => `/v1/schedules/${scheduleId}`,
			clone: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/clone`,
			},
			name: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/name`,
			},
			timezone: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/timezone`,
			},
			enable: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/enable`,
			},
			disable: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/disable`,
			},
			webCalendarUrl: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/web-calendar-url`,
			},
			timeline: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/timeline`,
			},
			overrides: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/overrides`,
				alias: {
					url: (
						{ scheduleId, alias }: PathParameters<'scheduleId' | 'alias'> = {
							scheduleId: ':scheduleId',
							alias: ':alias',
						},
					) => `/v1/schedules/${scheduleId}/overrides/${alias}`,
				},
				bulk: {
					url: (
						{ scheduleId }: PathParameters<'scheduleId'> = {
							scheduleId: ':scheduleId',
						},
					) => `/v1/schedules/${scheduleId}/overrides/bulk`,
				},
			},
			rotations: {
				url: (
					{ scheduleId }: PathParameters<'scheduleId'> = {
						scheduleId: ':scheduleId',
					},
				) => `/v1/schedules/${scheduleId}/rotations`,
				rotationId: {
					url: (
						{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
							scheduleId: ':scheduleId',
							rotationId: ':rotationId',
						},
					) => `/v1/schedules/${scheduleId}/rotations/${rotationId}`,
					order: {
						url: (
							{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
								scheduleId: ':scheduleId',
								rotationId: ':rotationId',
							},
						) => `/v1/schedules/${scheduleId}/rotations/${rotationId}/order`,
					},
					name: {
						url: (
							{ scheduleId, rotationId }: PathParameters<'scheduleId' | 'rotationId'> = {
								scheduleId: ':scheduleId',
								rotationId: ':rotationId',
							},
						) => `/v1/schedules/${scheduleId}/rotations/${rotationId}/name`,
					},
				},
			},
		},
		timeTemplates: {
			rotation: {
				url: () => '/v1/schedules/time-templates/rotation',
			},
		},
	},
	customers: {
		timezones: {
			url: () => '/v1/customers/timezones',
		},
	},
} as const);

export { urls };
