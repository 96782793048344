/**
 * @generated SignedSource<<9ddf22ac213b3d96ce5d8f5e3bcc044c>>
 * @relayHash e3433d594a40a4cb106c2820cd7dcb1d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 97d2b21bdeeed3feec83279fc508b8e3c2ffe5ee0a22f9918ba5d75f481ee3c7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "97d2b21bdeeed3feec83279fc508b8e3c2ffe5ee0a22f9918ba5d75f481ee3c7",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
