import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_JSM_CUSTOMER_AUTHENTICATION_SETTINGS,
	ONE_DAY_IN_MILLIS,
} from '../constants';
import type { JsmCustomerAuthenticationSettingsResponse } from '../types';

export const jsmCustomerAuthenticationSettingsResource =
	createResource<JsmCustomerAuthenticationSettingsResponse>({
		type: RESOURCE_TYPE_JSM_CUSTOMER_AUTHENTICATION_SETTINGS,
		getKey: () => 'settings',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-jsm-customer-authentication-settings", jiraSpaEntry: "async-resource-jsm-customer-authentication-settings" */ './get-data'
			),
		maxAge: ONE_DAY_IN_MILLIS,
	});
