import { ff } from '@atlassian/jira-feature-flagging';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	get(): boolean {
		return (
			ff('relay-migration-issue-fields-security-level_7y5uv') ||
			ff('relay-migration-issue-fields-watchers_iqhn4')
		);
	},
};
