import React from 'react';
import camelCase from 'lodash/camelCase';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import {
	CLASSIC_BACKLOG,
	COMPANY_MANAGED_BACKLOG,
} from '@atlassian/jira-capabilities/src/constants.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import {
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { globalLayout } from '@atlassian/jira-global-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { getProjectViewResources as getListViewResources } from '@atlassian/jira-router-resources-business-common';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	getUIFBacklogDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { devOpsProviderResource } from '@atlassian/jira-router-resources-devops-provider/src/services/index.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { classicRoadmapResource } from '@atlassian/jira-router-resources-roadmap/src/services/classic-roadmap/index.tsx';
import { codePageResource } from '@atlassian/jira-router-resources-software-code/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import {
	softwareRoadmapClassicRoute,
	softwareTimelineClassicRoute,
	softwareGoalsClassicRoute,
	rapidboardBacklogRoute,
	rapidboardBacklogEmbedRoute,
	rapidboardReportRoute,
	softwareReportsCycleTimeClassicRoute,
	softwareClassicProformaIssueFormsDirectRoute,
	softwareClassicProformaIssueFormsDirectEmbedRoute,
	softwareReportsDeploymentFrequencyClassicRoute,
	rapidboardUserBacklogRoute,
	rapidboardUserReportRoute,
	rapidboardBacklog1Route,
	rapidboardReport1Route,
	softwareCodeClassicRoute,
	rapidboardLastVisitedProjectRedirectRoute,
	projectDirectoryRedirectRoute,
	softwareListClassicRoute,
	softwareListEmbedClassicRoute,
	softwareCalendarClassicRoute,
	softwareCalendarClassicEmbedRoute,
	softwareFormClassicRoute,
	softwareFormBuilderClassicRoute,
	softwareFormSubmitClassicRoute,
} from '@atlassian/jira-router-routes-classic-projects-routes';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { RedirectToLastVisitedProjectPage } from '@atlassian/jira-router-routes-common/src/ui/last-visited-project-page/index.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import { softwareIncidentsClassicRouteEntry } from '@atlassian/jira-router-routes-incidents-entries';
import {
	// eslint-disable-next-line jira/import/no-restricted-import
	classicSoftwareBoardRouteEntry,
	// eslint-disable-next-line jira/import/no-restricted-import
	classicSoftwareBoardEmbedRouteEntry,
} from '@atlassian/jira-router-routes-software-classic-board-entries';
// eslint-disable-next-line jira/import/no-restricted-import
import { classicSoftwareBoardSettingsInsightsRouteEntries } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-entries';
// eslint-disable-next-line jira/import/no-restricted-import
import { legacyRapidBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-rapid-board-entries';
// eslint-disable-next-line jira/import/no-restricted-import
import { softwareDeploymentsClassicRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries';
import {
	FormBuilderPage,
	LazyFormBuilderView,
} from '@atlassian/jira-router-routes-software-form-builder-entries/src/ui';
import { FormPage, LazyFormView } from '@atlassian/jira-router-routes-software-form-entries/src/ui';
import {
	FormSubmitPage,
	LazyFormSubmitView,
} from '@atlassian/jira-router-routes-software-form-submit-entries/src/ui';
// eslint-disable-next-line jira/import/no-restricted-import
import { softwarePeriscopeClassicRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries';
// eslint-disable-next-line jira/import/no-restricted-import
import { softwareSecurityClassicRouteEntry } from '@atlassian/jira-router-routes-software-security-entries';
// eslint-disable-next-line jira/import/no-restricted-import
import { userBoardRouteEntry } from '@atlassian/jira-router-routes-user-board-entries';
import {
	RapidBoardSpaPage,
	LazyRapidBoard,
	LazyRapidBoardApps,
} from '@atlassian/jira-router-routes-user-board-entries/src/async';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import {
	CalendarSkeleton,
	CalendarEmbedSkeleton,
} from '@atlassian/jira-skeletons/src/ui/calendar/index.tsx';
import { ClassicReportSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-report/index.tsx';
import { ListSkeleton, ListEmbedSkeleton } from '@atlassian/jira-skeletons/src/ui/list/index.tsx';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/index.tsx';
import { RoadmapSkeleton } from '@atlassian/jira-skeletons/src/ui/roadmap/index.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import {
	classicSoftwareProjectLayout,
	classicSoftwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';
import type NextGenBacklogType from '@atlassian/jira-spa-apps-next-gen-backlog/src/ui/backlog/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import type { Route } from '@atlassian/react-resource-router';
import { TimelineRedirect } from './common/ui/redirect/timeline-redirect';
import { getReportsCommonResource } from './common/ui/resources/reports';
import AtlasGoalsPage from './ui/goals';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/proforma/issue-forms-direct';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/proforma/issue-forms-direct/embed';
import RoadmapPage, { LazyRoadmapViewClassic } from './ui/projects/roadmap';
import { softwareCalendarRouteEntry } from './ui/software/calendar';
import {
	CodePageClassic,
	LazyCode,
	LazySoftwareListView,
	SoftwareListPage,
	LazySoftwareListEmbedView,
	SoftwareListEmbedPage,
} from './ui/software/code';
import { softwareListRouteEntry } from './ui/software/list';
import {
	CycleTimeReportClassic,
	LazyCycleTimeReport,
} from './ui/software/reports/cycle-time-report';
import {
	DeploymentFrequencyReportClassic,
	LazyDeploymentFrequencyReport,
} from './ui/software/reports/deployment-frequency';

export const LazyBacklogPage = lazyForPaint<typeof NextGenBacklogType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-backlog", jiraSpaEntry: "next-gen-backlog" */ '@atlassian/jira-spa-apps-next-gen-backlog'
		),
	),
);
const ProjectsDirectoryRedirect = () => <ScreenRedirect to="/jira/projects" />;
/**
 * Ordinarily it would be inadvisable to evaluate something like this in
 * a static route, as this could lead to inconsistent behaviour between
 * the server and the browser.
 *
 * In this case it is necessary and safe because:
 * 1) There are two versions of the backlog that share the same route
 *    and we can only tell them apart at runtime. When the old version
 *    is eventually removed, this should be cleaned up.
 * 2) No requests for backlog data are made on the server, so even
 *    if the value is incorrect on the server it won't be utilised
 * */
const classicBacklogCapability = !__SERVER__ ? COMPANY_MANAGED_BACKLOG : CLASSIC_BACKLOG;

export const classicProjectRouteEntries: Route[] = [
	createEntry(softwareRoadmapClassicRoute, () => ({
        component: TimelineRedirect,
        skeleton: RoadmapSkeleton,

        layout: composeLayouts(
			classicSoftwareProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			classicRoadmapResource,
		],

        preloadOptions: {
			earlyChunks: ['software-roadmap-early-entry'],
		},

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRoadmapViewClassic],
        ufoName: 'classic-roadmap'
    })),
	createEntry(softwareTimelineClassicRoute, () => ({
        component: RoadmapPage,
        skeleton: RoadmapSkeleton,

        layout: composeLayouts(
			classicSoftwareProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			classicRoadmapResource,
		],

        preloadOptions: {
			earlyChunks: ['software-roadmap-early-entry'],
		},

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRoadmapViewClassic],
        ufoName: 'classic-roadmap'
    })),
	// This route need to be before backlog and board route
	...classicSoftwareBoardSettingsInsightsRouteEntries,
	createEntry(rapidboardBacklogEmbedRoute, () => ({
        ufoName: 'classic-backlog-embed',

        component: componentWithFG(
			'enable_backlog_smartlink_route',
			RapidBoardSpaPage,
			ErrorPagesNotFound,
		),

        layout: chromelessLayout,

        resources: [
			...getUIFBacklogDataResource(),
			...getRapidboardResources(),
			...getNavigationSidebarProjectResource(),
			getRapidBoardDataResource(),
			getEditModelResource(),
			getBoardDetailsResource(),
			themePreferenceResource,
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyBacklogPage],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: classicBacklogCapability,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
	createEntry(rapidboardBacklogRoute, () => ({
        ufoName: 'classic-backlog',
        component: RapidBoardSpaPage,
        skeleton: NextGenBacklogSkeleton,

        layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getUIFBacklogDataResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			getEditModelResource(),
			getBoardDetailsResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyBacklogPage],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: classicBacklogCapability,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),

	createEntry(softwareCalendarClassicEmbedRoute, () => ({
        skeleton: CalendarEmbedSkeleton,
        component: CalendarEmbedSkeleton,

        entryPoint() {
			if (__SERVER__) {
				return undefined;
			}
			return softwareCalendarRouteEntry;
		},

        layout: chromelessLayout,
        resources: [...getNavigationSidebarProjectResource(), themePreferenceResource],
        ufoName: 'classic-calendar-embed'
    })),

	createEntry(softwareCalendarClassicRoute, () => ({
        skeleton: CalendarSkeleton,
        component: CalendarSkeleton,

        entryPoint() {
			if (__SERVER__) {
				return undefined;
			}
			return softwareCalendarRouteEntry;
		},

        layout: composeLayouts(
			classicSoftwareProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation],
        ufoName: 'classic-calendar'
    })),

	// This route is owned by JWM Keystone team
	createEntry(softwareListEmbedClassicRoute, () => ({
        component: componentWithCondition(
			() => expValEquals('jsw_list_view', 'cohort', 'variation'),
			SoftwareListEmbedPage,
			ErrorPagesNotFound,
		),

        skeleton: ListEmbedSkeleton,

        entryPoint() {
			return expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
				? softwareListRouteEntry
				: undefined;
		},

        layout: chromelessLayout,
        resources: getListViewResources(),
        afterPaint: [LazyIssueApp],
        forPaint: [LazySoftwareListEmbedView],
        ufoName: 'classic-list-embed'
    })),
	// This route is owned by JWM Keystone team
	createEntry(softwareListClassicRoute, () => ({
        component: componentWithCondition(
			() => expValEquals('jsw_list_view', 'cohort', 'variation'),
			SoftwareListPage,
			ErrorPagesNotFound,
		),

        skeleton: ListSkeleton,

        entryPoint() {
			return expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
				? softwareListRouteEntry
				: undefined;
		},

        layout: composeLayouts(
			classicSoftwareProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: getListViewResources(),
        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazySoftwareListView],
        ufoName: 'classic-list'
    })),
	createEntry(rapidboardReportRoute, () => ({
        component: RapidBoardSpaPage,
        skeleton: ClassicReportSkeleton,
        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			...getReportsCommonResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
        ufoName: 'classic-report'
    })),
	createEntry(softwareReportsCycleTimeClassicRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			CycleTimeReportClassic,
		),

        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getReportsCommonResource(),
			devOpsProviderResource,
		],

        afterPaint: [LazyCycleTimeReport],
        forPaint: [LazyAtlassianNavigation, LazyCycleTimeReport],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC),
				teamName: 'fusion-solaris',
			},
		}
    })),
	createEntry(softwareFormBuilderClassicRoute, () => ({
        ufoName: 'classic-form-builder',
        component: componentWithFG('jwm_forms_to_jsw', FormBuilderPage, ErrorPagesNotFound),
        layout: classicSoftwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			projectDetailsResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyFormBuilderView],
        afterPaint: [LazyFormSubmitView]
    })),
	createEntry(softwareFormSubmitClassicRoute, () => ({
        ufoName: 'classic-form-submit',
        component: componentWithFG('jwm_forms_to_jsw', FormSubmitPage, ErrorPagesNotFound),
        layout: chromelessLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			projectDetailsResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyFormSubmitView]
    })),
	createEntry(softwareFormClassicRoute, () => ({
        ufoName: 'classic-form',
        component: componentWithFG('jwm_forms_to_jsw', FormPage, ErrorPagesNotFound),
        layout: classicSoftwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			projectDetailsResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyFormView],
        afterPaint: [LazyFormBuilderView]
    })),
	createEntry(softwareGoalsClassicRoute, () => ({
        component: AtlasGoalsPage,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()]
    })),
	createEntry(softwareClassicProformaIssueFormsDirectRoute, () => ({
        component: ProformaIssueFormsDirect,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        resources: [projectContextResource],
        forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsDirect],
        perfMetricKey: 'issue-forms-direct'
    })),
	createEntry(softwareClassicProformaIssueFormsDirectEmbedRoute, () => ({
        component: ProformaIssueFormsDirectEmbed,
        layout: chromelessLayout,
        resources: [projectContextResource],
        forPaint: [LazyProformaIssueFormsDirectEmbed],
        perfMetricKey: 'issue-forms-direct-embed'
    })),
	createEntry(softwareReportsDeploymentFrequencyClassicRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DeploymentFrequencyReportClassic,
		),

        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getReportsCommonResource(),
			devOpsProviderResource,
		],

        afterPaint: [LazyDeploymentFrequencyReport],
        forPaint: [LazyAtlassianNavigation, LazyDeploymentFrequencyReport],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC),
				teamName: 'fusion-solaris',
			},
		}
    })),
	classicSoftwareBoardEmbedRouteEntry,
	classicSoftwareBoardRouteEntry,
	createEntry(rapidboardUserBacklogRoute, () => ({
        component: RapidBoardSpaPage,
        skeleton: NextGenBacklogSkeleton,

        layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getUIFBacklogDataResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			...getUserBoardNavigationResources(),
			getEditModelResource(),
			getBoardDetailsResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyBacklogPage],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: classicBacklogCapability,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
	createEntry(rapidboardUserReportRoute, () => ({
        component: RapidBoardSpaPage,
        skeleton: ClassicReportSkeleton,
        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			...getReportsCommonResource(),
			...getUserBoardNavigationResources(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
	userBoardRouteEntry,
	createEntry(rapidboardBacklog1Route, () => ({
        ufoName: 'classic-backlog',
        component: RapidBoardSpaPage,
        skeleton: NextGenBacklogSkeleton,

        layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			...getUserBoardNavigationResources(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: CLASSIC_BACKLOG,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
	createEntry(rapidboardReport1Route, () => ({
        component: RapidBoardSpaPage,
        skeleton: ClassicReportSkeleton,
        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			...getReportsCommonResource(),
			...getUserBoardNavigationResources(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
        ufoName: 'classic-report'
    })),
	legacyRapidBoardRouteEntry,
	createEntry(softwareCodeClassicRoute, () => ({
        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			codePageResource,
		],

        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			CodePageClassic,
		),

        layout: classicSoftwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyCode]
    })),
	softwareSecurityClassicRouteEntry,
	softwareDeploymentsClassicRouteEntry,
	softwarePeriscopeClassicRouteEntry,
	createEntry(rapidboardLastVisitedProjectRedirectRoute, () => ({
        component: RedirectToLastVisitedProjectPage,
        layout: genericProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        isRedirect: true,
        resources: [...getNavigationResources()],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(projectDirectoryRedirectRoute, () => ({
        component: ProjectsDirectoryRedirect,
        layout: globalLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        isRedirect: true,
        resources: [...getNavigationResources()],
        forPaint: [LazyAtlassianNavigation]
    })),
	softwareIncidentsClassicRouteEntry,
];
