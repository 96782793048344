import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { HelpCenterSettingsTicketTypesConfigPage } from '@atlassian/jira-spa-apps-help-center-settings-ticket-types-config/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsTicketTypesConfig = lazyForPaint<
	typeof HelpCenterSettingsTicketTypesConfigPage
>(() =>
	import(
		/* webpackChunkName: "async-help-center-settings-ticket-types-config", jiraSpaEntry: "async-help-center-settings-ticket-types-config" */ '@atlassian/jira-spa-apps-help-center-settings-ticket-types-config/src/ui/index.tsx'
	).then((module) => module.HelpCenterSettingsTicketTypesConfigPage),
);

export const HelpCenterSettingsTicketTypesConfig = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsTicketTypesConfig}
		pageId="help-center-settings-ticket-types-config"
		shouldShowSpinner
	/>
);
