import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_ROADMAP } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const classicRoadmapResource = createResource<any>({
	type: RESOURCE_TYPE_ROADMAP,
	getKey: ({ match }) => match.params.boardId || '',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-roadmap", jiraSpaEntry: "async-resource-roadmap" */ './get-data'
		),
	isBrowserOnly: true,
});
