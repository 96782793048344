import {
	ROUTE_NAMES_EOC_OVERVIEW,
	ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
	ROUTE_GROUPS_EOC,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const eocOverviewRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_OVERVIEW,
	path: '/jira/ops/overview',
	exact: true,
};

export const eocOverviewAllTeamsRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
	path: '/jira/ops/overview/teams',
	exact: true,
};
