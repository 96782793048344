/**
 * ╔═══════════════╗
 * ║ !!IMPORTANT!! ║
 * ╚═══════════════╝
 * Please do not use statsig feature gating in this file.
 * Read https://hello.atlassian.net/wiki/spaces/JIE/pages/2858513117/Blockers+for+Statsig+in+the+issue-view
 * for why and to follow the fix.
 */
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { getEcosystemQuery } from './utils/ecosystem';

export const ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY = 'issueViewNonCriticalGiraQuery';

export const buildNonCriticalGiraQuery = () => `
query ${ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY}($issueKey: String!) {
    viewIssue(issueKey: $issueKey) {
        ${getEcosystemQuery()}
        ${ff('issue.details.gira-agg-migration-attachments_ecebe') ? 'deletableAttachmentsCount' : ''}
    }
}`;

export const getNonCriticalGiraQueryRequestOptions = (issueKey: string) => ({
	method: 'POST',
	body: JSON.stringify({
		operationName: ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY,
		query: buildNonCriticalGiraQuery(),
		variables: {
			issueKey,
		},
	}),
});
