import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { resources as alertDetailResources } from '@atlassian/eoc-alert-detail/resources';
import { resources as alertListResources } from '@atlassian/eoc-alert-list/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarEocGlobalAlerts,
	LazyGlobalAlertsSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-global-alerts/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	eocGlobalAlertDetailRoute,
	eocGlobalAlertListRoute,
} from '@atlassian/jira-router-routes-eoc-global-alerts-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyAlertList = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-alert-list", jiraSpaEntry: "async-jira-eoc-alert-list" */ '@atlassian/jira-eoc-alert-list/src/ui/index.tsx'
		),
);

export const AlertListComponent = () => (
	<LazyPage Page={LazyAlertList} pageId="async-jira-eoc-alert-list" shouldShowSpinner={false} />
);

export const eocGlobalAlertListRouteEntry = createEntry(eocGlobalAlertListRoute, () => ({
    component: AlertListComponent,
    layout: eocChangeboardingLayout,
    resources: [...getNavigationResources(), ...alertListResources, getConsolidationStateResource()],

    navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},

    forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyGlobalAlertsSidebar]
}));

export const eocGlobalAlertsDetailRouteEntry = createEntry(eocGlobalAlertDetailRoute, () => ({
    component: AlertListComponent,

    resources: [
		...getNavigationResources(),
		...alertListResources,
		...alertDetailResources,
		getConsolidationStateResource(),
	],

    navigation: {
		menuId: MENU_ID.HOME,
		sidebar: AsyncSidebarEocGlobalAlerts,
	},

    forPaint: [LazyAtlassianNavigation, LazyAlertList, LazyGlobalAlertsSidebar]
}));

export const eocGlobalAlertListRouteEntries = [
	eocGlobalAlertListRouteEntry,
	eocGlobalAlertsDetailRouteEntry,
];
