import { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/navigation-global-resource/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import type { ConnectDataItem } from '../../common/types';
import { findItemAndStack } from '../../common/utils/find-item-stack/index.tsx';
import { getLinks } from '../../common/utils/get-links/index.tsx';
import { transformLink } from '../../common/utils/transform-link/index.tsx';

// use memoised functions per hook to avoid re-computation between same hooks
const getItemsMemo = memoizeOne((d) =>
	getLinks(d, ['admin_plugins_menu'], { skipFilter: true })
		// remove sections also statically defined
		.filter((v) => !['upm_section', 'jpo-ws-admin'].includes(v.id))
		.map(transformLink)
		.filter(Boolean),
);
const findItemAndStackMemo = memoizeOne(findItemAndStack);

export const useGlobalSettingsConnectItems = () => {
	const [{ route, location }] = useRouter();
	const { data, loading, error } = useNavigationGlobalResource();

	const items: ConnectDataItem[] | null = useMemo(() => data && getItemsMemo(data), [data]);

	const found = useMemo(
		() => route && items && findItemAndStackMemo(items, route.name, location),
		[items, route, location],
	);

	return {
		data: items,
		loading,
		error,
		selected: found && found.selected,
		stack: found && found.stack,
	};
};
