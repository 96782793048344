// eslint-disable-next-line jira/restricted/@atlassian/react-resource-router
import { createResource, type RouterContext } from '@atlassian/react-resource-router';
import type { ProjectConfiguration } from '../../../../common/types';

export const CHANGE_MANAGEMENT_SETTINGS_PROJECT_CONFIG =
	'CHANGE_MANAGEMENT_SETTINGS_PROJECT_CONFIG';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-project-config", jiraSpaEntry: "async-resource-project-config" */ './get-data'
	);

const getKey = ({ match }: RouterContext): string =>
	typeof match.params.projectKey === 'string' ? match.params.projectKey : '';

export const changeManagementSettingsProjectConfigurationResource =
	createResource<ProjectConfiguration>({
		type: CHANGE_MANAGEMENT_SETTINGS_PROJECT_CONFIG,
		getKey,
		getDataLoader,
		maxAge: Infinity,
	});
