import { createSessionExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { type SessionStorage, PERSIST_KEY } from './types';

const crossProjectBoardTraitSessionProvider = createSessionExpirableStorageProvider(PERSIST_KEY);

export const crossProjectBoardTraitSessionStorageProvider = () => {
	const persist = (toPersist: SessionStorage) => {
		crossProjectBoardTraitSessionProvider.set(PERSIST_KEY, toPersist);
	};

	const get = () => crossProjectBoardTraitSessionProvider.get(PERSIST_KEY);

	return { get, persist };
};
