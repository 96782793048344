import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const escalationsRoute = {
	group: ROUTE_GROUPS_SERVICEDESK,
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS,
	path: '/jira/servicedesk/projects/:projectKey/escalations',
	exact: true,
} as const as Route;
