import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { resources as whoIsOnCallResources } from '@atlassian/eoc-who-is-on-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { eocChangeboardingLayout } from '@atlassian/jira-eoc-changeboarding-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { whoIsOnCallRoute } from '@atlassian/jira-router-routes-eoc-who-is-on-call-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyWhoIsOnCall = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-who-is-on-call", jiraSpaEntry: "async-jira-eoc-who-is-on-call" */ '@atlassian/jira-eoc-who-is-on-call/src/ui/index.tsx'
		),
);

export const WhoIsOnCallComponent = () => (
	<LazyPage
		Page={LazyWhoIsOnCall}
		pageId="async-jira-eoc-who-is-on-call"
		shouldShowSpinner={false}
	/>
);

export const whoIsOnCallRouteEntry = createEntry(whoIsOnCallRoute, () => ({
    layout: eocChangeboardingLayout,
    component: WhoIsOnCallComponent,

    resources: [
		...getNavigationResources(),
		getConsolidationStateResource(),
		...whoIsOnCallResources,
	],

    forPaint: [LazyAtlassianNavigation, LazyWhoIsOnCall]
}));
