import {
	ROUTE_GROUPS_ERRORS,
	ROUTE_NAMES_PROJECT_NOT_FOUND,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const projectNotFoundRoute = {
	group: ROUTE_GROUPS_ERRORS,
	name: ROUTE_NAMES_PROJECT_NOT_FOUND,
	path: '/jira/:projectType(software|servicedesk|core|polaris)/projects/:projectKey/not-found',
	exact: true,
};
