import camelCase from 'lodash/camelCase';
import {
	ROUTE_NAMES_SOFTWARE_INCIDENTS,
	ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	incidentsInitialPageChecksResource,
	incidentsCustomFieldIdResource,
} from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import {
	softwareIncidentsNextGenRoute,
	softwareIncidentsClassicRoute,
} from '@atlassian/jira-router-routes-incidents-routes';
import { IncidentsPageSkeleton } from '@atlassian/jira-skeletons/src/ui/incidents-page/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { incidentsPageEntryPoint } from '@atlassian/jira-software-incidents-page-internal/entrypoint';
import { incidentsPageEntryPoint as incidentsPageEntryPointDeprecated } from '@atlassian/jira-software-incidents-page/entrypoint';
import {
	classicSoftwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';

const entryPointDeprecated = createPageEntryPoint({
	main: incidentsPageEntryPointDeprecated,
	topNavigationMenuId: MENU_ID.PROJECTS,
});
const entryPoint = createPageEntryPoint({
	main: incidentsPageEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareIncidentsNextGenRouteEntry = createEntry(softwareIncidentsNextGenRoute, () => ({
    entryPoint() {
		return ff('corex-operandi-incidents-page-in-jira_5ebja') ? entryPoint : entryPointDeprecated;
	},

    layout: softwareProjectLayoutNoOnboarding,
    skeleton: IncidentsPageSkeleton,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		incidentsInitialPageChecksResource,
		incidentsCustomFieldIdResource,
	],

    meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_INCIDENTS,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_INCIDENTS),
			teamName: 'operandi',
		},
	},

    ufoName: 'software-incidents'
}));

export const softwareIncidentsClassicRouteEntry = createEntry(softwareIncidentsClassicRoute, () => ({
    entryPoint() {
		return ff('corex-operandi-incidents-page-in-jira_5ebja') ? entryPoint : entryPointDeprecated;
	},

    layout: classicSoftwareProjectLayout,
    skeleton: IncidentsPageSkeleton,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		incidentsInitialPageChecksResource,
		incidentsCustomFieldIdResource,
	],

    meta: {
		reporting: {
			id: ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
			packageName: camelCase(ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC),
			teamName: 'operandi',
		},
	},

    ufoName: 'software-incidents'
}));
