import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	globalSettingsMigrationsHomeRoute,
	globalSettingsMigrationsDashboardRoute,
	globalSettingsMigrationsFocusPageRoute,
} from '@atlassian/jira-router-routes-admin-pages-migrations-routes';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import MigrationsDashboard, {
	LazySystemC2cMigrationsDashboard,
} from './ui/spa/admin-pages/migrations/migrations-dashboard';
import MigrationsFocusPage, {
	LazySystemC2cMigrationsFocusPage,
} from './ui/spa/admin-pages/migrations/migrations-focus-page';
import MigrationsHome, {
	LazySystemMigrationsHome,
} from './ui/spa/admin-pages/migrations/migrations-home';

export const migrationRouteEntries: Route[] = [
	createEntry(globalSettingsMigrationsHomeRoute, () => ({
        ufoName: 'admin.c2c-migrations.home',
        canTransitionIn: () => ff('jira-system-settings-cloud-to-cloud-migration'),
        component: componentWithCondition(isFedRamp, ErrorPagesNotFound, MigrationsHome),
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazySystemMigrationsHome]
    })),
	createEntry(globalSettingsMigrationsDashboardRoute, () => ({
        ufoName: 'admin.c2c-migrations.dashboard',
        canTransitionIn: () => ff('jira-system-settings-cloud-to-cloud-migration'),
        component: componentWithCondition(isFedRamp, ErrorPagesNotFound, MigrationsDashboard),
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazySystemC2cMigrationsDashboard]
    })),
	createEntry(globalSettingsMigrationsFocusPageRoute, () => ({
        canTransitionIn: () => ff('jira-system-settings-cloud-to-cloud-migration'),
        component: componentWithCondition(isFedRamp, ErrorPagesNotFound, MigrationsFocusPage),
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazySystemC2cMigrationsFocusPage]
    })),
];
