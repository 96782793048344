import {
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_CONFLUENCE_PRODUCT_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

export const confluenceProductSettingsRoute: Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_CONFLUENCE_PRODUCT_SETTINGS,
	path: '/jira/settings/products/confluence',
	exact: true,
};
