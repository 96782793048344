import React from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import Context, { type ExperienceContext } from '../common/context';
import type { ProviderProps, SubProviderProps } from '../common/types';
import { ExperienceTracker } from '../experience-tracker-consumer/tracker-base';
import ExperienceTrackingProvider from './view';

export const ViewExperienceTrackingProvider = ({
	isExperienceReady,
	experience,
	experienceId,
	analyticsSource,
	application,
	edition,
	additionalAttributes,
	children,
}: ProviderProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<ExperienceTrackingProvider
			isExperienceReady={isExperienceReady}
			experience={experience}
			experienceId={experienceId}
			analyticsSource={analyticsSource}
			application={application}
			edition={edition}
			additionalAttributes={additionalAttributes}
		>
			{children}
		</ExperienceTrackingProvider>
	);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ProviderProps };

ViewExperienceTrackingProvider.defaultProps = {
	isExperienceReady: true,
	experienceId: null,
	additionalAttributes: {},
};
export default ViewExperienceTrackingProvider;

export const SubExperienceTrackingProvider = ({
	children,
	location,
	parentProviders,
}: SubProviderProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		// DO NOT use `useContext` or `static contextType = ...` to consume this context
		// as the tree walker used in SSR does not support hooks
		// and SSR does not support `static contextType` setting
		<Context.Consumer>
			{(context: ExperienceContext | null) => {
				if (context == null) {
					log.safeErrorWithoutCustomerData(
						'experience-sub-provider.missing-provider',
						'Experience sub-provider rendered without a parent provider',
					);
					return children;
				}
				const { experienceId } = context;

				return (
					<ExperienceTracker location={location} parentProviders={parentProviders}>
						{(trackerCallbacks) => (
							<ExperienceTrackingProvider
								parentTrackerCallbacks={trackerCallbacks}
								experience={location}
								experienceId={experienceId}
								analyticsSource={null}
								application={null}
								edition={null}
								isExperienceReady
							>
								{children}
							</ExperienceTrackingProvider>
						)}
					</ExperienceTracker>
				);
			}}
		</Context.Consumer>
	);

SubExperienceTrackingProvider.defaultProps = {
	parentProviders: null,
};
