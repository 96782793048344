import type { Route, PartialVariantRoute } from '@atlassian/react-resource-router/src/common/types';

/**
 * Create route entry with component/resources/etc. override
 * @param {Route} baseRoute - base route object that contains matching info
 * @param {PartialVariantRoute} opts - route definition to fill/override
 */
export const createEntry = (baseRoute: Route, opts: PartialVariantRoute): Route => {
	if (typeof opts === 'function') {
		if (process.env.BUILD_VARIANT === 'route-experiment') {
			return { ...baseRoute, lazyOpts: opts };
		}
		throw new Error(
			'Passing a funciton to createEntry is only available in the route-experiment variant',
		);
	}
	return Object.assign(baseRoute, opts);
};
