import React, { type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ReactGadgetProps } from '@atlassian/jira-react-gadgets-common/src/types.tsx';
import { FilterResultsEditSkeleton } from '@atlassian/jira-skeletons/src/ui/dashboard/components/filter-results-edit.tsx';
import type { IssueTableGadgetData } from '../../../types';

const LazyFilterResultsGadgetEdit = lazyForPaint<
	ComponentType<ReactGadgetProps<IssueTableGadgetData>>
>(() => import(/* webpackChunkName: "async-react-filter-results-gadget-edit" */ './main'));

export const FilterResultsGadgetEdit = (props: ReactGadgetProps<IssueTableGadgetData>) => (
	<Placeholder name="filter-results-gadget-edit" fallback={<FilterResultsEditSkeleton />}>
		<LazyFilterResultsGadgetEdit {...props} />
	</Placeholder>
);

export const { preload } = LazyFilterResultsGadgetEdit;
