import { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { useProjectSidebarConnectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { findItemAndStack } from '../../common/utils/find-item-stack/index.tsx';
import { transformLinkV4 } from '../../common/utils/transform-link/index.tsx';
import type { UseProjectConnectItemsReturn } from './types';

const findItemAndStackMemo = memoizeOne(findItemAndStack);

export const useProjectConnectItems = (): UseProjectConnectItemsReturn => {
	const [{ route, location }] = useRouter();
	const { data, loading, error } = useProjectSidebarConnectNavigation();
	const items = useMemo(() => data && data.map(transformLinkV4).filter(Boolean), [data]);
	const found = useMemo(
		() => route && items && findItemAndStackMemo(items, route.name, location),
		[items, route, location],
	);

	return {
		data: items,
		loading,
		error,
		selected: found && found.selected,
		stack: found && found.stack,
	};
};
