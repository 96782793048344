import { fetchSummaryPageData } from '@atlassian/jira-business-summary-services/src/services/summary-data/index.tsx';
import type { SummaryPageDataType } from '@atlassian/jira-business-summary-services/src/services/summary-data/types.tsx';
import { createResourceError } from '@atlassian/jira-router-resources-utils/src/utils/create-resource-error/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';

const RESOURCE_TYPE_KEY = 'BUSINESS_SUMMARY_DATA';

const EMPTY_DATA: SummaryPageDataType = {
	overviewItems: null,
	issuesByStatus: null,
	issuesByPriority: null,
	issuesByAssignee: null,
	issuesByType: null,
};

export const summaryDataResource = createResource<SummaryPageDataType>({
	type: RESOURCE_TYPE_KEY,
	getKey: (matchedRoute) => {
		const { projectKey } = getProjectKeyId(matchedRoute.match, matchedRoute.query);
		return `${RESOURCE_TYPE_KEY}_${projectKey}`;
	},
	getData: async ({ match, query }, { tenantContext: { cloudId, isAnonymous } }) => {
		// do not fetch the resource for anonymous access
		if (isAnonymous) {
			return EMPTY_DATA;
		}

		const { projectKey } = getProjectKeyId(match, query);
		const jqlContext = `project = "${projectKey}"`;

		try {
			return await fetchSummaryPageData({ cloudId, jqlContext, projectKey });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
			throw createResourceError({
				component: 'spa.view.routes.business.summary-data.getData',
				message: `An error was thrown while prefetching the summary data. Status code: ${error?.statusCode}. Message: ${error?.message}`,
				name: 'ResourceError',
				skipSentry: isClientError,
			});
		}
	},
	maxAge: 5 * 1000,
	isBrowserOnly: true,
});

export const useSummaryData = () =>
	// forcing the return type due to react-resource-router's weird error types
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	useResource<SummaryPageDataType>(summaryDataResource) as {
		data: SummaryPageDataType | null | undefined;
		error: Error | null | undefined;
		loading: boolean;
	};
