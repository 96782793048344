import { useEffect } from 'react';
import type { ItemTypename } from '../../../model/types';
import type { FavouriteSubscriber, FavoriteSubscriptionPayload } from './types';

/**
 * Simple pub-sub implementation to emit and listen to change events for the favorite state of an entity.
 */
class FavoritePubSub {
	subscribers: Partial<Record<ItemTypename, FavouriteSubscriber[]>> = {};

	subscribe = (typename: ItemTypename, subscriber: FavouriteSubscriber) => {
		this.subscribers[typename] = [...(this.subscribers[typename] ?? []), subscriber];
		return () => {
			this.subscribers[typename] =
				this.subscribers[typename]?.filter((sub) => sub !== subscriber) ?? [];
		};
	};

	publish = (typename: ItemTypename, payload: FavoriteSubscriptionPayload) => {
		this.subscribers[typename]?.forEach((subscriber) => subscriber(payload));
	};
}

// Singleton to emit and listen to events
const favoritePubSub = new FavoritePubSub();

/**
 * Publish a favorite change event for a given GraphQL type.
 */
export const publishFavorite = favoritePubSub.publish;

/**
 * Allows a consumer to subscribe to entity updates made via `changeFavorite()` and `changeFavoriteMutation()` in the
 * `FavoriteChangeProvider`.
 *
 * @param typename GraphQL typename of the favouritable entity/s to subscribe to for changes.
 * @param subscriber Function to invoke whenever a subscribed entity type is favorited/unfavorited.
 */
export const useSubscribeToFavorite = (
	typename: ItemTypename | ItemTypename[],
	subscriber: FavouriteSubscriber,
) => {
	useEffect(() => {
		const typenames = Array.isArray(typename) ? typename : [typename];
		const unsubscribes = typenames.map((val) => favoritePubSub.subscribe(val, subscriber));
		return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
	}, [typename, subscriber]);
};
