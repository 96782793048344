import {
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_SERVICEDESK_CALENDAR,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { getScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier';
import type { ScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier/types';
import { getBoardId } from '@atlassian/jira-router-resources-utils/src/utils/get-board-id/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import {
	// eslint-disable-next-line jira/restricted/@atlassian/react-resource-router
	createResource,
	type Match,
	type Query,
	useResource,
} from '@atlassian/react-resource-router';
import { fetchProjectSidebarNavInfo } from '../../../services/sidebar/project-sidebar-navigation/main.tsx';
import type { ProjectSidebarNavInfo } from '../../../services/sidebar/project-sidebar-navigation/types.tsx';

export const getIdentifier = (routeName: string, match: Match, query: Query): ScopeIdentifier => {
	/**
	 * `boardId` as an identifier should not be returned for JSM boards route,
	 * skip boards on `SERVICEDESK_BOARDS` or `SERVICEDESK_CALENDAR` route.
	 */
	if (
		routeName === ROUTE_NAMES_SERVICEDESK_BOARDS ||
		routeName === ROUTE_NAMES_SERVICEDESK_CALENDAR ||
		query['servicedesk.serviceDeskId']
	) {
		const { projectKey, projectId } = getProjectKeyId(match, query);
		return getScopeIdentifier({ projectKey, projectId });
	}

	const { projectKey, projectId } = getProjectKeyId(match, query);
	const boardId = getBoardId(match, query);
	return getScopeIdentifier({ projectKey, projectId, boardId });
};

const getRouterKey = (routeName: string, match: Match, query: Query): string => {
	const { identifierType, identifierValue } = getIdentifier(routeName, match, query);
	// Next-Gen software projects contain only one board. Hence, using the project key itself
	if (match.url.includes('/software/projects/')) {
		return `projectKey-${match.params.projectKey || ''}`;
	}
	return identifierType == null || identifierValue == null
		? 'none'
		: `${identifierType}-${identifierValue}`;
};

export const staticProjectSidebarNavigationResource = createResource<ProjectSidebarNavInfo | null>({
	type: 'NAVIGATION_SIDEBAR_JIRA_PROJECT',
	getKey: ({ match, query, route: { name } }) => getRouterKey(name, match, query),
	/**
	 * is called on resource's init fetch, or .refresh()
	 */
	getData: async ({ match, query, route: { name } }) => {
		const scopeIdentifier = getIdentifier(name, match, query);
		if (scopeIdentifier.identifierType == null || scopeIdentifier.identifierValue == null) {
			return null;
		}

		const navInfo = await fetchProjectSidebarNavInfo(scopeIdentifier);
		return navInfo;
	},
	maxAge: 5 * 60 * 1000,
});

export const useSidebarNavigationResource = () =>
	useResource(staticProjectSidebarNavigationResource);
