import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_TEAM_POLICIES,
	ROUTE_NAMES_EOC_POLICIES,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const alertPoliciesRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_TEAM_POLICIES,
	path: '/jira/ops/teams/:teamId/policies',
	exact: true,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalPoliciesRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_POLICIES,
	path: '/jira/settings/products/ops/policies',
	exact: true,
} as const as Route;
