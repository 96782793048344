import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_ARJ_SETTINGS_LOADING_LIMITS } from './constants';
import type { LoadingLimitsResponse } from './types';

export const arjSettingsLoadingLimitsResource = createResource<LoadingLimitsResponse>({
	type: RESOURCE_TYPE_ARJ_SETTINGS_LOADING_LIMITS,
	getKey: () => 'loading-limits-information',
	getData: async () => fetchJson('/rest/jpo/1.0/configuration'),
	maxAge: 0,
});
