import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_SERVICEDESK_PORTAL_GROUPS } from '../constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-portal-groups", jiraSpaEntry: "async-resource-servicedesk-portal-groups" */ './get-data'
	);

const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const servicedeskPortalGroupsResource = createResource<any>({
	type: RESOURCE_TYPE_SERVICEDESK_PORTAL_GROUPS,
	getKey: ({ match: { params } }) => params.projectKey || '',
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});
