import React from 'react';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales';
import { IntlProvider } from '@atlassian/jira-intl/src/intl-provider.tsx';
import type { IntlProvidersProps } from './types';

export const IntlProviders = ({ children, locale, messages }: IntlProvidersProps) => (
	<IntlProvider messages={messages} locale={standardizeLocale(locale)}>
		{children}
	</IntlProvider>
);
