import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type NextGenRoadmapType from '@atlassian/jira-spa-apps-roadmap/src/view/next-gen';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';

export const LazyNextGenRoadmap = lazyForPaint<typeof NextGenRoadmapType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-roadmap", jiraSpaEntry: "async-roadmap" */ '@atlassian/jira-spa-apps-roadmap/src/view/next-gen'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyNextGenRoadmap}
		pageId={`next-gen-roadmap-${match.params.boardId || ''}`}
		shouldShowSpinner={false}
	/>
);
