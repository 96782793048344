import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY_EMBED,
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const softwarePlanTimelineEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE_EMBED,
	path: '/jira/plans/:planId/scenarios/:scenarioId/timeline/embed',
};

export const softwarePlanSummaryEmbedRoute = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY_EMBED,
	path: '/jira/plans/:planId/scenarios/:scenarioId/summary/embed',
};
