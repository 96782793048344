import {
	createResource,
	useResource,
	type RouteResourceError,
} from '@atlassian/react-resource-router';
import { fetchConfiguration } from './services/configuration';
import type { ConfigurationResource, Field } from './types';

const RESOURCE_KEY = 'BUSINESS_CONFIGURATION';

export const configurationResource = createResource<ConfigurationResource>({
	type: RESOURCE_KEY,
	getKey: () => RESOURCE_KEY,
	getData: (_, { tenantContext: { cloudId } }) => fetchConfiguration(cloudId),
	maxAge: Infinity,
});

export const useConfigurationResource = () => useResource(configurationResource);

export const useRankField = (): {
	data: Field | null;
	loading: boolean;
	error: RouteResourceError | null;
} => {
	const { data, loading, error } = useConfigurationResource();

	return { data: data?.rankField ?? null, loading, error };
};

export const useStartDateField = (): {
	data: Field | null;
	loading: boolean;
	error: RouteResourceError | null;
} => {
	const { data, loading, error } = useConfigurationResource();

	return { data: data?.startDateField ?? null, loading, error };
};

export const useIsLinkingEnabled = (): boolean => {
	const { data } = useConfigurationResource();

	return data?.isLinkingEnabled ?? false;
};

export const useIsSubtasksEnabled = (): boolean => {
	const { data } = useConfigurationResource();

	return data?.isSubtasksEnabled ?? false;
};
