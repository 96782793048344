import {
	type WorkflowIssuesData,
	fetchWorkflowIssues,
	Unchanged,
} from '@atlassian/jira-business-board-workflow-issues';
import type { Workflow } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	prefetchApiReadyMark,
	prefetchApiStartMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import { createResourceError } from '@atlassian/jira-router-resources-utils/src/utils/create-resource-error/index.tsx';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';

const EMPTY_DATA: WorkflowIssuesData = {
	workflows: [],
	lastChangedTime: null,
};

/**
 * Matches types in @link src/entry/vendorless-entries/business/board/fetchBusinessBoardWorkflows.tsx,
 * which we can't import here.
 */
interface EarlyBusinessBoardWorkflowsError {
	success: false;
	workflows: null;
	error: {
		status: number;
		message: string;
	};
}

interface EarlyBusinessBoardWorkflowsData {
	success: true;
	workflows: Workflow[];
}

type EarlyBusinessBoardWorkflows =
	| EarlyBusinessBoardWorkflowsData
	| EarlyBusinessBoardWorkflowsError;

function getEarlyPreload(): Promise<WorkflowIssuesData> | null {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.__JIRA_BUSINESS_BOARD_WORKFLOWS_DATA_PROMISE__) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const earlyPreloadPromise = window.__JIRA_BUSINESS_BOARD_WORKFLOWS_DATA_PROMISE__;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.__JIRA_BUSINESS_BOARD_WORKFLOWS_DATA_PROMISE__ = null;
		return earlyPreloadPromise.then((response: EarlyBusinessBoardWorkflows) => {
			if (!response.success) {
				if (response.error.status === 304) {
					throw new Unchanged('Workflow issues have not changed since the last request');
				}

				throw new FetchError(response.error.status);
			}

			return { workflows: response.workflows, lastChangedTime: null };
		});
	}

	return null;
}

function fetchData(projectKey: string) {
	const earlyPreload = getEarlyPreload();
	if (earlyPreload !== null) {
		return earlyPreload;
	}

	return fetchWorkflowIssues(projectKey);
}

export default async function fetchWorkflows(
	{ match }: RouterDataContext,
	{ tenantContext }: ResourceStoreContext,
) {
	const { isAnonymous } = tenantContext;

	if (isAnonymous) {
		return EMPTY_DATA;
	}

	try {
		setMark(prefetchApiStartMark);
		const workflows = await fetchData(match.params.projectKey ?? '');
		setMark(prefetchApiReadyMark);
		return workflows;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw createResourceError(
			{
				component: 'spa.view.routes.business.board-workflows.getData',
				message: 'An error was thrown while prefetching board data',
				name: 'ResourceError',
			},
			error,
		);
	}
}
