import { ff } from '@atlassian/jira-feature-flagging';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_OFFERING_CATALOGUE_FEATURES } from './constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-offering-catalogue-features", jiraSpaEntry: "async-resource-offering-catalogue-features" */ './controllers/get-data'
	);

export const fetchFeatureDataResource = createResource<string | null>({
	type: RESOURCE_TYPE_OFFERING_CATALOGUE_FEATURES,
	getKey: () => 'state',
	getDataLoader,
	maxAge: 30 * 60 * 1000,
});

export const getOfferingCatalogueFeaturesResource = () => [
	resourceWithCondition2(
		() => ff('offeringcataloguefeatures-resource-layer-in-frontend_o5pnu'),
		fetchFeatureDataResource,
	),
];
