import { ff } from '@atlassian/jira-feature-flagging';
import type { JiraWorkManagementNavData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type { Features, ReportFeatures, SettingFeatures } from '../../../common/types';

export const getExtraData = (data: JiraWorkManagementNavData, isPremium: boolean): Features => {
	const { permissionInfo, hasComponents = false, project } = data;
	const { isAdmin, projectPermissionsQueried } = permissionInfo;
	const isProjectOrJiraAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true || isAdmin;
	const isSimplifiedProject = project.simplified;

	const hasSettings =
		isAdmin ||
		projectPermissionsQueried.ADMINISTER_PROJECTS ||
		projectPermissionsQueried.EDIT_ISSUE_LAYOUT ||
		projectPermissionsQueried.EDIT_WORKFLOW;

	return {
		hasVersions: true,
		hasSettings: ff('olympus-da-157-enable-project-settings-navigation_zxaq5')
			? hasSettings
			: isProjectOrJiraAdmin,
		hasBoard: true,
		hasReports: true,
		hasAddItem: isProjectOrJiraAdmin,
		hasComponents,
		hasIssues: true,
		hasSummary: true,
		hasPages: true,
		hasChangeTheme: isProjectOrJiraAdmin,
		hasApprovals: isSimplifiedProject && (isPremium || isProjectOrJiraAdmin),
	};
};

export const getExtraSettingsData = (
	data: JiraWorkManagementNavData,
	hasActiveSoftwareAccess: boolean,
): SettingFeatures => {
	const { isAdmin, projectPermissionsQueried } = data.permissionInfo;
	const isProjectAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true;
	const isProjectOrJiraAdmin = isProjectAdmin || isAdmin;

	const hasEditWorkflowPermissions = projectPermissionsQueried.EDIT_WORKFLOW === true;
	const hasIssueLayoutPermissions = projectPermissionsQueried.EDIT_ISSUE_LAYOUT === true;

	const hasDetails = isProjectOrJiraAdmin;
	const hasPeople = isProjectOrJiraAdmin;
	const hasIssueTypes = isAdmin;

	let hasAutomation = true;
	let hasSummary = true;
	let hasWorkflows = true;
	let hasScreens = true;
	let hasFields = true;
	let hasVersions = true;
	let hasComponents = true;
	let hasPermissions = true;
	let hasIssueSecurity = true;
	let hasNotifications = true;
	let hasIssueCollectors = true;
	let hasIssueLayout = true;

	const hasDevtools = isProjectAdmin && hasActiveSoftwareAccess;

	if (ff('olympus-da-157-enable-project-settings-navigation_zxaq5')) {
		hasAutomation = isProjectOrJiraAdmin;
		hasSummary = isProjectOrJiraAdmin;
		hasWorkflows = hasEditWorkflowPermissions || isProjectOrJiraAdmin;
		hasScreens = isProjectOrJiraAdmin;
		hasFields = isProjectOrJiraAdmin;
		hasVersions = isProjectOrJiraAdmin;
		hasComponents = isProjectOrJiraAdmin;
		hasPermissions = isProjectOrJiraAdmin;
		hasIssueSecurity = isProjectOrJiraAdmin;
		hasNotifications = isProjectOrJiraAdmin;
		hasIssueCollectors = isProjectOrJiraAdmin;
		hasIssueLayout = hasIssueLayoutPermissions || isProjectOrJiraAdmin;
	}

	return {
		hasAutomation,
		hasProjectGroupOneSection: hasDetails || hasPeople || hasSummary,
		hasIssueConfigSection: hasIssueTypes,
		hasProjectGroupTwoSection: hasWorkflows || hasScreens || hasFields,
		hasProjectGroupThreeSection: hasVersions || hasComponents,
		hasProjectGroupFourSection: hasPermissions || hasIssueSecurity || hasNotifications,
		hasgetIssueCollectorSection: hasIssueCollectors,
		hasComponents,
		hasDetails,
		hasFields,
		hasIssueCollectors,
		hasIssueSecurity,
		hasIssueTypes,
		hasNotifications,
		hasPeople,
		hasPermissions,
		hasScreens,
		hasSummary,
		hasVersions,
		hasWorkflows,
		hasDevtools,
		hasIssueLayout,
	};
};

export const getExtraReportData = (hasSoftwareAccess: boolean): ReportFeatures => ({
	hasDeveloperWorkload: true,
	hasForcastSection: true,
	hasTimeTracking: true,
	hasVersionWorkloadReport: hasSoftwareAccess,
});
