import React from 'react';
import { JSErrorBoundary as FeErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import SsrErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import type { ErrorBoundaryProps } from './types';

export const ErrorBoundary = ({ children, id, withExperienceTracker }: ErrorBoundaryProps) => {
	if (__SERVER__) {
		return <SsrErrorBoundary id={id}>{children}</SsrErrorBoundary>;
	}

	return (
		<FeErrorBoundary
			id={id}
			packageName="jiraEntry"
			fallback="page"
			withExperienceTracker={withExperienceTracker}
		>
			{children}
		</FeErrorBoundary>
	);
};
