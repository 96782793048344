import type { Match, Query } from '@atlassian/react-resource-router';
/**
 * Generates a unique identifier for pages based on various criteria to support features such as caching or analytics, ensuring each page is distinctly recognizable.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (pageIdPrefix: string, { params }: Match, { filter, orderby, keyword }: Query) => {
	const { projectKey, issueKey } = params;
	let pageId = '';
	try {
		pageId = `${pageIdPrefix}${(projectKey !== null && projectKey !== undefined ? `-${projectKey}` : '') + (issueKey !== null && issueKey !== undefined ? `-${issueKey}` : '') + (filter !== undefined ? `-${filter}` : '') + (orderby !== undefined ? `-${orderby}` : '') + (keyword !== undefined ? `-${keyword}` : '')}`;
	} catch {
		pageId = pageIdPrefix;
	}
	return pageId;
};
