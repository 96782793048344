import { createAri } from '@atlassian/jira-platform-ari';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type cardCoverSettingsQuery,
} from '@atlassian/jira-relay/src/__generated__/cardCoverSettingsQuery.graphql';
import { RESOURCE_TYPE_BOARD_CONTEXT_CARD_COVER_SETTINGS } from '../../constants';

export const cardCoverImageResource = createRelayResource<cardCoverSettingsQuery>({
	type: RESOURCE_TYPE_BOARD_CONTEXT_CARD_COVER_SETTINGS,
	getQuery: ({ match }, { tenantContext }) => ({
		parameters: QUERY,
		variables: {
			boardId: createAri({
				resourceOwner: 'jira-software',
				cloudId: tenantContext.cloudId,
				resourceType: 'board',
				resourceId: String(match.params.boardId),
			}),
		},
		options: { fetchPolicy: 'store-and-network' },
	}),
});
