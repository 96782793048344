import React, { useRef, useEffect } from 'react';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridgeWeak } from '@atlassian/jira-common-bridge/src';
import log from '@atlassian/jira-common-util-logging/src/log';
import { provideNavigationApi } from '@atlassian/jira-connect/src/core-jsapi-v5/js/spa-context/index.tsx';
import createBoardLegacy from '@atlassian/jira-create-board-legacy/src';
import {
	useLeftSidebarController,
	useLeftSidebarState,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/left-sidebar-controller/index.tsx';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { NavigationUIContainer } from '@atlassian/jira-navigation-ui-controller/src/components.tsx';
import { fromManagerKey } from '@atlassian/jira-providers-item-managers/src/types';
import { useRouterActions } from '@atlassian/react-resource-router';
import { itemIds, viewIds } from '../common/constants';
import type { ImperativeAPIInterface, ImperativeAPIProps } from './types';

const ERROR_LOCATION = 'legacy.imperative-api';
export const ImperativeAPI = ({ getItemManager }: ImperativeAPIProps) => {
	const { collapseLeftSidebar, expandLeftSidebar } = useLeftSidebarController();
	const { refresh } = useProjectSidebarNavigation();
	const { push } = useRouterActions();
	const sidebarState = useLeftSidebarState();
	const sidebarRef = useRef(sidebarState);
	const refreshSidebarRef = useRef(refresh);

	const imperativeAPIRef = useRef<ImperativeAPIInterface>({
		viewIds,
		itemIds,
		expand: expandLeftSidebar,
		collapse: collapseLeftSidebar,
		isCollapsed: () => sidebarRef.current.isCollapsed,
		onCollapse: () => undefined,
		onCollapseStart: () => undefined,
		onCollapseEnd: () => undefined,
		onExpand: () => undefined,
		onExpandStart: () => undefined,
		onExpandEnd: () => undefined,
		onItemClick: () => undefined,
		setView: () => undefined,
		updateActiveView: () => undefined,
		setProjectRootView: () => undefined,
		getActiveViewItems: () => [],
		isInProjectContext: () => false,
		updateContext: () => undefined,
		updateData: () => {
			refreshSidebarRef.current();
		},
		getItemManager: (itemManagerKey) =>
			getItemManager(itemManagerKey).catch((e) => {
				log.safeErrorWithoutCustomerData(
					ERROR_LOCATION,
					`There was a problem getting "${fromManagerKey(itemManagerKey)}" item manager`,
					e,
				);
				throw e;
			}),
		createBoard: () => {
			createBoardLegacy();
		},
		modalOpen: () => undefined,
		modalClose: () => undefined,
		navigate: (url) => push(url),
	});

	sidebarRef.current = sidebarState;
	refreshSidebarRef.current = refresh;

	useEffect(() => {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridgeWeak({
			name: 'jira/navigation/bridge',
		}).then((bridge) => {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			bridge(imperativeAPIRef.current);
		});

		provideNavigationApi({
			navigate: push,
		});
	}, [push]);

	return <NavigationUIContainer isGlobal />;
};
