import cond from 'lodash/cond';
import constant from 'lodash/constant';
import otherwise from 'lodash/stubTrue';
import { fg } from '@atlassian/jira-feature-gating';
import { NAV4_ROLLOUT_NONE } from '../../constants';
import type { Nav4Rollout } from '../../types';
import { getNav4RolloutForDogfooding } from './get-nav4-rollout-for-dogfooding';
import { getNav4RolloutForEap } from './get-nav4-rollout-for-eap';

// eslint-disable-next-line jira/ff/inline-usage
export const getNav4Rollout = cond<Nav4Rollout>([
	[() => !fg('jira_nav4'), constant(NAV4_ROLLOUT_NONE)],
	[() => fg('jira_nav4_dogfooding'), getNav4RolloutForDogfooding],
	[() => fg('jira_nav4_eap'), getNav4RolloutForEap],
	[otherwise, constant(NAV4_ROLLOUT_NONE)],
]);
