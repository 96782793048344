import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { plansDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/services/plans/index.tsx';
import { PlansDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/index.tsx';
import type PlansDirectoryType from '@atlassian/jira-spa-apps-directories-plans';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyPlansDirectory = lazyForPaint<typeof PlansDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-plans-directory", jiraSpaEntry: "async-plans-directory" */ '@atlassian/jira-spa-apps-directories-plans'
		),
	),
);

export const PlansDirectoryComponent = () => (
	<LazyPage Page={LazyPlansDirectory} pageId="plans-directory" shouldShowSpinner={false} />
);

export const plansDirectoryRouteEntry = createEntry(plansDirectoryRoute, () => ({
    component: PlansDirectoryComponent,
    skeleton: PlansDirectorySkeleton,
    layout: globalLayout,
    resources: [...getNavigationResources()],

    navigation: {
		menuId: MENU_ID.PLANS,
	},

    forPaint: [LazyAtlassianNavigation, LazyPlansDirectory]
}));
