import React, { useEffect, useRef, useState, useMemo } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import { createLoadingBarActionController } from '@atlassian/jira-entry-loading-bar-controller';
import usePageLoadedSubscriber from '@atlassian/jira-spa-state-controller/src/components/page-loaded-state/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state';
import { useRouter } from '@atlassian/react-resource-router';
import { getAllowedRoutes, getLoadingBarColor, LOADER_STYLES_CSS_SELECTOR } from '../../constants';
import { generateLoadingBarStyles } from '../../utils';

type LoadingBarActions = {
	startLoading: () => void;
	resetLoading: () => void;
	finishLoading: () => void;
};

type LoadingBarUIProps = {
	shouldRender: boolean;
	routeIdentifier: number;
};

/**
 * Dispatches loading bar actions for relevant UI events
 *
 */
export const LoadingBarController = ({
	startLoading,
	resetLoading,
	finishLoading,
}: LoadingBarActions) => {
	const [loadedState] = usePageLoadedSubscriber();

	useEffect(() => {
		startLoading();
		return () => {
			resetLoading();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (loadedState.hasLoaded) finishLoading();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadedState.hasLoaded]);

	return null;
};

/**
 * Sets up the loading bar stylesheet and controller purely in the client
 * This is a fallback if SSR fails
 *
 */
const LoadingBarUI = ({ shouldRender, routeIdentifier }: LoadingBarUIProps) => {
	const [actions, setActions] = useState({});
	const sheetRef = useRef<unknown>(null);

	useEffect(() => {
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLStyleElement | null'.
		const loadingBarActions = createLoadingBarActionController(sheetRef.current);
		setActions(loadingBarActions || {});
	}, []);

	return (
		<div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style
				// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLStyleElement> | undefined'.
				ref={sheetRef}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `
                    ${LOADER_STYLES_CSS_SELECTOR} {
                        ${generateLoadingBarStyles(0, getLoadingBarColor())}
                    }
                `,
				}}
			/>
			{/* @ts-expect-error - TS2339 - Property 'START' does not exist on type '{}'. */}
			{shouldRender && actions.START && (
				<LoadingBarController
					key={routeIdentifier}
					// @ts-expect-error - TS2339 - Property 'START' does not exist on type '{}'.
					startLoading={actions.START}
					// @ts-expect-error - TS2339 - Property 'RESET' does not exist on type '{}'.
					resetLoading={actions.RESET}
					// @ts-expect-error - TS2339 - Property 'FINISH' does not exist on type '{}'.
					finishLoading={actions.FINISH}
				/>
			)}
		</div>
	);
};

/**
 * Creates a function for generating unique identifier for each route transition
 * This identifier is used to determine if the loading bar should be re-rendered
 *
 * We need to do this because transitionsCount fires BEFORE route change so we
 * need to wait for changes in both the transitionsCount and route before considering
 * a transition has occured.
 *
 */
export const createTransitionKeyGenerator = () => {
	let currentTransitionsCount = 0;
	let currentRouteIdentifier = '';
	let currentRouteMatch = '';
	let hasTransitioned = false;
	let key = 0;

	return (transitionsCount: number, routeIdentifier: string | null, routeMatch: string): number => {
		if (transitionsCount !== currentTransitionsCount) {
			currentTransitionsCount = transitionsCount;
			hasTransitioned = true;
		}

		if (
			(routeIdentifier !== null && routeIdentifier !== currentRouteIdentifier) ||
			(routeIdentifier === currentRouteIdentifier && routeMatch !== currentRouteMatch)
		) {
			currentRouteIdentifier = routeIdentifier;
			currentRouteMatch = routeMatch;
			if (hasTransitioned) {
				hasTransitioned = false;
				key = currentTransitionsCount;
			}
		}

		return key;
	};
};

const getUniqueTransitionKey = createTransitionKeyGenerator();

/**
 * Determines if the <LoadingBarController> should render or re-render
 * Injects dependencies into <LoadingBarController />
 *
 */
const LoadingBarUIController = () => {
	const [routerState] = useRouter();
	const [{ transitionsCount }] = useSpaStateTransition();

	const currentRoute = routerState.route && routerState.route.name;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const loaderFailedInSSR = !window.JIRA_LOADING_BAR;
	const shouldRender = getAllowedRoutes().includes(currentRoute);

	// we generate a unique route identifier each time a transition happens
	// we use this key to force re-render of the <LoadingBarController />
	const routeIdentifier = useMemo(
		() => getUniqueTransitionKey(transitionsCount, currentRoute, JSON.stringify(routerState.match)),
		[transitionsCount, currentRoute, routerState.match],
	);

	// We always expect the loading bar to be initialised in SSR for any route in ALLOWED_ROUTES
	// If this doesn't happen, it means SSR failed or the loading bar code in SSR failed
	useEffect(() => {
		if (shouldRender && loaderFailedInSSR) {
			log.safeWarnWithoutCustomerData(
				'global-loading-bar',
				'Loading bar failed to initialise in SSR. This may be because SSR failed or a bug in the loading bar code.',
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaderFailedInSSR]);

	// If the loading bar fails to setup in SSR, we set up the action controller AND stylesheet in the client
	// We also do this if SSR renders a page that does not have the loading bar enabled (eg !shouldRender)
	if (loaderFailedInSSR)
		return <LoadingBarUI shouldRender={shouldRender} routeIdentifier={routeIdentifier} />;

	// if the loading bar is succesfully setup in SSR, all the client needs to do is dispatch loading actions

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (shouldRender && window.JIRA_LOADING_BAR)
		return (
			<LoadingBarController
				key={routeIdentifier}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				startLoading={window.JIRA_LOADING_BAR.START}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				resetLoading={window.JIRA_LOADING_BAR.RESET}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				finishLoading={window.JIRA_LOADING_BAR.FINISH}
			/>
		);

	return null;
};

export { LoadingBarUIController };
