import type { Route } from '../types';

const applyLazyOpts = (route: Route) => {
	if ('lazyOpts' in route && typeof route.lazyOpts === 'function') {
		if (process.env.BUILD_VARIANT !== 'route-experiment') {
			throw new Error('applyLazyOpts can only be called on the route-experiment variant');
		}
		const hydratedRoute = { ...route, ...route.lazyOpts() };
		delete hydratedRoute.lazyOpts;
		return hydratedRoute;
	}
	return route;
};

export default applyLazyOpts;
