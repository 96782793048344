import React from 'react';
import { ThemeContainer } from '@atlassian/jira-controllers-global-theme/src/controllers/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { DocumentTitleContainer } from '@atlassian/jira-global-document-title/src/main.tsx';
import { ripTitleTemplateFromMeta } from '@atlassian/jira-global-document-title/src/utils.tsx';
import type { PageConfigContainersProps } from './types';

export const PageConfigContainers = ({ children }: PageConfigContainersProps) => (
	<ThemeContainer isGlobal getMeta={getMeta}>
		<DocumentTitleContainer titleSuffix={ripTitleTemplateFromMeta(getMeta)}>
			{children}
		</DocumentTitleContainer>
	</ThemeContainer>
);
