import { fg } from '@atlassian/jira-feature-gating';
import { getIsNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-meta-boolean';

/*
 *  This is a similar implementation to Nav4
 *  (ref: src/packages/navigation-apps/sidebar-nav4/rollout/src/common/utils/get-will-show-nav4/get-will-show-nav4-for-eap/index.tsx)
 *  to accommodate the complex rollout logic of the visual refresh.
 */

// We have to use a DUMMY_CONDITION to not trigger ESLint errors.
const DUMMY_CONDITION = true;

// Used for the rollout of the visual refresh
// All changes to this function should be replicated in the backend (see readme.md).
export const isVisualRefreshEnabled = () =>
	fg('visual-refresh') && // Kill switch for the entire rollout of the visual refresh
	(isVisualRefreshInDogfooding() || isVisualRefreshInEAP() || isVisualRefreshInOpenBeta());

// Used for the dogfooding of the visual refresh
const isVisualRefreshInDogfooding = () => fg('visual-refresh-dogfood') && !getIsNav4UserOptOut();

// Used for the EAP of the visual refresh
const isVisualRefreshInEAP = () => fg('visual-refresh-eap') && !getIsNav4UserOptOut();

// Used for the OpenBeta of the visual refresh
// TODO: Implement the OpenBeta experiment
const isVisualRefreshInOpenBeta = () => DUMMY_CONDITION && fg('visual-refresh-open-beta');
