import React, {
	type ComponentPropsWithoutRef,
	createContext,
	useContext,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from 'react';
import Placeholder from '@atlassian/jira-placeholder';
import type { JSResourceReference } from '@atlassian/react-async';

export type EntryPoint = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	root: JSResourceReference<any>;
};

export type EntryPointPlaceholderProps = Omit<
	ComponentPropsWithoutRef<typeof Placeholder>,
	'name'
> & {
	name: string;
	entryPoint: EntryPoint;
};

type Placeholders = Map<string, string>;

export function getPlaceholdersFromHTML() {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const placeholders = document.querySelectorAll<HTMLElement>('[data-ep-placeholder-id]');

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return new Map(Array.from(placeholders, (el) => [el.dataset.epPlaceholderId!, el.innerHTML]));
}

export const EntryPointPlaceholderContext = createContext<Placeholders>(new Map());

/*
 * This should only be used by EntryPoints that are server-side rendered, and assumes that the data for the EntryPoint
 * is hydrated prior to (or during) the initial render on the client
 *
 * EntryPointPlaceholder should be replaced with Placeholder once the following conditions are met:
 *   - Jira upgrades to React 18+
 *   - Placeholder internally renders Suspense
 */
export function EntryPointPlaceholder({
	children,
	entryPoint,
	fallback: originalFallback,
	name,
	...placeholderProps
}: EntryPointPlaceholderProps) {
	const { root } = entryPoint;
	const hasMounted = useRef(false);

	const placeholders = useContext(EntryPointPlaceholderContext);
	const ssrFallback = placeholders.get(name);
	const [fallback, setFallback] = useState(
		!ssrFallback || root.getModuleIfRequired() ? (
			originalFallback
		) : (
			// eslint-disable-next-line react/no-danger
			<span dangerouslySetInnerHTML={{ __html: ssrFallback }} data-ep-placeholder-id={name} />
		),
	);

	const lastOriginalFallback = useRef(originalFallback);
	lastOriginalFallback.current = originalFallback;

	useLayoutEffect(() => {
		if (hasMounted.current && placeholders.has(name)) {
			placeholders.delete(name);
			setFallback(lastOriginalFallback.current);
		}
	}, [entryPoint, name, placeholders, setFallback]);

	useEffect(() => {
		const onComplete = () => {
			if (placeholders.has(name)) {
				placeholders.delete(name);
			}
			setFallback(lastOriginalFallback.current);
		};

		const unsubscribe = root.onComplete(onComplete, onComplete);

		return () => {
			unsubscribe();
			onComplete();
		};
	}, [name, placeholders, root]);

	useEffect(() => {
		hasMounted.current = true;
	}, []);

	return (
		<Placeholder fallback={fallback} name={name} {...placeholderProps}>
			<span data-ep-placeholder-id={name}>{children}</span>
		</Placeholder>
	);
}
