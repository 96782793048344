import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';

export const routeGroupsProjectSettingsServicedeskJourneysRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-journeys`,
	path: '/jira/servicedesk/projects/:projectKey/settings/journeys',
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskJourneyConfigurationRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-journey-configuration`,
	path: '/jira/servicedesk/projects/:projectKey/settings/journeys/:journeyId',
	exact: true,
};
