import React, { useMemo } from 'react';
import { JiraWorkspaceAri } from '@atlassian/ari/jira';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import killswitch from '@atlassian/jira-killswitch';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import type { ApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import {
	type PostOfficeEnvironmentConfig,
	PostOfficeProvider as PostOfficeBaseProvider,
	usePostOfficeRouteUpdate,
} from '@atlassian/post-office-context';
import { useRouter } from '@atlassian/react-resource-router';

const subproductToEdition = (subproduct: string | undefined, editions: ApplicationEditions) => {
	switch (subproduct) {
		case 'core':
			return editions.core;
		case 'software':
			return editions.software;
		case 'customerService':
			return editions.customerService;
		case 'serviceDesk':
			return editions.serviceDesk;
		default:
			undefined;
	}
};

const JiraPostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	const { data } = useProject();
	const isSiteAdmin = useIsSiteAdmin();
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const locale = useLocale();
	const environment = useEnvironment();
	const { data: orgId } = useOrgId();
	const editions = useAppEditions();
	const subproduct = data?.type;

	const edition = subproductToEdition(subproduct, editions);

	const workspaceAri = JiraWorkspaceAri.create({
		activationId,
		siteId: cloudId,
	}).toString();

	const context = useMemo(
		() => ({
			product: 'jira',
			tenantId: cloudId,
			workspaceAri,
			workspaceId: activationId,
			orgId,
			subproduct,
			isSiteAdmin,
			locale,
			productEdition: edition,
		}),
		[cloudId, orgId, activationId, subproduct, isSiteAdmin, locale, workspaceAri, edition],
	);
	const env = useMemo(() => transformJiraEnvToPostOfficeEnvironment(environment), [environment]);

	return (
		<PostOfficeBaseProvider context={{ current: context }} environment={{ env }}>
			{children}
		</PostOfficeBaseProvider>
	);
};

export const PostOfficeProvider = ({ children }: { children: React.ReactNode }) => {
	// Using the Feature Gate used for the placement app-switcher-discovery-section because PostOfficeProvider is always needed when the app-switcher-discovery-section is enabled
	const isPostOfficeEnabled = !killswitch('killswitch_post_office_in_jira');

	return isPostOfficeEnabled ? (
		<ErrorBoundary id="postOfficeJiraProvider" render={() => <>{children}</>}>
			<JiraPostOfficeProvider>{children}</JiraPostOfficeProvider>
		</ErrorBoundary>
	) : (
		<>{children}</>
	);
};

const ReactResourceRouterListener = ({ children }: { children: React.ReactNode }) => {
	const [route] = useRouter();

	usePostOfficeRouteUpdate([route.basePath, route.location.pathname]);

	return <>{children}</>;
};

export const PostOfficeRouterListener = ({ children }: { children: React.ReactNode }) => {
	const isPostOfficeEnabled = !killswitch('killswitch_post_office_in_jira');
	const isScreenSpaceFlagsEnabled = fg('post-office-screen-space-overlay-jfe');

	return isPostOfficeEnabled && isScreenSpaceFlagsEnabled ? (
		<ReactResourceRouterListener>{children}</ReactResourceRouterListener>
	) : (
		<>{children}</>
	);
};

export const transformJiraEnvToPostOfficeEnvironment = (
	environment: Environment | null,
): PostOfficeEnvironmentConfig['env'] => {
	switch (environment) {
		case 'dev':
		case 'staging':
			return 'staging';
		case 'prod':
			return 'production';
		case 'local':
			return 'local';
		default:
			return 'local';
	}
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ScreenSpaceFlagsPlacement } from './screen-space-flags-placement';
