import { resources as assignCustomRolesResources } from '@atlassian/eoc-assign-custom-roles/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { eocAssignCustomRolesRoute } from '@atlassian/jira-router-routes-eoc-assign-custom-roles-routes';
import AssignCustomRoles, { LazyAssignCustomRoles } from './ui/spa/eoc/assign-custom-roles';

export const eocAssignCustomRolesRouteEntry = createEntry(eocAssignCustomRolesRoute, () => ({
    component: AssignCustomRoles,
    layout: globalSettingsLayout,

    navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getNavigationResources(),
		...assignCustomRolesResources,
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

    forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyAssignCustomRoles,
	]
}));
