import {
	ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
	ROUTE_GROUPS_SERVICEDESK,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskOrganizationsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	group: ROUTE_GROUPS_SERVICEDESK,
	path: '/jira/servicedesk/projects/:projectKey/organizations',
	exact: true,
};

export const servicedeskOrganizationDetailsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
	group: ROUTE_GROUPS_SERVICEDESK,
	path: '/jira/servicedesk/projects/:projectKey/organizations/:organizationId',
	exact: true,
};
