import { useEffect, useMemo } from 'react';
import type { DataState } from '@atlassian/jira-common-services/src/types';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	WORK_CATEGORIES_QUEUE_SUPPORTED,
	DEVELOPER_ESCALATION,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type {
	WorkCategoriesQueueSupported,
	WorkCategoriesApiResource,
	WorkCategoryApiResource,
} from '@atlassian/jira-servicedesk-work-category/src/common/types.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_SERVICEDESK_PRACTICES } from '../constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-practices", jiraSpaEntry: "async-resource-servicedesk-practices" */ './get-data'
	);

const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

type ResourceData = {
	practices: {
		key: WorkCategoryApiResource;
	}[];
};

export const servicedeskPracticesResource = createResource<ResourceData>({
	type: RESOURCE_TYPE_SERVICEDESK_PRACTICES,
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

const queuesOrder: WorkCategoriesQueueSupported = [...WORK_CATEGORIES_QUEUE_SUPPORTED];

const workCategoryOrder: WorkCategoriesApiResource = [...queuesOrder, DEVELOPER_ESCALATION];

const transformItsmPracticesList = (
	data: ResourceData | null,
): WorkCategoriesQueueSupported | null => {
	if (!data?.practices) return null;

	return queuesOrder.filter((practice) =>
		data.practices.some((practiceInData) => practiceInData.key === practice),
	);
};

const transformServiceDeskPracticesList = (
	data: ResourceData | null,
): WorkCategoriesApiResource | null => {
	if (!data?.practices) return null;

	return workCategoryOrder.filter((practice) =>
		data.practices.some((practiceInData) => practiceInData.key === practice),
	);
};

// If you're using this hook in your route please make sure you add servicedeskPracticesResource to
// your route definition e.g.
// {
//    path: `/projects/.../queues`
//    resources: [ servicedeskPracticesResource ],
//    ...
// }
export const useWorkCategoriesQueueSupported = (): DataState<WorkCategoriesQueueSupported> => {
	const { data, loading, ...resource } = useResource<ResourceData>(servicedeskPracticesResource);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const error = resource.error as Error;

	return useMemo(
		() => ({
			data: transformItsmPracticesList(data),
			loading,
			error,
		}),
		[data, error, loading],
	);
};

export const useWorkCategoriesAllSupported = (): DataState<WorkCategoriesApiResource> => {
	const { data, loading, ...resource } = useResource<ResourceData>(servicedeskPracticesResource);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const error = resource.error as Error;

	return useMemo(
		() => ({
			data: transformServiceDeskPracticesList(data),
			loading,
			error,
		}),
		[data, error, loading],
	);
};

// This is for use where the practices resource may or may not be a route resource (eg in a nav component), but we need
// to eventually have the data anyway. We will force a refresh the first time the component with this hook is mounted.
// Only for use when you don't rely on this resource for SSR or initial render.
export const useWorkCategoriesAllSupportedWithForceRefreshOnMount: () => DataState<WorkCategoriesApiResource> =
	() => {
		const {
			data,
			loading,
			refresh: refreshPractices,
			...resource
		} = useResource<ResourceData>(servicedeskPracticesResource);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const error = resource.error as Error;

		useEffect(() => {
			if (!loading && !data && !error) {
				refreshPractices();
			}
		}, [data, error, loading, refreshPractices]);

		return useMemo(
			() => ({
				data: transformServiceDeskPracticesList(data),
				loading,
				error,
			}),
			[data, error, loading],
		);
	};
