import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import srcVirtualAgentBrowseIntentsQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentBrowseIntentsQuery$parameters';
import srcVirtualAgentBrowseIntentsStatisticsQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentBrowseIntentsStatisticsQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

// When changing the default time range, make sure to also update jira/src/packages/servicedesk/virtual-agent/intents/browse-intents/src/ui/index.tsx
const DEFAULT_TIME_RANGE_MS = 7 * 24 * 60 * 60 * 1000; // 7 days

const formatFetchDate = (date: Date) => date.toISOString().substring(0, 10);

export const browseIntentsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-virtual-agent-browse-intents" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			page: {
				parameters: srcVirtualAgentBrowseIntentsQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey ?? '',
					checkForAiEnabledFF: ff('check-for-ai-enabling-in-get-premium-banner_oxh6r'),
				},
			},
			statistics: {
				parameters: srcVirtualAgentBrowseIntentsStatisticsQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey ?? '',
					startDate: formatFetchDate(new Date(Date.now() - DEFAULT_TIME_RANGE_MS)),
					endDate: formatFetchDate(new Date()),
				},
			},
		},
	}),
});
