import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './constants';

const AsyncEndOfTrialMessageSharedGlobalComponent = lazyAfterPaint(() =>
	import(/* webpackChunkName: "async-end-of-trial-message-shared" */ './index').then(
		({ EndOfTrialMessageSharedGlobalComponent }) => EndOfTrialMessageSharedGlobalComponent,
	),
);

// Internal components are wrapped by further JSErrorBoundaries where-ever UI is loaded,
// this is here to report errors thrown due to lazy loading this top level component in global shared components.
export const AsyncEndOfTrialMessageSharedWrapper = () => (
	<ErrorBoundary id="end-of-trial-message-shared-async" packageName={PACKAGE_NAME}>
		<ReportErrors
			teamName={TEAM_NAME}
			id="end-of-trial-message-shared-async"
			packageName={PACKAGE_NAME}
		>
			{/* @note: @nmatenaar mentioned the lack of Suspense here is intentional and was a recommendation from the navigation team */}
			{/* @see: https://atlassian.slack.com/archives/C06RM956G8M/p1717659249827849?thread_ts=1717643124.929329&cid=C06RM956G8M */}
			<AsyncEndOfTrialMessageSharedGlobalComponent />
		</ReportErrors>
	</ErrorBoundary>
);
