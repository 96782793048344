import type { ServiceDeskOrganizationPanel as ServiceDeskOrganizationPanelType } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { type RouterContext, createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_ORGANIZATION_PANEL_FORGE_DATA } from '../constants';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-organization-panel", jiraSpaEntry: "async-resource-servicedesk-organization-panel" */ './get-data'
	);

export const serviceDeskOrganizationPanelForgeDataResource = createResource<
	ServiceDeskOrganizationPanelType[] | null
>({
	type: RESOURCE_TYPE_ORGANIZATION_PANEL_FORGE_DATA,
	getKey: ({ match }: RouterContext) => {
		const { projectKey } = match.params;
		return `extension-${projectKey}`;
	},
	getDataLoader,
	maxAge: Number.MAX_SAFE_INTEGER,
	isBrowserOnly: true,
});
