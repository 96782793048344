import {
	ROUTE_GROUPS_ISSUE,
	ROUTE_NAMES_ISSUE_CREATE_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const issueCreateEmbedRoute = {
	group: ROUTE_GROUPS_ISSUE,
	name: ROUTE_NAMES_ISSUE_CREATE_EMBED,
	path: '/jira/issues/create/embed',
};
