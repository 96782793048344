import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	OG_CONSOLIDATION_USER_STATE_STORAGE_KEY,
	DEFAULT_CONSOLIDATION_USER_STATE,
	ERROR_CONSOLIDATION_USER_STATE,
} from '../common/constants';
import type { OgConsolidationUserState } from '../common/types';

export const getOgConsolidationUserState = (
	accountId: string | null,
): Promise<OgConsolidationUserState> => {
	if (!accountId) {
		return Promise.resolve(ERROR_CONSOLIDATION_USER_STATE);
	}

	return getUserProperty<OgConsolidationUserState>(
		accountId,
		OG_CONSOLIDATION_USER_STATE_STORAGE_KEY,
	)
		.then((val) => {
			if (!val || typeof val !== 'object') {
				fireErrorAnalytics({
					meta: {
						id: 'getUserProperty',
						packageName: 'jiraRouterResourcesEocOgConsolidationUserState',
						teamName: 'alexstrasza',
					},
					error: new ValidationError(
						'User property is not an object. It must be changed outside of the automated flow.',
					),
					sendToPrivacyUnsafeSplunk: true,
				});
				return DEFAULT_CONSOLIDATION_USER_STATE;
			}
			if (
				!Object.keys(val).every((key) =>
					Object.prototype.hasOwnProperty.call(DEFAULT_CONSOLIDATION_USER_STATE, key),
				)
			) {
				return {
					...DEFAULT_CONSOLIDATION_USER_STATE,
					...Object.fromEntries(
						Object.entries(val).filter(([key]) =>
							Object.prototype.hasOwnProperty.call(DEFAULT_CONSOLIDATION_USER_STATE, key),
						),
					),
				};
			}
			if (
				!Object.keys(DEFAULT_CONSOLIDATION_USER_STATE).every((key) =>
					Object.prototype.hasOwnProperty.call(val, key),
				)
			) {
				// Missing properties will happen when adding new properties to the type,
				// we are making sure they are filled in from the defaults
				return { ...DEFAULT_CONSOLIDATION_USER_STATE, ...val };
			}
			return val;
		})
		.catch((error) => {
			// Property not saved yet, so we return the default values
			if (error instanceof FetchError && error.statusCode === 404) {
				return DEFAULT_CONSOLIDATION_USER_STATE;
			}
			throw error;
		});
};
