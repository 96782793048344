import { contactAdministratorRouteEntries } from '@atlassian/jira-router-routes-admin-pages-contact-administrator-entries';
import { systemConfigurationEmailNotificationsLogRouteEntries } from '@atlassian/jira-router-routes-admin-pages-email-notifications-log-entries';
import { externalImportRouteEntries } from '@atlassian/jira-router-routes-admin-pages-external-import-entries';
import { globalPermissionsRouteEntries } from '@atlassian/jira-router-routes-admin-pages-global-permissions-entries';
import { issueTypesRouteEntries } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries';
import { authenticationSettingRouteEntries } from '@atlassian/jira-router-routes-admin-pages-jsm-authentication-settings-entries';
import { customerAccessRouteEntries } from '@atlassian/jira-router-routes-admin-pages-jsm-customer-access-entries';
import { newIssueFeaturesRouteEntries } from '@atlassian/jira-router-routes-admin-pages-new-issue-features-entries';
import { outgoingMailRouteEntries } from '@atlassian/jira-router-routes-admin-pages-outgoing-mail-entries';
import { performanceAndScaleRouteEntries } from '@atlassian/jira-router-routes-admin-pages-performance-entries';
import { permissionPageRouteEntries } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-entries';
import { prioritySchemesRouteEntries } from '@atlassian/jira-router-routes-admin-pages-priorities-entries';
import { proformaRouteEntries } from '@atlassian/jira-router-routes-admin-pages-proforma-entries';
import { screensPageRouteEntries } from '@atlassian/jira-router-routes-admin-pages-screens-entries';
import { systemConfigurationRouteEntries } from '@atlassian/jira-router-routes-admin-pages-system-configuration-entries';
import { systemRouteEntries } from '@atlassian/jira-router-routes-admin-pages-system-entries';
import { workflowPagesRouteEntries } from '@atlassian/jira-router-routes-admin-pages-workflows-entries';
import type { Route } from '@atlassian/react-resource-router';

export const jiraAdminPagesRouteEntries: Route[] = [
	...screensPageRouteEntries,
	...workflowPagesRouteEntries,
	...issueTypesRouteEntries,
	...contactAdministratorRouteEntries,
	...systemRouteEntries,
	...customerAccessRouteEntries,
	...authenticationSettingRouteEntries,
	...outgoingMailRouteEntries,
	...permissionPageRouteEntries,
	...proformaRouteEntries,
	...newIssueFeaturesRouteEntries,
	...performanceAndScaleRouteEntries,
	...prioritySchemesRouteEntries,
	...globalPermissionsRouteEntries,
	...systemConfigurationRouteEntries,
	...externalImportRouteEntries,
	...systemConfigurationEmailNotificationsLogRouteEntries,
];
