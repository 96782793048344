import {
	ROUTE_GROUPS_REDIRECTS,
	ROUTE_NAMES_ARCHIVED_PROJECT_DIRECT_LINK,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const archivedProjectDirectLinkRoute = {
	group: ROUTE_GROUPS_REDIRECTS,
	name: ROUTE_NAMES_ARCHIVED_PROJECT_DIRECT_LINK,
	path: '/jira/errors/project-archived',
	exact: true,
};
