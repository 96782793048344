import React, { Fragment } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Section } from '@atlaskit/side-navigation';
import { PROJECT_CLEANUP_KEY } from '@atlassian/jira-admin-pages-performance/src/common/assets/project-cleanup/index.tsx';
import type { Metric } from '@atlassian/jira-admin-pages-performance/src/common/assets/types.tsx';
import { getAllVisibleMetrics } from '@atlassian/jira-admin-pages-performance/src/common/utils.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { TitleHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/title-header/main.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import messages from './messages';
import { ProjectCleanupSpotlight, PROJECT_CLEANUP_SPOTLIGHT_NAME } from './spotlight';

export const PerformanceAndScaleMenu = () => {
	const { formatMessage } = useIntl();

	const basePerformanceUrl = '/jira/settings/system/performance';

	const allVisibleMetrics = getAllVisibleMetrics();

	return (
		<>
			<TitleHeader>{formatMessage(messages.title)}</TitleHeader>
			<Section>
				<MenuItem
					analytics={{ itemId: 'performance-overview' }}
					selectedOn={matchUrlPathname()}
					href={`${basePerformanceUrl}/overview`}
				>
					{formatMessage(messages.overview)}
				</MenuItem>
			</Section>
			{allVisibleMetrics.length > 0 && (
				<Section title={formatMessage(messages.allReports)}>
					{allVisibleMetrics.map((metric: Metric) => {
						if (metric.key === PROJECT_CLEANUP_KEY) {
							return (
								<Fragment key="project-cleanup-item">
									<SpotlightTarget name={PROJECT_CLEANUP_SPOTLIGHT_NAME}>
										<MenuItem
											analytics={{ itemId: `performance-metric-${metric.key}` }}
											href={`${basePerformanceUrl}/metrics/${metric.key}`}
											selectedOn={matchUrlPathname()}
											key={metric.key}
										>
											{formatMessage(metric.name)}
										</MenuItem>
									</SpotlightTarget>
									<ProjectCleanupSpotlight />
								</Fragment>
							);
						}

						return (
							<MenuItem
								analytics={{ itemId: `performance-metric-${metric.key}` }}
								href={`${basePerformanceUrl}/metrics/${metric.key}`}
								selectedOn={matchUrlPathname()}
								key={metric.key}
							>
								{formatMessage(metric.name)}
							</MenuItem>
						);
					})}
				</Section>
			)}
		</>
	);
};
