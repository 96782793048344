import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Lozenge from '@atlaskit/lozenge';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { Box, Flex } from '@atlaskit/primitives';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { NestingMenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/nesting-menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { SECTION_ID } from '../../../../common/constants';
import messages from './messages';
import { PerformanceAndScaleMenu } from './performance-and-scale-menu';
import { hasSeenSiteOptimiserSpotlight } from './utils';

export const PerformanceMenuItem = () => {
	const accountId = useAccountId();
	const { formatMessage } = useIntl();
	const [isOptimiserSpotlightActive, setIsOptimiserSpotlightActive] = useState(false);

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	const BackMenuItem = {
		href: '/jira/settings/system/general-configuration',
		title: formatMessage(messages.itemBack),
	};

	const fetchSpotlightState = useCallback(async () => {
		if (accountId != null) {
			const response = await hasSeenSiteOptimiserSpotlight(accountId);
			setIsOptimiserSpotlightActive(!response);
		}
	}, [accountId]);

	useEffect(() => {
		fetchSpotlightState();
	}, [fetchSpotlightState]);

	return (
		<SpotlightManager>
			<SpotlightTarget name="site-optimiser-spotlight">
				<NestingMenuItem
					analytics={{ itemId: 'performance-and-scale' }}
					href={navigationPath.troubleshootingPerformanceAndScale}
					title={
						<Flex gap="space.050" justifyContent="start" alignItems="center">
							<Box>{formatMessage(messages.performanceAndScale)}</Box>
							<Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>
						</Flex>
					}
					overrides={{ BackMenuItem }}
					id={SECTION_ID.PERFORMANCE_AND_SCALE}
				>
					<PerformanceAndScaleMenu />
				</NestingMenuItem>
			</SpotlightTarget>

			<SpotlightTransition>
				{isOptimiserSpotlightActive && (
					<JiraSpotlight
						actions={[
							{
								onClick: (_, analyticsEvent: UIAnalyticsEvent) => {
									setIsOptimiserSpotlightActive(false);
									fireUIAnalytics(analyticsEvent, 'performanceMenuItemSpotlightComplete');
								},
								text: formatMessage(messages.spotlightAction),
							},
						]}
						heading={formatMessage(messages.spotlightHeading)}
						target="site-optimiser-spotlight"
						key="site-optimiser-spotlight"
						targetRadius={3}
						targetBgColor={token('elevation.surface', N0)}
						dialogPlacement="right top"
						messageId="navigation-apps-sidebar-global-settings-system.ui.menu.troubleshooting-and-support.performance-menu-item.jira-spotlight.site-optimiser-spotlight"
						messageType="engagement"
					>
						{formatMessage(messages.spotlightBody)}
					</JiraSpotlight>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
};
