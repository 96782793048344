import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_GROUPS_GLOBAL_SETTINGS,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_ARCHIVED_PLANS_DIRECTORY,
	ROUTE_NAMES_TRASH_PLANS_DIRECTORY,
	ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

export const planIncrementsRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_INCREMENTS,
	path: '/jira/plans/:planId/scenarios/:scenarioId/program',
};

export const planIncrementRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	path: '/jira/plans/:planId/scenarios/:scenarioId/program/:incrementId',
};

export const archivedPlansRoute: Route = {
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	name: ROUTE_NAMES_ARCHIVED_PLANS_DIRECTORY,
	path: '/jira/plans/settings/archive',
};

export const trashedPlansRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_TRASH_PLANS_DIRECTORY,
	path: '/jira/plans/settings/trash',
};

export const trashedPlanDirectLinkRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_TRASHED_PLAN_DIRECT_LINK,
	path: '/jira/plans/plan-trashed',
	exact: true,
};

export const arjSettingsFinancialYearPageRoute: Route = {
	group: ROUTE_GROUPS_SOFTWARE,
	name: ROUTE_NAMES_SOFTWARE_ARJ_SETTINGS_FINANCIAL_YEAR,
	path: '/jira/plans/settings/financial-year',
};
