import type { FlagConfiguration } from '@atlassian/jira-flags';
import { messages } from './messages';

export const errorFlag = (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
	actions: [
		{
			content: messages.goHome,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			onClick: () => window.location.assign('/'),
		},
	],
});
