import React, { createContext, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useRouter } from '@atlassian/react-resource-router';
import type { MenuId } from '../../common/types';
import { getInitialSelectedPath } from '../../common/utils/selected-path/getInitialSelectedPath';
import { isSelectedPath } from '../../common/utils/selected-path/isSelectedPath';
import { routeToSelectedPath } from '../../common/utils/selected-path/routeToSelectedPath';

type SidebarNav4ContextType = Readonly<{
	isInitialSelectedPath(menuId: MenuId): boolean;
	isSelectedPath(menuId: MenuId): boolean;
}>;

const SidebarNav4Context = createContext<SidebarNav4ContextType | null>(null);

type Props = {
	children: React.ReactNode;
	// By passing a function, we have the ability to make ALL routes initially selected, or none, or just one.
	isInitialSelectedPath?(menuId: string): boolean;
};

/**
 * This context/provider is used by the menu components (like Nav4ExpandableMenuItem and Nav4MenuLinkItem),
 * to know if they should appear in a “selected” state, based on the component’s `menuId` prop.
 * This is done by providing *read-only* access to the “selected path” / `isSelectedPath`.
 * Selected path is changed based on **current route**.
 *
 * Should work during SSR (see getInitialSelectedPath docs) and when navigating.
 */
export function SidebarNav4ContextProvider(props: Props) {
	const { children, isInitialSelectedPath } = props;
	const [routerContext] = useRouter();
	const initialSelectedPath = getInitialSelectedPath(routerContext);
	const [selectedPath, setSelectedPath] = useState(initialSelectedPath);

	// When the `routerContext` changes, we need to recalculate the selected path.
	// useLayoutEffect, so this happens before render.
	useLayoutEffect(() => {
		setSelectedPath((currentPath) => {
			const newPath = routeToSelectedPath(routerContext);
			return currentPath.toString() === newPath.toString() ? currentPath : newPath;
		});
	}, [routerContext]);

	const sidebarNav4Controller = useMemo(
		() => ({
			isInitialSelectedPath: isInitialSelectedPath ?? isSelectedPath(initialSelectedPath),
			isSelectedPath: isSelectedPath(selectedPath),
		}),
		[initialSelectedPath, isInitialSelectedPath, selectedPath],
	);

	return (
		<SidebarNav4Context.Provider value={sidebarNav4Controller}>
			{children}
		</SidebarNav4Context.Provider>
	);
}

export function useSidebarNav4() {
	const context = useContext(SidebarNav4Context);

	if (context == null) {
		throw new Error('useSidebarNav4 must be used inside a SidebarNav4ContextProvider');
	}

	return context;
}
