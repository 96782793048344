/**
 * ╔═══════════════╗
 * ║ !!IMPORTANT!! ║
 * ╚═══════════════╝
 * Please do not use statsig feature gating in this file.
 * Read https://hello.atlassian.net/wiki/spaces/JIE/pages/2858513117/Blockers+for+Statsig+in+the+issue-view
 * for why and to follow the fix.
 */
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { getIsNewValueRequested } from '../../common/utils/get-feature-flag-value';
import { getPermalinkId, COMMENTS } from '../../common/utils/permalinks';
import {
	GIRA_AGG_MIGRATION_ISARCHIVED_FLAG,
	isParentInIssueViewEnabled,
} from '../../feature-flags';

export const getMainIssueViewAggQueryFeatureFlagVariables = () => ({
	issueViewRelayCascadingSelectFlag: ff(
		'issue.details.relay-cascading-select-field-issue-view-layout-integration',
	),

	giraAGGMigrationIsArchivedFlag: getIsNewValueRequested(GIRA_AGG_MIGRATION_ISARCHIVED_FLAG),
	parentInIssueViewFlag: isParentInIssueViewEnabled(),
	isCompassComponentsEnabled: ff('compass-components-in-jira-components_xw42u', false),
	giraAGGMigrationAttachmentsFlag: ff('issue.details.gira-agg-migration-attachments_ecebe'),

	isRelayReleaseVersionFieldIssueViewIntegrationEnabled: ff(
		'issue.details.relay-release-version-field-issue-view-integration',
	),

	// This needs to be ff rather than fg/experiment for running "before scrips": https://atlassian.slack.com/archives/C06SJ813D8S/p1724034232723999
	issueViewRelayGoalsFlag: ff('hrz-716-integrate-goal-picker-into-issue-view', false),

	giraAGGMigrationCommentsFlag:
		ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9', false) ||
		ff('issue-jiv-14530-comments-consistency-check_x716d', false),

	isCMDBAttributeIdRemoved: ff('assets-rearch-deprecate-attribute-value-id_ykukg', false),
	jsmSentimentCustomFieldFlag: ff('sentiment-custom-field', false),
	isJPDPanelEnabled: ff('polaris.jpd-panel-in-issue-view', false),
	issueViewRelayPriorityFieldFlag: ff('relay-migration-issue-fields-priority_ri9vi', false),

	// if assignee/reporter/user is true, it represents SingleSelectUserPicker being used
	issueViewRelayAssigneeFieldFlag:
		ff('relay-migration-issue-fields-assignee-ld', false) ||
		ff('relay-migration-issue-fields-reporter_jfzs2', false) ||
		ff('relay-migration-issue-fields-user', false),

	issueViewRelayNumberFieldFlag: ff('relay-migration-issue-fields-number_q0em4', false),
	issueViewRelaySprintFieldFlag: ff('relay-migration-issue-fields-sprint_jozpu', false),
	issueViewRelayAttachmentsFlag: ff('relay-migration-issue-view-attachments', false),

	issueViewRelaySingleLineTextFieldFlag:
		ff('relay-migration-issue-fields-single-line-text_in6w6', false) ||
		ff('relay-migration-issue-fields-single-line-text-message', false),

	issueViewRelayDateFieldFlag: ff('relay-migration-issue-fields-date_ptj72', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent: ff(
		'relay-migration-issue-fields-parent_5p1ac',
		false,
	),

	issueViewRelayParentFieldFlag: ff('relay-migration-issue-fields-parent_5p1ac', false),
	issueViewRelayProjectFieldFlag: ff('relay-migration-issue-fields-project-select_w0xem', false),
	issueViewRelayDateTimeFieldFlag: ff('relay-migration-issue-fields-date-time_eaqd2', false),
	issueViewRelayMetadataFieldFlag: ff('relay-migration-issue-fields-metadata_d42b0', false),

	isJPDDeliveryLinkTypeFilteringEnabled: ff(
		'polaris.jpd-panel-filter-by-delivery-link-type',
		false,
	),

	issueViewRelayUrlFieldFlag: ff('relay-migration-issue-fields-url_ob8uu', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider:
		ff('relay-migration-issue-fields-assignee-ld', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider:
		ff('relay-migration-issue-fields-reporter_jfzs2', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider: ff(
		'relay-migration-issue-fields-user',
		false,
	),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider:
		ff('relay-migration-issue-fields-security-level_7y5uv', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider:
		ff('relay-migration-issue-fields-checkbox-select_ytl5n', false),

	issueViewRelaySummaryFieldFlag: ff('relay-migration-issue-fields-summary_eog1v', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider:
		ff('relay-migration-issue-fields-original-estimate_eb4wv', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider:
		ff('relay-migration-issue-fields-single-line-text-message', false),

	issueViewRelayColorFieldFlag: ff('relay-migration-issue-fields-color_wrbay', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider:
		ff('relay-migration-issue-fields-time-tracking_eefh0', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider:
		ff('relay-migration-issue-fields-people', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider:
		ff('relay-migration-issue-fields-approvers', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider:
		ff('relay-migration-issue-fields-watchers_iqhn4', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider:
		ff('relay-migration-issue-fields-watchers_iqhn4', false) ||
		ff('relay-migration-issue-fields-security-level_7y5uv', false),

	issueViewRelayHeaders:
		ff('relay-migration-issue-fields-watchers_iqhn4', false) ||
		ff('relay-migration-issue-fields-parent_5p1ac', false) ||
		ff('relay-migration-issue-fields-security-level_7y5uv', false),

	issueViewRelayPeopleAndApproversFieldFlag:
		ff('relay-migration-issue-fields-people', false) ||
		ff('relay-migration-issue-fields-approvers', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider:
		ff('relay-migration-issue-fields-components-select_prpz1', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider:
		ff('relay-migration-issue-fields-multi-user', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider:
		ff('relay-migration-issue-fields-issue-type-switcher_l0lrt', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider:
		ff('relay-migration-issue-fields-resolution_86iya', false),

	__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider:
		ff('relay-migration-issue-fields-group-picker', false),
});

export const getMainIssueViewAggQueryVariables = () => ({
	...getMainIssueViewAggQueryFeatureFlagVariables(),
	...getCommentQueries(),
	isPinFieldConfigurabilityEnabled: ff('pin_field_exp_launch_darkly_flag_ef4mc'),
});

export const getCommentQueries = () => ({
	commentTargetId:
		ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9') ||
		ff('issue-jiv-14530-comments-consistency-check_x716d')
			? getPermalinkId(COMMENTS) || null
			: null,
});
