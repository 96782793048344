import {
	ROUTE_GROUPS_PERSONAL_SETTINGS,
	ROUTE_NAMES_PERSONAL_SETTINGS,
	ROUTE_NAMES_PERSONAL_SETTINGS_GENERAL,
	ROUTE_NAMES_PERSONAL_SETTINGS_NOTIFICATIONS,
	ROUTE_NAMES_PERSONAL_SETTINGS_OPS_NOTIFICATIONS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	LEGACY_PERSONAL_SETTINGS_PATH,
	GENERAL_PERSONAL_SETTINGS_PATH,
	NOTIFICATIONS_PERSONAL_SETTINGS_PATH,
	OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH,
} from '@atlassian/jira-personal-settings-paths';
import { PersonalSettingsNotificationsAppSkeleton } from '@atlassian/jira-skeletons/src/ui/personal-settings-notifications/index.tsx';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const legacyPersonalSettingsRoute = {
	group: ROUTE_GROUPS_PERSONAL_SETTINGS,
	name: ROUTE_NAMES_PERSONAL_SETTINGS,
	path: LEGACY_PERSONAL_SETTINGS_PATH,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const generalPersonalSettingsRoute = {
	group: ROUTE_GROUPS_PERSONAL_SETTINGS,
	name: ROUTE_NAMES_PERSONAL_SETTINGS_GENERAL,
	path: GENERAL_PERSONAL_SETTINGS_PATH,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const notificationsPersonalSettingsRoute = {
	group: ROUTE_GROUPS_PERSONAL_SETTINGS,
	name: ROUTE_NAMES_PERSONAL_SETTINGS_NOTIFICATIONS,
	path: NOTIFICATIONS_PERSONAL_SETTINGS_PATH,
	skeleton: PersonalSettingsNotificationsAppSkeleton,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const opsNotificationsPersonalSettingsRoute = {
	group: ROUTE_GROUPS_PERSONAL_SETTINGS,
	name: ROUTE_NAMES_PERSONAL_SETTINGS_OPS_NOTIFICATIONS,
	path: OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH,
} as const as Route;

export const getGeneralPersonalSettingsRouteForLink: () => Route = () => {
	if (ff('sea-1973-personal-settings-sidebar')) {
		return generalPersonalSettingsRoute;
	}
	return legacyPersonalSettingsRoute;
};

export const getNotificationsPersonalSettingsRouteForLink: () => Route = () => {
	if (ff('sea-1973-personal-settings-sidebar')) {
		if (ff('sea-1973-notifications-hub')) {
			return notificationsPersonalSettingsRoute;
		}
		return generalPersonalSettingsRoute;
	}
	return legacyPersonalSettingsRoute;
};
