import { stringify as qsStringify } from 'query-string';
import type {
	CmdbObjectId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ObjectByIdActivityRequest, ObjectRecentRequest } from '../types/object';
import type {
	ObjectSchemaQuickSearchObjectRequest,
	ObjectSchemaQuickSearchAttributeRequest,
	AqlSearchRequestQueryParams,
} from '../types/object-schema-quick-search';
import type { QrCodeObjectRequest } from '../types/qr-code';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object
 */
const getCmdbObjectUrl = (workspaceId: WorkspaceId) => `${getCmdbRestBaseUrl(workspaceId)}/object`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/${objectId}?includeExtendedInfo=false
 * includeExtendedInfo should be false unless explicitly required
 */
export const getCmdbObjectByIdUrl = (
	workspaceId: WorkspaceId,
	objectId: CmdbObjectId,
	includeExtendedInfo = false,
) => {
	const path = getCmdbObjectByIdUrlWithoutQueryParams(workspaceId, objectId);
	if (!includeExtendedInfo) {
		return `${path}?${qsStringify({ includeExtendedInfo })}`;
	}
	return path;
};

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/${objectId}
 */
export const getCmdbObjectByIdUrlWithoutQueryParams = (
	workspaceId: WorkspaceId,
	objectId: CmdbObjectId,
) => `${getCmdbObjectUrl(workspaceId)}/${objectId}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/${objectId}/activity
 */
export const getCmdbObjectByIdActivityUrl = (
	workspaceId: WorkspaceId,
	objectId: CmdbObjectId,
	queryParams: ObjectByIdActivityRequest,
) =>
	`${getCmdbObjectByIdUrlWithoutQueryParams(workspaceId, objectId)}/activity?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/${objectId}/avatar
 */
export const getCmdbObjectByIdAvatarUrl = (workspaceId: WorkspaceId, objectId: CmdbObjectId) =>
	`${getCmdbObjectByIdUrlWithoutQueryParams(workspaceId, objectId)}/avatar`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/${objectId}/referenceinfo
 */
export const getCmdbObjectByIdReferenceInfoUrl = (
	workspaceId: WorkspaceId,
	objectId: CmdbObjectId,
) => `${getCmdbObjectByIdUrlWithoutQueryParams(workspaceId, objectId)}/referenceinfo`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/clone/${objectId}
 */
export const getCmdbObjectCloneByIdUrl = (workspaceId: WorkspaceId, objectId: CmdbObjectId) =>
	`${getCmdbObjectUrl(workspaceId)}/clone/${objectId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/create
 */
export const getCmdbObjectCreateUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/create`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/navlist
 */
export const getCmdbObjectNavlistUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/navlist`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/navlist/aql
 */
export const getCmdbObjectNavlistQueryLanguageUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectNavlistUrl(workspaceId)}/aql`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/recent
 */
export const getCmdbObjectRecentUrl = (
	workspaceId: WorkspaceId,
	queryParams: ObjectRecentRequest,
) => `${getCmdbObjectUrl(workspaceId)}/recent?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/aql/totalcount
 */
export const getCmdbObjectQueryLanguageTotalCountUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/aql/totalcount`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/search
 */
export const getCmdbObjectSearchUrl = (
	workspaceId: WorkspaceId,
	queryParams: ObjectSchemaQuickSearchObjectRequest,
) => `${getCmdbObjectUrl(workspaceId)}/search?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/searchbyattributes
 */
export const getCmdbAttributeSearchUrl = (
	workspaceId: WorkspaceId,
	queryParams: ObjectSchemaQuickSearchAttributeRequest,
) => `${getCmdbObjectUrl(workspaceId)}/searchbyattributes?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/aql
 */
export const getCmdbObjectQueryLanguageUrl = (
	workspaceId: WorkspaceId,
	queryParams: AqlSearchRequestQueryParams,
) => `${getCmdbObjectUrl(workspaceId)}/aql?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/bulkexport
 */
export const getCmdbAsyncObjectExportUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/bulkexport`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/bulkqrcode
 */
export const getCmdbAsyncBulkQrCodeUrl = (
	workspaceId: WorkspaceId,
	queryParams: QrCodeObjectRequest,
) => `${getCmdbObjectUrl(workspaceId)}/bulkqrcode?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/bulkdelete
 */
export const getCmdbAsyncBulkDeleteUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/bulkdelete`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/object/bulkedit
 */
export const getCmdbAsyncBulkEditUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectUrl(workspaceId)}/bulkedit`;
