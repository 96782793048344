import { memoizedFetchSiteUserPersonalization } from '@atlassian/jira-personalization-service/src/services/site-user/index.tsx';
import type { Trait } from '@atlassian/jira-personalization-service/src/types.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge/src/types';
import { getTailoredOnboardingExperimentCohort } from '../../../utils/tailored-onboarding';
import {
	EXPERIENCE_TYPE,
	PERSONA_TRAIT_NAME,
	PERSONA_TYPE,
	type PersonaExperience,
} from '../../model/types';

const getTrait = async (cloudId: string, userId: string): Promise<Trait | undefined> => {
	const userTraits = await memoizedFetchSiteUserPersonalization({
		cloudId,
		userId,
	});
	const userTraitsPersona = userTraits?.find((trait) => trait.name === PERSONA_TRAIT_NAME);

	return userTraitsPersona;
};

export const getPersonaExperience = async (
	cloudId: string,
	userId: string,
	fireEvent: (data: AnalyticsAttributes) => void,
) => {
	const userTraitsPersona = await getTrait(cloudId, userId);
	const personaExperience = mapPersonaTraitsToExperienceOld(userTraitsPersona);

	fireEvent({
		personaExperience,
		persona: userTraitsPersona?.value,
		tailoredOnboardingExperiment1: getTailoredOnboardingExperimentCohort(),
	});
	return personaExperience;
};

const mapPersonaTraitsToExperienceOld = (
	userTraitsPersona?: Trait,
): PersonaExperience | undefined => {
	switch (userTraitsPersona?.value) {
		case PERSONA_TYPE.MARKETING_GENERIC:
		case PERSONA_TYPE.CAMPAIGN_MARKETING_LEAD:
			return EXPERIENCE_TYPE.MARKETER_LEAD;
		case PERSONA_TYPE.PROJECT_MARKETING_LEAD:
			return EXPERIENCE_TYPE.MARKETER_PROJECT;
		case PERSONA_TYPE.CONTENT_MARKETING_LEAD:
			return EXPERIENCE_TYPE.MARKETER_CONTENT;
		case PERSONA_TYPE.PMM:
			return EXPERIENCE_TYPE.MARKETER_PMM;
		default:
			return undefined;
	}
};
