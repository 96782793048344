import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import type GlobalPermissions from '@atlassian/jira-global-permissions/src/ui/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { globalPermissionsResource } from '@atlassian/jira-router-resources-admin-pages-permissions/src/services/global-permissions/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { globalSettingsGlobalPermissionsRoute } from '@atlassian/jira-router-routes-admin-pages-global-permissions-routes';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

export const LazyGlobalPermissions = lazyForPaint<typeof GlobalPermissions>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-global-permissions", jiraSpaEntry: "async-global-permissions" */ '@atlassian/jira-global-permissions'
		),
	),
);

export const LazyGlobalPermissionsPage = () => (
	<LazyPage Page={LazyGlobalPermissions} pageId="jira-global-permissions" shouldShowSpinner />
);

export const globalPermissionsRouteEntries: Route[] = [
	createEntry(globalSettingsGlobalPermissionsRoute, () => ({
        component: componentWithFG(
			'olympus-global-permissions-fe-modernisation',
			LazyGlobalPermissionsPage,
			ErrorPagesNotFound,
		),

        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			globalPermissionsResource,
		],

        canTransitionIn: () => fg('olympus-global-permissions-fe-modernisation'),
        forPaint: [LazyAtlassianNavigation, LazyGlobalPermissions],

        meta: {
			reporting: {
				id: 'GlobalPermissions',
				packageName: 'jiraAdminPagesGlobalPermissions',
				teamName: 'olympus',
			},
		}
    })),
];
