import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { globalSettingsContactAdministratorRoute } from '@atlassian/jira-router-routes-admin-pages-contact-administrator-routes';
import type { Route } from '@atlassian/react-resource-router';
import ContactAdministratorPage, { LazyContactAdministratorPage } from './ui';

export const contactAdministratorRouteEntries: Route[] = [
	createEntry(globalSettingsContactAdministratorRoute, () => ({
        ufoName: 'admin.contact-administrator',
        component: ContactAdministratorPage,
        layout: globalLayout,
        resources: [...getNavigationResources()],
        forPaint: [LazyAtlassianNavigation, LazyContactAdministratorPage]
    })),
];
