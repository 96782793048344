/**
 * @generated SignedSource<<20bdf2f6a28a2f1106b529d91ed20817>>
 * @relayHash 511deef67eb8feb6cf7da5b022122f74
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cedf20e4b8b5da842afafec10bb661c651e0e28ffe617f9222244e8a14b0af11

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentSettingsQuery } from './srcVirtualAgentSettingsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "cedf20e4b8b5da842afafec10bb661c651e0e28ffe617f9222244e8a14b0af11",
    "metadata": {},
    "name": "srcVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
