import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type { ForgeGlobalPageSidebar as ForgeGlobalPageSidebarType } from '@atlassian/jira-navigation-apps-sidebar-forge-global-page/src/ui/index.tsx';

const LazyForPaintForgeGlobalPageSidebar = lazyForPaint<typeof ForgeGlobalPageSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-forge-global-page" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ForgeGlobalPageSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="forgeGlobalPage">
		<LazyForPaintForgeGlobalPageSidebar />
	</SidebarBoundary>
));

ForgeGlobalPageSidebar.displayName = 'ForgeGlobalPageSidebar';

export default ForgeGlobalPageSidebar;
