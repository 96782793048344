/**
 * @generated SignedSource<<9f129380be4be11ebffebf75dd5bc4d9>>
 * @relayHash 1fe2ed93013b48c5a46e637ab51649d9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f33b0622a91566db2f969f1acf85aeb275e995bcfc0d44c36f5a9618fb4235be

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseConversationsQuery } from './srcVirtualAgentBrowseConversationsQuery.graphql';

import conversationFilterEnabled_provider from '@atlassian/jira-relay-provider/src/is-conversation-filter-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseConversationsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f33b0622a91566db2f969f1acf85aeb275e995bcfc0d44c36f5a9618fb4235be",
    "metadata": {},
    "name": "srcVirtualAgentBrowseConversationsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisconversationfilterenabledrelayprovider": conversationFilterEnabled_provider
    }
  }
};

export default node;
