/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { AppSkeletonImage } from '../../common/ui';
import insightsImgNewLayout from './assets/insights-new-layout.tpl.svg';
import insightsImg from './assets/insights.tpl.svg';
import periscopePageNewLayoutImg from './assets/periscope-page-new-layout.tpl.svg';
import periscopePageStableImg from './assets/periscope-page-stable.tpl.svg';
import suggestionsImg from './assets/suggestions.tpl.svg';
import workItemVulnTabImg from './assets/vuln-tab-skeleton.tpl.svg';
import workItemsImg from './assets/work-items.tpl.svg';

const PeriscopePageSkeletonStyles = css({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		border: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		border: 'none',
	},
});

export const PeriscopePageSkeletonOld = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-skeleton-image-periscope-page-old"
	>
		<AppSkeletonImage src={periscopePageStableImg} />
	</div>
);

export const PeriscopePageSkeletonNew = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-skeleton-image-periscope-page-new"
	>
		<AppSkeletonImage src={periscopePageNewLayoutImg} />
	</div>
);

export const PeriscopeSuggestionsSkeleton = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-suggestions-skeleton"
	>
		<AppSkeletonImage src={suggestionsImg} />
	</div>
);

export const PeriscopeInsightsSkeletonOld = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-insights-old-skeleton"
	>
		<AppSkeletonImage src={insightsImg} />
	</div>
);

export const PeriscopeInsightsSkeletonNew = () => (
	<div
		css={PeriscopePageSkeletonStyles}
		data-testid="skeletons.ui.periscope-page.app-insights-new-skeleton"
	>
		<AppSkeletonImage src={insightsImgNewLayout} />
	</div>
);

export const PeriscopeWorkItemsSkeleton = () => (
	<div css={PeriscopePageSkeletonStyles}>
		<AppSkeletonImage src={workItemsImg} />
	</div>
);

export const PeriscopePageSkeleton = componentWithFG(
	'jira_periscope_new_widgets',
	PeriscopePageSkeletonNew,
	PeriscopePageSkeletonOld,
);

export const PeriscopeInsightsSkeleton = componentWithFG(
	'jira_periscope_new_widgets',
	PeriscopeInsightsSkeletonNew,
	PeriscopeInsightsSkeletonOld,
);

export const DevelopmentPageWorkItemsVulnTabSkeleton = () => (
	<div css={PeriscopePageSkeletonStyles}>
		<AppSkeletonImage src={workItemVulnTabImg} />
	</div>
);
