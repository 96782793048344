import { ROUTE_NAMES_POLARIS_COLLECTIONS } from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/react-resource-router';
import { MENU_ID_ROADMAPS } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([ROUTE_NAMES_POLARIS_COLLECTIONS]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [];

	switch (routeName) {
		case ROUTE_NAMES_POLARIS_COLLECTIONS:
			path.push(MENU_ID_ROADMAPS);
			break;

		default:
	}

	return path;
}

export const roadmapsConverter = { routes, convert };
