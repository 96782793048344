import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { bind } from 'bind-event-listener';

import {
	SetSideNavDesktopVisibility,
	SetSideNavMobileVisibility,
	SideNavDesktopVisibility,
	SideNavMobileVisibility,
} from './visibility-context';

export const useSideNavVisibility = ({
	defaultCollapsed = false,
}: {
	/**
	 * The default visibility state of the side nav on desktop screens.
	 * This value will be used if the visibility state is not set in context yet, e.g. during SSR.
	 */
	defaultCollapsed?: boolean;
} = {}): {
	visibleOnDesktop: boolean;
	setVisibleOnDesktop: Dispatch<SetStateAction<boolean | null>>;
	visibleOnMobile: boolean;
	setVisibleOnMobile: Dispatch<SetStateAction<boolean>>;
	isSideNavVisible: boolean;
	showSideNav: () => void;
} => {
	const visibleOnDesktop = useContext(SideNavDesktopVisibility);
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const visibleOnMobile = useContext(SideNavMobileVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	const [isSideNavVisible, setIsSideNavVisible] = useState<boolean>(!defaultCollapsed);

	useEffect(() => {
		const isSideNavVisibleOnDesktop =
			visibleOnDesktop === null ? !defaultCollapsed : visibleOnDesktop;

		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		setIsSideNavVisible(mediaQueryList.matches ? isSideNavVisibleOnDesktop : visibleOnMobile);

		// reactive screen size changes
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				setIsSideNavVisible(mediaQueryList.matches ? isSideNavVisibleOnDesktop : visibleOnMobile);
			},
		});
	}, [defaultCollapsed, visibleOnDesktop, visibleOnMobile]);

	const showSideNav = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		matches ? setVisibleOnDesktop(true) : setVisibleOnMobile(true);
	}, [setVisibleOnDesktop, setVisibleOnMobile]);

	return {
		visibleOnDesktop: visibleOnDesktop === null ? !defaultCollapsed : visibleOnDesktop,
		visibleOnMobile,
		setVisibleOnDesktop,
		setVisibleOnMobile,
		isSideNavVisible,
		showSideNav,
	};
};
