import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { HelpCenterSettingsTicketTypeFieldsPage } from '@atlassian/jira-spa-apps-help-center-settings-ticket-type-fields/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyHelpCenterSettingsTicketTypeFields = lazyForPaint<
	typeof HelpCenterSettingsTicketTypeFieldsPage
>(() =>
	import(
		/* webpackChunkName: "async-help-center-settings-ticket-type-fields", jiraSpaEntry: "async-help-center-settings-ticket-type-fields" */ '@atlassian/jira-spa-apps-help-center-settings-ticket-type-fields/src/ui/index.tsx'
	).then((module) => module.HelpCenterSettingsTicketTypeFieldsPage),
);

export const HelpCenterSettingsTicketTypeFields = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsTicketTypeFields}
		pageId="help-center-settings-ticket-type-fields"
		shouldShowSpinner
	/>
);
