import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { JiraServiceManagementNavReportsData } from './types';

export const fetchJsmReportsSidebarNavInfo = (
	projectIdOrKey: string,
): Promise<JiraServiceManagementNavReportsData> => {
	if (projectIdOrKey == null) {
		throw new Error('invalid project id or key');
	}
	return performGetRequest(`/rest/navigation/4/servicedesk/project/${projectIdOrKey}/reports`);
};
