import React, { type ComponentType } from 'react';
import type { UserPreferenceType } from '@atlassian/jira-dashboard-user-preference/src/types.tsx';
import type {
	GadgetDefinition,
	ReactGadgetProps,
} from '@atlassian/jira-react-gadgets-common/src/types.tsx';
import type { IssueTableGadgetData, IssueTableGadgetEditData } from './types';
import { AssignedToMeGadgetEdit } from './ui/assigned-to-me/edit/async.tsx';
import { FilterResultsGadgetEdit } from './ui/filter-results/edit/async.tsx';
import { IssuesInProgressGadgetEdit } from './ui/issues-in-progress/edit/async.tsx';
import { IssueTableGadgetView, preload as issueTableGadgetPreload } from './ui/view/async.tsx';
import { VotedIssuesGadgetEdit } from './ui/voted-issues/edit/async.tsx';
import { WatchedIssuesGadgetEdit } from './ui/watched-issues/edit/async.tsx';
import createGetInitialState from './utils/get-initial-state';

const componentViewWithAdditionalState =
	(
		Component: ComponentType<ReactGadgetProps<IssueTableGadgetData>>,
		additionalStateProps: Partial<IssueTableGadgetData>,
	) =>
	(props: ReactGadgetProps<IssueTableGadgetData>) => (
		<Component
			{...props}
			state={props.state ? { ...props.state, ...additionalStateProps } : null}
		/>
	);

const componentEditWithAdditionalState =
	(
		Component: ComponentType<ReactGadgetProps<IssueTableGadgetEditData>>,
		additionalStateProps: Partial<IssueTableGadgetEditData>,
	) =>
	(props: ReactGadgetProps<IssueTableGadgetEditData>) => (
		<Component
			{...props}
			state={props.state ? { ...props.state, ...additionalStateProps } : null}
		/>
	);

const FILTER_RESULTS_CONFIG = {
	testId: 'react-issue-table-gadgets.filter-results.view',
} as const;

export const FILTER_RESULTS_DEFINITION: GadgetDefinition<
	IssueTableGadgetData,
	IssueTableGadgetData,
	UserPreferenceType[]
> = {
	componentView: componentViewWithAdditionalState(IssueTableGadgetView, FILTER_RESULTS_CONFIG),
	componentEdit: FilterResultsGadgetEdit,
	getInitialState: createGetInitialState(FILTER_RESULTS_CONFIG),
	preload: issueTableGadgetPreload,
};

const ASSIGNED_TO_ME_CONFIG = {
	predeterminedJql:
		'assignee = currentUser() AND statusCategory != 3 ORDER BY priority DESC, created ASC',
	defaultSortPrefs: { field: 'priority', direction: 'DESC' },
	testId: 'react-issue-table-gadgets.assigned-to-me.view',
} as const;

export const ASSIGNED_TO_ME_DEFINITION: GadgetDefinition<
	IssueTableGadgetData,
	IssueTableGadgetEditData,
	UserPreferenceType[]
> = {
	componentView: componentViewWithAdditionalState(IssueTableGadgetView, ASSIGNED_TO_ME_CONFIG),
	componentEdit: componentEditWithAdditionalState(AssignedToMeGadgetEdit, ASSIGNED_TO_ME_CONFIG),
	getInitialState: createGetInitialState(ASSIGNED_TO_ME_CONFIG),
	preload: issueTableGadgetPreload,
};

const ISSUES_IN_PROGRESS_CONFIG = {
	predeterminedJql:
		'status = "In Progress" AND statusCategory != Done AND assignee = currentUser() ORDER BY priority DESC, created ASC',
	defaultSortPrefs: { field: 'priority', direction: 'DESC' },
	testId: 'react-issue-table-gadgets.issues-in-progress.view',
} as const;

export const ISSUES_IN_PROGRESS_DEFINITION: GadgetDefinition<
	IssueTableGadgetData,
	IssueTableGadgetEditData,
	UserPreferenceType[]
> = {
	componentView: componentViewWithAdditionalState(IssueTableGadgetView, ISSUES_IN_PROGRESS_CONFIG),
	componentEdit: componentEditWithAdditionalState(
		IssuesInProgressGadgetEdit,
		ISSUES_IN_PROGRESS_CONFIG,
	),
	getInitialState: createGetInitialState(ISSUES_IN_PROGRESS_CONFIG),
	preload: issueTableGadgetPreload,
};

const VOTED_ISSUES_CONFIG = {
	predeterminedJql: 'issue in votedIssues() AND statusCategory != Done ORDER BY votes DESC',
	predeterminedJqlIncludeResolved: 'issue in votedIssues() ORDER BY votes DESC',
	defaultSortPrefs: { field: 'votes', direction: 'DESC' },
	testId: 'react-issue-table-gadgets.voted-issues.view',
} as const;

export const VOTED_ISSUES_DEFINITION: GadgetDefinition<
	IssueTableGadgetData,
	IssueTableGadgetEditData,
	UserPreferenceType[]
> = {
	componentView: componentViewWithAdditionalState(IssueTableGadgetView, VOTED_ISSUES_CONFIG),
	componentEdit: componentEditWithAdditionalState(VotedIssuesGadgetEdit, VOTED_ISSUES_CONFIG),
	getInitialState: createGetInitialState(VOTED_ISSUES_CONFIG),
	preload: issueTableGadgetPreload,
};

const WATCHED_ISSUES_CONFIG = {
	predeterminedJql: 'issue in watchedIssues() AND statusCategory != Done ORDER BY watchers DESC',
	predeterminedJqlIncludeResolved: 'issue in watchedIssues() ORDER BY watchers DESC',
	defaultSortPrefs: { field: 'watchers', direction: 'DESC' },
	testId: 'react-issue-table-gadgets.watched-issues.view',
} as const;

export const WATCHED_ISSUES_DEFINITION: GadgetDefinition<
	IssueTableGadgetData,
	IssueTableGadgetEditData,
	UserPreferenceType[]
> = {
	componentView: componentViewWithAdditionalState(IssueTableGadgetView, WATCHED_ISSUES_CONFIG),
	componentEdit: componentEditWithAdditionalState(WatchedIssuesGadgetEdit, WATCHED_ISSUES_CONFIG),
	getInitialState: createGetInitialState(WATCHED_ISSUES_CONFIG),
	preload: issueTableGadgetPreload,
};
