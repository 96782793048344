import { isFedRamp } from '@atlassian/atl-context';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	browseConversationsRoute,
	browseIntentsRoute,
	browseStandardFlowsRoute,
	landingPageRoute,
	settingsRoute,
	viewIntentRoute,
	viewStandardFlowRoute,
} from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { browseIntentsLayout } from '@atlassian/jira-servicedesk-virtual-agent-browse-intents-layout';
import { VirtualAgentBrowseConversationsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/browse-conversations/index.tsx';
import { VirtualAgentBrowseIntentsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/browse-intents/index.tsx';
import { VirtualAgentBrowseStandardFlowsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/browse-standard-flows/index.tsx';
import { VirtualAgentLandingSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/landing/index.tsx';
import { VirtualAgentSettingsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/settings/index.tsx';
import { VirtualAgentViewIntentSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/view-intent/index.tsx';
import { VirtualAgentViewStandardFlowSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/view-standard-flow/index.tsx';
import { browseConversationsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-conversations/entrypoint';
import { browseIntentsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-intents/entrypoint';
import { browseStandardFlowsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-standard-flows/entrypoint';
import { landingEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-landing/entrypoint';
import { settingsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-settings/entrypoint';
import { viewIntentEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-view-intent/entrypoint';
import { viewStandardFlowEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-view-standard-flow/entrypoint';
import type { Route } from '@atlassian/react-resource-router';
import {
	defaultRedirectForSettings,
	getSettingsNavigationResources,
	SERVICE_DESK_PRODUCT,
} from './ui/product-routes/common';

const browseConversationsPageEntryPoint = createPageEntryPoint({
	main: browseConversationsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const browseIntentsPageEntryPoint = createPageEntryPoint({
	main: browseIntentsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const browseStandardFlowsPageEntryPoint = createPageEntryPoint({
	main: browseStandardFlowsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const landingPageEntryPoint = createPageEntryPoint({
	main: landingEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const settingsPageEntryPoint = createPageEntryPoint({
	main: settingsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const viewStandardFlowPageEntryPoint = createPageEntryPoint({
	main: viewStandardFlowEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const viewIntentPageEntryPoint = createPageEntryPoint({
	main: viewIntentEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

const getVirtualAgentRoutesCommon = (): Pick<Route, 'layout' | 'navigation'> => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
});

const FedRAMPFallbackRedirectToProjectSettings = defaultRedirectForSettings(SERVICE_DESK_PRODUCT);

export const virtualAgentRouteEntries: Route[] = [
	createEntry(landingPageRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return landingPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentLandingSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_SETTINGS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_SETTINGS),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(browseIntentsRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseIntentsPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        layout: browseIntentsLayout,
        skeleton: VirtualAgentBrowseIntentsSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(viewIntentRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return viewIntentPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentViewIntentSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_VIEW_INTENT,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_VIEW_INTENT),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(browseStandardFlowsRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseStandardFlowsPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentBrowseStandardFlowsSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_STANDARD_FLOWS),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(viewStandardFlowRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return viewStandardFlowPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentViewStandardFlowSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_VIEW_STANDARD_FLOW,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_VIEW_STANDARD_FLOW),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(browseConversationsRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return browseConversationsPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentBrowseConversationsSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_BROWSE_CONVERSATIONS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_CONVERSATIONS),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
	createEntry(settingsRoute, () => ({
        ...getVirtualAgentRoutesCommon(),

        entryPoint() {
			if (isFedRamp()) {
				return undefined;
			}
			return settingsPageEntryPoint;
		},

        // this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
        component: FedRAMPFallbackRedirectToProjectSettings,

        skeleton: VirtualAgentSettingsSkeleton,

        resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],

        meta: {
			reporting: {
				id: APP_NAMES.VIRTUAL_AGENT_SETTINGS,
				packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_SETTINGS),
				teamName: 'jsd-flux-capacitors',
			},
		}
    })),
];
