// BE VERY CAREFUL IMPORTING ANY PACKAGES IN THIS FILE
// THIS CODE IS AN INLINE FRAGMENT AND RUNS BEFORE POLYFILLS AND MAY BREAK IE
import { getMeta } from './utils';

export const DEFAULT_LOADING_BAR_COLOR = 'rgb(76, 154, 255)';
export const DEFAULT_LOADING_DURATION = 15000;
export const TIMEOUT_TIMER = 40000;
const LOADER_STYLES_CSS_SELECTOR = 'body::before';
const EXIT_ANIMATION_SPEED = 300;
export const getDefaultLoadingBarColor = () =>
	getMeta('ajs-lf-navigation-highlightcolour') || DEFAULT_LOADING_BAR_COLOR;
export const generateStartAnimationCss = (duration: number) => `
    ${LOADER_STYLES_CSS_SELECTOR} {
        transform: scaleX(0.99);
        transition-duration: ${duration}ms;
        transition-timing-function: cubic-bezier(0,1,0.5,1);
    }
`;
export const generateFinishAnimationCss = () => `
    ${LOADER_STYLES_CSS_SELECTOR} {
        transform: scaleX(1);
        transition-duration: ${EXIT_ANIMATION_SPEED}ms;
        animation: none;
        background-image: none;
    }
`;
export const generateResetAnimationCss = () => `
    ${LOADER_STYLES_CSS_SELECTOR} {
        transform: scaleX(0);
        transition-duration: 0ms;
        animation: none;
        background-image: none;
    }
`;
export const generateKeyframeCss = () => `
    @keyframes shimmer {
        from {
            left: -80%;
            width: 80%;
        }

        to {
            left: 110%;
            width: 10%;
        }
    }
`;
export const generateShimmerAnimationCss = (loadingBarColor: string) => `
        ${LOADER_STYLES_CSS_SELECTOR} {
            transform: scaleX(1);
            transition-duration: 0ms;
            background: ${loadingBarColor};
            animation: shimmer 2s infinite;
        }
    `;
const browserFocusCheck = (action: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document && document.hidden) {
		action();
	} else {
		requestAnimationFrame(() => {
			action();
		});
	}
};
/**
 * Manages the visual state of a loading bar through dynamic CSS rule manipulation. This controller
 * orchestrates the start, progress, and completion phases of the loading animation, ensuring
 * visual consistency across different browser focus states. It handles potential delays and
 * timeouts, ensuring the loading bar accurately reflects the application's loading state.
 */
export const createLoadingBarActionController = (stylesheet: HTMLStyleElement | null) => {
	const { sheet } = stylesheet || {};
	if (!sheet) {
		return undefined;
	}
	let isRunning = false;
	let isDelayed = false;
	let exitAnimationTimer: ReturnType<typeof setTimeout>;
	let delayedAnimationTimer: ReturnType<typeof setTimeout>;
	let timeoutTimer: ReturnType<typeof setTimeout>;
	return {
		START: (duration: number = DEFAULT_LOADING_DURATION) => {
			if (isRunning) return;
			isRunning = true;
			// requestAnimationFrame will not run until the browser tab is focused
			// if a user opens a new tab the start animation will not begin until the tab is focused
			// this can cause the loading bar css to be applied in the incorrect order
			// to solve this we remove requestAnimationFrame if the tab is not focused
			browserFocusCheck(() => {
				sheet.insertRule(generateStartAnimationCss(duration), sheet.cssRules.length);
			});

			// Set a new delayed animation if loader hasn't finished after `${duration}` ms
			delayedAnimationTimer = setTimeout(() => {
				if (!isRunning) return;
				isDelayed = true;
				browserFocusCheck(() => {
					sheet.insertRule(generateKeyframeCss(), sheet.cssRules.length);
					sheet.insertRule(
						generateShimmerAnimationCss(getDefaultLoadingBarColor()),
						sheet.cssRules.length,
					);
				});
			}, DEFAULT_LOADING_DURATION);

			// Remove the loader if it hasn't finished after `${TIMEOUT_TIMER}` ms
			timeoutTimer = setTimeout(() => {
				if (!isRunning) return;
				isRunning = false;
				isDelayed = false;
				browserFocusCheck(() => {
					sheet.insertRule(generateResetAnimationCss(), sheet.cssRules.length);
				});
				clearTimeout(exitAnimationTimer);
				clearTimeout(delayedAnimationTimer);
				clearTimeout(timeoutTimer);
			}, TIMEOUT_TIMER);
		},
		FINISH: () => {
			if (!isRunning) return;
			isRunning = false;
			if (isDelayed) {
				isDelayed = false;
				clearTimeout(exitAnimationTimer);
				clearTimeout(timeoutTimer);
				clearTimeout(delayedAnimationTimer);
				browserFocusCheck(() => {
					sheet.insertRule(generateResetAnimationCss(), sheet.cssRules.length);
				});
				return;
			}
			browserFocusCheck(() => {
				sheet.insertRule(generateFinishAnimationCss(), sheet.cssRules.length);
			});
			exitAnimationTimer = setTimeout(() => {
				clearTimeout(exitAnimationTimer);
				clearTimeout(timeoutTimer);
				clearTimeout(delayedAnimationTimer);
				browserFocusCheck(() => {
					sheet.insertRule(generateResetAnimationCss(), sheet.cssRules.length);
				});
			}, EXIT_ANIMATION_SPEED + 500);
		},
		RESET: () => {
			isRunning = false;
			isDelayed = false;
			clearTimeout(exitAnimationTimer);
			clearTimeout(delayedAnimationTimer);
			clearTimeout(timeoutTimer);
			browserFocusCheck(() => {
				sheet.insertRule(generateResetAnimationCss(), sheet.cssRules.length);
			});
		},
	};
};
const setupLoadingBarActionController = () => {
	const LOADER_STYLESHEET_ID = 'loaderStylesheet';

	// @ts-expect-error - TS2322 - Type 'HTMLElement | null' is not assignable to type 'HTMLStyleElement | null'.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const styleSheet: HTMLStyleElement | null = document.getElementById(LOADER_STYLESHEET_ID);

	// SSR will not attach the stylesheet for routes not included in ALLOWED_ROUTES
	if (!styleSheet || !styleSheet.sheet) {
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.JIRA_LOADING_BAR = createLoadingBarActionController(styleSheet);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.JIRA_LOADING_BAR && window.JIRA_LOADING_BAR.START) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.JIRA_LOADING_BAR.START();
	}
};
setupLoadingBarActionController();
