import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { homeRedirectRoute } from '@atlassian/jira-router-routes-home-redirect-routes';
import JiraBaseRedirect from './common/ui/redirect/jira-base-redirect';

export const homeRedirectRouteEntry = createEntry(homeRedirectRoute, () => ({
    component: JiraBaseRedirect,
    layout: globalLayout,
    resources: [...getNavigationResources()],
    isRedirect: true,
    forPaint: [LazyAtlassianNavigation]
}));
