import { issueHierarchyPageResource } from '@atlassian/jira-admin-pages-issue-hierarchy-directory/src/ui/query';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { insightsBoardSettingsResource } from '@atlassian/jira-board-settings-insights-route/src/common/ui/services/insights-board-settings-resource/index.tsx';
import { CLASSIC_BOARD, CLASSIC_BACKLOG } from '@atlassian/jira-capabilities/src/constants.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	rapidboardBacklogRoute,
	rapidboardBoardRoute,
} from '@atlassian/jira-router-routes-software-classic-board-settings-insights-routes';
import {
	LazyRapidBoard,
	LazyRapidBoardApps,
	LazyRapidBoardResource,
	LazyRapidBoardConfigApiResource,
	LazyRapidBoardDataApiResource,
	LazyRapidBoardWrmBundleResource,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/async';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/index.tsx';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import type { Route } from '@atlassian/react-resource-router';
import InsightsSettingsSpaPage from './async';
import { InsightsSettingsRedirect } from './common';

export const classicSoftwareBoardSettingsInsightsRouteEntries: Route[] = [
	createEntry(rapidboardBacklogRoute, () => ({
        ufoName: 'rapidboard-backlog-insights',

        component: componentWithFG(
			'remove_jsw_insights_settings_ui',
			InsightsSettingsRedirect,
			componentWithFF(
				'simplified-boards-milestone-2_07hbk',
				InsightsSettingsSpaPage,
				RapidBoardSpaPage,
			),
		),

        skeleton: NextGenBacklogSkeleton,

        layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			insightsBoardSettingsResource,
			issueHierarchyPageResource,
			getBoardDetailsResource(),
		],

        afterPaint: [LazyIssueApp],

        forPaint: [
			LazyAtlassianNavigation,
			LazyRapidBoard,
			LazyRapidBoardApps,
			LazyRapidBoardDataApiResource,
			LazyRapidBoardConfigApiResource,
			LazyRapidBoardWrmBundleResource,
			LazyRapidBoardResource,
		],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: CLASSIC_BACKLOG,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
	createEntry(rapidboardBoardRoute, () => ({
        ufoName: 'rapidboard-board-insights',

        component: componentWithFG(
			'remove_jsw_insights_settings_ui',
			InsightsSettingsRedirect,
			componentWithFF(
				'simplified-boards-milestone-2_07hbk',
				InsightsSettingsSpaPage,
				RapidBoardSpaPage,
			),
		),

        skeleton: ClassicBoardSkeleton,

        layout: composeLayouts(
			genericProjectLayout,
			embeddedIssuePreloadLayoutBuilder([
				{ query: ['selectedIssue', 'view=detail'] },
				{ query: ['selectedIssue', 'modal=detail'] },
			]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			insightsBoardSettingsResource,
			issueHierarchyPageResource,
			getBoardDetailsResource(),
		],

        afterPaint: [LazyIssueApp],

        forPaint: [
			LazyAtlassianNavigation,
			LazyRapidBoard,
			LazyRapidBoardApps,
			LazyRapidBoardDataApiResource,
			LazyRapidBoardConfigApiResource,
			LazyRapidBoardWrmBundleResource,
			LazyRapidBoardResource,
		],

        preloadOptions: {
			earlyChunks: ['rapidboard-early-entry'],
		},

        meta: {
			capability: CLASSIC_BOARD,
		},

        earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
    })),
];
