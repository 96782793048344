import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import Link from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ENTRYPOINT_ID_NAVIGATION_REFRESH } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { NAV4_GENERAL_FEEDBACK_GROUP_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/common/constants/index.tsx';
import { getWillShowNav4UserOptIn } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4-user-opt-in.tsx';
import { getWillShowNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4-user-opt-out/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import messages from './messages';

export const SidebarFeedbackButton = () => {
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();

	const { openFeedbackCollector, closeFeedbackCollector } = useFeedbackCollectorActions();

	const onClickHandler = useCallback(() => {
		const showFeedbackTitleDetailsWithLink =
			getWillShowNav4UserOptIn() || getWillShowNav4UserOptOut();

		const formattedMessageNode = showFeedbackTitleDetailsWithLink ? (
			<FormattedMessage
				{...messages.feedbackTitleDetailsWithLink}
				values={{
					a: (chunks) => (
						<Link href="/jira/settings/personal/general" onClick={closeFeedbackCollector}>
							{chunks}
						</Link>
					),
				}}
			/>
		) : (
			<FormattedMessage {...messages.feedbackTitleDetailsNoLink} />
		);

		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_NAVIGATION_REFRESH,
			feedbackContext: [
				{
					id: 'environment',
					value: environment,
				},
				{
					id: 'cloud-id',
					value: cloudId,
				},
				{
					id: 'location',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					value: normaliseUrl(window?.location?.href ?? ''),
				},
			],
			feedbackGroupId: NAV4_GENERAL_FEEDBACK_GROUP_ID,
			feedbackTitle: formatMessage(messages.feedbackTitle),
			feedbackTitleDetails: formattedMessageNode,
		});
	}, [closeFeedbackCollector, cloudId, environment, formatMessage, openFeedbackCollector]);

	return (
		<Box xcss={containerStyles}>
			<Button
				shouldFitContainer
				iconBefore={MegaphoneIcon}
				appearance="subtle"
				onClick={onClickHandler}
			>
				{formatMessage(messages.sidebarFeedbackButtonLabel)}
			</Button>
		</Box>
	);
};

const containerStyles = xcss({
	flex: 'none',
	borderTop: `1px solid ${token('color.border')}`,
	padding: 'space.150',
	background: token('elevation.surface.raised', '#FFF'),
});
