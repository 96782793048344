import React from 'react';
import { resources as alertIssueSyncingResources } from '@atlassian/eoc-alert-issue-syncing/resources';
import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	eocAlertIssueSyncingRoute,
	eocAlertIssueSyncingCreateRoute,
	eocAlertIssueSyncingDetailRoute,
	eocTeamAlertIssueSyncingRoute,
	eocTeamAlertIssueSyncingCreateRoute,
	eocTeamAlertIssueSyncingDetailRoute,
} from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { LazyAlertIssueSync, LazyAlertIssueSyncDetail, LazyAlertIssueSyncCreate } from './async';

export const AlertIssueSync = () => (
	<LazyPage
		Page={LazyAlertIssueSync}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const AlertIssueSyncDetail = () => (
	<LazyPage
		Page={LazyAlertIssueSyncDetail}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const AlertIssueSyncCreate = () => (
	<LazyPage
		Page={LazyAlertIssueSyncCreate}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

export const eocAlertIssueSyncingRouteEntry = createEntry(eocAlertIssueSyncingRoute, () => ({
    component: AlertIssueSync,

    // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
    slot: null,

    basePath: '/jira/settings/products/ops',

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],

    navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync]
}));

export const eocAlertIssueSyncingDetailRouteEntry = createEntry(eocAlertIssueSyncingDetailRoute, () => ({
    component: AlertIssueSync,

    // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
    slot: AlertIssueSyncDetail,

    basePath: '/jira/settings/products/ops',

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],

    navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync]
}));

export const eocAlertIssueSyncingCreateRouteEntry = createEntry(eocAlertIssueSyncingCreateRoute, () => ({
    component: AlertIssueSync,

    // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
    slot: AlertIssueSyncCreate,

    basePath: '/jira/settings/products/ops',

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],

    navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync]
}));

export const eocTeamAlertIssueSyncingRouteEntry = createEntry(eocTeamAlertIssueSyncingRoute, () => ({
    component: AlertIssueSync,
    layout: opsTeamLayout,

    // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
    slot: null,

    basePath: '/jira/ops/teams',

    resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		getConsolidationStateResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],

    navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyAlertIssueSync,
		LazyTeamDashboardSidebar,
	]
}));

export const eocTeamAlertIssueSyncingDetailRouteEntry = createEntry(
	eocTeamAlertIssueSyncingDetailRoute,
	() => ({
        component: AlertIssueSync,
        layout: opsTeamLayout,

        // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
        slot: AlertIssueSyncDetail,

        basePath: '/jira/ops/teams',

        resources: [
			...getNavigationResources(),
			...teamDashboardResources,
			getConsolidationStateResource(),
			alertIssueSyncingResources.configuredSyncsResource,
			alertIssueSyncingResources.userAccessRightsResource,
		],

        navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyAlertIssueSync,
			LazyTeamDashboardSidebar,
		]
    }),
);

export const eocTeamAlertIssueSyncingCreateRouteEntry = createEntry(
	eocTeamAlertIssueSyncingCreateRoute,
	() => ({
        component: AlertIssueSync,
        layout: opsTeamLayout,

        // @ts-expect-error - 'slot' and 'basePath' do not exist in type Route
        slot: AlertIssueSyncCreate,

        basePath: '/jira/ops/teams',

        resources: [
			...getNavigationResources(),
			...teamDashboardResources,
			getConsolidationStateResource(),
			alertIssueSyncingResources.configuredSyncsResource,
			alertIssueSyncingResources.userAccessRightsResource,
			alertIssueSyncingResources.getTeamResource,
		],

        navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarEocTeamDashboard,
			horizontal: AsyncHorizontalOperationsNav,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [
			LazyHorizontalOperationsNav,
			LazyAtlassianNavigation,
			LazyAlertIssueSync,
			LazyTeamDashboardSidebar,
		]
    }),
);

export const eocAlertIssueSyncingRouteEntries = [
	eocTeamAlertIssueSyncingRouteEntry,
	eocTeamAlertIssueSyncingCreateRouteEntry,
	eocTeamAlertIssueSyncingDetailRouteEntry,
	eocAlertIssueSyncingRouteEntry,
	eocAlertIssueSyncingCreateRouteEntry,
	eocAlertIssueSyncingDetailRouteEntry,
];
