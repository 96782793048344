import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { PLAN_CALENDAR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { setCapability } from '@atlassian/jira-capabilities/src/services/active-capability-tracker/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async';
import { planLayout } from '@atlassian/jira-plan-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsScenarioIdResource,
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsPlanReportPageInitialChecksResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { advancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary/src/services/summary-data/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation';
import {
	softwareAdvancedRoadmapsRoute,
	softwarePlanDependenciesRoute,
	softwarePlanSummaryRoute,
	softwarePlanCalendarRoute,
	softwarePlanReleasesRoute,
	softwarePlanTimelineRoute,
	softwarePlanSettingsPermissionsRoute,
	softwarePlanSettingsSavedViewsRoute,
	softwarePlanSettingsProgramBoardsRoute,
	softwarePlanSettingsPlanInfoRoute,
	softwarePlanSettingsSchedulingRoute,
	softwarePlanSettingsScenariosRoute,
	softwarePlanSettingsAutoSchedulerRoute,
	softwarePlanSettingsCustomFieldsRoute,
	softwarePlanSettingsExclusionRulesRoute,
	softwarePlanSettingsIssueSourcesRoute,
	softwarePlanSettingsFindYourIssueRoute,
	softwarePlanSettingsRemovedIssuesRoute,
	softwarePlanTeamsRoute,
	softwareAdvancedRoadmaps1Route,
	softwareAdvancedRoadmapsRedirectRoute,
	softwareAdvancedRoadmaps3Route,
	softwareAdvancedRoadmapsRedirect1Route,
	softwareAdvancedRoadmaps5Route,
	softwareAdvancedRoadmapsSetupRoute,
	softwareAdvancedRoadmapsGettingStartedRedirectRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';
import {
	RedirectLegacyPlanPage,
	RedirectPlanPageWithoutScenarioId,
} from './common/ui/redirect/plan';
import RedirectLegacyReportPage from './common/ui/redirect/report';
import { arjEmbedRouteEntries } from './ui/embed';
import { arjSettingsRouteEntries } from './ui/settings';
import {
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
} from './ui/spa';
import PlanCalendarPage, { LazyPlanCalendar, planCalendarRouteEntry } from './ui/spa/plan-calendar';
import PlanDependenciesReportPage, {
	LazyPlanDependenciesReport,
} from './ui/spa/plan-dependencies-report';
import PlanReleasesPage, { LazyPlanReleases } from './ui/spa/plan-releases';
import PlanReportPage from './ui/spa/plan-report';
import PlanPage, { LazyAdvancedRoadmapsPlan } from './ui/spa/plan-report/plan';
import PlanSettingsAutoSchedulerPage, {
	LazyPlanSettingsAutoScheduler,
} from './ui/spa/plan-settings-auto-scheduler';
import PlanSettingsCustomFieldsPage, {
	LazyPlanSettingsCustomFields,
} from './ui/spa/plan-settings-custom-fields';
import PlanSettingsExclusionRulesPage, {
	LazyPlanSettingsExclusionRules,
} from './ui/spa/plan-settings-exclusion-rules';
import PlanSettingsIssueFindYourIssuePage, {
	LazyPlanSettingsFindYourIssue,
} from './ui/spa/plan-settings-find-your-issue';
import PlanSettingsIssueSourcesPage, {
	LazyPlanSettingsIssueSources,
} from './ui/spa/plan-settings-issue-sources';
import PlanSettingsPermissionsPage, {
	LazyPlanSettingsPermissions,
} from './ui/spa/plan-settings-permissions';
import PlanSettingsPlanInfoPage, {
	LazyPlanSettingsPlanInfo,
} from './ui/spa/plan-settings-plan-info';
import PlanSettingsProgramBoardsPage, {
	LazyPlanSettingsProgramBoards,
} from './ui/spa/plan-settings-program-boards';
import PlanSettingsRemovedIssuesPage, {
	LazyPlanSettingsRemovedIssues,
} from './ui/spa/plan-settings-removed-issues';
import PlanSettingsSavedViewsPage, {
	LazyPlanSettingsSavedViews,
} from './ui/spa/plan-settings-saved-views';
import PlanSettingsScenariosPage, {
	LazyPlanSettingsScenarios,
} from './ui/spa/plan-settings-scenarios';
import PlanSettingsSchedulingPage, {
	LazyPlanSettingsScheduling,
} from './ui/spa/plan-settings-scheduling';
import PlanSummaryPage, { LazyPlanSummary } from './ui/spa/plan-summary';
import PlanTeamsPage, { LazyPlanTeams } from './ui/spa/plan-teams';
import PlanTimelinePage, { LazyPlanTimeline } from './ui/spa/plan-timeline';
import SetupPage, { LazyAdvancedRoadmapsSetup } from './ui/spa/setup';
import { withRedirection } from './ui/spa/utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { arjSettingsRouteEntries } from './ui/settings';

export const advancedRoadmapsRouteEntries = [
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
];

// Exported for testing
export const planPageRedirect = withRedirection(PlanPage, {
	to: '/timeline',
	redirectCondition: () => true, // This redirect is required to redirect correctly from the View All Plans Page #plans/roadmap route
});

export const planPageRouteEntry: Route = createEntry(softwareAdvancedRoadmapsRoute, () => ({
    ufoName: 'software-advanced-roadmaps-scenario',
    component: planPageRedirect,
    layout: globalLayout,

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],

    preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

    navigation: {
		menuId: MENU_ID.PLANS,
	},

    forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
}));

export const planRouteEntries: Route[] = [
	...arjSettingsRouteEntries,
	...arjEmbedRouteEntries,
	createEntry(softwarePlanDependenciesRoute, () => ({
        component: PlanDependenciesReportPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanDependenciesReport]
    })),
	createEntry(softwarePlanSummaryRoute, () => ({
        ufoName: 'software-advanced-roadmaps-summary',
        component: PlanSummaryPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsSummaryDataResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSummary]
    })),
	createEntry(softwarePlanCalendarRoute, () => ({
        ufoName: 'software-advanced-roadmaps-calendar',
        component: PlanCalendarPage,
        skeleton: () => (fg('platform_calendar_adoption_in_plans') ? <CalendarSkeleton /> : undefined),

        entryPoint() {
			setCapability(PLAN_CALENDAR);
			return fg('platform_calendar_adoption_in_plans') ? planCalendarRouteEntry : undefined;
		},

        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        meta: {
			capability: PLAN_CALENDAR,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanCalendar]
    })),
	createEntry(softwarePlanReleasesRoute, () => ({
        component: PlanReleasesPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanReleases]
    })),
	createEntry(softwarePlanTimelineRoute, () => ({
        component: PlanTimelinePage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanTimeline]
    })),
	createEntry(softwarePlanSettingsPermissionsRoute, () => ({
        component: PlanSettingsPermissionsPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsPermissions]
    })),
	createEntry(softwarePlanSettingsSavedViewsRoute, () => ({
        component: PlanSettingsSavedViewsPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsSavedViews]
    })),
	createEntry(softwarePlanSettingsPlanInfoRoute, () => ({
        component: PlanSettingsPlanInfoPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsPlanInfo]
    })),
	createEntry(softwarePlanSettingsProgramBoardsRoute, () => ({
        component: PlanSettingsProgramBoardsPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsProgramBoards]
    })),
	createEntry(softwarePlanSettingsSchedulingRoute, () => ({
        component: PlanSettingsSchedulingPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsScheduling]
    })),
	createEntry(softwarePlanSettingsScenariosRoute, () => ({
        component: PlanSettingsScenariosPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsScenarios]
    })),
	createEntry(softwarePlanSettingsAutoSchedulerRoute, () => ({
        component: PlanSettingsAutoSchedulerPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsAutoScheduler]
    })),
	createEntry(softwarePlanSettingsCustomFieldsRoute, () => ({
        component: PlanSettingsCustomFieldsPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsCustomFields]
    })),
	createEntry(softwarePlanSettingsExclusionRulesRoute, () => ({
        component: PlanSettingsExclusionRulesPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsExclusionRules]
    })),
	createEntry(softwarePlanSettingsIssueSourcesRoute, () => ({
        component: PlanSettingsIssueSourcesPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsIssueSources]
    })),
	createEntry(softwarePlanSettingsFindYourIssueRoute, () => ({
        component: PlanSettingsIssueFindYourIssuePage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsFindYourIssue]
    })),
	createEntry(softwarePlanSettingsRemovedIssuesRoute, () => ({
        component: PlanSettingsRemovedIssuesPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanSettingsRemovedIssues]
    })),
	createEntry(softwarePlanTeamsRoute, () => ({
        component: PlanTeamsPage,
        layout: composeLayouts(globalLayout, planLayout),

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        forPaint: [LazyAtlassianNavigation, LazyPlanTeams]
    })),
	createEntry(softwareAdvancedRoadmapsGettingStartedRedirectRoute, () => ({
        component: () => <ScreenRedirect to="/jira/plans?create-sample-plan=true" />,
        isRedirect: true,
        layout: globalLayout,
        resources: [...getNavigationResources()],

        navigation: {
			menuId: MENU_ID.PLANS,
		},

        forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
    })),
];

export const planPageWithoutScenarioIdRouteEntry: Route = createEntry(
	softwareAdvancedRoadmaps1Route,
	() => ({
        ufoName: 'software-advanced-roadmaps-plan',
        component: RedirectPlanPageWithoutScenarioId,
        layout: globalLayout,

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
		],

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        navigation: {
			menuId: MENU_ID.PLANS,
		},

        forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
    }),
);

export const legacyPlanPageRedirectRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsRedirectRoute,
	() => ({
        component: RedirectLegacyPlanPage,
        isRedirect: true,
        layout: globalLayout,
        resources: [...getNavigationResources(), advancedRoadmapsScenarioIdResource],

        navigation: {
			menuId: MENU_ID.PLANS,
		},

        forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
    }),
);

/**
 * Report Page
 */

export const planReportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps3Route, () => ({
    ufoName: 'software-advanced-roadmaps-report',
    component: PlanReportPage,
    layout: composeLayouts(globalLayout, planLayout),

    resources: [
		...getNavigationResources(),
		advancedRoadmapsPlanReportPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],

    preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

    navigation: {
		menuId: MENU_ID.PLANS,
	},

    forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
}));

export const legacyPlanReportPageRedirectRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsRedirect1Route,
	() => ({
        component: RedirectLegacyReportPage,
        isRedirect: true,
        layout: globalLayout,
        resources: [...getNavigationResources()],

        navigation: {
			menuId: MENU_ID.PLANS,
		},

        forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan]
    }),
);

/**
 * Export Page
 */

const ExportPageComponent = () => <LazyAdvancedRoadmapsPlan viewType="export" />;

const ExportPage = () => (
	<LazyPage Page={ExportPageComponent} pageId="advanced-roadmap" shouldShowSpinner />
);

export const planExportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps5Route, () => ({
    ufoName: 'software-advanced-roadmaps-scenario-export',
    component: ExportPage,
    layout: { isChromeless: true, globalComponents: [], rightSidebars: [] },
    resources: [advancedRoadmapsPlanPageInitialChecksResource],
    forPaint: [LazyAdvancedRoadmapsPlan]
}));

export const planSetupPageRouteEntry: Route = createEntry(softwareAdvancedRoadmapsSetupRoute, () => ({
    component: SetupPage,
    layout: globalLayout,
    resources: [...getNavigationResources(), advancedRoadmapsPlanPageInitialChecksResource],
    forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsSetup]
}));
