import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectsComponentsResource } from '@atlassian/jira-router-resources-project-components/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	projectComponentsRedirectRoute,
	projectComponentsRoute,
} from '@atlassian/jira-router-routes-project-components-routes';
import Components, { ComponentsRedirect, LazyProjectComponents } from './ui';

export const projectComponentsRouteEntry = createEntry(projectComponentsRedirectRoute, () => ({
    component: !__SERVER__ ? ComponentsRedirect : Components,
    layout: genericProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

    resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		projectsComponentsResource,
		getConsolidationStateResource(),
	],

    forPaint: [LazyAtlassianNavigation, LazyProjectComponents],
    isRedirect: true
}));

export const projectComponents1RouteEntry = createEntry(projectComponentsRoute, () => ({
    ufoName: 'project-components-view',
    component: Components,
    layout: genericProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},

    resources: [
		...getBusinessProjectResources(),
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectContextResource,
		projectsComponentsResource,
		getConsolidationStateResource(),
	],

    forPaint: [LazyAtlassianNavigation, LazyProjectComponents]
}));
