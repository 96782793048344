import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_INCIDENTS_PAGE_INITIAL_CHECKS,
	RESOURCE_TYPE_INCIDENTS_PAGE_CUSTOM_FIELD_ID,
	RESOURCE_TYPE_INCIDENT_EXPERIMENT_TRAITS,
} from '../common/constants';
import type {
	IncidentsPageInitialChecksResponse,
	IncidentsCustomFieldIdResponse,
	IncidentExperimentTraitsResourceData,
} from './types';

export const incidentsInitialPageChecksResource =
	createResource<IncidentsPageInitialChecksResponse>({
		type: RESOURCE_TYPE_INCIDENTS_PAGE_INITIAL_CHECKS,
		getKey: ({ query }) => query.projectKey,
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-incidents-initial-page-check", jiraSpaEntry: "async-resource-incidents-initial-page-check" */ './get-data'
			),
	});

export const incidentsCustomFieldIdResource = createResource<IncidentsCustomFieldIdResponse>({
	type: RESOURCE_TYPE_INCIDENTS_PAGE_CUSTOM_FIELD_ID,
	getKey: ({ query }) => query.projectKey,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-incidents-custom-field-id-check", jiraSpaEntry: "async-resource-incidents-custom-field-id-check" */ './get-custom-field-data'
		),
});

export const incidentExperimentTraitResource = createResource<IncidentExperimentTraitsResourceData>(
	{
		type: RESOURCE_TYPE_INCIDENT_EXPERIMENT_TRAITS,
		getKey: () => 'incident_experiment_traits',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-incident-experiment-traits", jiraSpaEntry: "async-resource-incident-experiment-traits" */ './get-incident-experiment-traits'
			),
		maxAge: Number.MAX_SAFE_INTEGER,
	},
);
