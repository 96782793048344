/** @jsx jsx */
import type { ComponentType, ReactNode } from 'react';
import { jsx, styled } from '@compiled/react';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import type { ErrorBoundaryFallbackComponent } from '@atlassian/jira-error-boundary/src/main.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { NavErrorBoundary } from '@atlassian/jira-navigation-kit-error-boundary/src/ui/js-error-boundary/index.tsx';
import { ProjectViewNavigationSkipLink } from '../page-container/page-container-skip-links';

const NavErrorBoundaryFallback: ErrorBoundaryFallbackComponent = () =>
	// null = equivalent of "unmount"
	// We should do more graceful degradation.
	// Let's review this after dogfooding:
	// Tracked https://hello.jira.atlassian.cloud/browse/SEA-3059
	null;

export const HorizontalNav = () => {
	const HorizontalNavComponent = useHorizontalNavComponent();

	return (
		HorizontalNavComponent && (
			<NavErrorBoundary
				packageName="page-container"
				id="spa-horizontal-nav"
				fallback={NavErrorBoundaryFallback}
			>
				<NavErrorBoundary
					packageName="page-container"
					id="projectNavigationSkipLink"
					fallback="unmount"
				>
					<ProjectViewNavigationSkipLink />
				</NavErrorBoundary>
				<HorizontalNavComponent />
			</NavErrorBoundary>
		)
	);
};

type PropsOld = {
	children: ReactNode;
	navComponent: ComponentType;
};

/**
 * @deprecated no longer used with jira_nav4
 */
export const HorizontalNavWrapper = ({
	children,
	navComponent: HorizontalNavComponent,
}: PropsOld) => {
	return (
		<ThemedContainer>
			<NavErrorBoundary
				packageName="page-container"
				id="spa-horizontal-nav"
				fallback={NavErrorBoundaryFallback}
			>
				<NavErrorBoundary
					packageName="page-container"
					id="projectNavigationSkipLink"
					fallback="unmount"
				>
					<ProjectViewNavigationSkipLink />
				</NavErrorBoundary>
				<HorizontalNavComponent />
			</NavErrorBoundary>

			{children}
		</ThemedContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ThemedContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE,
	// When an image background is applied via the background css var the image
	// will be darkened in dark mode by blending with a black transparent background.
	backgroundBlendMode: 'darken',
	overflow: 'hidden',
});
