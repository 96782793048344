import { useMemo } from 'react';
import { useProjectSettingsSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-settings-connect-addons/index.tsx';
import { transformLinkV4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/transform-link';
import { LEGACY_CONNECT_ITEM_IDS } from './constants';

export const useConnectApps = () => {
	const {
		data: connectResourceItems,
		error,
		loading,
	} = useProjectSettingsSidebarConnectAddonsNavigationResource();

	const connectApps = useMemo(() => {
		if (connectResourceItems == null) {
			return [];
		}

		const transformedConnectApps = [];

		for (const item of connectResourceItems) {
			const transformedItem = transformLinkV4(item);

			if (transformedItem && !LEGACY_CONNECT_ITEM_IDS.has(transformedItem.id)) {
				transformedConnectApps.push(transformedItem);
			}
		}

		return transformedConnectApps;
	}, [connectResourceItems]);

	return {
		data: connectApps,
		loading,
		error,
	};
};
