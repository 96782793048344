import { useEffect, useState } from 'react';
import { isPageVisible } from '@atlassian/jira-common-page-visibility';

const usePageVisibility = () => {
	const [isVisible, setIsVisible] = useState(isPageVisible());

	useEffect(() => {
		const onVisibilityChange = () => setIsVisible(isPageVisible());

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, []);

	return isVisible;
};

export { usePageVisibility };
