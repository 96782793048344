import Deferred from '../deferred';

/**
 * Awaiting this promise will ensure heritage remainder has already been loaded.
 */
export const waitForHeritage = (): null | Deferred<void> | Promise<void> => {
	if (__SERVER__) {
		// WRM is not used on server
		// return null because we don't want to ?.then() chain into loadBridge functions
		return null;
	}
	if (!__SPA__) {
		// Heritage remainder is an SPA concept
		// return promise because we want to ?.then() chain into loadBridge functions
		return Promise.resolve();
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!window.__WAIT_FOR_HERITAGE__) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.__WAIT_FOR_HERITAGE__ = new Deferred<void>();
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return window.__WAIT_FOR_HERITAGE__;
};
