import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	breadcrumbsLabel: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'navigation-apps.breadcrumbs.aria-label',
		defaultMessage: 'Empty Breadcrumbs item',
		description: 'An empty breadcrumb, to avoid visual jumping',
	},
});
