import { getCookie } from '@atlassian/jira-browser-storage-controls/src/utils/get-cookie/index.tsx';

/*
This logic was ported from MobileUtils class from jira monolith to
provide the same conditions for checking whether the user agent is mobile or not.
*/

const DESKTOP_SWITCH_COOKIE_KEY = 'jira.mobile.desktop.switch';
const ANDROID_CHROME_USER_AGENT = new RegExp('Chrome/[.\\d]* Mobile');
const OLD_ANDROID_USER_AGENT = new RegExp('Linux;(?: U;)? Android (?:[23]\\.\\d|4\\.0\\.[12])');
const MOBILE_USER_AGENT = new RegExp(
	'(iPhone;|iPhone Simulator;|iPad;|iPod;|iPod touch;|Linux;(?: U;)? Android)',
);
const MOBILE_STRING_IN_USER_AGENT = new RegExp('Mobile');
const IPAD_USER_AGENT = new RegExp('iPad;');
const IOS_USER_AGENT = new RegExp('(iPhone;|iPad;|iPod;)');
/**
 * Determines if the current user agent is supported based on specific criteria.
 * It checks if the execution is server-side, the presence and validity of the navigator
 * object, and matches against known patterns for Android Chrome and mobile user agents,
 * excluding old Android versions.
 */
export const isSupportedUserAgent = () => {
	if (__SERVER__) return false;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!navigator || !navigator.userAgent || !navigator.userAgent.length) {
		return false;
	}
	return (
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		ANDROID_CHROME_USER_AGENT.test(navigator.userAgent) ||
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		(MOBILE_USER_AGENT.test(navigator.userAgent) &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			!OLD_ANDROID_USER_AGENT.test(navigator.userAgent))
	);
};
export const isAndroidUserAgent = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	ANDROID_CHROME_USER_AGENT.test(navigator.userAgent) ||
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	OLD_ANDROID_USER_AGENT.test(navigator.userAgent);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isIosUserAgent = () => IOS_USER_AGENT.test(navigator.userAgent);
/**
 * Checks whether the device's user agent suggests it's a tablet by comparing it
 * against known tablet patterns. Specifically, it identifies iPads and Android
 * tablets, ensuring they are not confused with mobile phones.
 */
export const isTabletUserAgent = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!navigator || !navigator.userAgent || !navigator.userAgent.length) {
		return false;
	}
	return (
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		IPAD_USER_AGENT.test(navigator.userAgent) ||
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		(isAndroidUserAgent() && !MOBILE_STRING_IN_USER_AGENT.test(navigator.userAgent))
	);
};
const isDesktopSwitchEnabled = () => getCookie(DESKTOP_SWITCH_COOKIE_KEY) === 'true';
const isMobileUserAgent = () => isSupportedUserAgent() && !isDesktopSwitchEnabled();
export const shouldShowReturnToMobileBanner = () => isSupportedUserAgent();
export const shouldShowDismissButton = () =>
	shouldShowReturnToMobileBanner() && isTabletUserAgent();
export const determineOperatingSystemAttribute = () => {
	if (isIosUserAgent()) {
		return 'ios';
	}
	if (isAndroidUserAgent()) {
		return 'android';
	}
	return 'other';
};
export default isMobileUserAgent;
