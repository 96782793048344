import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getQueuesResources,
	getIssueListSsrResource,
	getKnowledgeBaseResources,
	getInsightResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	// eslint-disable-next-line jira/import/no-restricted-import
	customPracticeQueueRouteEntry,
	// eslint-disable-next-line jira/import/no-restricted-import
	customQueueRouteEntry,
} from '@atlassian/jira-router-routes-servicedesk-queue-entries';
import {
	servicedeskIssueRoute,
	servicedeskQueuesLandingRoute,
	servicedeskQueuesNewRoute,
	servicedeskQueuesEditRoute,
	servicedeskQueuesIssueRoute,
	servicedeskQueuesManageViewRoute,
	servicedeskQueuesAddonRoute,
	servicedeskPracticeLandingRoute,
	servicedeskPracticeNewRoute,
	servicedeskPracticeManageViewRoute,
	servicedeskPracticeEditRoute,
	servicedeskPracticeIssueRoute,
} from '@atlassian/jira-router-routes-servicedesk-queues-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import ServiceDeskQueuesAddonView, { LazyServicedeskQueuesAddonView } from './ui/addon-view';
import ServiceDeskQueuesCreateView, { LazyServicedeskQueuesCreateView } from './ui/create-view';
import ServiceDeskQueuesEditView, { LazyServicedeskQueuesEditView } from './ui/edit-view';
import ServiceDeskQueuesIssueView, { LazyServicedeskQueuesIssueView } from './ui/issue-view';
import ServiceDeskQueuesLanding, { LazyServicedeskQueuesLanding } from './ui/landing';
import ServicedeskQueuesManageView, { LazyServicedeskQueuesManageView } from './ui/manage-view';
import ServiceDeskQueuesIssueViewRedirect, {
	LazyServicedeskQueuesIssueViewRedirect,
} from './ui/queues-issue-redirect';

export const queuesRouteEntries: Route[] = [
	createEntry(servicedeskIssueRoute, () => ({
        component: ServiceDeskQueuesIssueViewRedirect,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			...getKnowledgeBaseResources(),
			...getInsightResources(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesIssueViewRedirect]
    })),
	createEntry(servicedeskQueuesLandingRoute, () => ({
        isRedirect: true,
        component: ServiceDeskQueuesLanding,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesLanding]
    })),
	createEntry(servicedeskQueuesNewRoute, () => ({
        component: ServiceDeskQueuesCreateView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [...getQueuesResources(), getConsolidationStateResource()],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesCreateView],
        ufoName: 'service-management.queues.new'
    })),
	createEntry(servicedeskQueuesEditRoute, () => ({
        component: ServiceDeskQueuesEditView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			...getIssueListSsrResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesEditView],
        ufoName: 'service-management.queues.edit'
    })),
	customQueueRouteEntry,
	createEntry(servicedeskQueuesIssueRoute, () => ({
        component: ServiceDeskQueuesIssueView,

        layout: composeLayouts(
			serviceProjectLayout,
			embeddedIssuePreloadLayoutBuilder([{ params: [{ key: 'issueKey' }] }]),
		),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			...getKnowledgeBaseResources(),
			...getInsightResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesIssueView]
    })),
	createEntry(servicedeskQueuesManageViewRoute, () => ({
        component: ServicedeskQueuesManageView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: getQueuesResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesManageView]
    })),
	createEntry(servicedeskQueuesAddonRoute, () => ({
        component: ServiceDeskQueuesAddonView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [...getQueuesResources(), getConsolidationStateResource()],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAddonView]
    })),
	createEntry(servicedeskPracticeLandingRoute, () => ({
        isRedirect: true,
        component: ServiceDeskQueuesLanding,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [...getQueuesResources(), getConsolidationStateResource()],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesLanding]
    })),
	createEntry(servicedeskPracticeNewRoute, () => ({
        component: ServiceDeskQueuesCreateView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [...getQueuesResources(), getConsolidationStateResource()],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesCreateView]
    })),
	createEntry(servicedeskPracticeManageViewRoute, () => ({
        component: ServicedeskQueuesManageView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: getQueuesResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesManageView]
    })),
	createEntry(servicedeskPracticeEditRoute, () => ({
        component: ServiceDeskQueuesEditView,
        layout: serviceProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			...getIssueListSsrResource(),
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesEditView]
    })),
	customPracticeQueueRouteEntry,
	createEntry(servicedeskPracticeIssueRoute, () => ({
        component: ServiceDeskQueuesIssueView,
        layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

        resources: [
			...getQueuesResources(),
			...getKnowledgeBaseResources(),
			...getInsightResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],

        afterPaint: [LazyIssueApp],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesIssueView]
    })),
];
