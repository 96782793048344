import {
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { RouterContext } from '@atlassian/react-resource-router';
import { ENTITY_ID, MENU_ID_OVERVIEWS } from '../../../../constants';
import type { SelectedPath } from '../../../../types';

const routes = new Set<string>([
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_OVERVIEW_TIMELINE,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: string[] = [MENU_ID_OVERVIEWS];

	switch (routeName) {
		default: {
			const { overviewId } = match.params;
			overviewId && path.push(ENTITY_ID.OVERVIEW(overviewId));
		}
	}

	return path;
}

export const overviewsConverter = { routes, convert };
