import React, { useCallback } from 'react';
import AppProvider from '@atlaskit/app-provider';
import { token } from '@atlaskit/tokens';
import AnalyticsProviders from '@atlassian/jira-analytics-providers';
import AppBase from '@atlassian/jira-app-base';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import EngagementProvider from '@atlassian/jira-engagement-provider';
import getMeta from '@atlassian/jira-get-meta';
import { SidebarNav4ControllerProvider } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-controller-context';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	CONTAINER_EDITION,
	CONTAINER_EXPERIENCE_ID,
	CONTAINER_EXPERIENCE,
	CONTAINER_ANALYTICS_SOURCE,
	CONTAINER_APPLICATION,
	APP_BASE_CONTAINERS_END_MARK,
	APP_BASE_CONTAINERS_MARK,
	APP_BASE_CONTAINERS_START_MARK,
	ROUTER_CONTAINERS_END_MARK,
	ROUTER_CONTAINERS_MARK,
	ROUTER_CONTAINERS_START_MARK,
} from '@atlassian/jira-page-container-constants';
import { routeAnalyticsAttributes } from '@atlassian/jira-platform-route-analytics/src/main.tsx';
import { PostOfficeRouterListener } from '@atlassian/jira-post-office-provider';
import { ScreenSpaceFlagsPlacement } from '@atlassian/jira-post-office-provider/src/screen-space-flags-placement.tsx';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { setInitialPageLoadTimingFromPerformanceMarks } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { TenantContextContainer } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { ErrorBoundary } from './error-boundary/main.tsx';
import { IntlProviders } from './intl-providers/main.tsx';
import { MetricsContainers } from './metrics-containers/main.tsx';
import { NavigationContainers } from './navigation-containers/main.tsx';
import { PageConfigContainers } from './page-config-containers/main.tsx';
import type { GlobalContainersProps } from './types';
import { UiManagers } from './ui-managers/main.tsx';
import { UserContainers } from './user-containers/main.tsx';

/**
 * Serves as the foundational component orchestrating the integration of internationalization,
 * analytics, performance monitoring, and feature-flag based conditional rendering. It ensures a consistent
 * application structure and style setup, facilitating performance tracking and user experience improvements.
 */
export const GlobalContainers = ({
	id,
	children,
	baseUrl,
	locale,
	messages,
	Router,
	routerLinkComponent,
}: GlobalContainersProps) => {
	const globalContainersPerformanceTiming = useCallback(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			ROUTER_CONTAINERS_MARK,
			ROUTER_CONTAINERS_START_MARK,
			ROUTER_CONTAINERS_END_MARK,
			true,
			true,
		);
		setInitialPageLoadTimingFromPerformanceMarks(
			APP_BASE_CONTAINERS_MARK,
			APP_BASE_CONTAINERS_START_MARK,
			APP_BASE_CONTAINERS_END_MARK,
			true,
			true,
		);
	}, []);
	const { data: orgId } = useOrgId();
	// Unstable components cause bugs and performance problems - please fix this! go/unstable-components
	// eslint-disable-next-line react/no-unstable-nested-components
	const MonolithStyleOverride = () => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
		<style
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: `
                        body#jira {
                            overflow-x: hidden;
                            overflow-y: auto;
                        }

                        #jira-frontend {
                            background: ${token('elevation.surface', 'white')};
                        }

                        #jira_request_timing_info {
                            margin: 0;
                        }
                    `,
			}}
		/>
	);

	return (
		<IntlProviders messages={messages} locale={locale}>
			{__SPA__ && <MonolithStyleOverride />}
			<TenantContextContainer isGlobal getMeta={getMeta}>
				<AnalyticsProviders>
					<EngagementProvider orgId={orgId}>
						<ViewExperienceTrackingProvider
							analyticsSource={CONTAINER_ANALYTICS_SOURCE}
							experience={CONTAINER_EXPERIENCE}
							experienceId={CONTAINER_EXPERIENCE_ID}
							application={CONTAINER_APPLICATION}
							edition={CONTAINER_EDITION}
							additionalAttributes={routeAnalyticsAttributes}
						>
							<ErrorBoundary id={id} withExperienceTracker>
								<PerformanceMark metricKey={ROUTER_CONTAINERS_START_MARK} />
								<Router>
									<AppProvider UNSAFE_isThemingDisabled routerLinkComponent={routerLinkComponent}>
										<PerformanceMark metricKey={ROUTER_CONTAINERS_END_MARK} />
										<PageConfigContainers>
											<MetricsContainers>
												<JiraRelayEnvironmentProvider>
													<NavigationContainers baseUrl={baseUrl}>
														<UserContainers baseUrl={baseUrl}>
															<UiManagers>
																<PerformanceMark metricKey={APP_BASE_CONTAINERS_START_MARK} />
																<PostOfficeRouterListener>
																	<AppBase
																		id="page-container-v2.nav-v3.app-base"
																		hasErrorBoundary={false}
																	>
																		<PerformanceMark
																			metricKey={APP_BASE_CONTAINERS_END_MARK}
																			onSet={globalContainersPerformanceTiming}
																		/>
																		{getWillShowNav4() ? (
																			<SidebarNav4ControllerProvider>
																				{children}
																			</SidebarNav4ControllerProvider>
																		) : (
																			children
																		)}
																	</AppBase>
																</PostOfficeRouterListener>
																<ScreenSpaceFlagsPlacement />
															</UiManagers>
														</UserContainers>
													</NavigationContainers>
												</JiraRelayEnvironmentProvider>
											</MetricsContainers>
										</PageConfigContainers>
									</AppProvider>
								</Router>
							</ErrorBoundary>
						</ViewExperienceTrackingProvider>
					</EngagementProvider>
				</AnalyticsProviders>
			</TenantContextContainer>
		</IntlProviders>
	);
};
