import React from 'react';
import ScreenSpaceFlags from '@post-office-placement/screen-space-flags/placement';
import { fg } from '@atlassian/jira-feature-gating';
import killswitch from '@atlassian/jira-killswitch';
import { Placement } from '@atlassian/post-office-placement';

import { POST_OFFICE_KILL_SWITCH } from './constants';

export const ScreenSpaceFlagsPlacement = () => {
	return !__SERVER__ &&
		!killswitch(POST_OFFICE_KILL_SWITCH) &&
		fg('post-office-screen-space-overlay-jfe') ? (
		<Placement
			placementId="screen-space-flags"
			PlacementComponent={ScreenSpaceFlags}
			disableInitialData
		/>
	) : null;
};

// Comment to trigger Jira cypress tests
