import React from 'react';
import queryString from 'query-string';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { cardCoverImageResource } from '@atlassian/jira-board-settings-card/src/services/card-cover-settings';
import { insightsBoardSettingsResource } from '@atlassian/jira-board-settings-insights-route/src/common/ui/services/insights-board-settings-resource/index.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { customFiltersResource } from '@atlassian/jira-project-settings-custom-filters/src/services/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectSettingsResource,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { useProjectKeyByProjectResource } from '@atlassian/jira-router-resources-project-context/src/utils/get-project-key-by-project-resource/index.tsx';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields';
import { projectSettingsToolchainResource } from '@atlassian/jira-router-resources-project-settings-toolchain';
import { roadmapSettingsResource } from '@atlassian/jira-router-resources-roadmap/src/services/roadmap-settings/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	routeGroupsProjectSettingsSoftwareIssuetypesRoute,
	routeGroupsProjectSettingsSoftwareIssuetypesWorkflowRoute,
	routeGroupsProjectSettingsSoftwareAppsFieldsRoute,
	routeGroupsProjectSettingsSoftwareApps1Route,
	routeGroupsProjectSettingsSoftwareDetailsRoute,
	routeGroupsProjectSettingsSoftwareAccessRoute,
	routeGroupsProjectSettingsSoftwareAccessRedirectRoute,
	routeGroupsProjectSettingsSoftwareProformaProjectFormsRoute,
	routeGroupsProjectSettingsSoftwareProformaJiraFormBuilderRoute,
	projectSettingsSoftwareAutomationRoute,
	routeGroupsProjectSettingsSoftwareDataClassificationsRoute,
	routeGroupsProjectSettingsSoftwareNextGenFeaturesRoute,
	projectSettingsSoftwareNotificationsRoute,
	projectSettingsSoftwareNotificationsIssueLogsRoute,
	projectSettingsToolchainRoute,
	classicProjectSettingsToolchainRoute,
	classicProjectSettingsFeaturesRoute,
	classicProjectSettingsDetailsRoute,
	classicProjectSettingsSoftwareNotificationsIssueLogsRoute,
	projectSettingsSoftwareNotificationEmailRoute,
	projectSettingsSoftwareNotificationEmail1Route,
	legacyProjectReleasesNoteRoute,
	legacyProjectReleasesNoteConfigRoute,
	softwareReleaseNotesRoute,
	routeGroupsProjectSettingsSoftwareAutomationRoute,
	classicProjectSettingsSoftwareProformaFormsRoute,
	classicProjectSettingsSoftwareProformaEditFormsRoute,
	projectSettingsSoftwareBoardsRedirectRoute,
	projectSettingsSoftwareBoardsCsmRoute,
	projectSettingsSoftwareBoardsCustomFiltersRoute,
	projectSettingsSoftwareBoardsCardRoute,
	projectSettingsSoftwareBoardsRoadmapRoute,
	projectSettingsSoftwareBoardsTimelineRoute,
	projectSettingsAutomation1Route,
	projectSettingsForgeAppsRoute,
	projectSettingsSoftwareBoardsInsightsRoute,
	projectSettingsForgeAppsNextgenRoute,
	routeGroupsProjectSettingsSoftwareRedirectRoute,
	classicProjectSettingsOpsgenieRoute,
	projectSettingsOpsgenieRoute,
	classicProjectSettingsSoftwareRedirectRoute,
	routeGroupsProjectSettingsSoftwareFieldsRoute,
} from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes';
import {
	ProjectSettingsFeaturesSkeleton,
	ProjectSettingsAccessSkeleton,
	ProjectSettingsDetailsSkeleton,
} from '@atlassian/jira-skeletons/src/ui/project-settings/index.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils';
import {
	softwareProjectLayout,
	softwareProjectLayoutNoOnboarding,
	classicSoftwareProjectLayoutNoOnboarding,
} from '@atlassian/jira-software-project-layout';
import { type RouteContext, Redirect, type Route } from '@atlassian/react-resource-router';
import ConnectAppControlsPage, {
	LazyConnectAppControlsSettings,
} from './ui/project-settings/connect-app-controls';
import ConnectAppsPage, { LazyConnectAppsSettings } from './ui/project-settings/connect-apps';
import DevOpsConnectionsSettings, {
	LazyDevOpsConnectionsSettings,
} from './ui/project-settings/connections';
import CsmPage, { LazyCsmSettings } from './ui/project-settings/csm';
import CustomFiltersSettings, {
	LazyCustomFiltersSettings,
} from './ui/project-settings/custom-filters';
import DataClassifications, {
	LazyDataClassificationsSettings,
} from './ui/project-settings/data-classifications';
import Details, { LazyDetailsSettings } from './ui/project-settings/details';
import FieldSettingsPage, { LazyFieldsSettings } from './ui/project-settings/fields';
import ForgeProjectSettingsPage, {
	LazyForgeProjectSettingsPage,
	forgeProjectSettingsPageResources,
} from './ui/project-settings/forge';
import IssueTypePage, { LazyIssueTypeSettings } from './ui/project-settings/issue-type';
import JiraAutomation, {
	LazyProjectSettingsJiraAutomation,
} from './ui/project-settings/jira-automation';
import LinkOpsgeniePage, { LazyLinkOpsgeniePage } from './ui/project-settings/link-opsgenie';
import Features, { LazyFeaturesSettings } from './ui/project-settings/next-gen-features';
import EmailNotifications, {
	LazyProjectSettingsNotificationEmail,
} from './ui/project-settings/notification-email';
import Access, { LazyPeopleSettings, peopleAccessRedirect } from './ui/project-settings/people';
import {
	RedirectForSettingsForClassicProject,
	RedirectForSettings,
	getSettingsNavigationResources,
	SOFTWARE_PRODUCT,
} from './ui/project-settings/product-routes/common';
import Card, { LazyCard } from './ui/project-settings/product-routes/software/board-settings/card';
import Insights, {
	LazyInsights,
} from './ui/project-settings/product-routes/software/board-settings/insights';
import Roadmap, {
	LazyRoadmap,
} from './ui/project-settings/product-routes/software/board-settings/roadmap';
import ProformaJiraFormBuilder, {
	LazyProjectSettingsProformaJiraFormBuilder,
} from './ui/project-settings/proforma-jira-form-builder';
import ProformaProjectForms, {
	LazyProjectSettingsProformaProjectForms,
} from './ui/project-settings/proforma-project-forms';
import SoftwareNotifications, {
	LazySoftwareNotificationsSettings,
} from './ui/project-settings/software-notifications';
import {
	SoftwareNotificationsIssueLogs,
	LazySoftwareNotificationsIssueLogs,
	emailNotificationsLogEntryPointPage,
} from './ui/project-settings/software-notifications-issue-logs';
import WorkflowNavigationPage from './ui/project-settings/workflow-navigation';
import { ClassicFeaturesPage, LazySoftwareClassicFeatures } from './ui/projects/settings/features';

const BoardSettingsRedirectToCSM = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/software/projects/${String(match.params.projectKey)}/settings/boards/${String(
			match.params.boardId,
		)}/columns`}
		push={false}
	/>
);

const RoadmapSettingsRedirectToTimelineSettings = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/software/projects/${String(match.params.projectKey)}/settings/boards/${String(
			match.params.boardId,
		)}/timeline`}
		push={false}
	/>
);

const SpaReleaseNoteRedirectToVersionDetails = ({ query }: RouteContext) => {
	const { version: versionId } = query;
	const { loading, error, projectKey } = useProjectKeyByProjectResource();

	if (loading === false && error) {
		return null;
	}

	if (loading) {
		return null;
	}

	return (
		<Redirect
			to={{
				pathname: `/projects/${projectKey}/versions/${versionId}/tab/release-report-all-issues`,
				hash: '',
				search: queryString.stringify({ isReleaseNoteModalOpen: true }),
			}}
		/>
	);
};

const LegacyFrontendReleaseNoteRedirect = ({ query }: RouteContext) => (
	<Redirect
		to={{
			pathname: '/secure/ReleaseNote.jspa',
			hash: '',
			search: queryString.stringify({ ...query, isRedirectedFromLegacy: true }),
		}}
	/>
);

const ufoNamesMap: Record<string, string> = {
	'project-settings-software-issuetypes': 'project-settings.issue-type.jsw-tmp',
	'project-settings-servicedesk-issuetypes': 'project-settings.issue-type.jsm-tmp',
};

const getUFOName = (name: string) => ufoNamesMap[name] || name;
const commonRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsSoftwareIssuetypesRoute, () => ({
        ufoName: getUFOName(`${ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE}-issuetypes`),
        component: IssueTypePage,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareIssuetypesWorkflowRoute, () => ({
        component: WorkflowNavigationPage,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareAppsFieldsRoute, () => ({
        component: ConnectAppControlsPage,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyConnectAppControlsSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareApps1Route, () => ({
        component: ConnectAppsPage,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyConnectAppsSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareDetailsRoute, () => ({
        component: Details,
        skeleton: ProjectSettingsDetailsSkeleton,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyDetailsSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareFieldsRoute, () => ({
        ufoName: 'project-settings.fields.jsw-tmp',

        component: componentWithFG(
			'project_fields_page_for_tmp_-_mvp',
			FieldSettingsPage,
			ErrorPagesNotFound,
		),

        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), fieldSettingsRelayResource],
        forPaint: [LazyAtlassianNavigation, LazyFieldsSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareAccessRoute, () => ({
        skeleton: ProjectSettingsAccessSkeleton,
        component: Access,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
        ufoName: 'project-settings.access'
    })),
	createEntry(routeGroupsProjectSettingsSoftwareAccessRedirectRoute, () => ({
        component: peopleAccessRedirect(`/jira/${SOFTWARE_PRODUCT}`),
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyPeopleSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareProformaProjectFormsRoute, () => ({
        component: ProformaProjectForms,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaProjectForms],
        perfMetricKey: 'forms-settings'
    })),
	createEntry(routeGroupsProjectSettingsSoftwareProformaJiraFormBuilderRoute, () => ({
        component: ProformaJiraFormBuilder,
        layout: softwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder],
        perfMetricKey: 'form-builder'
    })),
	createEntry(projectSettingsSoftwareAutomationRoute, () => ({
        ufoName: `project-settings-automation-${SOFTWARE_PRODUCT}`,
        component: JiraAutomation,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareDataClassificationsRoute, () => ({
        component: DataClassifications,
        skeleton: ProjectSettingsDetailsSkeleton,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyDataClassificationsSettings]
    })),
];

/**
 * Contains all software settings routes using the new url format `jira/:product/projects/:projectKey/settings`.
 */
export const softwareRouteEntries: Route[] = [
	...commonRouteEntries,
	createEntry(classicProjectSettingsDetailsRoute, () => ({
        component: Details,
        skeleton: ProjectSettingsDetailsSkeleton,
        layout: softwareProjectLayout,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyDetailsSettings]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareNextGenFeaturesRoute, () => ({
        ufoName: 'project-settings.next-gen-features',
        component: Features,
        skeleton: ProjectSettingsFeaturesSkeleton,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyFeaturesSettings]
    })),
	createEntry(projectSettingsSoftwareNotificationsRoute, () => ({
        ufoName: 'project-settings.software.notifications',
        component: SoftwareNotifications,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazySoftwareNotificationsSettings]
    })),
	createEntry(projectSettingsSoftwareNotificationsIssueLogsRoute, () => ({
        ufoName: 'project-settings.software.notifications.issue-logs',
        component: SoftwareNotificationsIssueLogs,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazySoftwareNotificationsIssueLogs],

        entryPoint() {
			return ff('jlovep-692-changes-to-project-settings-notifications-page_jqk18')
				? emailNotificationsLogEntryPointPage
				: undefined;
		}
    })),
	createEntry(projectSettingsOpsgenieRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			LinkOpsgeniePage,
		),

        layout: softwareProjectLayoutNoOnboarding,
        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation, LazyLinkOpsgeniePage],
        ufoName: 'project-settings.link-opsgenie'
    })),
	createEntry(classicProjectSettingsOpsgenieRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			LinkOpsgeniePage,
		),

        layout: softwareProjectLayoutNoOnboarding,

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
		],

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation, LazyLinkOpsgeniePage],
        ufoName: 'project-settings.link-opsgenie'
    })),
	createEntry(projectSettingsToolchainRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DevOpsConnectionsSettings,
		),

        layout: softwareProjectLayoutNoOnboarding,

        resources: [
			...getSettingsNavigationResources(SOFTWARE_PRODUCT),
			projectSettingsToolchainResource,
		],

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation, LazyDevOpsConnectionsSettings],
        ufoName: 'project-settings.devops-connections'
    })),
	createEntry(classicProjectSettingsToolchainRoute, () => ({
        component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DevOpsConnectionsSettings,
		),

        layout: softwareProjectLayoutNoOnboarding,

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
			projectSettingsToolchainResource,
		],

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation, LazyDevOpsConnectionsSettings],
        ufoName: 'project-settings.devops-connections'
    })),
	createEntry(classicProjectSettingsFeaturesRoute, () => ({
        ufoName: 'classic-features-page',
        component: ClassicFeaturesPage,
        skeleton: ProjectSettingsFeaturesSkeleton,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
		],

        forPaint: [LazyAtlassianNavigation, LazySoftwareClassicFeatures]
    })),
	createEntry(classicProjectSettingsSoftwareNotificationsIssueLogsRoute, () => ({
        ufoName: 'project-settings.classic-issue-logs-page',
        component: SoftwareNotificationsIssueLogs,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
		],

        forPaint: [LazyAtlassianNavigation, LazySoftwareNotificationsIssueLogs],

        entryPoint() {
			return ff('jlovep-692-changes-to-project-settings-notifications-page_jqk18')
				? emailNotificationsLogEntryPointPage
				: undefined;
		}
    })),
	createEntry(projectSettingsSoftwareNotificationEmailRoute, () => ({
        component: EmailNotifications,
        skeleton: ProjectSettingsFeaturesSkeleton,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
		],

        forPaint: [
			LazyAtlassianNavigation,
			LazySoftwareClassicFeatures,
			LazyProjectSettingsNotificationEmail,
		]
    })),
	createEntry(projectSettingsSoftwareNotificationEmail1Route, () => ({
        component: RedirectForSettings,
        skeleton: ProjectSettingsFeaturesSkeleton,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(legacyProjectReleasesNoteRoute, () => ({
        resources: [getConsolidationStateResource()],
        component: LegacyFrontendReleaseNoteRedirect,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(legacyProjectReleasesNoteConfigRoute, () => ({
        resources: [getConsolidationStateResource()],
        component: LegacyFrontendReleaseNoteRedirect,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(softwareReleaseNotesRoute, () => ({
        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
			getConsolidationStateResource(),
		],

        component: SpaReleaseNoteRedirectToVersionDetails,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareAutomationRoute, () => ({
        component: JiraAutomation,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation]
    })),
	createEntry(classicProjectSettingsSoftwareProformaFormsRoute, () => ({
        component: ProformaProjectForms,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaProjectForms]
    })),
	createEntry(classicProjectSettingsSoftwareProformaEditFormsRoute, () => ({
        component: ProformaJiraFormBuilder,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder]
    })),

	createEntry(projectSettingsSoftwareBoardsRedirectRoute, () => ({
        component: BoardSettingsRedirectToCSM,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        isRedirect: true,
        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(projectSettingsSoftwareBoardsCsmRoute, () => ({
        ufoName: 'software.next-gen.boards.column-status-mapping.page.load',
        component: CsmPage,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation, LazyCsmSettings]
    })),
	createEntry(projectSettingsSoftwareBoardsCustomFiltersRoute, () => ({
        ufoName: 'project-settings.custom-filters',
        component: CustomFiltersSettings,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), customFiltersResource],
        forPaint: [LazyAtlassianNavigation, LazyCustomFiltersSettings]
    })),
	createEntry(projectSettingsSoftwareBoardsCardRoute, () => ({
        ufoName: 'board-settings-apps.card-cover-settings-page.page.load',
        component: Card,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), cardCoverImageResource],
        forPaint: [LazyAtlassianNavigation, LazyCard]
    })),
	createEntry(projectSettingsSoftwareBoardsRoadmapRoute, () => ({
        component: RoadmapSettingsRedirectToTimelineSettings,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), roadmapSettingsResource],
        forPaint: [LazyAtlassianNavigation, LazyRoadmap]
    })),
	createEntry(projectSettingsSoftwareBoardsTimelineRoute, () => ({
        ufoName: 'board-settings-apps.roadmap-settings-page.page.load',
        component: Roadmap,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), roadmapSettingsResource],
        forPaint: [LazyAtlassianNavigation, LazyRoadmap]
    })),
	createEntry(projectSettingsAutomation1Route, () => ({
        ufoName: 'project-settings-automation-c',
        component: JiraAutomation,
        layout: classicSoftwareProjectLayoutNoOnboarding,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			projectContextResource,
			getConsolidationStateResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation]
    })),
	createEntry(projectSettingsForgeAppsRoute, () => ({
        component: ForgeProjectSettingsPage,
        layout: softwareProjectLayoutNoOnboarding,

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarProjectResource(),
			...getNavigationSidebarProjectSettingsResource(),
			...forgeProjectSettingsPageResources,
		],

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        forPaint: [LazyAtlassianNavigation, LazyForgeProjectSettingsPage]
    })),
	createEntry(projectSettingsSoftwareBoardsInsightsRoute, () => ({
        ufoName: 'board-settings-apps.insights-settings-page.page.load',
        component: componentWithFG('remove_jsw_insights_settings_ui', RedirectForSettings, Insights),
        layout: softwareProjectLayoutNoOnboarding,

        // Add the resource as an element in the resources array
        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT), insightsBoardSettingsResource],
        forPaint: [LazyAtlassianNavigation, LazyInsights]
    })),
	createEntry(projectSettingsForgeAppsNextgenRoute, () => ({
        component: ForgeProjectSettingsPage,
        layout: softwareProjectLayout,

        navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [
			...getSettingsNavigationResources(SOFTWARE_PRODUCT),
			...forgeProjectSettingsPageResources,
		],

        forPaint: [LazyAtlassianNavigation, LazyForgeProjectSettingsPage]
    })),
	createEntry(routeGroupsProjectSettingsSoftwareRedirectRoute, () => ({
        component: RedirectForSettings,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation]
    })),
	createEntry(classicProjectSettingsSoftwareRedirectRoute, () => ({
        component: RedirectForSettingsForClassicProject,
        layout: softwareProjectLayoutNoOnboarding,

        navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

        resources: [...getSettingsNavigationResources(SOFTWARE_PRODUCT)],
        forPaint: [LazyAtlassianNavigation]
    })),
];
