/**
 * @generated SignedSource<<d327b730e85a00f29719aa9ef0bedadb>>
 * @relayHash 2b16c4530e6a4991c643936f8b7f7a75
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 61418abde1b6f9e8308be8231e429dad5a1ab50e615aed86e4cf34c33e6a7d68

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentLandingQuery } from './srcVirtualAgentLandingQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentLandingQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "61418abde1b6f9e8308be8231e429dad5a1ab50e615aed86e4cf34c33e6a7d68",
    "metadata": {},
    "name": "srcVirtualAgentLandingQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
