import { ff } from '@atlassian/jira-feature-flagging-using-meta';

// Rach start
// Rach end

// </ksayapina>
export const isParentInIssueViewEnabled = () => ff('parent-field-in-issue-view_x4zn4', false);
// </ksayapina>

// <jsmTimeline>
export const isJsmTimelineEnabled = (): boolean => ff('jsm-timelines-phase-2_70gja', false);
// <jsmTimeline>

// <gchan3>
export const GIRA_AGG_MIGRATION_ISARCHIVED_FLAG = 'issue.details.gira-agg-migration-isarchived';
// </gchan3>
