import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_RAPIDBOARDS_WRM_BUNDLE } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wrmBundleResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_WRM_BUNDLE,
	getKey: () => 'state',
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-wrm-bundle", jiraSpaEntry: "async-resource-rapidboards-wrm-bundle" */ './get-data'
		),
	maxAge: Number.MAX_SAFE_INTEGER,
	isBrowserOnly: true,
});
