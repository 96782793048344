import React from 'react';
import { ProjectStyle } from '@atlassian/jira-common-constants/src/project-types';
import {
	ContextualAnalyticsData as ContextualAnalyticsDataBase,
	type AnalyticsContextData,
} from '@atlassian/jira-product-analytics-bridge';
import { useProject as useProjectDI } from '../../controllers/project';
import { generateViewId } from '../../utils/generate-view-id.tsx';

export type ContextualAnalyticsDataProps = AnalyticsContextData & {
	menuId: string;
	stack?: string[];
};

export type IssuesContextualAnalyticsDataProps = AnalyticsContextData & {
	navigatorScope: string;
	useProject?: typeof useProjectDI;
};

export const ContextualAnalyticsData = ({
	attributes,
	menuId,
	stack = [],
	...rest
}: ContextualAnalyticsDataProps) => (
	<ContextualAnalyticsDataBase
		{...rest}
		attributes={{
			navVersion: '3',
			viewId: generateViewId(menuId, ...stack),
			navigationLayer: 'container',
			...attributes,
		}}
	/>
);

export const IssuesContextualAnalyticsData = ({
	attributes,
	navigatorScope,
	useProject = useProjectDI,
	...rest
}: IssuesContextualAnalyticsDataProps) => {
	const { data: project } = useProject();
	const projectConfig =
		(project?.isSimplified ? ProjectStyle.NEXT_GEN : ProjectStyle.CLASSIC) || undefined;
	const projectType = project?.type || undefined;
	return (
		<ContextualAnalyticsDataBase
			{...rest}
			attributes={{
				...attributes,
				projectConfig,
				projectType,
				navigatorScope,
			}}
		/>
	);
};
