import { transformIssuesByAssigneeData, transformIssuesByTypeData } from '../utils';
import type { ResponseData, SummaryPageDataType } from './types';

export const transformData = (data: ResponseData): SummaryPageDataType => ({
	overviewItems: {
		doneThisWeek: data.jira.doneThisWeek?.totalCount ?? 0,
		updatedThisWeek: data.jira.updatedThisWeek?.totalCount ?? 0,
		createdThisWeek: data.jira.createdThisWeek?.totalCount ?? 0,
		dueThisWeek: data.jira.dueThisWeek?.totalCount ?? 0,
	},

	issuesByStatus:
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data.issuesByStatus?.nodes.map((node: any) => ({
			id: node.id,
			count: node.totalCount,
			status: {
				name: node.field.name,
				jqlTerm: data.jira.jqlBuilder?.status.edges.find(
					(status) => status.node.displayName === node.field.name,
				)?.node.jqlTerm,
				category: {
					key: node.field.category.key,
				},
			},
		})) ?? null,

	issuesByPriority:
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data.issuesByPriority?.nodes.map((node: any) => ({
			id: node.id,
			count: node.totalCount,
			priority: {
				name: node.field.name,
				iconUrl: node.field.iconUrl,
			},
		})) ?? null,

	issuesByAssignee: data.issuesByAssignee
		? {
				...transformIssuesByAssigneeData(data.issuesByAssignee),
				totalUnresolvedIssues: data.jira.totalUnresolvedIssues?.totalCount ?? 0,
			}
		: null,

	issuesByType: data.issuesByType
		? {
				...transformIssuesByTypeData(data.issuesByType),
				totalUnresolvedIssues: data.jira.totalUnresolvedIssues?.totalCount ?? 0,
			}
		: null,
	projectIssueTypesCount: data?.jira?.projectIssueTypesCount?.issueTypes?.totalCount ?? null,
	worklowsCount: data?.worklowsCount?.totalCount ?? null,
});
