import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type { SidebarGlobalSettingsProducts as GlobalProductsSettingsSidebarType } from './ui/index.tsx';

const LazyGlobalProductsSettingsSidebar = lazyAfterPaint<typeof GlobalProductsSettingsSidebarType>(
	() => {
		setPerformanceMarkLoadStart();
		return import(/* webpackChunkName: "async-sidebar-global-settings-products" */ './index').then(
			(bundle) => {
				setPerformanceMarkLoadEnd();
				return bundle;
			},
		);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalProductsSettingsSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="globalProductsSettings">
		<LazyGlobalProductsSettingsSidebar />
	</SidebarBoundary>
));

GlobalProductsSettingsSidebar.displayName = 'GlobalProductsSettingsSidebar';

export default GlobalProductsSettingsSidebar;
