import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { servicedeskCreateProjectRoute } from '@atlassian/jira-router-routes-servicedesk-create-project-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type ServiceDeskCreateProjectType from '@atlassian/jira-spa-apps-servicedesk-create-project';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServiceDeskCreateProject = lazyForPaint<typeof ServiceDeskCreateProjectType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-create-project", jiraSpaEntry: "async-servicedesk-create-project" */ '@atlassian/jira-spa-apps-servicedesk-create-project'
		),
);

export const ServiceDeskCreateProject = () => (
	<LazyPage
		Page={LazyServiceDeskCreateProject}
		pageId="spa-apps/servicedesk/create-project"
		shouldShowSpinner
	/>
);

export const servicedeskCreateProjectRouteEntry = createEntry(servicedeskCreateProjectRoute, () => ({
    isRedirect: true,
    component: ServiceDeskCreateProject,
    layout: serviceProjectLayout,
    resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
    forPaint: [LazyAtlassianNavigation, LazyServiceDeskCreateProject],

    meta: {
		reporting: {
			id: APP_NAMES.CREATE_PROJECT,
			packageName: toPackageName(APP_NAMES.CREATE_PROJECT),
			teamName: 'itops-mithril',
		},
	}
}));
