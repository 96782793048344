import type { JiraUserBoardNavigationData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/jsw-userboard-sidebar-navigation/types.tsx';
import type { JiraSoftwareNavData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type { Board } from '../../../types';

export const extractData = (jiraSoftwareNavData: JiraSoftwareNavData): Board | null => {
	if (jiraSoftwareNavData.boardInfo == null) {
		return null;
	}
	const {
		boardInfo: { id, type = 'scrum', name },
	} = jiraSoftwareNavData;

	return {
		id,
		type,
		name,
		isProjectBoard: true,
	};
};

export const extractUserBoard = (userBoardNavData: JiraUserBoardNavigationData): Board | null => {
	const {
		boardInfo: { id, type = 'scrum', name },
	} = userBoardNavData;

	return {
		id,
		type,
		name,
		isProjectBoard: false,
	};
};
