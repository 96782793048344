import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type PersonalSettingsSidebarType from './ui/index.tsx';

const LazyPersonalSettingsSidebar = lazyAfterPaint<typeof PersonalSettingsSidebarType>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-personal-settings" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

const PersonalSettingsSidebar = () => (
	<SidebarBoundary id="personalSettings">
		<LazyPersonalSettingsSidebar />
	</SidebarBoundary>
);

export default memo(PersonalSettingsSidebar);
