import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { PlanSidebarNavInfo } from './types';

const requestSideBarInfo = (planId: string, scenarioId: string): Promise<PlanSidebarNavInfo> =>
	performGetRequest(`/rest/jpo/1.0/navigation/plans/${planId}/scenarios/${scenarioId}`);

export const fetchPlanSidebarNavInfo = (
	planId: string,
	scenarioId: string,
): Promise<PlanSidebarNavInfo | null> => {
	if (planId == null || planId.trim() === '') {
		throw new Error('Plan cannot be loaded without a planId');
	}

	if (scenarioId == null || scenarioId.trim() === '') {
		throw new Error('Plan cannot be loaded without a scenarioId');
	}

	return requestSideBarInfo(planId, scenarioId);
};
