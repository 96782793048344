import { useMemo } from 'react';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/navigation-global-resource/index.tsx';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error';
import { useGlobalSettingsSystemConnectItems as useGlobalSettingsSystemConnectItemsWithoutThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-connect/src/controllers/global-settings-system/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { UseFeaturesReturn } from './types';
import { extractData } from './utils/extract-data.tsx';

export const useFeatures = withThrowOnError((): UseFeaturesReturn => {
	const { data, loading, error } = useNavigationGlobalResource();
	const { isDevMode, isAdmin, isSiteAdmin } = useTenantContext();
	const extractedData = useMemo(
		() => data && extractData(data, isDevMode, isAdmin, isSiteAdmin),
		[data, isDevMode, isAdmin, isSiteAdmin],
	);
	return { data: extractedData, loading, error };
});

export const useGlobalSettingsSystemConnectItems = withThrowOnError(
	useGlobalSettingsSystemConnectItemsWithoutThrowOnError,
);
