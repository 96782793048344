import { createResource } from '@atlassian/react-resource-router';
import { withResourceEarlyData } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const issueMediaReadPermissionResource = createResource<any>(
	withResourceEarlyData({
		type: 'ISSUE_MEDIA_READ_PERM',
		getKey: ({ match }) =>
			match && match.params && match.params.issueKey !== undefined && match.params.issueKey !== null
				? match.params.issueKey
				: '',
		getData: () => null,
		isBrowserOnly: true,
	}),
);
