import type { Metric } from '../types';
import messages from './messages';

export const PROJECT_CLEANUP_KEY = 'projectcleanup';

export const PROJECT_CLEANUP: Metric = {
	key: PROJECT_CLEANUP_KEY,
	visible: true,
	name: messages.nameNonFinal,
	description: messages.descriptionNonFinal,
	tabLabels: [messages.analysisNonFinal, messages.activityLogNonFinal],
	warningMessage: messages.warningNonFinal,
	exceededMessage: messages.exceededNonFinal,
	risks: [],
	mitigations: [],
	currentValueLabel: messages.currentValueLabel,
	cleanupValueLabel: messages.cleanupValueLabel,
	graphHeader: messages.graphHeader,
	recommendationUrl:
		'https://support.atlassian.com/jira-cloud-administration/docs/optimize-the-number-of-projects-in-your-site/',
	recommendationUrlText: messages.recommendationUrlText,
};
