/**
 * @generated SignedSource<<9de9cf14716e190cd364d9cece0b3659>>
 * @relayHash fd00c37771e9ea1df0cdf4841e027e26
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d872a2224078a5211b7a3cd4e805eb457a02f0382476b7930c707b68a23f378c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type uiHorizontalSoftwareProjectNavQuery$variables = {
  boardId?: string | null | undefined;
  boardIdTemp: string;
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  hasBoardId: boolean;
  isTmpRoute: boolean;
  projectAri: string;
  projectKey: string;
  useContainerNavigation: boolean;
  useProjectAri: boolean;
  useProjectKey: boolean;
};
export type uiHorizontalSoftwareProjectNavQuery$data = {
  readonly jira: {
    readonly containerNavigation?: {
      readonly canEdit?: boolean | null | undefined;
      readonly navigationItems?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
      } | null | undefined;
      readonly scopeId?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitAddTab_AddTab" | "ui_navigationKitTabList_TabList_containerFragment">;
    } | null | undefined;
    readonly jiraSoftwareNavigationItems?: {
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
    };
    readonly projectDataById?: {
      readonly __id: string;
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly canViewProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly key: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly projectStyle: JiraProjectStyle | null | undefined;
      readonly projectType: JiraProjectType | null | undefined;
      readonly softwareBoardCount: AGG$Long | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"projectHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    };
    readonly projectDataByKey?: {
      readonly __id: string;
      readonly avatar: {
        readonly large: string | null | undefined;
      } | null | undefined;
      readonly canEditProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly canViewProjectConfig: {
        readonly canPerform: boolean;
      } | null | undefined;
      readonly key: string;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly projectStyle: JiraProjectStyle | null | undefined;
      readonly projectType: JiraProjectType | null | undefined;
      readonly softwareBoardCount: AGG$Long | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"projectHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"boardHeader_horizontalNavHeader">;
  };
};
export type uiHorizontalSoftwareProjectNavQuery = {
  response: uiHorizontalSoftwareProjectNavQuery$data;
  variables: uiHorizontalSoftwareProjectNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardIdTemp"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBoardId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTmpRoute"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectAri"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectKey"
},
v12 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v13 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v14 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectKey"
  }
],
v15 = [
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_tabsFragment"
  }
],
v16 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "containerNavigationInput"
  }
],
v17 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v18 = {
  "kind": "ScalarField",
  "name": "scopeId"
},
v19 = [
  (v13/*: any*/)
],
v20 = [
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v21 = {
  "kind": "ScalarField",
  "name": "name"
},
v22 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v23 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v24 = {
  "kind": "ScalarField",
  "name": "projectStyle"
},
v25 = {
  "kind": "ScalarField",
  "name": "key"
},
v26 = {
  "kind": "ScalarField",
  "name": "softwareBoardCount"
},
v27 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v28 = {
  "alias": "canEditProjectConfig",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "EDIT_PROJECT_CONFIG"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
},
v29 = {
  "alias": "canViewProjectConfig",
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "VIEW_PROJECT_CONFIG"
    }
  ],
  "concreteType": "JiraProjectAction",
  "kind": "LinkedField",
  "name": "action",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
},
v30 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "large"
    }
  ]
},
v31 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v32 = [
  {
    "kind": "FragmentSpread",
    "name": "projectHeader_horizontalNavHeader"
  },
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_projectFragment"
  },
  (v21/*: any*/),
  (v22/*: any*/),
  (v23/*: any*/),
  (v24/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/)
],
v33 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v34 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v35 = {
  "kind": "ScalarField",
  "name": "id"
},
v36 = {
  "kind": "ScalarField",
  "name": "label"
},
v37 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v38 = {
  "kind": "ScalarField",
  "name": "url"
},
v39 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v40 = [
  {
    "concreteType": "JiraNavigationItemEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v34/*: any*/),
          (v35/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isDefault"
          },
          {
            "kind": "ScalarField",
            "name": "typeKey"
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraNavigationItem"
          },
          (v36/*: any*/),
          {
            "kind": "ScalarField",
            "name": "canRemove"
          },
          {
            "kind": "ScalarField",
            "name": "canSetAsDefault"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "appId"
              },
              {
                "concreteType": "JiraAppSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v36/*: any*/),
                  {
                    "concreteType": "JiraAppNavigationItemNestedLink",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": true,
                    "selections": [
                      (v36/*: any*/),
                      (v37/*: any*/),
                      (v38/*: any*/),
                      (v39/*: any*/)
                    ]
                  }
                ]
              },
              (v38/*: any*/),
              (v37/*: any*/),
              (v39/*: any*/),
              {
                "kind": "ScalarField",
                "name": "appType"
              },
              {
                "kind": "ScalarField",
                "name": "envLabel"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v31/*: any*/)
                ],
                "type": "JiraNavigationItem",
                "abstractKey": "__isJiraNavigationItem"
              }
            ],
            "type": "JiraAppNavigationItem"
          },
          (v31/*: any*/)
        ]
      }
    ]
  }
],
v41 = {
  "kind": "InlineFragment",
  "selections": [
    (v35/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v42 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v43 = {
  "kind": "ScalarField",
  "name": "boardType"
},
v44 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v45 = [
  (v34/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v42/*: any*/),
      (v21/*: any*/),
      (v17/*: any*/),
      (v43/*: any*/),
      {
        "concreteType": "JiraFavouriteValue",
        "kind": "LinkedField",
        "name": "favouriteValue",
        "plural": false,
        "selections": [
          (v44/*: any*/),
          (v35/*: any*/)
        ]
      },
      (v31/*: any*/)
    ],
    "type": "JiraBoard"
  },
  (v41/*: any*/)
],
v46 = [
  {
    "kind": "LinkedField",
    "name": "mostRecentlyViewedBoard",
    "plural": false,
    "selections": (v45/*: any*/)
  },
  (v35/*: any*/)
],
v47 = [
  (v21/*: any*/),
  (v22/*: any*/),
  (v24/*: any*/),
  (v30/*: any*/),
  (v28/*: any*/),
  (v35/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  (v44/*: any*/),
  (v23/*: any*/),
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  (v29/*: any*/),
  (v31/*: any*/),
  {
    "kind": "LinkedField",
    "name": "navigationMetadata",
    "plural": false,
    "selections": [
      (v34/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v42/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isSimpleBoard"
          },
          (v35/*: any*/)
        ],
        "type": "JiraSoftwareProjectNavigationMetadata"
      }
    ]
  },
  {
    "kind": "LinkedField",
    "name": "mostRecentlyViewedBoard",
    "plural": false,
    "selections": [
      (v34/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v42/*: any*/),
          (v43/*: any*/)
        ],
        "type": "JiraBoard"
      },
      (v41/*: any*/)
    ]
  },
  (v25/*: any*/),
  (v26/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v14/*: any*/),
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "jiraSoftwareNavigationItems",
                    "plural": false,
                    "selections": (v15/*: any*/)
                  },
                  "action": "THROW",
                  "path": "jira.jiraSoftwareNavigationItems"
                }
              ]
            },
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": (v16/*: any*/),
                  "kind": "LinkedField",
                  "name": "containerNavigation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitTabList_TabList_containerFragment"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitAddTab_AddTab"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v17/*: any*/),
                        (v18/*: any*/),
                        {
                          "args": (v19/*: any*/),
                          "concreteType": "JiraNavigationItemConnection",
                          "kind": "LinkedField",
                          "name": "navigationItems",
                          "plural": false,
                          "selections": (v15/*: any*/)
                        }
                      ],
                      "type": "JiraContainerNavigation"
                    }
                  ]
                }
              ]
            },
            {
              "condition": "isTmpRoute",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "boardId",
                      "variableName": "boardIdTemp"
                    },
                    (v12/*: any*/),
                    {
                      "kind": "Variable",
                      "name": "hasBoardId",
                      "variableName": "hasBoardId"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectAri",
                      "variableName": "projectAri"
                    },
                    {
                      "kind": "Variable",
                      "name": "projectKey",
                      "variableName": "projectKey"
                    },
                    {
                      "kind": "Variable",
                      "name": "useProjectAri",
                      "variableName": "useProjectAri"
                    },
                    {
                      "kind": "Variable",
                      "name": "useProjectKey",
                      "variableName": "useProjectKey"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "boardHeader_horizontalNavHeader"
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": "projectDataByKey",
                    "args": (v20/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProjectByKey",
                    "plural": false,
                    "selections": (v32/*: any*/)
                  },
                  "action": "THROW",
                  "path": "jira.projectDataByKey"
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "condition": "useProjectAri",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": "projectDataById",
                        "args": (v33/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProject",
                        "plural": false,
                        "selections": (v32/*: any*/)
                      },
                      "action": "THROW",
                      "path": "jira.projectDataById"
                    }
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v9/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v14/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jiraSoftwareNavigationItems",
                "plural": false,
                "selections": (v40/*: any*/)
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v16/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  (v34/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraContainerNavigationResult"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v35/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "args": (v19/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": (v40/*: any*/)
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  (v41/*: any*/)
                ]
              }
            ]
          },
          {
            "condition": "isTmpRoute",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "hasBoardId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "boardIdTemp"
                      }
                    ],
                    "kind": "LinkedField",
                    "name": "jiraBoard",
                    "plural": false,
                    "selections": (v45/*: any*/)
                  }
                ]
              },
              {
                "condition": "useProjectKey",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "hasBoardId",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "projectDataByKey",
                        "args": (v20/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProjectByKey",
                        "plural": false,
                        "selections": (v46/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "useProjectAri",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "hasBoardId",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": "projectDataById",
                        "args": (v33/*: any*/),
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "jiraProject",
                        "plural": false,
                        "selections": (v46/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "projectDataByKey",
                "args": (v20/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": (v47/*: any*/)
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "useProjectAri",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "projectDataById",
                    "args": (v33/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProject",
                    "plural": false,
                    "selections": (v47/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d872a2224078a5211b7a3cd4e805eb457a02f0382476b7930c707b68a23f378c",
    "metadata": {},
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c0eb5df9bfa71243a2566f7ce20359b4";

export default node;
