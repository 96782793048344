import type { Action } from '@atlassian/react-sweet-state';
import type { LayoutState } from '../../../../common/types';

export const setIsChromeless =
	(isChromeless: boolean): Action<LayoutState> =>
	({ setState }) => {
		setState({
			isChromeless,
		});
	};
