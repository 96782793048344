import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';

/**
 * Maps a given project type to its corresponding sub-product. This function is designed to
 * facilitate the identification of product categories based on project characteristics.
 * Special cases are handled explicitly, with a fallback to 'unknown' for unlisted types,
 * ensuring broad applicability across different project contexts.
 */
export const subProductFromProjectType = (projectType: ProjectType): SubProduct => {
	switch (projectType) {
		case 'business':
			return 'core';
		case 'product_discovery': // for now, product discovery/polaris is bound to JSW
		case 'software':
			return 'software';
		case 'service_desk':
			return 'serviceDesk';
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(projectType as never);
			return 'unknown';
	}
};

export const useSubProduct = () => {
	const [{ data }] = useContainerContext();

	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	if (!data?.project) return null;

	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	return subProductFromProjectType(data.project.type);
};
