import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type JiraSoftwareListType from '@atlassian/jira-spa-apps-jsw-list';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';

export const LazyNextGenList = lazyForPaint<typeof JiraSoftwareListType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-nextgen-list", jiraSpaEntry: "async-nextgen-list" */ '@atlassian/jira-spa-apps-jsw-list'
		),
	),
);

export const LazyNextGenListEmbed = lazyForPaint<typeof JiraSoftwareListType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-nextgen-list-embed", jiraSpaEntry: "async-nextgen-list-embed" */ '@atlassian/jira-spa-apps-jsw-list-embed'
		),
	),
);

export const SoftwareListPage = ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyNextGenList}
		pageId={`next-gen-list-${match.params.boardId || ''}`}
		shouldShowSpinner={false}
	/>
);

export const SoftwareListEmbedPage = ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyNextGenListEmbed}
		pageId={`next-gen-list-${match.params.boardId || ''}-embed`}
		shouldShowSpinner={false}
	/>
);
