import React, { memo, useCallback } from 'react';
import StarFilledIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import StarIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import { Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { NavigationProject } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import messages from './messages';
import { Button } from './styled';

type Props = {
	project: NavigationProject;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onToggle: (favorite: boolean) => any;
};

const FavoriteButton = ({ project, onToggle }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const label = project.isFavorite ? formatMessage(messages.unstar) : formatMessage(messages.star);

	const onClick = useCallback(() => {
		const star = !project.isFavorite;

		onToggle(star);

		fireUIAnalytics(createAnalyticsEvent({}), 'project starred', 'sidebar', {
			projectId: project.id,
			star,
		});
	}, [project, onToggle, createAnalyticsEvent]);

	return (
		<Box padding="space.025">
			<Tooltip content={label} hideTooltipOnClick>
				<Button
					isFavorite={project.isFavorite ?? false}
					onClick={onClick}
					data-testid="navigation-apps-sidebar-core.common.ui.favorite-button"
				>
					{project.isFavorite ? (
						<StarFilledIcon color="currentColor" label={label} />
					) : (
						<StarIcon color="currentColor" label={label} />
					)}
				</Button>
			</Tooltip>
		</Box>
	);
};

export default memo(FavoriteButton);
