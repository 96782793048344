import { useEffect } from 'react';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

type PartialOrNull<T> = {
	[P in keyof T]?: T[P] | null;
};

export type Actions = typeof actions;

export const initialState: State = {};

const Store = createStore<State, Actions>({
	name: 'project-properties',
	initialState,
	actions,
});

/**
 * Exported for testing purposes only.
 * Use `useProjectProperties` instead.
 */
export const useProjectPropertiesStore = createHook(Store);

export const useProjectProperties = <ReturnType extends Record<string, unknown>>(
	propertyKeys: (keyof ReturnType)[],
	projectIdOverride?: string,
): PartialOrNull<ReturnType> => {
	const { data } = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectId = projectIdOverride !== undefined ? projectIdOverride : data?.id && `${data.id}`;

	const [state, { get }] = useProjectPropertiesStore();
	const prevPropertyKeys = usePrevious(propertyKeys);

	useEffect(() => {
		if (projectId === undefined) {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'jiraProjectPropertiesServices undefinedProjectId',
			);
		}
		if (isEqual(propertyKeys, prevPropertyKeys) && !projectIdOverride) {
			return;
		}
		if (projectId) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			get(projectId, propertyKeys as string[]);
		}
	}, [projectId, propertyKeys, prevPropertyKeys, get, createAnalyticsEvent, projectIdOverride]);

	return projectId !== undefined
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(pickBy(state[projectId], (_, key: string) =>
				includes(propertyKeys, key),
			) as PartialOrNull<ReturnType>)
		: {};
};
