import queryString from 'query-string';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { PACKAGE_NAME } from '../../../constants';
import type { Page, Workflow } from '../../../types';
import { toApiUrlTransformer, fromUrlTransformer } from '../../../utils/transformers';

export const getListWorkFlows = async (qs: string): Promise<Page<Workflow>> => {
	const filter = fromUrlTransformer(queryString.parse(qs));
	const query = queryString.stringify(toApiUrlTransformer(filter));

	try {
		return await performGetRequest(`/rest/api/3/workflow/search?${query}`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'getWorkflowsSearchAPI',
				packageName: PACKAGE_NAME,
				teamName: 'helix',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};
