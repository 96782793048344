import React, { useMemo } from 'react';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ROUTE_NAMES_POLARIS_IDEAS,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { V4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/nav-state';
import { SIDEBAR } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-container';
import { GLOBAL } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer';
import AsyncBusinessCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-business-company-managed-project-settings';
import AsyncBusinessTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-business-team-managed-project-settings';
import { AsyncJsmCmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-jsm-cmp-project-settings';
import { AsyncJsmTmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-jsm-tmp-project-settings';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-personal-settings';
import AsyncPlanSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-plan-settings/src/ui/index.tsx';
import { Skeleton as ProjectSettingsSkeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/common/ui/skeleton';
import { useIsConnectAppActive as useIsProjectSettingsConnectAppActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-common/src/controllers/is-connect-app-active';
import AsyncJpdProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-project-settings-jpd-settings/src/ui/index.tsx';
import { getNav4Rollout } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-nav4-rollout/index.tsx';
import AsyncSidebarContent from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebar-content/src/async';
import {
	NavigateBackProvider,
	useNavigateBack,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-back-button/src/controllers/navigate-back-context/index.tsx';
import AsyncBoardSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-board/src/ui/index.tsx';
import { useGlobalAppsSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-apps/src/controllers/global-settings/index.tsx';
import AsyncGlobalAppsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-apps/src/ui/index.tsx';
import AsyncGlobalIssuesSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-issues/src/ui/index.tsx';
import AsyncGlobalProductsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-products/src/ui/index.tsx';
import AsyncGlobalProjectsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-projects/src/ui/index.tsx';
import { useGlobalSystemSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-system/src/controllers/global-settings/index.tsx';
import AsyncGlobalSystemSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-system/src/ui/index.tsx';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-help-center/src/ui/index.tsx';
import AsyncSoftwareCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-software-company-managed-project-settings';
import AsyncSoftwareTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-software-team-managed-project-settings';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants';
import { useRouter } from '@atlassian/react-resource-router';

const ProjectSettingsSidebarSelector = () => {
	const { data: project, loading, error } = useProjectContext();

	let sidebar = null;
	if (loading) {
		sidebar = <ProjectSettingsSkeleton />;
	}

	if (project?.projectType === CORE_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncBusinessTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncBusinessCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === SOFTWARE_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncSoftwareTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncSoftwareCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === SERVICE_DESK_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncJsmTmpProjectSettingsSidebar project={project} />
		) : (
			<AsyncJsmCmpProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === PRODUCT_DISCOVERY_PROJECT) {
		sidebar = <AsyncJpdProjectSettingsSidebar project={project} />;
	}

	if (project?.projectType === CUSTOMER_SERVICE_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncJsmTmpProjectSettingsSidebar project={project} />
		) : (
			<AsyncJsmCmpProjectSettingsSidebar project={project} />
		);
	}

	if (error) {
		throw error;
	}

	if (!sidebar) {
		throw new Error(
			`No project settings sidebar found for the project type: ${project?.projectType}`,
		);
	}

	return (
		<ContextualAnalyticsData
			containerId={project ? String(project.projectId) : undefined}
			containerType="project"
		>
			{sidebar}
		</ContextualAnalyticsData>
	);
};

const InternalSidebarSelector = () => {
	const [
		{
			match: { params },
			route,
			location,
			query,
		},
	] = useRouter();
	const { saveCurrentRoute } = useNavigateBack();
	const { isActive: isGlobalAppsSettingsConnectItemActive } =
		useGlobalAppsSettingsConnectItemActive();
	const { isActive: isGlobalSystemSettingsConnectItemActive } =
		useGlobalSystemSettingsConnectItemActive();
	const { isActive: isProjectSettingsConnectAppActive } = useIsProjectSettingsConnectAppActive();
	const { shouldUseJSM } = useOgTenantInfo();

	switch (route.navigation?.sidebarId) {
		case SIDEBAR_ID.PLAN_SETTINGS:
			return <AsyncPlanSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_APPS_SETTINGS:
			if (!location.pathname.includes('/discover')) {
				return <AsyncGlobalAppsSettingsSidebar />;
			}
			break;
		case SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS:
			return <AsyncGlobalProjectsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS:
			return <AsyncGlobalIssuesSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS:
			if (
				location.pathname.includes('/jira-service-management-chatops-and-video-tools') &&
				shouldUseJSM &&
				fg('fix_chatops_new_nav_bug')
			) {
				break;
			}
			return <AsyncGlobalProductsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS:
			return <AsyncGlobalSystemSettingsSidebar />;
		case SIDEBAR_ID.PERSONAL_SETTINGS:
			return <AsyncPersonalSettingsSidebar />;
		case SIDEBAR_ID.HELP_CENTER_SETTINGS:
			return <AsyncHelpCenterSettingsSidebar />;
		case SIDEBAR_ID.PROJECT_SETTINGS:
			return <ProjectSettingsSidebarSelector />;
		default:
			break;
	}

	// Project settings sidebars
	if (
		(route.name === ROUTE_NAMES_POLARIS_IDEAS && params.section === 'settings') ||
		isProjectSettingsConnectAppActive
	) {
		return <ProjectSettingsSidebarSelector />;
	}

	// Board settings sidebar
	if (
		route.group === ROUTE_GROUPS_CLASSIC_SOFTWARE &&
		BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(query.config) &&
		ff('simplified-boards-milestone-2_07hbk')
	) {
		return <AsyncBoardSettingsSidebar />;
	}

	// Global settings sidebars
	if (isGlobalAppsSettingsConnectItemActive) {
		return <AsyncGlobalAppsSettingsSidebar />;
	}
	if (isGlobalSystemSettingsConnectItemActive) {
		return <AsyncGlobalSystemSettingsSidebar />;
	}

	// the route to navigate back to will be whichever route was last displayed
	// with the default sidebar on
	saveCurrentRoute();
	return <AsyncSidebarContent />;
};

export const SidebarSelector = () => {
	const attributes = useMemo(
		() => ({
			navigationLayer: GLOBAL,
			navigationContainer: SIDEBAR,
			navState: V4,
			stage: getNav4Rollout().stage,
		}),
		[],
	);

	return (
		<NavigateBackProvider>
			<ContextualAnalyticsData attributes={attributes}>
				<InternalSidebarSelector />
			</ContextualAnalyticsData>
		</NavigateBackProvider>
	);
};
