import { useCallback } from 'react';
import type { Theme } from '@atlassian/jira-common-constants/src/theme-preference';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useResource, createResource } from '@atlassian/react-resource-router';
import {
	THEME_PREFERENCES_ANALYTICS_SUBJECT,
	THEME_PREFERENCES_ANALYTICS_ACTION,
} from '../../common/constants';
import { setThemePreference } from '../../services';
import type { ThemePreferenceResource } from './types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-router-resources-theme-preference", jiraSpaEntry: "async-router-resources-theme-preference" */ './get-data'
	);

export const themePreferenceResource = createResource<ThemePreferenceResource>({
	type: 'RESOURCE_THEME_PREFERENCE',
	getKey: () => 'state',
	getDataLoader,
	maxAge: 5 * 60 * 1000,
});

export const useThemePreference = () => {
	const { data, update: updateThemePreferenceResource } = useResource(themePreferenceResource);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireThemeAnalytics = useCallback(
		(current?: Theme, next?: Theme) => {
			const OStheme =
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light';
			const analyticsEvent = createAnalyticsEvent({
				action: THEME_PREFERENCES_ANALYTICS_ACTION,
				actionSubject: THEME_PREFERENCES_ANALYTICS_SUBJECT,
			});
			fireUIAnalytics(analyticsEvent, {
				newPreference: next,
				oldPreference: current,
				OStheme,
			});
		},
		[createAnalyticsEvent],
	);

	const setTheme = useCallback(
		async (theme: Theme): Promise<void> => {
			updateThemePreferenceResource(() => ({ theme }));
			if (data?.theme !== theme) {
				fireThemeAnalytics(data?.theme, theme);
				await setThemePreference(theme);
			}
		},
		[data?.theme, updateThemePreferenceResource, fireThemeAnalytics],
	);

	return {
		theme: data?.theme,
		setTheme,
	};
};
