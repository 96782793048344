import { DisplayTypes } from './types';

export const SIDE_PANEL_WIDTH = 0.5;

export const getDisplayInformation = () => {
	/*
	We set the screen width to 50% of the screen width if the screen
	width is larger than 1024px because we want the Embedded Confluence in the side panel to
	take up half the screen width. The max of the side panel is 50% of the screen width and
	we are not able to set it wider. Jira Issue details panel will be forced down around 1875px
	screen width.
	If the screen width is less than 1024px, we'll set the panel width to 0 because we will be
	opening the EmbeddedContentModal instead and set displayType to Modal.
	This also assumes that we are currently on the full page version of a Jira Issue.
	*/

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const screenWidth = window.innerWidth;

	if (screenWidth >= 1024) {
		return { initialWidth: screenWidth * SIDE_PANEL_WIDTH, displayType: DisplayTypes.PANEL };
	}

	return { initialWidth: 0, displayType: DisplayTypes.MODAL };
};
