import { useMemo } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { type CustomTheme, getCustomThemeStyles } from './get-custom-theme-styles';

/**
 * Processes the provided theme and returns theme styles if possible.
 */
export function useCustomTheme(
	theme: CustomTheme | undefined,
): { isEnabled: false } | { isEnabled: true; style: React.CSSProperties } {
	const style = useMemo(() => {
		/**
		 * TODO: After cleaning up this flag we may want to remove `isEnabled`
		 * and just use `Boolean(theme)` directly.
		 */
		if (!fg('platform_design_system_nav4_top_bar_theming')) {
			return null;
		}

		if (!theme) {
			return null;
		}

		return getCustomThemeStyles(theme);
	}, [theme]);

	if (!style) {
		return { isEnabled: false };
	}

	return {
		isEnabled: true,
		style,
	};
}
