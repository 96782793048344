import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { customerServiceProductsQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-products/src/services/index.tsx';
import { productsRoute } from '@atlassian/jira-router-routes-servicedesk-customer-service-products-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import type { ProductsAppLayout as ServicedeskCustomerServiceProductsType } from '@atlassian/jira-spa-apps-servicedesk-customer-service-products/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

const LazyServicedeskCustomerServiceProducts = lazyForPaint<
	typeof ServicedeskCustomerServiceProductsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customer-service-products", jiraSpaEntry: "async-servicedesk-customer-service-products" */ '@atlassian/jira-spa-apps-servicedesk-customer-service-products'
		),
	),
);

export const ServicedeskCustomerServiceProducts = () => (
	<LazyPage
		Page={LazyServicedeskCustomerServiceProducts}
		pageId="spa-apps/servicedesk/customer-service-products"
		shouldShowSpinner
	/>
);

export const customerServiceProductsRouteEntry = createEntry(productsRoute, () => ({
    layout: serviceProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncDirectoryNav,
		// TODO: Remove this during jira_nav4 cleanup
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		customerServiceProductsQueryResource,
	],

    component: ServicedeskCustomerServiceProducts,
    perfMetricKey: 'jsm-customer-service-products-component-load',
    forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerServiceProducts],

    meta: {
		reporting: {
			id: APP_NAMES.CUSTOMER_SERVICE_PRODUCTS,
			packageName: toPackageName(APP_NAMES.CUSTOMER_SERVICE_PRODUCTS),
			teamName: 'boysenberry',
		},
	}
}));
