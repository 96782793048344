import React, { type ComponentPropsWithoutRef } from 'react';
import { EntryPointContainer, type PreloadedEntryPoint } from 'react-relay';
import type {
	AnyEntryPoint,
	ComponentOfEntryPoint,
} from '@atlassian/jira-entry-point/src/common/types.tsx';
import {
	type JSErrorBoundaryProps,
	JSErrorBoundary,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import UFOSegment from '@atlassian/jira-ufo-segment';

type EntryPointContainerProps<TEntryPoint extends AnyEntryPoint> = ComponentPropsWithoutRef<
	typeof EntryPointContainer<PreloadedEntryPoint<ComponentOfEntryPoint<TEntryPoint>>>
>;
type PlaceholderProps = ComponentPropsWithoutRef<typeof Placeholder>;

export type InternalEntryPointContainerProps<TEntryPoint extends AnyEntryPoint> = {
	entryPointReference: EntryPointContainerProps<TEntryPoint>['entryPointReference'];
	errorAttributes?: JSErrorBoundaryProps['attributes'];
	errorFallback?: JSErrorBoundaryProps['fallback'];
	fallback?: PlaceholderProps['fallback'];
	id: JSErrorBoundaryProps['id'];
	packageName?: JSErrorBoundaryProps['packageName'];
	placeholderName?: PlaceholderProps['name'];
	runtimeProps: EntryPointContainerProps<TEntryPoint>['props'];
	teamName?: JSErrorBoundaryProps['teamName'];
};

export const InternalEntryPointContainer = <TEntryPoint extends AnyEntryPoint>({
	entryPointReference,
	errorAttributes,
	errorFallback,
	fallback,
	id,
	packageName,
	placeholderName,
	runtimeProps,
	teamName,
}: InternalEntryPointContainerProps<TEntryPoint>) => (
	/* eslint-disable-next-line jira/ufo/valid-labels */
	<UFOSegment name={id}>
		<JSErrorBoundary
			id={id}
			packageName={packageName}
			teamName={teamName}
			attributes={errorAttributes}
			fallback={errorFallback}
		>
			<Placeholder
				name={placeholderName ?? packageName ?? 'internal-entry-point-container'}
				fallback={fallback}
			>
				<EntryPointContainer entryPointReference={entryPointReference} props={runtimeProps} />
			</Placeholder>
		</JSErrorBoundary>
	</UFOSegment>
);
