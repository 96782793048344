import React, { useMemo } from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { useFeatures } from '../../../controllers/features';
import messages from './messages';
import { isIncomingMailSelected } from './utils';

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const MailSection = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	if (features == null) return <SkeletonItem />;

	return (
		<Section title={formatMessage(messages.mailGroup)}>
			{features.hasGlobalMailSettings && (
				<MenuItem
					analytics={{ itemId: 'global-mail-settings' }}
					href={navigationPath.mailSectionGlobalMailSettings}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.globalMailSettings)}
				</MenuItem>
			)}
			{features.hasOutgoingMail && (
				<MenuItem
					analytics={{ itemId: 'outgoing-mail' }}
					href={navigationPath.mailSectionOutgoingMail}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.outgoingMail)}
				</MenuItem>
			)}
			{features.hasIncomingMail && (
				<MenuItem
					analytics={{ itemId: 'incoming-mail' }}
					href={navigationPath.mailSectionIncomingMail}
					selectedOn={isIncomingMailSelected}
				>
					{formatMessage(messages.incomingMail)}
				</MenuItem>
			)}
			{features.hasSendMail && (
				<MenuItem
					analytics={{ itemId: 'send-email' }}
					href={navigationPath.mailSectionSendMail}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.sendEmail)}
				</MenuItem>
			)}
		</Section>
	);
};
