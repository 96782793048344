import {
	ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
	ROUTE_GROUPS_SERVICEDESK,
} from '@atlassian/jira-common-constants/src/spa-routes';

export const servicedeskCustomersV2Route = {
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	group: ROUTE_GROUPS_SERVICEDESK,
	path: '/jira/servicedesk/projects/:projectKey/customers',
	exact: true,
};

export const servicedeskCustomerDetailsRoute = {
	name: ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
	group: ROUTE_GROUPS_SERVICEDESK,
	path: '/jira/servicedesk/projects/:projectKey/customers/:customerId',
	exact: true,
};
