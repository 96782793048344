import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyFormSubmitView = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-form-submit", jiraSpaEntry: "async-software-form-submit" */ '@atlassian/jira-spa-apps-software-form-submit'
		),
);

export const FormSubmitPage = () => (
	<LazyPage Page={LazyFormSubmitView} pageId="async-software-form-submit" shouldShowSpinner />
);
