import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { CLASSIC_BOARD, COMPANY_MANAGED_BOARD } from '@atlassian/jira-capabilities/src/constants';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts, createLayout } from '@atlassian/jira-route-layout';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	classicSoftwareBoardRoute,
	classicSoftwareBoardEmbedRoute,
} from '@atlassian/jira-router-routes-software-classic-board-routes';
import {
	UIFBoardSpa,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/async';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';

/**
 * Ordinarily it would be inadvisable to evaluate something like this in
 * a static route, as this could lead to inconsistent behaviour between
 * the server and the browser.
 *
 * In this case it is necessary and safe because:
 * 1) There are two versions of the board that share the same route
 *    and we can only tell them apart at runtime. When the old version
 *    is eventually removed, this should be cleaned up.
 * 2) No requests for board data are made on the server, so even
 *    if the value is incorrect on the server it won't be utilised
 * */
const classicBoardCapability = !__SERVER__ ? COMPANY_MANAGED_BOARD : CLASSIC_BOARD;

export const classicSoftwareBoardRouteEntry = createEntry(classicSoftwareBoardRoute, () => ({
    component: RapidBoardSpaPage,
    skeleton: ClassicBoardSkeleton,

    layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{ query: ['selectedIssue', 'view=detail'] },
			{ query: ['selectedIssue', 'modal=detail'] },
		]),
	),

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
		resourceWithCondition2(
			() => shouldLoadUIFBoardResources() && !isFedRamp(),
			nextBestTaskFeatureResource,
		),
		getEditModelResource(),
		getBoardDetailsResource(),
	],

    afterPaint: [LazyIssueApp],
    forPaint: [LazyAtlassianNavigation, UIFBoardSpa],

    preloadOptions: {
		earlyChunks: ['rapidboard-early-entry'],
	},

    meta: {
		capability: classicBoardCapability,
	},

    earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY
}));

export const classicSoftwareBoardEmbedRouteEntry = createEntry(classicSoftwareBoardEmbedRoute, () => ({
    component: componentWithFG('jira_board_smartlink_embed', RapidBoardSpaPage, ErrorPagesNotFound),

    layout: createLayout({
		isChromeless: true,
	}),

    resources: [
		...getNavigationSidebarProjectResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
		resourceWithCondition2(
			() => shouldLoadUIFBoardResources() && !isFedRamp(),
			nextBestTaskFeatureResource,
		),
		getEditModelResource(),
		getBoardDetailsResource(),
		themePreferenceResource,
	],

    afterPaint: [LazyIssueApp],
    forPaint: [UIFBoardSpa],

    meta: {
		capability: classicBoardCapability,
	}
}));
