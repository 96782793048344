import { useEffect, useMemo } from 'react';
import {
	useIncomingMailExpiryStatus,
	DEFAULT_RESPONSE,
} from '@atlassian/jira-router-resources-incoming-mail-expiry-status/src/services/index.tsx';
import { BANNER_NAME, HEIGHT } from '../../constants';
import { useBannerActions, useBannerState } from '../use-banner-store';

export const useBannerMailExpiry = () => {
	const { responseStatus, state } = useIncomingMailExpiryStatus();
	const { onStateFetch } = useBannerActions();
	const { isBannerShown } = useBannerState();

	useEffect(() => {
		if (responseStatus === 'error') {
			onStateFetch(DEFAULT_RESPONSE);
			return;
		}

		if (responseStatus === 'success') {
			onStateFetch(state);
		}
	}, [onStateFetch, responseStatus, state]);

	return useMemo(
		() => ({
			isEnabled: isBannerShown,
			name: BANNER_NAME,
			height: HEIGHT,
		}),
		[isBannerShown],
	);
};
