import type React from 'react';

import { themedLogoIcon, themedLogoText } from '../nav-logo';

import {
	themedButtonBackground,
	themedButtonBackgroundHovered,
	themedButtonBackgroundPressed,
	themedButtonBorder,
	themedButtonPrimaryBackground,
	themedButtonPrimaryBackgroundHovered,
	themedButtonPrimaryBackgroundPressed,
	themedButtonPrimaryText,
	themedButtonSelectedBackground,
	themedButtonSelectedBackgroundHovered,
	themedButtonSelectedBackgroundPressed,
	themedButtonSelectedText,
} from './button';
import { getTextColor, hexToRGB, isLight, toRGBAString } from './color-utils';

export type CustomTheme = {
	backgroundColor: string;
	highlightColor: string;
};

// Using raw hex codes because custom colors break out of the tokens model
// We will need to invert the colors based on backgroundColor independently of the app's color mode (dark/light)
// Values here are not final
const buttonStyles = {
	light: {
		[themedButtonBackground]: 'transparent',
		[themedButtonBackgroundHovered]: '#0515240F',
		[themedButtonBackgroundPressed]: '#0B120E24',
		[themedButtonBorder]: '#0B120E24',
		[themedButtonSelectedText]: '#1868DB', // Blue700
		[themedButtonSelectedBackground]: '#E9F2FE', // Blue100
		[themedButtonSelectedBackgroundHovered]: '#CFE1FD', // Blue200
		[themedButtonSelectedBackgroundPressed]: '#8FB8F6', // Blue300
	} as React.CSSProperties,
	dark: {
		[themedButtonBackground]: 'transparent',
		[themedButtonBackgroundHovered]: '#CECED912',
		[themedButtonBackgroundPressed]: '#E3E4F21F',
		[themedButtonBorder]: '#E3E4F21F',
		[themedButtonSelectedText]: '#669DF1', // Blue400
		[themedButtonSelectedBackground]: '#1C2B42', // Blue1000
		[themedButtonSelectedBackgroundHovered]: '#123263', // Blue900
		[themedButtonSelectedBackgroundPressed]: '#1558BC', // Blue800
	} as React.CSSProperties,
};

const logoStyles = {
	light: {
		[themedLogoIcon]: '#101214', // PrimaryBlack
		[themedLogoText]: '#101214', // PrimaryBlack
	} as React.CSSProperties,
	dark: {
		[themedLogoIcon]: '#FFFFFF', // PrimaryWhite
		[themedLogoText]: '#FFFFFF', // PrimaryWhite
	} as React.CSSProperties,
};

export function getCustomThemeStyles({
	backgroundColor,
	highlightColor,
}: CustomTheme): React.CSSProperties {
	const isLightBackground = isLight(backgroundColor);
	const color = getTextColor(backgroundColor);

	const highlightTextColor = getTextColor(highlightColor);

	const highlightRgb = hexToRGB(highlightColor);

	return {
		backgroundColor,
		color,
		...buttonStyles[isLightBackground ? 'light' : 'dark'],
		...logoStyles[isLightBackground ? 'light' : 'dark'],
		[themedButtonPrimaryBackground]: highlightColor,
		/**
		 * These interaction states are the same as nav3
		 */
		[themedButtonPrimaryBackgroundHovered]: toRGBAString(highlightRgb, 0.8),
		[themedButtonPrimaryBackgroundPressed]: toRGBAString(highlightRgb, 0.65),
		[themedButtonPrimaryText]: highlightTextColor,
	} as React.CSSProperties;
}
