import { useEffect, useMemo } from 'react';
import type { DataState } from '@atlassian/jira-common-services/src/types';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_SERVICEDESK_ANALYTICS_CONTEXT } from '../constants';
import type { ServiceDeskAnalyticAttributes } from './get-data/types';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-analytics-context", jiraSpaEntry: "async-resource-servicedesk-analytics-context" */ './get-data'
	);

const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serviceDeskAnalyticsContextResource = createResource<any>({
	type: RESOURCE_TYPE_SERVICEDESK_ANALYTICS_CONTEXT,
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

export const useServiceDeskAnalyticsContext: () => DataState<ServiceDeskAnalyticAttributes> =
	() => {
		const { data, loading, error, refresh } = useResource(serviceDeskAnalyticsContextResource);

		useEffect(() => {
			if (!data && !loading && !error) {
				refresh();
			}
		}, [data, loading, error, refresh]);

		return useMemo(
			() => ({
				data,
				loading,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: error as Error,
			}),
			[data, loading, error],
		);
	};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ServiceDeskAnalyticAttributes } from './get-data/types';
