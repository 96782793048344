import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ServicedeskReportsType from '@atlassian/jira-spa-apps-servicedesk-reports';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteContext } from '@atlassian/react-resource-router';

export const LazyServicedeskReports = lazyForPaint<typeof ServicedeskReportsType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-reports", jiraSpaEntry: "async-servicedesk-reports" */ '@atlassian/jira-spa-apps-servicedesk-reports'
		),
);
type Props = RouteContext;
/**
 * Dynamically loads the ServicedeskReports component, enhancing user experience by using deferred loading with a visual feedback.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: Props) => {
	const oldIssueView = `/${(match.query && match.query.oldIssueView) || ''}`;
	return (
		<LazyPage
			Page={LazyServicedeskReports}
			pageId={`spa-apps/servicedesk/reports/${match ? match.url : ''}${oldIssueView}`}
			shouldShowSpinner
		/>
	);
};
