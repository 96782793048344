import type {
	DashboardAMDModulesResourceData,
	AmdResourceSharedValue,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
// eslint-disable-next-line jira/import-whitelist
import { asyncWrmRequire } from '@atlassian/jira-wrm/src/async-wrm.tsx';
import type { RouterDataContext } from '@atlassian/react-resource-router';
import { SharedValueAggregateError, type DataOrErrorOrNull } from '../common/dependent-resources';
import { evalAmdModules, loadAmdModules } from '../common/load-eval-amd-modules';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		_maybePrevData: DataOrErrorOrNull<DashboardAMDModulesResourceData>,
		sharedValue: DataOrErrorOrNull<AmdResourceSharedValue>,
	) =>
	({ isPrefetch }: RouterDataContext): Promise<DashboardAMDModulesResourceData> => {
		if (!sharedValue || sharedValue instanceof Error) {
			throw new SharedValueAggregateError({
				sharedValue,
				isPrefetch,
				name: 'AMD modules resource',
			});
		}

		return (async () => {
			const { moduleNames, isPreloaded } = sharedValue;
			const maybePromiseAmdModules =
				isPreloaded || (await loadAmdModules(asyncWrmRequire, moduleNames))
					? evalAmdModules(moduleNames)
					: null;
			const amdModules =
				maybePromiseAmdModules instanceof Promise
					? await maybePromiseAmdModules
					: maybePromiseAmdModules;

			return { sharedValue, amdModules };
		})();
	};
