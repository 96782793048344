import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalLayout } from '@atlassian/jira-global-layout';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async';
import { planLayout } from '@atlassian/jira-plan-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { arjSettingsFinancialYearResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/financial-year/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	archivedPlansRoute,
	trashedPlansRoute,
	trashedPlanDirectLinkRoute,
	planIncrementRoute,
	planIncrementsRoute,
	arjSettingsFinancialYearPageRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/ui/spa/index.tsx';
import { ArchivedPlansDirectory, LazyArchivedPlans } from './plan-archive';
import { PlanIncrement, LazyPlanIncrement } from './plan-increment';
import { PlanProgram, LazyPlanProgram } from './plan-program';
import { TrashedPlansDirectory, LazyTrashedPlans } from './plan-trash';
import {
	ArjSettingsFinancialYear,
	LazyArjSettingsFinancialYear,
} from './settings/financial-year/index.tsx';
import { TrashedPlanDirectLink, LazyTrashedPlanDirectLink } from './trashed-plan-direct-link';

// program route - redirect or empty state
export const planIncrementsRouteEntry = createEntry(
	{ ...planIncrementsRoute, exact: true },
	() => ({
        component: PlanProgram,
        layout: composeLayouts(globalLayout, planLayout),

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			advancedRoadmapsLastVisitedProgramBoardIdResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyPlanProgram]
    }),
);

// "Increment" board page
export const planIncrementRouteEntry = createEntry(
	{ ...planIncrementRoute, exact: true },
	() => ({
        component: PlanIncrement,
        layout: composeLayouts(globalLayout, planLayout),

        navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

        preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

        resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyPlanIncrement]
    }),
);

export const archivedPlansRouteEntry = createEntry(
	{ ...archivedPlansRoute, exact: true },
	() => ({
        component: ArchivedPlansDirectory,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyArchivedPlans]
    }),
);

export const trashPlansRouteEntry = createEntry(
	{ ...trashedPlansRoute, exact: true },
	() => ({
        component: TrashedPlansDirectory,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [...getNavigationResources(), navigationSidebarGlobalResource],
        forPaint: [LazyAtlassianNavigation, LazyTrashedPlans]
    }),
);

export const trashedPlanDirectLinkRouteEntry = createEntry(trashedPlanDirectLinkRoute, () => ({
    component: TrashedPlanDirectLink,
    layout: globalLayout,
    resources: [...getNavigationResources()],
    forPaint: [LazyAtlassianNavigation, LazyTrashedPlanDirectLink],
    ufoName: 'plan-trashed'
}));

export const arjSettingsFinancialYearPageEntry = createEntry(
	{ ...arjSettingsFinancialYearPageRoute, exact: true },
	() => ({
        component: ArjSettingsFinancialYear,
        layout: globalSettingsLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			arjSettingsFinancialYearResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyArjSettingsFinancialYear]
    }),
);
