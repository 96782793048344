import { useMemo } from 'react';
import { staticJSMProjectConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-jsm-connect-addons-queues/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectReportsSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-reports-connect-addons/index.tsx';
import { staticProjectSettingsSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-settings-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import type { ProjectSidebarAddonNavItem } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-connect-addons-navigation/types.tsx';
import type {
	JiraProductDiscoveryNavData,
	JiraServiceManagementNavData,
	JiraSoftwareNavData,
	JiraWorkManagementNavData,
	ProjectSidebarNavInfo,
} from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type {
	UseResourceCustomContext,
	UseResourceReturn,
} from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { useJiraSoftwareCMPNavigationSynchronizer } from './software-cmp-nav-synchronizer';
import type { QueueResourceCustomContext } from './types';

/**
 * This hook returns data needed for sidebar navigation. This hook is used across all products and calls navigation v4 api's
 * In JSW CMP projects, the navigation data is cached across project and board based url's
 */
export const useProjectSidebarNavigation = <RouteParams,>(
	customContext?: UseResourceCustomContext<RouteParams>,
): UseResourceReturn<ProjectSidebarNavInfo> => {
	const {
		data: navResponse,
		loading,
		error,
		refresh,
		clearAll,
		...rest
	} = useResourceWithCustomRouterContext<ProjectSidebarNavInfo | null, RouteParams>(
		staticProjectSidebarNavigationResource,
		customContext,
	);
	const memoizedResponse = useMemo(() => navResponse, [navResponse]);

	useJiraSoftwareCMPNavigationSynchronizer(memoizedResponse);

	return {
		data: memoizedResponse,
		loading: memoizedResponse != null ? false : loading,
		refresh,
		clearAll,
		error,
		...rest,
	};
};

export const useProjectSidebarConnectNavigation = () =>
	useResource<ProjectSidebarAddonNavItem[] | null>(
		staticProjectSidebarConnectAddonsNavigationResource,
	);

export const useProjectReportsSidebarConnectNavigation = () =>
	useResource<ProjectSidebarAddonNavItem[] | null>(
		staticProjectReportsSidebarConnectAddonsNavigationResource,
	);

export const useProjectSettingsSidebarConnectNavigation = () =>
	useResource<ProjectSidebarAddonNavItem[] | null>(
		staticProjectSettingsSidebarConnectAddonsNavigationResource,
	);

export const useJSMProjectConnectQueuesNavigation = () =>
	useResource<ProjectSidebarAddonNavItem[] | null>(staticJSMProjectConnectAddonsNavigationResource);

export const useJiraWorkmanagementProjectNavigation =
	(): UseResourceReturn<JiraWorkManagementNavData> => {
		const { data, ...rest } = useProjectSidebarNavigation();
		const response = data?.jiraWorkManagementNavData || null;
		return { data: response, ...rest };
	};

export const useJiraSoftwareProjectNavigation = (): UseResourceReturn<JiraSoftwareNavData> => {
	const { data, ...rest } = useProjectSidebarNavigation();
	const response = data?.jiraSoftwareNavData || null;
	return { data: response, ...rest };
};

export const useJiraServiceManagementProjectNavigation = (
	customContext?: QueueResourceCustomContext,
): UseResourceReturn<JiraServiceManagementNavData> => {
	const { data, ...rest } = useProjectSidebarNavigation(customContext);
	const response = data?.jiraServiceManagementNavData || null;
	return { data: response, ...rest };
};

export const useJiraProductDiscoveryProjectNavigation =
	(): UseResourceReturn<JiraProductDiscoveryNavData> => {
		const { data, ...rest } = useProjectSidebarNavigation();
		const response = data?.jiraProductDiscoveryNavData || null;
		return { data: response, ...rest };
	};
