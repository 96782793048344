import {
	ROUTE_GROUPS_EOC,
	ROUTE_NAMES_EOC_MAINTENANCE,
	ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const maintenanceRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_MAINTENANCE,
	path: '/jira/ops/teams/:teamId/maintenance',
	exact: true,
} as const as Route;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const globalMaintenanceRoute = {
	group: ROUTE_GROUPS_EOC,
	name: ROUTE_NAMES_EOC_GLOBAL_MAINTENANCE,
	path: '/jira/settings/products/ops/maintenance',
	exact: true,
} as const as Route;
