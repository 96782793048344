import type { Action } from '@atlassian/react-sweet-state';
import type { State } from './types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (experimentKey: string, value: boolean): Action<State> =>
	({ setState }) => {
		// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: { data: { value: boolean; }; loading: false; error: null; }; }' is not assignable to parameter of type 'Partial<State>'.
		setState({
			[experimentKey]: {
				data: { value },
				loading: false,
				error: null,
			},
		});
	};
