import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { qs } from 'url-parse';
import type GlobalAutomationType from '@atlassian/jira-spa-apps-automation';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Match, Query } from '@atlassian/react-resource-router';
import { RedirectToProjectType } from './project-type';

export const LazyGlobalAutomation = lazyForPaint<typeof GlobalAutomationType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-global-automation", jiraSpaEntry: "async-global-automation" */ '@atlassian/jira-spa-apps-automation'
		),
	),
);

export const AutomationRedirect = () => {
	const page = (_: Match, { selectedItem, ...rest }: Query) => {
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
		const querystring = qs.stringify(rest, true);
		return `/automation${querystring}`;
	};
	return <RedirectToProjectType page={page} />;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyGlobalAutomation} pageId="global-automation" shouldShowSpinner={false} />
);
