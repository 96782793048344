// eslint-disable-next-line jira/import-whitelist
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	getDefaultJql as getDefaultJqlPin,
	mapFilterToJql as mapFilterToJqlPin,
} from '@atlassian/jira-spa-apps-project-issue-navigator-utils';
import type { RouterDataContext, RouterContext } from '@atlassian/react-resource-router';

export const getParams = (
	{ match: { params }, query }: RouterDataContext | RouterContext,
	projectKey: string,
) => {
	const { issueKey } = params;

	const { filter, jql } = query;

	// Default JQL when no relevant query parameter is set
	const defaultJql = getDefaultJqlPin(toProjectKey(projectKey));

	return { page: 1, jql, issueKey, projectKey, filter, defaultJql };
};

export const getJqlOrFilterId = (
	projectKey: string,
	defaultJql: string,
	jqlParam?: string,
	filter?: string,
): { jql: string } | { filterId: string } => {
	// If JQL param has been defined (even if it's empty) we should use it
	if (typeof jqlParam === 'string') {
		return { jql: jqlParam };
	}
	// If filter is non-empty then use the filter parameter
	if (typeof filter === 'string' && filter !== '') {
		if (isFilterId(filter)) {
			return { filterId: filter };
		}

		const filterJql: string | undefined = mapFilterToJqlPin(projectKey, filter);

		if (filterJql !== undefined) {
			return { jql: filterJql };
		}
	}

	// Fallback to the default JQL if neither JQL or valid filter parameters have been provided.
	return { jql: defaultJql };
};
