import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { systemConfigurationGeneralResource } from '@atlassian/jira-router-resources-system-configuration-settings';
import {
	systemConfigurationRoute,
	routeNamesSystemConfigurationRedirectRoute,
} from '@atlassian/jira-router-routes-admin-pages-system-configuration-routes';
import type { Route } from '@atlassian/react-resource-router';
import SystemConfigurationGeneralRedirect from './common/ui/redirect/system-configuration-general-redirect';
import SystemConfigurationGeneral, {
	LazySystemConfigurationGeneral,
} from './ui/spa/admin-pages/system-configuration/async';

export const systemConfigurationRouteEntries: Route[] = [
	createEntry(systemConfigurationRoute, () => ({
        component: SystemConfigurationGeneral,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			systemConfigurationGeneralResource,
		],

        forPaint: [LazyAtlassianNavigation, LazySystemConfigurationGeneral]
    })),
	createEntry(routeNamesSystemConfigurationRedirectRoute, () => ({
        component: SystemConfigurationGeneralRedirect,

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

        resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			systemConfigurationGeneralResource,
		]
    })),
];
