import { useMemo } from 'react';
import { createHook } from '@atlassian/react-sweet-state';
import type { RightSidebar } from '../../../../common/types';
import { selectCurrentRightSidebar } from '../../actions/right-sidebar';
import { layoutStore, useLayoutStoreActions } from '../../store';

const useActiveRightSidebar = createHook(layoutStore, {
	selector: (state) => {
		const rightSidebarState = selectCurrentRightSidebar(state.content.rightSidebar);

		if (!rightSidebarState) return null;

		return {
			...rightSidebarState,
			shouldRender: !state.isChromeless,
		};
	},
});

type ExtendedRightSidebarState = {
	shouldRender: boolean;
} & RightSidebar;

export const useActiveRightSidebarState = (): ExtendedRightSidebarState | null => {
	const [rightSidebar] = useActiveRightSidebar();
	return rightSidebar;
};

export type UseRightSidebarStateType = typeof useActiveRightSidebarState;

const useRightSidebar = createHook(layoutStore, {
	selector: (state) => state.content.rightSidebar,
});

export const useRightSidebarState = () => {
	const [rightSidebarState] = useRightSidebar();
	return rightSidebarState;
};

export const useRightSidebarController = () => {
	const [
		,
		{
			setRightSidebarState,
			openRightSidebar,
			closeRightSidebar,
			closeCurrentRightSidebar,
			closeAllRightSidebar,
			minimiseRightSidebar,
		},
	] = useLayoutStoreActions();
	return useMemo(
		() => ({
			setRightSidebarState,
			closeRightSidebar,
			openRightSidebar,
			closeCurrentRightSidebar,
			closeAllRightSidebar,
			minimiseRightSidebar,
		}),
		[
			closeAllRightSidebar,
			closeCurrentRightSidebar,
			closeRightSidebar,
			openRightSidebar,
			setRightSidebarState,
			minimiseRightSidebar,
		],
	);
};

export type UseRightSidebarControllerType = typeof useRightSidebarController;
