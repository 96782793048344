import type { GraphQLError } from 'graphql';
import log from '@atlassian/jira-common-util-logging/src/log';

/**
 * Errors come in in with type unknown, but from the Sink interface:
 *
 * An error that has occured. Calling this function "closes" the sink.
 * Besides the errors being `Error` and `readonly GraphQLError[]`, it
 * can also be a `CloseEvent`, but to avoid bundling DOM typings because
 * the client can run in Node env too, you should assert the close event
 * type during implementation.
 *
 * error(error: unknown): void;
 */

export type SinkError = Error | readonly GraphQLError[] | CloseEvent;

export const getSinkError = (error: SinkError): Error => {
	let errorInfo: Error;
	if (Array.isArray(error)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[errorInfo] = error as readonly GraphQLError[];
	} else if (error instanceof Error) {
		errorInfo = error;
	} else if (typeof error === 'object' && 'type' in error && error.type === 'close') {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		errorInfo = new Error(`CloseEvent: ${(error as CloseEvent).reason}`);
	} else if (error instanceof Event) {
		/**
		 * As part of this investigation https://atlassian.slack.com/archives/CFG45A3C0/p1727746723536979 we want to
		 * understand which information is available in the event error object.
		 */
		const errorToLog: {
			errorCode: number | undefined;
			errorReason: string | undefined;
			errorType: string | undefined;
			errorStringify?: string;
		} = {
			errorCode: error?.code,
			errorReason: error?.reason,
			errorType: error?.type,
		};

		try {
			// wrapping stringify in try-catch to avoid throwing an error in case of circular references
			errorToLog.errorStringify = JSON.stringify(error);
		} catch (e) {
			errorToLog.errorStringify = 'Error while stringifying error';
		}

		log.unsafeErrorWithCustomerData(
			'jiraGraphqlSubscription.sinkEventError',
			`Event: ${error.type}`,
			errorToLog,
		);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		errorInfo = new Error(`Event: ${(error as Event).type}`);
	} else {
		errorInfo = new Error(`Unknown sink error: ${error}`);
	}

	return errorInfo;
};
