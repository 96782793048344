import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { JiraUserBoardNavigationData } from './types';

export const fetchUserBoardNavigationData = (
	boardId: string | number,
): Promise<JiraUserBoardNavigationData> => {
	if (boardId == null) {
		throw new Error('invalid boardId');
	}
	return performGetRequest(`/rest/navigation/4/sidebar/people/boards/${boardId}`);
};
