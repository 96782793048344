import type { GraphQLResponseWithData } from 'relay-runtime';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ForgeFetchError } from '../errors';

export const getTraceIdFromExtensions = ({
	extensions,
}: Pick<GraphQLResponseWithData, 'extensions'>): string | undefined => {
	const traceId = extensions?.gateway?.request_id;
	if (traceId && typeof traceId === 'string') {
		return traceId;
	}
	return undefined;
};

export const getTraceIdFromError = (
	error: Error | ForgeFetchError | FetchError,
): string | undefined => {
	if (error instanceof ForgeFetchError || error instanceof FetchError) {
		return error.traceId;
	}
	return undefined;
};
