import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { isFedRamp } from '@atlassian/atl-context';
import {
	createContactsResource,
	createNotificationPreferencesResource,
	eocUserResource,
	createMuteResource,
	createQuietHoursResource,
	createForwardingRulesResource,
} from '@atlassian/eoc-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { checkShouldUseJSM } from '@atlassian/jira-eoc-tenant-info/src/controllers/utils.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async';
import {
	LEGACY_PERSONAL_SETTINGS_PATH,
	GENERAL_PERSONAL_SETTINGS_PATH,
	getNotificationsPersonalSettingsPath,
} from '@atlassian/jira-personal-settings-paths';
import { createEntry } from '@atlassian/jira-route-entry';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import type {
	ConsolidationState,
	TenantInfo,
} from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status/src/services/index.tsx';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	legacyPersonalSettingsRoute,
	generalPersonalSettingsRoute,
	notificationsPersonalSettingsRoute,
	opsNotificationsPersonalSettingsRoute,
} from '@atlassian/jira-router-routes-personal-settings-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import type GeneralPersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-general';
import type PersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-legacy';
import { personalSettingsNotificationsEntryPoint } from '@atlassian/jira-spa-apps-personal-settings-notifications/entrypoint';
import type OpsNotificationsPersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-ops-notifications';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';

const LazyLegacyPersonalSettings = lazyForPaint<typeof PersonalSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings", jiraSpaEntry: "async-personal-settings" */ '@atlassian/jira-spa-apps-personal-settings-legacy'
		),
	),
);

const personalSettingsNotificationsEntryPointPage = createPageEntryPoint({
	main: personalSettingsNotificationsEntryPoint,
	topNavigationMenuId: MENU_ID.NOTIFICATIONS,
});

const LazyGeneralPersonalSettings = lazyForPaint<typeof GeneralPersonalSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings-general", jiraSpaEntry: "async-personal-settings-general" */ '@atlassian/jira-spa-apps-personal-settings-general'
		),
	),
);
const LazyOpsNotificationsPersonalSettings = lazyForPaint<
	typeof OpsNotificationsPersonalSettingsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings-ops-notifications", jiraSpaEntry: "async-personal-settings-ops-notifications" */ '@atlassian/jira-spa-apps-personal-settings-ops-notifications'
		),
	),
);

// Redefined here since it is not exported from RRR.
type ResourceDependencies = {
	[type: string]: RouteResourceResponse;
};

const checkConsolidationOnRouteDependency = (dependencies: ResourceDependencies) => {
	const { data } = dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return checkShouldUseJSM(data as ConsolidationState | TenantInfo | null);
};

export const LegacyPersonalSettingsComponent = () => (
	<LazyPage Page={LazyLegacyPersonalSettings} pageId="personal-settings" shouldShowSpinner />
);

export const GeneralPersonalSettingsComponent = () => (
	<LazyPage
		Page={LazyGeneralPersonalSettings}
		pageId="personal-settings-general"
		shouldShowSpinner
	/>
);

export const OpsNotificationsPersonalSettingsComponent = () => (
	<LazyPage
		Page={LazyOpsNotificationsPersonalSettings}
		pageId="personal-settings-notifications"
		shouldShowSpinner
	/>
);

const getLabsResources = () => [
	newIssueViewTransitionLockInStatusResource,
	resourceWithCondition2(() => !isFedRamp(), nextBestTaskFeatureResource),
];

export const personalSettingsRouteEntry = createEntry(legacyPersonalSettingsRoute, () => ({
    component: componentWithFF(
		'sea-1973-personal-settings-sidebar',
		() => <ScreenRedirect to={GENERAL_PERSONAL_SETTINGS_PATH} />,
		LegacyPersonalSettingsComponent,
	),

    layout: globalLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
		menuId: MENU_ID.PROFILE,
	},

    resources: [
		...getNavigationResources(),
		getConsolidationStateResource(),
		createContactsResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		createNotificationPreferencesResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		eocUserResource,
		createMuteResource(checkConsolidationOnRouteDependency, [CONSOLIDATION_STATE_RESOURCE_TYPE]),
		createQuietHoursResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		createForwardingRulesResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		...getLabsResources(),
	],

    forPaint: [LazyAtlassianNavigation, LazyLegacyPersonalSettings]
}));

export const generalPersonalSettingsRouteEntry = createEntry(generalPersonalSettingsRoute, () => ({
    component: componentWithFF(
		'sea-1973-personal-settings-sidebar',
		GeneralPersonalSettingsComponent,
		() => <ScreenRedirect to={LEGACY_PERSONAL_SETTINGS_PATH} />,
	),

    layout: globalLayout,

    navigation: {
		sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
		menuId: MENU_ID.PROFILE,
		sidebar: AsyncPersonalSettingsSidebar,
	},

    resources: [...getNavigationResources(), getConsolidationStateResource(), ...getLabsResources()],
    forPaint: [LazyAtlassianNavigation, LazyGeneralPersonalSettings]
}));

export const notificationsPersonalSettingsRouteEntry = createEntry(
	notificationsPersonalSettingsRoute,
	() => ({
        component: () => <ScreenRedirect to={getNotificationsPersonalSettingsPath()} />,
        layout: globalLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},

        resources: [...getNavigationResources(), getConsolidationStateResource()],
        pageId: 'personal-settings-notifications',

        entryPoint() {
			return ff('sea-1973-notifications-hub')
				? personalSettingsNotificationsEntryPointPage
				: undefined;
		}
    }),
);

export const personalSettingsRouteEntries = [
	personalSettingsRouteEntry,
	generalPersonalSettingsRouteEntry,
	notificationsPersonalSettingsRouteEntry,
	createEntry(opsNotificationsPersonalSettingsRoute, () => ({
        component: componentWithFF(
			'sea-1973-personal-settings-sidebar',
			OpsNotificationsPersonalSettingsComponent,
			() => <ScreenRedirect to={LEGACY_PERSONAL_SETTINGS_PATH} />,
		),

        layout: globalLayout,

        navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},

        resources: [
			...getNavigationResources(),
			getConsolidationStateResource(),
			createContactsResource(),
			createNotificationPreferencesResource(),
			eocUserResource,
			createMuteResource(),
			createQuietHoursResource(),
			createForwardingRulesResource(),
		],

        forPaint: [LazyAtlassianNavigation, LazyOpsNotificationsPersonalSettings]
    })),
];
