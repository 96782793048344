import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { resources as roleBasedNotificationsResources } from '@atlassian/eoc-role-based-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import {
	roleBasedNotificationsRoute,
	roleBasedNotificationsDetailRoute,
} from '@atlassian/jira-router-routes-eoc-role-based-notifications-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyRoleBasedNotifications = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-role-based-notifications", jiraSpaEntry: "async-jira-eoc-role-based-notifications" */ '@atlassian/jira-eoc-role-based-notifications'
	).then((module) => module.RoleBasedNotificationsPage),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyRoleBasedNotificationDetail = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-role-based-notifications-detail", jiraSpaEntry: "async-jira-eoc-role-based-notifications-detail" */ '@atlassian/jira-eoc-role-based-notifications'
	).then((module) => module.RoleBasedNotificationDetail),
);

export const RoleBasedNotificationsComponent = () => (
	<LazyPage
		Page={LazyRoleBasedNotifications}
		pageId="async-jira-eoc-role-based-notifications"
		shouldShowSpinner={false}
	/>
);

export const RoleBasedNotificationDetail = () => (
	<LazyPage
		Page={LazyRoleBasedNotificationDetail}
		pageId="async-jira-eoc-role-based-notifications"
		shouldShowSpinner={false}
	/>
);

export const roleBasedNotificationsRouteEntry = createEntry(roleBasedNotificationsRoute, () => ({
    component: RoleBasedNotificationsComponent,
    layout: globalSettingsLayout,

    resources: [
		...getNavigationResources(),
		roleBasedNotificationsResources[0],
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

    navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},

    forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyRoleBasedNotifications,
	]
}));

export const roleBasedNotificationDetailRouteEntry = createEntry(
	roleBasedNotificationsDetailRoute,
	() => ({
        component: RoleBasedNotificationsComponent,
        layout: globalSettingsLayout,

        // @ts-expect-error: 'slot' should be expected because it is being used inside platform package to show the slot inside a drawer
        slot: RoleBasedNotificationDetail,

        resources: [
			...getNavigationResources(),
			roleBasedNotificationsResources[0],
			roleBasedNotificationsResources[1],
			getConsolidationStateResource(),
			navigationSidebarGlobalResource,
		],

        navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

        forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyRoleBasedNotifications,
		]
    }),
);

export const eocRoleBasedNotificationsRouteEntries = [
	roleBasedNotificationsRouteEntry,
	roleBasedNotificationDetailRouteEntry,
];
