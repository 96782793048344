import React from 'react';
import AppBase from '@atlassian/jira-app-base';
import type { Breadcrumb } from './model/types';
import BreadcrumbNav from './view/view.tsx';

type Props = {
	isVisible: boolean;
	items: Breadcrumb[];
};

export default function BreadcrumbNavApp(props: Props) {
	const { isVisible, items } = props;
	return (
		<AppBase>
			<BreadcrumbNav isVisible={isVisible} items={items} />
		</AppBase>
	);
}
