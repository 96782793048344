import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { softwarePlanTimelineEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/ui/embed';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type PlanTimelineType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanTimelineEmbed = lazyForPaint<typeof PlanTimelineType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-timeline-embed", jiraSpaEntry: "async-plan-timeline-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline'
			),
		),
	{ ssr: false },
);

const PlanTimelineEmbedPage = () => (
	<LazyPage Page={LazyPlanTimelineEmbed} pageId="plan-timeline-embed" shouldShowSpinner />
);

export const arjTimelineEmbedRouteEntry: Route = createEntry(softwarePlanTimelineEmbedRoute, () => ({
    component: componentWithFG('smart_links_for_plans', PlanTimelineEmbedPage, ErrorPagesNotFound),

    layout: createLayout({
		isChromeless: true,
	}),

    resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
	],

    preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

    forPaint: [LazyPlanTimelineEmbed]
}));
