import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_RAPIDBOARDS_WRM_INITIAL_ALL_DATA } from '../../constants';
import { isBoardRoute, isBacklogRoute } from './utils/is-route.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardDataResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_WRM_INITIAL_ALL_DATA,
	getKey: ({ match, query, route }) => {
		const { name = '' } = route || {};
		const boardId = match.params.boardId != null ? match.params.boardId : query.rapidView;
		const { config } = query;
		const hasConfig = Boolean(config);

		let key = 'rapidBoardData';
		if (isBoardRoute(name)) {
			key = `boardData-${boardId}::hasConfig=${hasConfig}`;
		} else if (isBacklogRoute(name)) {
			key = `backlogData-${boardId}::hasConfig=${hasConfig}`;
		}
		return key;
	},
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-data-api", jiraSpaEntry: "async-resource-rapidboards-data-api" */ './get-data'
		),
	maxAge: 0, // Keeping it 0 for initial release in order to avoid any caching issues.
	isBrowserOnly: true,
});
