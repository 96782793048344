import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type PlanRoadmapType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-report';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

/**
 * Report page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanReport = lazyForPaint<typeof PlanRoadmapType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-report", jiraSpaEntry: "async-plan-report" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-report'
			),
		),
	{ ssr: false },
);

const PlanReport = () => <LazyPage Page={LazyPlanReport} pageId="plan-report" shouldShowSpinner />;

export default PlanReport;
