import { fg } from '@atlassian/jira-feature-gating';
import type { Action } from '@atlassian/react-sweet-state';
import { KEY_OPEN, QUICKSTART_UI_STATUS, getBackendVisibilityKey } from '../../../common/constants';
import { pushToBackend } from '../../../common/utils/push-to-backend.tsx';
import type { State } from '../../model/types';
import { getQuickstartTypeSelector } from '../../model/utils';
import { setLocalStorageItem } from '../../utils';

// TODO: Remove this and replace with set visibility action
export const nextSetChecklistIsOpen =
	(isOpen: boolean): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		const currentIsOpen = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.OPEN;
		const currentIsMinimised = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.MINIMIZED;
		if ((currentIsOpen && isOpen) || (currentIsMinimised && !isOpen)) {
			// if state has not been updated we just return
			return;
		}
		const quickstartStatus = isOpen ? QUICKSTART_UI_STATUS.OPEN : QUICKSTART_UI_STATUS.MINIMIZED;

		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: quickstartStatus,
			},
			hasNudgeActive: false,
			isFocusedOn: isOpen,
		});

		pushToBackend({
			key: getBackendVisibilityKey(quickstartType),
			value: quickstartStatus,
		});
		if (fg('jsw_onboarding_reactufo_qs')) {
			setLocalStorageItem(`${quickstartType}.visibility`, quickstartStatus);
		}

		// Delete all code below when cleaning up isOpen in state
		pushToBackend({
			key: KEY_OPEN,
			value: isOpen,
		});
	};
