import type { Action } from '@atlassian/react-sweet-state';
import type { TaskId } from '../../../common/types';
import { pushToBackend } from '../../../common/utils/push-to-backend.tsx';
import type { State } from '../../model/types';

// TODO: we will be calling markAsCompleteWithAutoExpand which will call this
// instead of the old markComplete action (nudge click + click on primary CTAs in button)

export const nextMarkAsCompletedTask =
	(taskId: TaskId): Action<State> =>
	({ getState, setState }) => {
		// When a task is complete:
		// Check to see if the task is complete in the completedKeys already
		// update the checklist dict (maybe should be at a different point or use setState)
		const { completedTaskList } = getState();

		if (completedTaskList.includes(taskId)) {
			return;
		}

		// update the backend with the KEY and true
		pushToBackend({
			key: taskId,
			value: true,
		});

		// update itemsList to reflect checked status. clone -> update -> override
		setState({
			completedTaskList: [...completedTaskList, taskId],
		});
	};
