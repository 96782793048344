import { createSubscriber, createContainer, createHook } from '@atlassian/react-sweet-state';
import type { Actions } from './actions';
import Store from './basket';
import type { State, Props } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default } from './basket';

export const ConnectAppsSubscriber = createSubscriber<State, Actions>(Store, {
	displayName: 'ConnectAppsSubscriber',
});

export const useConnectApps = createHook(Store);

export const ConnectAppsContainer = createContainer<State, Actions, Props>(Store);
