import React, { memo } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import type { SidebarUser as UserSidebarType } from './ui/index.tsx';

const LazyUserSidebar = lazyAfterPaint<typeof UserSidebarType>(() => {
	setPerformanceMarkLoadStart();

	return import(/* webpackChunkName: "async-sidebar-user" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="user">
		<LazyUserSidebar />
	</SidebarBoundary>
));

UserSidebar.displayName = 'UserSidebar';

export default UserSidebar;
