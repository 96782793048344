import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { ff } from '@atlassian/jira-feature-flagging';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { JiraProjectsTemplateListRightPanel } from '@atlassian/jira-growth-recommendations-in-project-list';
import { serverRenderedRightSidebar } from '@atlassian/jira-growth-recommendations-in-project-list/src/ui/ssr-experiment/entrypoint';
import { homepageLayout } from '@atlassian/jira-homepage-layout';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { projectsDirectoryPageResource } from '@atlassian/jira-router-resources-project-directories/src/services/index.tsx';
import {
	projectsDirectoryRoute,
	manageProjectsDirectoryRoute,
	projectsDirectoryRedirectRoute,
	projectsDirectoryLegacyPathRedirectRoute,
	projectsDirectoryManageRedirectRoute,
	projectsDirectoryManageRootRedirectRoute,
} from '@atlassian/jira-router-routes-directories-routes/src/services/projects/index.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { ProjectsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/index.tsx';
import { projectsDirectoryEntryPoint } from '@atlassian/jira-spa-apps-directories-projects-v3/entrypoint';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const ProjectsDirectoryRedirect = () => <ScreenRedirect to="/jira/projects" />;

export const ProjectsDirectoryManageRedirect = () => (
	<ScreenRedirect to="/jira/settings/projects/manage" />
);

const LazyProjectsDirectory = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-projects-directory-v3", jiraSpaEntry: "async-projects-directory-v3" */ '@atlassian/jira-spa-apps-directories-projects-v3'
		),
	),
);

const LazyAdminProjectsDirectory = () => <LazyProjectsDirectory isAdminProjectDirectoryRoute />;

export const ProjectsDirectoryManageComponent = () => (
	<LazyPage
		Page={LazyAdminProjectsDirectory}
		pageId="projects-directory-manage-v3"
		shouldShowSpinner={false}
	/>
);

const rightSidebars = () => {
	if (__SERVER__) return [];

	return ff('server-render-projects-directory-sidebar_rj9ki')
		? []
		: [JiraProjectsTemplateListRightPanel];
};

const projectsDirectoryHomepageLayout = () =>
	composeLayouts(homepageLayout, {
		rightSidebars: rightSidebars(),
	});

const projectsDirectorySettingsLayout = () =>
	composeLayouts(globalSettingsLayout, {
		rightSidebars: rightSidebars(),
	});

const ffOffProjectsDirectoryPageEntryPoint = createPageEntryPoint({
	main: projectsDirectoryEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	rightSidebar: undefined,
});

const ffOnProjectsDirectoryPageEntryPoint = createPageEntryPoint({
	main: projectsDirectoryEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	rightSidebar: serverRenderedRightSidebar,
});

export const projectsDirectoryRouteEntry = createEntry(projectsDirectoryRoute, () => ({
    entryPoint() {
		return ff('server-render-projects-directory-sidebar_rj9ki')
			? ffOnProjectsDirectoryPageEntryPoint
			: ffOffProjectsDirectoryPageEntryPoint;
	},

    skeleton: ProjectsDirectorySkeleton,
    layout: projectsDirectoryHomepageLayout(),

    navigation: {
		menuId: MENU_ID.PROJECTS,
	},

    resources: [...getNavigationResources()],
    ufoName: 'projects-directory-v3'
}));

const adminProjectDirectoryResources = [
	navigationSidebarGlobalResource,
	projectsDirectoryPageResource,
];

export const manageProjectsDirectoryRouteEntry = createEntry(manageProjectsDirectoryRoute, () => ({
    component: ProjectsDirectoryManageComponent,
    skeleton: ProjectsDirectorySkeleton,
    layout: projectsDirectorySettingsLayout(),

    navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProjects,
	},

    resources: [...getNavigationResources(), ...adminProjectDirectoryResources],
    forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory],
    ufoName: 'projects-directory-v3-manage'
}));

export const projectsDirectoryRouteEntries = [
	createEntry(projectsDirectoryRedirectRoute, () => ({
        component: ProjectsDirectoryRedirect,
        skeleton: ProjectsDirectorySkeleton,
        isRedirect: true,
        layout: projectsDirectoryHomepageLayout(),

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        resources: [...getNavigationResources(), projectsDirectoryPageResource],
        forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory]
    })),
	createEntry(projectsDirectoryLegacyPathRedirectRoute, () => ({
        component: ProjectsDirectoryRedirect,
        skeleton: ProjectsDirectorySkeleton,
        isRedirect: true,
        layout: projectsDirectoryHomepageLayout(),

        navigation: {
			menuId: MENU_ID.PROJECTS,
		},

        resources: [...getNavigationResources(), projectsDirectoryPageResource],
        forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory]
    })),
	projectsDirectoryRouteEntry,

	createEntry(projectsDirectoryManageRedirectRoute, () => ({
        component: ProjectsDirectoryManageRedirect,
        skeleton: ProjectsDirectorySkeleton,
        isRedirect: true,
        layout: projectsDirectorySettingsLayout(),

        navigation: {
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},

        resources: [...getNavigationResources(), ...adminProjectDirectoryResources],
        forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory]
    })),
	manageProjectsDirectoryRouteEntry,
	createEntry(projectsDirectoryManageRootRedirectRoute, () => ({
        component: ProjectsDirectoryManageRedirect,
        skeleton: ProjectsDirectorySkeleton,
        isRedirect: true,
        layout: projectsDirectorySettingsLayout(),

        navigation: {
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},

        resources: [...getNavigationResources(), ...adminProjectDirectoryResources],
        forPaint: [LazyAtlassianNavigation, LazyProjectsDirectory]
    })),
];
