/* eslint-disable jira/import-whitelist */
import { loadQuery, type PreloadedQuery } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import {
	isCustomFilter,
	isFilterId,
} from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import {
	getMaxIssuesPerPage,
	ISSUE_SEARCH_NAMESPACE,
	MAX_AMOUNT_OF_COLUMNS,
	NATURAL_LANGUAGE_TO_JQL,
} from '@atlassian/jira-issue-navigator/src/common/constants';
import { createAri } from '@atlassian/jira-platform-ari';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises';
import { RELAY_RESOURCE_TYPE } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import HeaderFilterRelayQuery, {
	type main_FilterQuery as FilterRelayQueryType,
} from '@atlassian/jira-relay/src/__generated__/main_FilterQuery.graphql';
import IssueNavigatorRelayQuery, {
	type JiraAtlassianIntelligenceFeatureEnum,
	type main_IssueNavigatorQuery as IssueNavigatorRelayQueryType,
	type main_IssueNavigatorQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_ISSUE_NAVIGATOR_RELAY,
	RESOURCE_TYPE_ISSUE_NAVIGATOR_FILTER_RELAY,
} from './constants';
import { getJqlOrFilterId, getParams } from './controllers/get-data/utils';

/**
 * We are using `createResource` instead of `createRelayResource` for two main reasons:
 * 1. The `getKey` provided by `createRelayResource` triggers this resource to reload every time a URL parameters change
 *    causes the query variables to change, preventing us from using our own custom reload hooks that are further down
 *    in Issue Navigator. This results in a full-page loading state any time one of the page params changes.
 * 2. Disabling this resource for SSR+Anonymous users (see comment below in `getData`)
 */
export const issueNavigatorRelayResource = createResource<
	PreloadedQuery<IssueNavigatorRelayQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_ISSUE_NAVIGATOR_RELAY}`,
	getKey: () => 'issue-navigator-relay',
	// @ts-expect-error - might return null
	getData: async (routerContext, { tenantContext }) => {
		// SSR/AGG does not currently work for anonymous users and returns a 403. We should enable anonymous SSR once
		// the underlying auth issues have been fixed. See more details here https://hello.atlassian.net/wiki/spaces/~hkim/pages/1853841608/Why+are+Jira+SSR+requests+to+AGG+not+working+for+anonymous+users
		if (__SERVER__ && tenantContext.isAnonymous) {
			return null;
		}

		const { projectKey, issueKey, jql: jqlParam, defaultJql, filter } = getParams(routerContext);

		const jqlOrFilterId = getJqlOrFilterId(defaultJql, jqlParam, filter, projectKey);

		const maxIssuesPerPage = getMaxIssuesPerPage();
		const variables: main_IssueNavigatorQuery$variables = {
			cloudId: tenantContext.cloudId,
			first: issueKey == null ? maxIssuesPerPage : null,
			issueSearchInput: jqlOrFilterId,
			namespace: ISSUE_SEARCH_NAMESPACE,
			after: null,
			options: { issueKey },
			fieldSetIds: [],
			shouldQueryFieldSetsById: false,
			filterId: Boolean(filter) && isCustomFilter(filter) ? filter : null,
			amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
			atlassianIntelligenceProductFeature:
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				NATURAL_LANGUAGE_TO_JQL as JiraAtlassianIntelligenceFeatureEnum,
			queryStatusForIssuekeyStrikethrough: fg('jiv-18659-query-status-for-issuekey-strikethrough'),
		};

		const queryReference = loadQuery<IssueNavigatorRelayQueryType>(
			getRelayEnvironment(),
			IssueNavigatorRelayQuery,
			variables,
			{
				fetchPolicy: 'network-only',
				networkCacheConfig: {
					metadata: { META_SLOW_ENDPOINT: true },
				},
			},
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
});

/**
 * We are using `createResource` instead of `createRelayResource` for two main reasons:
 * 1. The `getKey` provided by `createRelayResource` triggers this resource to reload every time a URL parameters change
 *    causes the query variables to change, preventing us from using our own custom reload hooks that are further down
 *    in Issue Navigator. This results in a full-page loading state any time one of the page params changes.
 * 2. Disabling this resource for SSR+Anonymous users (see comment below in `getData`)
 */
export const issueNavigatorFilterRelayResource = createResource<
	PreloadedQuery<FilterRelayQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_ISSUE_NAVIGATOR_FILTER_RELAY}`,
	getKey: () => 'issue-navigator-filter-relay',
	// @ts-expect-error - might return null
	getData: async (routerContext, { tenantContext }) => {
		// SSR/AGG does not currently work for anonymous users and returns a 403. We should enable anonymous SSR once
		// the underlying auth issues have been fixed. See more details here https://hello.atlassian.net/wiki/spaces/~hkim/pages/1853841608/Why+are+Jira+SSR+requests+to+AGG+not+working+for+anonymous+users
		if (__SERVER__ && tenantContext.isAnonymous) {
			return null;
		}

		const { filter } = getParams(routerContext);
		const includeFilter = Boolean(filter) && isFilterId(filter);

		const variables = {
			includeFilter,
			filterAri: includeFilter
				? createAri({
						resourceOwner: 'jira',
						cloudId: tenantContext.cloudId,
						resourceType: 'filter',
						resourceId: filter,
						activationId: tenantContext.activationId,
					})
				: '',
		};
		const options = { fetchPolicy: includeFilter ? 'network-only' : 'store-only' } as const;

		const queryReference = loadQuery(
			getRelayEnvironment(),
			HeaderFilterRelayQuery,
			variables,
			options,
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
});
