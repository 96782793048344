import type { IntlShape } from '@atlassian/jira-intl';
import {
	INIT,
	LOADING,
	FAILURE,
	SUCCESS,
} from '@atlassian/jira-project-settings-apps-issue-type-page-product-features/src/common/constants.tsx';
import type { ProductFeatures } from '@atlassian/jira-project-settings-apps-issue-type-page-product-features/src/common/types.tsx';
import type { IssueLayoutOwner } from '@atlassian/jira-project-settings-apps-issue-type-page-product-features/src/common/types/issue/issue-layout-owner.tsx';
import {
	createStore,
	createHook,
	createSubscriber,
	createActionsHook,
	type SubscriberComponent,
	type Action,
	type HookActionsFunction,
} from '@atlassian/react-sweet-state';
import { queryIssueTypesNavigationItems } from '../../services/issue-types-navigation-items';
import type { State, SelectorState } from '../../types';
import { getIssueTypeNavigationItemsLevels, getIsIssueTypeLimitReached } from './utils';

const initialState: State = {
	projectId: null,
	asyncState: INIT,
	data: {
		limitPerProject: null,
		issueTypes: [],
	},
	addIssueTypeTooltipMessage: '',
};

const load =
	(projectId: number, productFeatures: ProductFeatures, intl: IntlShape): Action<State> =>
	async ({ setState, getState }) => {
		try {
			const currentState = getState();
			if (currentState.asyncState === LOADING && projectId === currentState.projectId) {
				return;
			}

			setState({
				...(projectId === currentState.projectId ? currentState : initialState),
				projectId,
				asyncState: LOADING,
			});

			const result = await queryIssueTypesNavigationItems(projectId, productFeatures);
			if (getState().projectId !== projectId) {
				return;
			}

			const { limitPerProject, data } = result.data.simplifiedProject.issueTypeCollection;

			setState({
				projectId,
				asyncState: SUCCESS,
				data: {
					limitPerProject,
					issueTypes: data,
				},
				addIssueTypeTooltipMessage: intl.formatMessage(
					productFeatures.navigationMessages.addIssueTypeDisabledTooltip,
					{ limit: limitPerProject },
				),
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				asyncState: FAILURE,
			});
		}
	};

const remove =
	(issueTypeId: number): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();
		if (state.asyncState !== SUCCESS) {
			return;
		}

		setState({
			...state,
			data: {
				...state.data,
				issueTypes: state.data.issueTypes.filter(({ id }) => id !== issueTypeId),
			},
		});
	};

const update =
	(layoutOwners: IssueLayoutOwner[]): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();
		if (state.asyncState !== SUCCESS) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newDataById: Record<string, any> = {};
		for (let i = 0; i < layoutOwners.length; i += 1) {
			const { data } = layoutOwners[i];
			newDataById[data.id] = {
				name: data.name,
				avatarId: data.avatarId,
				icon: data.iconUrl,
			};
		}

		const issueTypes = state.data.issueTypes.map((issueType) => {
			if (!newDataById[issueType.id]) {
				return issueType;
			}

			const { name, avatarId } = newDataById[issueType.id];

			return {
				...issueType,
				name,
				avatarId: avatarId || issueType.avatarId,
			};
		});

		setState({
			...state,
			data: {
				...state.data,
				issueTypes,
			},
		});
	};

const actions = {
	load,
	remove,
	update,
} as const;

const selectIssueTypesNavigationItemsData = ({
	projectId,
	asyncState,
	data,
	addIssueTypeTooltipMessage,
}: State): SelectorState => {
	const { issueTypes, limitPerProject } = data;

	const { topLevel, baseLevel, bottomLevel } = getIssueTypeNavigationItemsLevels(issueTypes);
	const isIssueTypeLimitReached = getIsIssueTypeLimitReached(limitPerProject, issueTypes.length);
	const addIssueTypeTooltip = isIssueTypeLimitReached ? addIssueTypeTooltipMessage : '';

	return {
		asyncState,
		projectId,
		isAddIssueTypeDisabled: asyncState !== SUCCESS || isIssueTypeLimitReached,
		addIssueTypeTooltip,
		topLevel,
		baseLevel,
		bottomLevel,
	};
};

// Made it const for visibility. Apparently, store name must be unique or it breaks the library
const STORE_NAME = 'issue-type-page-navigation-items-new';

const Store = createStore<State, typeof actions>({
	name: STORE_NAME,
	initialState,
	actions,
});

const useIssueTypesNavigationItems = createHook(Store, {
	selector: selectIssueTypesNavigationItemsData,
});

// @ts-expect-error - TS2322 - Type 'HookActionsFunction<BoundActions<State, { readonly load: (projectId: number, productFeatures: Readonly<{ issueTypeHierarchy: boolean; addIssueTypeMessages: AddIssueTypeMessages | undefined; issueTypeTemplates: TemplateIntl[]; ... 43 more ...; countOfDisabledAppsEnabled: boolean; }>, intl: IntlShape) => Action<...>...' is not assignable to type 'HookActionsFunction<{ readonly load: (projectId: number, productFeatures: Readonly<{ issueTypeHierarchy: boolean; addIssueTypeMessages: AddIssueTypeMessages | undefined; issueTypeTemplates: TemplateIntl[]; ... 43 more ...; countOfDisabledAppsEnabled: boolean; }>, intl: IntlShape) => Action<...>; readonly remove: (...'.
const useIssueTypesNavigationItemsActions: HookActionsFunction<typeof actions> =
	createActionsHook(Store);

const IssueTypesNavigationItemsSubscriber: SubscriberComponent<SelectorState, typeof actions> =
	createSubscriber(Store, {
		selector: selectIssueTypesNavigationItemsData,
	});

export {
	actions,
	initialState,
	selectIssueTypesNavigationItemsData,
	useIssueTypesNavigationItems,
	useIssueTypesNavigationItemsActions,
	IssueTypesNavigationItemsSubscriber,
};
