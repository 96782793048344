/**
 * @generated SignedSource<<0a2c47ac432e504d8693a67627d1b346>>
 * @relayHash 9114d91fcebea81ad465cacf122c3ea9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 35983d7f2b4662d37dd74cd8f579a25c2cf6564d608ff39943ea5bc0a20cb909

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsQuery } from './srcVirtualAgentBrowseIntentsQuery.graphql';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "35983d7f2b4662d37dd74cd8f579a25c2cf6564d608ff39943ea5bc0a20cb909",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
