import React, { useState, createContext, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { DEFAULT_CONTAINER_QUERY_OBSERVER_STATE } from '@atlassian/jira-issue-view-container-query-observer/src/common/constants.tsx';
import { ContainerQueryObserver } from '@atlassian/jira-issue-view-container-query-observer/src/ui/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import {
	setCachedIsCompactValue,
	getCachedIsCompactValue,
	invalidateCacheIfRouteChanged,
} from './utils';

export const CompactModeContext = createContext<boolean>(false);
const { Provider, Consumer } = CompactModeContext;

export const COMPACT_LAYOUT_MAX_WIDTH = 700;
const COMPACT_LAYOUT_MAX_WIDTH_QUERY = `(max-width: ${COMPACT_LAYOUT_MAX_WIDTH}px)`;

type Props = {
	children: ReactNode;
};

/**
 * A utility hook which returns a key based on the route
 */
function useRouteKey(): string {
	const [routeState] = useRouter();

	const {
		match: { path, params },
	} = routeState;

	// handle case where JSM doesn't have an issueKey on their route
	const hasIssueKey = params?.issueKey !== undefined;

	return `${path}${hasIssueKey ? '/hasIssueKey' : ''}`;
}

export const CompactModeProvider = ({ children }: Props) => {
	const key = useRouteKey();

	invalidateCacheIfRouteChanged(key);

	let defaultState = getCachedIsCompactValue(key);

	// in case there is no state change triggered
	if (defaultState === undefined) {
		defaultState = DEFAULT_CONTAINER_QUERY_OBSERVER_STATE;
		setCachedIsCompactValue(defaultState, key);
	}

	const [isCompact, setIsCompact] = useState<boolean>(defaultState);

	return (
		<StyledWrapper>
			<ContainerQueryObserver
				query={COMPACT_LAYOUT_MAX_WIDTH_QUERY}
				onChange={(state: boolean) => {
					setCachedIsCompactValue(state, key);
					setIsCompact(state);
				}}
				defaultState={defaultState}
			/>
			<Provider value={isCompact}>{children}</Provider>
		</StyledWrapper>
	);
};

export const CompactModeConsumer = Consumer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});
