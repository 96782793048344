import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4ForDogfooding } from './get-will-show-nav4-for-dogfooding';
import { getWillShowNav4ForEap } from './get-will-show-nav4-for-eap';

// Returns true if the user should see nav4 instead of nav3.
// We don't inline usage of feature flags/gates as our logic to show or no show will become complex at times and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design+draft#Decision-points
// All changes to this function should be replicated in the backend (see readme.md).
// All changes to this function should be communicated to Atlassian Home (see readme.md).
export const getWillShowNav4 = () =>
	fg('jira_nav4') && (getWillShowNav4ForDogfooding() || getWillShowNav4ForEap());
