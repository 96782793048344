import { ISSUES_BY_ASSIGNEE_FRAGMENT, ISSUES_BY_TYPE_FRAGMENT } from '../constants';

export const SUMMARY_PAGE_DATA_QUERY = `
  query JiraBusinessSummaryPageData($cloudId: ID!, $jqlContext: String!, $doneThisWeekJql: String!, $updatedThisWeekJql: String!, $createdThisWeekJql: String!, $dueThisWeekJql: String!, $issuesByStatusJql: String!, $issuesByPriorityJql: String!, $issuesByAssigneeJql: String!, $issuesByTypeJql: String!, $pagedDataPageSize: Int!, $projectKey: String!, $isWorkflowsAndIssueTypesCountEnabled: Boolean!) {
    jira {
      jqlBuilder(cloudId: $cloudId) {
        status: fieldValues(
          jqlTerm: "status"
          jqlContext: $jqlContext
          viewContext: JWM
        ) {
          edges {
            node {
              jqlTerm
              displayName
            }
          }
        }
      }
      doneThisWeek: jwmViewItems(cloudId: $cloudId, jql: $doneThisWeekJql, first: 0) {
        ... on JiraWorkManagementViewItemConnection {
          totalCount
        }
      }
      updatedThisWeek: jwmViewItems(cloudId: $cloudId, jql: $updatedThisWeekJql, first: 0) {
        ... on JiraWorkManagementViewItemConnection {
          totalCount
        }
      }
      createdThisWeek: jwmViewItems(cloudId: $cloudId, jql: $createdThisWeekJql, first: 0) {
        ... on JiraWorkManagementViewItemConnection {
          totalCount
        }
      }
      dueThisWeek: jwmViewItems(cloudId: $cloudId, jql: $dueThisWeekJql, first: 0) {
        ... on JiraWorkManagementViewItemConnection {
          totalCount
        }
      }
      totalUnresolvedIssues: jwmViewItems(cloudId: $cloudId, jql: $issuesByAssigneeJql, first: 0) {
        ... on JiraWorkManagementViewItemConnection {
          totalCount
        }
      }
      projectIssueTypesCount: jiraProjectByKey(key: $projectKey, cloudId: $cloudId) @include(if: $isWorkflowsAndIssueTypesCountEnabled) {
        issueTypes {
          totalCount
        }
      }
    }
    issuesByStatus: jwmItemGroups(jql: $issuesByStatusJql, groupByFieldId: "status", fieldIds: [], first: 50) {
      nodes {
        id
        field {
          ...on StatusField {
              name
              category {
                key
              }
          }
        }
        totalCount
      }
    }
    issuesByPriority: jwmItemGroups(jql: $issuesByPriorityJql, groupByFieldId: "priority", fieldIds: [], first: 50) {
      nodes {
        id
        field {
          ...on PriorityField {
            iconUrl
            name
          }
        }
        totalCount
      }
    }
    issuesByAssignee: jwmItemGroups(jql: $issuesByAssigneeJql, first: $pagedDataPageSize, fieldIds: [], groupByFieldId: "assignee", sortBy: {field: GROUP_COUNT, direction: DESC}) {
      ...IssuesByAssigneeFragment
    }
    issuesByType: jwmItemGroups(jql: $issuesByTypeJql, first: $pagedDataPageSize, fieldIds: [], groupByFieldId: "issuetype", sortBy: {field: GROUP_COUNT, direction: DESC}) {
      ...IssuesByTypeFragment
    }
    worklowsCount: jwmWorkflowWithIssueTypes(projectIdOrKey: $projectKey) @include(if: $isWorkflowsAndIssueTypesCountEnabled) {
      totalCount
    }
  }

  ${ISSUES_BY_ASSIGNEE_FRAGMENT}
  ${ISSUES_BY_TYPE_FRAGMENT}
`;
