import React, { useEffect } from 'react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Section } from '@atlaskit/side-navigation';
import { isFedRamp } from '@atlassian/atl-context';
import { useCohortDetails } from '@atlassian/jira-c2c-migrations-common-utils/src/services/cohort-details/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { TitleHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/title-header/main.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { messages } from './messages';

export const MigrationsMenu = () => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const [{ data }, { fetchCohortDetails }] = useCohortDetails();
	const baseMigrateCloudUrl = '/jira/settings/system/migration';

	const isCohort4Enabled = ff('change-management-cohort-4-ff_4bpld');

	useEffect(() => {
		fetchCohortDetails(cloudId);
	}, [fetchCohortDetails, cloudId]);

	if (isFedRamp()) return;
	if (
		data?.isCohortEnabled &&
		(data?.cohortType === 'NEVER_USED_C2C_JIRA' ||
			data?.cohortType === 'NOT_USED_C2C_JIRA_IN_3_MONTHS' ||
			data?.hasOptedIn ||
			data?.status === 'IN_PROGRESS' ||
			data?.status === 'FAILED' ||
			isCohort4Enabled)
	) {
		return (
			<MenuItem
				analytics={{ itemId: 'migration-home' }}
				selectedOn={matchUrlPathname()}
				href={`${baseMigrateCloudUrl}/home`}
			>
				{formatMessage(messages.title)}
			</MenuItem>
		);
	}

	return (
		<>
			<TitleHeader>{formatMessage(messages.title)}</TitleHeader>
			<Section>
				<MenuItem
					analytics={{ itemId: 'migration-home' }}
					selectedOn={matchUrlPathname()}
					href={`${baseMigrateCloudUrl}/home`}
				>
					{formatMessage(messages.migrationOverview)}
				</MenuItem>
				<MenuItem
					analytics={{ itemId: 'plan-your-migration' }}
					href="https://support.atlassian.com/migration/docs/cloud-to-cloud-migration/"
					iconAfter={<ShortcutIcon size="small" label="" />}
					target="_blank"
				>
					{formatMessage(messages.planYourMigration)}
				</MenuItem>
				<MenuItem
					analytics={{ itemId: 'manage-migrations' }}
					selectedOn={matchUrlPathname()}
					href={`${baseMigrateCloudUrl}/dashboard`}
				>
					{formatMessage(messages.manageMigrations)}
				</MenuItem>
			</Section>
		</>
	);
};
